<script setup lang="ts">
import { computed, onMounted, reactive, Ref, ref, shallowReactive, ShallowUnwrapRef, UnwrapNestedRefs } from 'vue';
import Form from '@/assets/libraries/form/form';
import {
    EhicCardFields,
    InjuryForms,
    TraumaFields,
} from '@/Apps/ClaimsTravel/Interfaces/Forms/AccidentDetails/InjuryForms';
import Validation from '@/services/validation.service';
import { useTranslate } from '@/Composables/Translate';
import Option from '@/Apps/ClaimsTravel/Interfaces/Option';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import { InputOption } from '@/interfaces/InputOptionInterface';
import { FormNames as InjuryFormNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentDetails/Injury/FormNames';
import ClaimsTravelService from '@/Apps/ClaimsTravel/Services/ClaimsTravelService';
import { FieldNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentDetails/Injury/FieldNames';
import ButtonWithCallbackParams from '@/Components/Buttons/ButtonWithCallback/Enums/button.params';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import ButtonIcon from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.enum';
import ButtonIconPosition from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.position.enum';
import FormField from '@/assets/libraries/form/form-field';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import { useClaimsTravelHtml } from '@/Apps/ClaimsTravel/Composables/ClaimsTravelHtml';
import ClaimsTravelFormFields from '@/Apps/ClaimsTravel/Interfaces/ClaimsTravelFormFields';

const props = defineProps({
    subType: { type: String, required: true },
});

const emit = defineEmits<{
    (event: 'completed'): void;
}>();

const { translateForType, applyTranslationType, type, translate } = useTranslate();

const claimsTravelService: ClaimsTravelService = ClaimsTravelService.getInstance();

type FormNames = `${InjuryFormNames}`;

const hintIconHtml =
    '<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path d="M6.86001 3.07347L1.21335 12.5001C1.09693 12.7018 1.03533 12.9303 1.03467 13.1631C1.03402 13.396 1.09434 13.6249 1.20963 13.8272C1.32492 14.0294 1.49116 14.198 1.69182 14.316C1.89247 14.4341 2.12055 14.4976 2.35335 14.5001H13.6467C13.8795 14.4976 14.1076 14.4341 14.3082 14.316C14.5089 14.198 14.6751 14.0294 14.7904 13.8272C14.9057 13.6249 14.966 13.396 14.9654 13.1631C14.9647 12.9303 14.9031 12.7018 14.7867 12.5001L9.14001 3.07347C9.02117 2.87754 8.85383 2.71555 8.65414 2.60313C8.45446 2.4907 8.22917 2.43164 8.00001 2.43164C7.77086 2.43164 7.54557 2.4907 7.34588 2.60313C7.1462 2.71555 6.97886 2.87754 6.86001 3.07347V3.07347Z" stroke="#1D1F23" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>' +
    '<path d="M8 6.5V9.16667" stroke="#1D1F23" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>' +
    '<path d="M8 11.8335H8.00667" stroke="#1D1F23" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>' +
    '</svg>' +
    translated('leave_field_empty_if_no_expenses');

const forms: ShallowUnwrapRef<{ [FormKey in keyof InjuryForms]: Form<InjuryForms[FormKey]> }> = shallowReactive(
    {},
) as ShallowUnwrapRef<{
    [FormKey in keyof InjuryForms]: Form<InjuryForms[FormKey]>;
}>;
const inputOptions: UnwrapNestedRefs<Record<FormNames, Option>> = reactive({}) as UnwrapNestedRefs<
    Record<FormNames, Option>
>;

function preparePanels(): void {
    Object.values(InjuryFormNames).forEach((panelName: FormNames) => {
        inputOptions[panelName] = reactive(
            new (class implements Option {
                public enabled: boolean = true;
                public passed: boolean = false;
                public visible: boolean = claimsTravelService.storageFields.formVisibility[panelName] ?? false;
                public value: LimitedVariant = '';
                public options: InputOption[] = [];
            })(),
        );
    });

    buildHadEhicCardOptions();
}

function buildHadEhicCardOptions(): void {
    inputOptions[InjuryFormNames.EhicCard].options = [
        new InputOptionBuilder().setValue(true).setName(translate('btar_yes')).build(),
        new InputOptionBuilder().setValue(false).setName(translate('btar_no')).build(),
    ];
}

function isPanelVisible(panel: FormNames): boolean {
    return inputOptions[panel].visible;
}

function setupForms(): void {
    const requiredValidator = { required: Validation.required };

    const traumaForm: Form<TraumaFields> = new Form({ useValidationV2: true });
    traumaForm.addField(new FormField(FieldNames.Trauma, '', requiredValidator));
    forms[InjuryFormNames.Trauma] = traumaForm;

    const ehicCardForm: Form<EhicCardFields> = new Form({ useValidationV2: true });
    ehicCardForm.addField(new FormField(FieldNames.HadEhicCard, '', requiredValidator));
    ehicCardForm.addField(new FormField(FieldNames.MedicalExpensesTotal, ''));
    forms[InjuryFormNames.EhicCard] = ehicCardForm;
}

function firstFormName(): string {
    return InjuryFormNames.Trauma;
}

function scrollToLastForm(): void {
    const lastForm = Object.keys(inputOptions).findLast((form) => inputOptions[form as FormNames].visible);

    useClaimsTravelHtml().scrollToPanel(lastForm ? lastForm : firstFormName());
}

function clearForms(): void {
    Object.keys(forms).forEach((form) => {
        claimsTravelService.storageFields.formVisibility[form as keyof ClaimsTravelFormFields] = false;
        clearForm(form as FormNames);
    });
}

function clearFormsAhead(currentForm: FormNames): void {
    const currentFormIndex = Object.keys(forms).findIndex((formName) => formName === currentForm);
    const nextFormKeys: FormNames[] = Object.keys(forms).slice(
        currentFormIndex + 1,
        Object.keys(forms).length,
    ) as FormNames[];
    nextFormKeys.forEach((formName: FormNames) => {
        clearForm(formName);
        inputOptions[formName].visible = false;
        claimsTravelService.storageFields.formVisibility[formName] = false;
    });
}

function clearForm(formName: FormNames): void {
    const form: Form<TraumaFields> | Form<EhicCardFields> = forms[formName];
    switch (formName) {
        case InjuryFormNames.Trauma: {
            const traumaForm = form as Form<TraumaFields>;
            traumaForm.field(FieldNames.Trauma).setValue([]);
            break;
        }
        case InjuryFormNames.EhicCard: {
            const ehicCardForm = form as Form<EhicCardFields>;
            ehicCardForm.field(FieldNames.HadEhicCard).setValue('');
            ehicCardForm.field(FieldNames.MedicalExpensesTotal).setValue('');
            break;
        }
    }

    storeForm(formName);
}

function proceedButtonParams(): ButtonWithCallbackParams {
    return {
        title: translated('proceed'),
        textColor: ButtonTextColor.White,
        backgroundColor: ButtonBackground.Red,
        icon: ButtonIcon.LongArrowRight,
        iconPosition: ButtonIconPosition.Right,
    };
}

function onTraumaProceedClick(): void {
    proceedToNextForm(InjuryFormNames.Trauma);
}

function onEhicCardProceedClick(): void {
    emit('completed');
}

function proceedToNextForm(formName: FormNames, nextFormName: FormNames | null = null): void {
    const nextPanel = nextFormName ? nextFormName : findNextFormName(formName);
    if (nextPanel) {
        inputOptions[nextPanel].visible = true;
        claimsTravelService.storageFields.formVisibility[nextPanel] = true;
        useClaimsTravelHtml().scrollToPanel(nextPanel);
    }
}

function findNextFormName(currentForm: FormNames): FormNames | undefined {
    const currentFormIndex = Object.keys(forms).findIndex((formName) => formName === currentForm);

    return Object.keys(forms).at(currentFormIndex + 1) as FormNames | undefined;
}

function markFormsAsReady(): void {
    Object.values(forms).forEach((form) => form.setReady());
}

function translated(stringId: string): string {
    return translateForType(stringId, type());
}

function storeForm(formName: FormNames): void {
    return claimsTravelService.storeForm(formName as keyof ClaimsTravelFormFields, forms[formName]);
}

function onFormInputChange(formName: FormNames): void {
    clearFormsAhead(formName);
}

onMounted(() => {
    applyTranslationType('claims_travel');
    setupForms();
    claimsTravelService.restoreForms(forms);
    markFormsAsReady();
    preparePanels();
});

defineExpose({
    clearForms,
    scrollToLastForm,
});
</script>

<template>
    <app-custom-form
        v-if="forms[InjuryFormNames.Trauma]?.isReady()"
        :form="forms[InjuryFormNames.Trauma]"
        class="form"
        @change="storeForm(InjuryFormNames.Trauma)"
    >
        <div class="whiteboard-panel">
            <div class="whiteboard" :data-type="'whiteboard-2'" :data-scroll="InjuryFormNames.Trauma">
                <div class="inputs row">
                    <div class="input-block">
                        <h4>{{ translated('select_damaged_body_parts') }}</h4>
                        <app-human-body-parts
                            :form-field="forms[InjuryFormNames.Trauma].field(FieldNames.Trauma)"
                            :translation-type="type()"
                        ></app-human-body-parts>
                    </div>
                </div>
                <app-button-with-callback
                    class="button"
                    v-bind="proceedButtonParams()"
                    @click="onTraumaProceedClick()"
                />
            </div>
        </div>
    </app-custom-form>
    <app-custom-form
        v-if="forms[InjuryFormNames.EhicCard]?.isReady()"
        :form="forms[InjuryFormNames.EhicCard]"
        class="form"
        @change="storeForm(InjuryFormNames.EhicCard)"
    >
        <div class="whiteboard-panel">
            <div
                v-if="isPanelVisible(InjuryFormNames.EhicCard)"
                class="whiteboard"
                :data-type="'whiteboard-3'"
                :data-scroll="InjuryFormNames.EhicCard"
            >
                <div class="inputs row">
                    <div class="input-block">
                        <h4>{{ translated('did_you_have_ehic_card') }}</h4>
                        <div class="input-radio-container">
                            <app-input-radio
                                :form-field="forms[InjuryFormNames.EhicCard].field(FieldNames.HadEhicCard)"
                                :options="inputOptions[InjuryFormNames.EhicCard].options"
                                :type="'radio'"
                                @change="onFormInputChange(InjuryFormNames.EhicCard)"
                            ></app-input-radio>
                        </div>
                    </div>
                </div>
                <div class="inputs row medical-expenses">
                    <div class="input-block">
                        <h4>{{ translated('total_medical_expenses') }}</h4>
                        <app-input-text
                            :placeholder="translated('total_medical_expenses_placeholder')"
                            :inside-label="'EUR'"
                            :hint-label="hintIconHtml"
                            :form-field="forms[InjuryFormNames.EhicCard].field(FieldNames.MedicalExpensesTotal)"
                            @change="onFormInputChange(InjuryFormNames.EhicCard)"
                        >
                        </app-input-text>
                    </div>
                </div>
                <app-button-with-callback
                    class="button"
                    v-bind="proceedButtonParams()"
                    @click="onEhicCardProceedClick()"
                />
            </div>
        </div>
    </app-custom-form>
</template>

<style scoped lang="scss">
.form {
    .button-with-callback {
        height: 52px;
    }

    .input-radio-container {
        width: 50%;
    }

    .medical-expenses {
        margin: var(--size-medium) 0;
    }

    :deep(.hint-label) {
        display: flex;
        gap: 10px;
        color: var(--text-color-subtlest);
        font-size: var(--font-size-nano);
        font-style: normal;
        align-items: center;
    }
}
</style>
