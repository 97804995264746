import Form from '@/assets/libraries/form/form';

export const useFormHelper = (): FormHelperParams => {
    const submitAttemptMultiple = async (forms: Form[]): Promise<void> => {
        const submitAttempt: Promise<void>[] = forms.map((form: Form): Promise<void> => form.submitAttempt());
        return Promise.all(submitAttempt).then();
    };

    const isMultipleFormsValid = (forms: Form[]): boolean => {
        return forms.every((form: Form): boolean => form.isValid());
    };

    return {
        submitAttemptMultiple,
        isMultipleFormsValid,
    };
};

export interface FormHelperParams {
    submitAttemptMultiple: (forms: Form[]) => Promise<void>;
    isMultipleFormsValid: (forms: Form[]) => boolean;
}
