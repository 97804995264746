<script lang="ts" setup>
import { computed, onMounted, ref, Ref } from 'vue';
import Url from '@/Enums/UrlEnum';
import { AxiosResponse } from 'axios';
import Error from '@/services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import { useTranslate } from '@/Composables/Translate';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import NextBestActionCustomer from '@/interfaces/next.best.action.customer.interface';
import OneDate from '@/assets/libraries/Date/OneDate';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import moment from 'moment/moment';
import RequestService from '@/services/request.service';

const { translateForType } = useTranslate();
const componentIsReady: Ref<boolean> = computed((): boolean => {
    return Object.keys(nbaCustomer.value).length > 0;
});
const nbaCustomer: Ref<NextBestActionCustomer> = ref({} as NextBestActionCustomer);

let timer: NodeJS.Timeout;

onMounted((): void => {
    fetchItems().then(() => {
        if (forceShowPopup()) {
            timer = setTimeout(showPopup, nbaCustomer.value.delay * 1000);
        }
    });
});

async function fetchItems(): Promise<void> {
    return RequestService.getInstance()
        .get({ uri: Url.Ajax.nextBestAction })
        .then((response: AxiosResponse): void => {
            applyNbaCustomer(response);
        })
        .catch((reason: DynamicDictionary): void => {
            Error.log(ErrorType.Error, 'app-next-best-action', reason);
        });
}

function applyNbaCustomer(response: AxiosResponse): void {
    if (response.data.data.body.nbaCustomer) {
        Object.assign(nbaCustomer.value, response.data.data.body.nbaCustomer);
    }
}

function forceShowPopup(): boolean {
    return popupNotShown() || showPopupWhen();
}

function popupNotShown(): boolean {
    return nbaCustomer.value.popup_shown_at === null;
}

function showPopupWhen(): boolean {
    const popupShownOn: Date = new Date(nbaCustomer.value.popup_shown_at ?? '');
    popupShownOn.setDate(popupShownOn.getDate() + nbaCustomer.value.break);

    return Date.now() > popupShownOn.getTime();
}

function showPopup() {
    PopupService.getInstance().show(
        new OnePopup().withType().nextBestAction.withData(JSON.parse(JSON.stringify(nbaCustomer.value))),
    );
    updatePopupShownAt();
    clearInterval(timer);
}

function dueDate(): string {
    const date: moment.Moment = moment(nbaCustomer.value.start_date).add(nbaCustomer.value.duration, 'days');

    return OneDate.short(date.toDate());
}

function dueDateText(): string {
    return [translateForType('btar_one_dashboard_due', 'dashboard'), dueDate()].join(' ');
}

function ctaText(): string {
    let result: string = translateForType('btar_dashboard_view_offer', 'dashboard');
    if (nbaCustomer.value.nba.cta_text) {
        result = nbaCustomer.value.nba.cta_text;
    }

    return result;
}

function updatePopupShownAt(): void {
    const params: DynamicDictionary = {
        data: {
            id: nbaCustomer.value.id,
        },
    };
    RequestService.getInstance()
        .post({ uri: Url.Ajax.nextBestActionUpdatePopupShownAt, content: params })
        .then((response: AxiosResponse): void => {
            applyNbaCustomer(response);
        })
        .catch((reason: DynamicDictionary): void => {
            Error.log(ErrorType.Error, 'app-next-best-action-update-popup-shown-at', reason);
        });
}
</script>

<template>
    <div v-if="componentIsReady" class="next-best-action-notification">
        <div class="content-wrapper">
            <div class="nba-container">
                <div class="status-block">
                    <div class="status">
                        {{ translateForType('upgrade_offer', 'nba') }}
                    </div>
                    <div v-if="nbaCustomer.nba.show_term">
                        {{ dueDateText() }}
                    </div>
                </div>
                <p class="heading">{{ nbaCustomer.nba.heading }}</p>
                <a class="view-offer" @click="showPopup()">
                    {{ ctaText() }}
                    <svg
                        class="icon-right"
                        fill="none"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M9 18L15 12L9 6"
                            stroke="#007A76"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                        />
                    </svg>
                </a>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.next-best-action-notification {
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: -40px 0 40px;
    animation: fade-in 1s;

    .content-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: var(--size-big);
        background-color: #e2f1f3;
        border-radius: 0 0 var(--size-tiny) var(--size-tiny);
        padding: var(--size-small);

        @include respond-above('lp') {
            max-width: 1368px;
        }

        .nba-container {
            display: flex;
            flex-direction: column;
            gap: var(--size-pico);
            font-weight: 600;

            @include respond-above('lp') {
                flex-direction: row;
                align-items: center;
            }

            .status-block {
                display: flex;
                align-items: center;
                flex-direction: row;
                font-size: var(--size-nano);
                gap: var(--size-nano);
            }

            .status {
                padding: 6px var(--size-tiny);
                color: var(--system-color-success-dark);
                border-radius: var(--size-normal);
                background-color: var(--teal-100);
                font-size: 14px;
                font-weight: 600;
            }

            .heading {
                font-size: var(--size-tiny);
                color: var(--text-color-default);
                font-weight: 600;

                @include respond-above('lp') {
                    padding-left: var(--size-small);
                    border-left: 1px solid var(--black-100);
                    margin-left: 10px;
                }
            }

            .view-offer {
                display: flex;
                flex-direction: row;
                align-items: center;
                color: var(--system-color-success-dark);
                font-size: 14px;
                cursor: pointer;

                @include respond-above('lp') {
                    margin-left: auto;
                }

                .icon-right {
                    margin-left: var(--size-nano);
                }
            }
        }
    }
}
</style>
