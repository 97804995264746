import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import Sanitizer from '@/services/sanitizer.service';
import type { SanitizerCallback } from '@/assets/libraries/form/form-field';

export default class SanitizersMap {
    public static byCountryIso(): DynamicDictionary {
        return {
            LV: Sanitizer.cleanPersonCodeLV,
            LT: Sanitizer.clearPersonCodeLT,
            EE: Sanitizer.clearPersonCodeEE,
        };
    }

    public static default(): SanitizerCallback<string> {
        return Sanitizer.cleanIdentityNumberNonBaltic;
    }
}
