<script lang="ts">
import { defineComponent, ref, Ref, nextTick } from 'vue';
import OneBaseService from '@/services/OneBaseService';
import Form from '@/assets/libraries/form/form';
import { useStepsSubmitter } from '@/Composables/StepsSubmitter';
import { useTranslate } from '@/Composables/Translate';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import ErrorType from '@/Enums/ErrorTypeEnum';
import { AxiosParams, useAxios } from '@/Composables/Axios';
import Url from '@/Enums/UrlEnum';
import FormField from '@/assets/libraries/form/form-field';
import Error from '@/services/error.service';
import ProductBlockItemBuilder from '@/Components/Other/ProductBlock/Builders/ProductBlockItemBuilder';
import { MovableProperty } from '@/interfaces/resources/MovableProperties/MovablePropertyInterface';
import ProductBlockItem from '@/Components/Other/ProductBlock/Interfaces/ProductBlockItemInterface';
import { CoverageRisk } from '@/interfaces/resources/MovableProperties/CoverageRiskInterface';
import { useScroll } from '@/Composables/Scroll';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import CoveredPopupTypes from '@/Components/Popups/CoveredPopup/Enums/CoveredPopupTypes';
import { CoveredPopupBuilder } from '@/pages/ItemsAndBelongings/Classes/CoveredPopupBuilder';
import EventBus from '@/services/event.bus.service';
import { take } from 'rxjs/operators';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();
        const request: AxiosParams = useAxios();
        const { translate, translateForType } = useTranslate();
        const stepsSubmitter = useStepsSubmitter();

        const CurrentStep: number = 1;
        const Facility: string = 'items-and-belongings';
        const ProductsContainerId: string = 'movablePropertyProducts';
        const BadgeTextSuffix: string = '_BADGE_TEXT';
        const ProductDescriptionSuffix: string = '_INFO';
        const AutoSubmitEvent: string = 'auto-submit';

        const form: Form<{ 'product-panel-blocks': any }> = new Form({ useValidationV2: true });
        const programs: Ref<ProductBlockItem[]> = ref([]);
        const program: Ref<string> = ref('');
        const coveredPopup: CoveredPopupBuilder = new CoveredPopupBuilder();

        function onAppReady(): void {
            btaBase.dynamicStepper.enableAll();
            PopupService.getInstance().show(new OnePopup().withType().loading);
            request
                .get(Url.Ajax.movableProperties, {})
                .then((response: DynamicDictionary): void => {
                    const policies: DynamicDictionary = response.data.data.body;
                    buildProductBlocks(policies.policies);
                    coveredPopup
                        .withCoveredTitle('covered_compare')
                        .withCoveredType(CoveredPopupTypes.CoveredPopupMultiple)
                        .withTranslationKey('items_and_belongings')
                        .withExcludedRisks(btaBase.settings.movablePropertySettings().excludedRisks)
                        .withOnSelectClick(onSelectProductClick as Function)
                        .withContent(policies.policies)
                        .build();
                    setupForm();
                    stepsSubmitter.addForm(form);
                    if (canAutoSubmit()) {
                        EventBus.getInstance().emit(AutoSubmitEvent, { search: idFromUrlParams() });
                    }
                })
                .catch((reason: DynamicDictionary): void => {
                    Error.log(ErrorType.Error, 'Items & Belongings / Insurance APP / onAppReady', reason);
                })
                .finally((): void => {
                    PopupService.getInstance().hide();
                });
        }

        function onSubmitStep(): void {
            useScroll().scrollToId(ProductsContainerId).then();
        }

        function setupForm(): void {
            form.addField(new FormField('product-panel-blocks'));
            form.setReady();
        }

        function buildProductBlocks(products: MovableProperty[]): void {
            products.forEach((policy: MovableProperty): void => {
                const productBlockItemBuilder: ProductBlockItemBuilder = new ProductBlockItemBuilder();
                const productName: string = translateForType(policy.id, 'items_and_belongings');
                const price: number = policy.price;
                const discountPostfix: string = '&euro;' + translate('btar_policy_price_small_text');
                const customModule: string = 'AppProductBlockInnerMovableProperty';
                const selectButtonText: string = translate('one_day_select');
                const customButtonText: string = translate('see_what_covered');
                const badgeText: string = translateForType(policy.id + BadgeTextSuffix, 'items_and_belongings');
                const productDescription: string = translateForType(
                    policy.id + ProductDescriptionSuffix,
                    'items_and_belongings',
                );
                productBlockItemBuilder
                    .withProductId(policy.id)
                    .withProductName(productName)
                    .withDiscountSum(price)
                    .withDiscountSumPostfix(discountPostfix)
                    .withDiscountTooltip({
                        title: translateForType('tooltip_title', 'items_and_belongings'),
                        description: translateForType('tooltip_description_' + policy.id, 'items_and_belongings'),
                    })
                    .withDynamicContent(customModule, featuredRisks(policy.coveragePlans[0].risks))
                    .withButtonSelectTitle(selectButtonText)
                    .withDescription(productDescription)
                    .withButtonCustomTitle(customButtonText);
                if (policy.params.withBadge) {
                    productBlockItemBuilder.withBadgeText(badgeText);
                }
                if (policy.params.defaultOnMobile) {
                    productBlockItemBuilder.asDefaultOnMobile();
                }
                programs.value.push(productBlockItemBuilder.build());
            });
        }

        function featuredRisks(risks: CoverageRisk[]): CoverageRisk[] {
            return risks.filter((risk: CoverageRisk): boolean => risk.isFeatured);
        }

        function autoSubmit(productId: string): void {
            nextTick((): void => {
                form.validate().then((): void => {
                    removeUrlQueryParams().then((): void => {
                        onSelectProductClick([productId]);
                    });
                });
            });
        }

        function onSelectProductClick(programId: string[]): void {
            PopupService.getInstance().show(new OnePopup().withType().loadingWait);
            program.value = programId[0];
            prepareSubmit();
            stepsSubmitter.proceedStep('', 0);
        }

        function prepareSubmit(): void {
            stepsSubmitter.addSubmitCustomParam('nextStep', btaBase.nextStep());
            stepsSubmitter.addSubmitCustomParam('facility', Facility);
            stepsSubmitter.addSubmitCustomParam('productId', program.value);
        }

        function onCustomProductClick(): void {
            showCoveredPopup();
        }

        function showCoveredPopup(): void {
            PopupService.getInstance().show(new OnePopup().withType().oneCovered);
        }

        function canAutoSubmit(): boolean {
            let result: boolean = false;
            const productQueryParam: string | null = idFromUrlParams();
            if (productQueryParam) {
                const product: ProductBlockItem | undefined = programs.value.find(
                    (item: ProductBlockItem): boolean => item.id === productQueryParam,
                );
                result = !!product;
            }

            return result;
        }

        function removeUrlQueryParams(): Promise<void> {
            return new Promise((resolve) => {
                history.pushState({}, '', location.origin);
                resolve();
            });
        }

        function idFromUrlParams(): string | null {
            const urlParams: URLSearchParams = new URLSearchParams(location.search);
            return urlParams.get('productId');
        }

        function listenForAutoSubmitEvent(): void {
            EventBus.getInstance().subscribe(AutoSubmitEvent, (params: DynamicDictionary): void => {
                autoSubmit(params.search);
            });
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                form,
                onSubmitStep,
                onAppReady,
                programs,
                onSelectProductClick,
                onCustomProductClick,
                program,
                showCoveredPopup,
                coveredPopup,
                listenForAutoSubmitEvent,
            },
        };
    },

    mounted() {
        this.applyApp(this);
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);

        this.onAppIsPreparedAndReady.pipe(take(1)).subscribe((): void => {
            this.onAppReady();
            this.listenForAutoSubmitEvent();
        });
    },
});
</script>
