<script setup lang="ts">
import Form from '@/assets/libraries/form/form';
import { computed, getCurrentInstance, onMounted, ref, Ref, watch } from 'vue';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import ButtonIcon from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.enum';
import ButtonIconPosition from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.position.enum';
import Translations from '@/services/translations.service';
import { useTranslate } from '@/Composables/Translate';
import { Router, useRouter } from 'vue-router';
import FormField from '@/assets/libraries/form/form-field';
import Storage from '@/Apps/SolarPanels/Services/Storage';
import UserCredentials from '@/interfaces/user.credentials.interface';
import User from '@/services/user.service';
import StepsGuard from '@/Apps/SolarPanels/Services/StepsGuard';
import Steps from '@/Apps/SolarPanels/Enums/Steps';
import OneBaseService from '@/services/OneBaseService';
import AppCustomForm from '@/Components/Inputs/CustomForm/CustomForm.vue';
import AppAddressFinder from '@/Components/Inputs/AddressFinder/AddressFinder.vue';
import AppButtonWithCallback from '@/Components/Buttons/ButtonWithCallback/ButtonWithCallback.vue';
import Validation from '@/services/validation.service';
import AddressFieldInterface from '@/interfaces/address.field.interface';

const { translate, translateForType } = useTranslate();
const form: Form<{ address: AddressFieldInterface }> = new Form({ useValidationV2: true });
const router: Router = useRouter();
const storage: Storage = Storage.getInstance();
const user: User = User.getInstance();
const addressFinderMode: Ref<string> = ref('mixed');
const isAuthenticatedUser: Ref<boolean> = computed(() => user.isLogged());

const confirmAddressIsDisabled: Ref<boolean> = computed(() => {
    return form.field('address').isEmpty() || !form.field('address').isValid;
});

watch(
    () => form.field('address').value,
    (): void => {
        storage.fields.address = form.field('address').value;
    },
);

onMounted(() => {
    OneBaseService.getInstance().applySpa(getCurrentInstance());
    storage.updateRoute();
    StepsGuard.getInstance(storage).init();
    Translations.getInstance().addType('solar_panels_address');
    setupForm();
});

function setupForm(): void {
    form.addField(new FormField('address').addValidators({ required: Validation.required }));
    form.restoreValues({ address: storage.fields.address });
    if (isAuthenticatedUser.value && !form.field('address').value) {
        patchUserAddress();
    }
    if (form.field('address').value && !form.field('address').value.apartment) {
        addressFinderMode.value = '';
    }
    form.setReady();
}

function patchUserAddress(): void {
    const currentUser: UserCredentials = user.current;
    if (currentUser.addressCode !== '') {
        form.field('address').patch({
            addressCode: currentUser.addressCode,
            addressName: currentUser.address,
            label: currentUser.address,
            value: '',
            additional: currentUser.addressExtra,
            postCode: currentUser.postCode,
        });
        storage.fields.address = form.field('address').value;
        addressFinderMode.value = '';
    } else {
        addressFinderMode.value = 'mixed';
    }
}

function localized(stringUid: string): string {
    return translateForType(stringUid, Translations.getInstance().type);
}

function onProceed(): void {
    form.submitAttempt().then(() => {
        if (form.isValid()) {
            updateAddress();
            router.push({ name: Steps.SummaryAndPayment });
        }
    });
}

function updateAddress(): void {
    storage.fields.address = form.field('address').value;
    storage.fields.address!.apartment = form.field('address').value.apartment ?? '';
    storage.fields.address!.detailed = form.field('address').value.detailed ?? '';
    storage.fields.address!.label =
        form.field('address').value.detailed !== ''
            ? [form.field('address').value.addressName, form.field('address').value.detailed].join(', ')
            : form.field('address').value.addressName;
}

function onAddressEditClick(): void {
    addressFinderMode.value = 'mixed';
}
</script>

<template>
    <div class="container">
        <app-custom-form v-if="form.isReady()" :form="form" class="form">
            <h1 class="page-title">{{ localized('title') }}</h1>
            <div id="whiteboard-0" class="whiteboard">
                <app-address-finder
                    :form-field="form.field('address')"
                    :label="localized('address')"
                    :mobile-mode-enabled="true"
                    :skip-apartment-validation="true"
                    :mode="addressFinderMode"
                    @edit-click="onAddressEditClick"
                >
                </app-address-finder>
                <app-button-with-callback
                    class="button"
                    :title="localized('confirm_address')"
                    :text-color="ButtonTextColor.White"
                    :background-color="ButtonBackground.Red"
                    :icon="ButtonIcon.LongArrowRight"
                    :icon-position="ButtonIconPosition.Right"
                    :disabled="confirmAddressIsDisabled"
                    @button-callback-click="onProceed()"
                >
                </app-button-with-callback>
            </div>
        </app-custom-form>
    </div>
</template>

<style lang="scss" scoped>
.container {
    .whiteboard {
        button.button-with-callback.button.background-red {
            width: 100%;
            min-height: 52px;
        }
    }
}
</style>
