import ErrorType from '@/Enums/ErrorTypeEnum';
import Error from '@/services/error.service';
import TransferStateService from '@/Core/ServerState/TransferStateService';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';

export default class Captcha {
    private static readonly DefaultAction: string = 'validate_captcha';
    private static readonly NotLoaded: string = 'captcha_script_not_loaded';
    private static instance: Captcha;
    private transferStateService: TransferStateService = TransferStateService.getInstance();
    private readonly siteKey: string;
    private readonly enabled: boolean;

    private constructor() {
        const captchaConfig: DynamicDictionary | undefined = this.transferStateService.get('captcha');
        this.siteKey = captchaConfig ? captchaConfig.siteKey : '';
        this.enabled = captchaConfig ? captchaConfig.enabled : false;
    }

    public static getInstance(): Captcha {
        if (!Captcha.instance) {
            Captcha.instance = new Captcha();
        }
        return Captcha.instance;
    }

    public executeCaptcha(callback: Function): Promise<string> {
        let result: Promise<string>;
        if (!this.enabled) {
            callback();
            result = Promise.resolve('');
        } else {
            result = new Promise((resolve, reject) => {
                if (typeof grecaptcha === 'undefined') {
                    reject(Captcha.NotLoaded);
                }
                grecaptcha.ready((): void => {
                    grecaptcha.execute();
                });
            });
        }

        return result;
    }

    public executeParametricCaptcha(callback: Function, captchaId: string): void {
        if (!this.enabled) {
            callback('');
        } else {
            grecaptcha.ready(() => {
                if (typeof grecaptcha === 'undefined') {
                    Error.log(
                        ErrorType.Error,
                        'executeParametricCaptcha',
                        'Failed to execute captcha for:  ' + captchaId,
                        true,
                    );
                } else {
                    grecaptcha.execute(this.siteKey, { action: captchaId }).then((token: string): void => {
                        callback(token);
                    });
                }
            });
        }
    }

    public resetCaptcha(): void {
        if (this.enabled && typeof grecaptcha !== 'undefined') {
            grecaptcha.reset();
        }
    }

    public renderCaptcha(containerId: string = ''): void {
        const containerElement: string = containerId ? `recaptcha-container ${containerId}` : 'recaptcha-container';

        grecaptcha.ready((): void => {
            grecaptcha.render(containerElement, {
                sitekey: this.siteKey,
            });
        });
    }

    public isEnabled(): boolean {
        return this.enabled;
    }
}
