import AppModule from '@/Components/Other/Module/Module.vue';
import ContactUs from '@/Components/Other/ContactUs/ContactUs.vue';
import PreviewHeader from '@/Components/Other/PreviewHeader/PreviewHeader.vue';
import AppProductBlock from '@/Components/Other/ProductBlock/ProductBlock.vue';
import SubscribeAndPay from '@/Components/Other/SubscribeAndPay/SubscribeAndPay.vue';
import ResultMessage from '@/Components/Other/ResultMessage/ResultMessage.vue';
import OneDayMini from '@/Components/Other/ProductBlock/Components/MiniPlans/OneDayMini.vue';
import BicycleMini from '@/Components/Other/ProductBlock/Components/MiniPlans/BicycleMini.vue';
import MovablePropertyMini from '@/Components/Other/ProductBlock/Components/MiniPlans/MovablePropertyMini.vue';
import ProductBlockInnerOneDayPolicy from '@/Components/Other/ProductBlock/Components/ProductBlockInnerOneDayPolicy.vue';
import ProductBlockInnerMovableProperty from '@/Components/Other/ProductBlock/Components/ProductBlockInnerMovableProperty.vue';
import ProductBlockInnerBicyclePolicy from '@/Components/Other/ProductBlock/Components/ProductBlockInnerBicyclePolicy.vue';
import Repeatable from '@/Components/Other/Repeatable/Repeatable.vue';
import RepeatableWhiteboardItem from '@/Components/Other/Repeatable/RepeatableWhiteboardItem.vue';
import Components from '@/Core/App/Interfaces/ComponentsInterface';
import Svg from '@/Components/Other/Svg/Svg.vue';

export default {
    AppContactUs: ContactUs,
    AppModule: AppModule,
    AppPreviewHeader: PreviewHeader,
    AppProductBlock: AppProductBlock,
    AppSubscribeAndPay: SubscribeAndPay,
    AppResultMessage: ResultMessage,
    AppOneDayMini: OneDayMini,
    AppBicycleMini: BicycleMini,
    AppMovablePropertyMini: MovablePropertyMini,
    AppProductBlockInnerOneDayPolicy: ProductBlockInnerOneDayPolicy,
    AppProductBlockInnerMovableProperty: ProductBlockInnerMovableProperty,
    AppProductBlockInnerBicyclePolicy: ProductBlockInnerBicyclePolicy,
    AppSvg: Svg,
    AppRepeatable: Repeatable,
    AppRepeatableWhiteboardItem: RepeatableWhiteboardItem,
} as Components;
