<script setup lang="ts">
import { computed, onMounted, reactive, Ref, watch, UnwrapNestedRefs } from 'vue';
import FormField from '@/assets/libraries/form/form-field';
import ProductBlockItem from '@/Components/Other/ProductBlock/Interfaces/ProductBlockItemInterface';
import CssClass from '@/Enums/CssClassEnum';
import BreakPoints from '@/Enums/BreakPointsEnum';
import ProductBlockEmitters from '@/Components/Other/ProductBlock/Enums/ProductBlockEmittersEnum';
import ProductBlockItemButtonType from '@/Components/Other/ProductBlock/Enums/ProductBlockItemButtonTypeEnum';
import ProductBlockSumType from '@/Components/Other/ProductBlock/Enums/ProductBlockSumTypeEnum';
import AppTextWithTip from '@/Components/Tooltips/TextWithTip/TextWithTip.vue';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import { useTranslate } from '@/Composables/Translate';
import { usePrice } from '@/Composables/Price';

const { sparse } = usePrice();
const props = defineProps({
    componentName: { type: String, default: 'ProductBlock' },
    translationType: { type: String, default: 'product_block' },
    formField: { type: FormField, default: () => new FormField('') },
    dataStoreDisabled: { type: Boolean, default: false },
    mobileBadgeVisible: { type: Boolean, default: true },
    selectedFrameHidden: { type: Boolean, default: false },
    products: { type: Array<ProductBlockItem>, default: () => [] },
    productSumType: { type: String, default: ProductBlockSumType.None },
    miniPlansComponent: { type: String, default: '' },
});
const emit = defineEmits([ProductBlockEmitters.Selected, ProductBlockEmitters.Custom]);
const { translate, translateForType } = useTranslate();
const productBlockItemButtonType: Ref<typeof ProductBlockItemButtonType> = computed(() => {
    return ProductBlockItemButtonType;
});
const selectedProduct: UnwrapNestedRefs<DynamicDictionary> = reactive({
    selectedProductId: '',
    miniSelectedProductId: '',
    width: 0,
});

watch(
    props.products,
    () => {
        if (props.products && props.products.length > 0) {
            selectedProduct.miniSelectedProductId = (props.products[0] as ProductBlockItem).id;
        }
    },
    { deep: true, immediate: true },
);

window.addEventListener('resize', () => {
    selectedProduct.width = window.innerWidth;
});

onMounted((): void => {
    selectedProduct.width = window.innerWidth;
});

function onMiniPlanSelected(productId: string): void {
    selectedProduct.miniSelectedProductId = productId;
}

function productClasses(product: ProductBlockItem): string {
    const result: string[] = [];
    if (selectedProduct.selectedProductId === product.id && !props.selectedFrameHidden) {
        result.push(CssClass.Selected);
    }
    if (selectedProduct.width <= BreakPoints.Sm && selectedProduct.miniSelectedProductId !== product.id) {
        result.push(CssClass.Hidden);
    }

    return result.join(' ');
}

function discountedSumPostfix(product: ProductBlockItem): string {
    return isProductDiscountAvailable(product) ? product.discountSumPostfix : '&euro;' + productSumLabel();
}

function isProductDiscountAvailable(product: ProductBlockItem): boolean {
    return product.discountSavedSum > 0;
}

function onSelectedClick(product: ProductBlockItem): void {
    selectedProduct.selectedProductId = product.id;
    selectedProduct.miniSelectedProductId = product.id;
    emit(ProductBlockEmitters.Selected, [product.id]);
}

function onCustomClick(product: ProductBlockItem): void {
    emit(ProductBlockEmitters.Custom, [product.id]);
}

function badgeIsVisible(product: ProductBlockItem): boolean {
    return product.badgeText !== '';
}

function isCoverageTitleVisible(product: ProductBlockItem): boolean {
    return product.coverageTitle !== '';
}

function isDescriptionVisible(product: ProductBlockItem): boolean {
    return product.description !== '';
}

function isVisibleDiscountGap(product: ProductBlockItem): boolean {
    const ids: string[] = [];
    (props.products as ProductBlockItem[]).forEach((value: ProductBlockItem) => {
        if (isProductDiscountAvailable(value)) {
            ids.push(value.id);
        }
    });

    return ids.length > 0 && !ids.includes(product.id);
}

function printSparsePolicyPrice(product: ProductBlockItem): string {
    return sparse(product.discountSum, false);
}

function productSumLabel(): string {
    let result: string = '';
    if (props.productSumType !== '') {
        result = '/' + translateForType(props.productSumType, props.translationType);
    }
    return result;
}
</script>

<template>
    <div
        :id="formField.name"
        class="products-block"
        :class="{ ...formField.classes() }"
        :data-store="dataStoreDisabled ? '' : formField.name"
        :data-store-value="dataStoreDisabled ? '' : JSON.stringify(formField.value)"
    >
        <component
            :is="miniPlansComponent"
            v-if="miniPlansComponent"
            :products="products"
            :product-sum-type="productSumType"
            :selected-product="selectedProduct"
            @mini-selected="onMiniPlanSelected($event)"
        ></component>
        <div
            v-for="(product, index) in products"
            :id="'product-block-' + index"
            :key="index"
            class="product-block"
            :class="productClasses(product)"
        >
            <div v-if="badgeIsVisible(product)" class="badge" :class="{ 'hide-on-mobile': !mobileBadgeVisible }">
                {{ translate(product.badgeText) }}
            </div>
            <div class="info">
                <div class="title">{{ product.productName }}</div>
                <div class="price">
                    <div class="price-with-tooltip" :class="{ 'space-between': product.discountTooltip.title }">
                        <app-text-with-tip
                            v-if="product.discountTooltip.title"
                            class="tooltip"
                            :title="product.discountTooltip.title"
                            :tip-description="product.discountTooltip.description"
                            :mode="'popup'"
                        ></app-text-with-tip>
                        <div>
                            <span class="num">{{ printSparsePolicyPrice(product) }}</span>
                            <span class="text-currency" v-html="discountedSumPostfix(product)"></span>
                        </div>
                    </div>
                </div>
                <div v-if="isProductDiscountAvailable(product)" class="price-and-discount">
                    <s class="strike"
                        ><span class="base-price">{{ product.discountFullSum }} &euro;</span></s
                    >
                    <span class="base-discount">{{ product.discountSavedSumText }}</span>
                </div>
            </div>
            <div v-if="isCoverageTitleVisible(product)" class="coverage">{{ product.coverageTitle }}</div>
            <div v-if="isVisibleDiscountGap(product)" class="discount-gap"></div>
            <component
                :is="product.dynamicComponent"
                v-if="product.dynamicContent"
                :data="product.dynamicContent"
            ></component>
            <div v-if="isDescriptionVisible(product)" class="description">
                {{ product.description }}
            </div>
            <div class="buttons">
                <button class="button red" @click="onSelectedClick(product)">
                    {{ product.buttonSelect.title
                    }}<span
                        v-if="product.buttonSelect.type === productBlockItemButtonType.WithArrow"
                        style="padding-left: 10px; font-size: 18px"
                        >&rarr;</span
                    >
                </button>
                <button class="button outside button-custom" @click="onCustomClick(product)">
                    <span
                        v-if="product.buttonCustom.type === productBlockItemButtonType.WithShield"
                        class="shield-icon"
                    >
                        <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M10.697 25C10.697 25 20.3939 20.2 20.3939 13V4.6L10.697 1L1 4.6V13C1 20.2 10.697 25 10.697 25Z"
                                stroke="#00B4AD"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                opacity="0.24"
                                d="M10.697 2.19971H16.7576L20.394 4.59971L21.0001 12.9997L16.1516 21.3997L10.697 23.7997V2.19971Z"
                                fill="#00B4AD"
                            />
                        </svg>
                    </span>
                    {{ product.buttonCustom.title }}
                </button>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.products-block {
    scroll-margin-top: 4em;
    width: 100%;
    display: flex;
    padding-left: var(--size-tiny);
    padding-right: var(--size-tiny);
    flex-flow: column;
    gap: 19px;
    justify-content: center;

    @include respond-above('lg') {
        width: auto;
        padding-left: 0;
        padding-right: 0;
        flex-direction: row;
    }

    @include respond-below('lg') {
        gap: 0;
    }

    .product-block {
        position: relative;
        background: var(--white);
        border: solid 1px var(--black-100);
        border-radius: var(--size-tiny);
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-grow: 1;
        margin-left: 0;
        margin-right: 0;
        padding: var(--size-small);
        max-width: 400px;
        min-width: 320px;

        @include respond-below('lg') {
            max-width: 100%;
        }

        &.selected {
            border-color: var(--brand-red);
        }

        .badge {
            position: absolute;
            top: -22px;
            min-width: 153px;
            height: 40px;
            padding-right: 15px;
            padding-left: 15px;
            background: var(--teal-800);
            color: var(--white);
            font-size: var(--font-size-nano);
            line-height: 18px;
            font-weight: 400;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 100px;

            &.hide-on-mobile {
                display: none;

                @include respond-above('sm') {
                    display: inline-flex;
                }
            }
        }
    }

    .info {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: var(--size-normal);
        margin-top: 22px;

        .title {
            font-size: var(--font-size-medium);
            font-weight: 600;
            margin-bottom: 0;
            width: inherit;
            text-align: center;
        }

        .price {
            color: var(--teal-800);
            width: inherit;
            font-weight: bold;
            margin-top: 22px;

            .price-with-tooltip {
                text-align: center;

                .tooltip {
                    color: var(--text-color-subtlest);

                    :deep(.sub-title) {
                        font-size: var(--font-size-nano);
                        font-weight: 500;
                    }

                    :deep(.tooltip-popup) {
                        max-width: 360px;
                        width: max-content;
                    }
                }

                &.space-between {
                    display: flex;
                    justify-content: space-between;
                }
            }

            .num {
                font-size: var(--font-size-big);
                font-weight: 600;
                display: inline-flex;
                align-items: baseline;
            }

            .text {
                font-size: var(--font-size-normal);
                font-weight: 600;

                @include respond-above('sm') {
                    position: relative;
                    top: -1px;
                }

                @include respond-below('xs') {
                    font-weight: normal;
                }
            }

            .text-currency {
                font-size: var(--font-size-small);
                font-weight: 600;
                padding-left: var(--size-pico);

                @include respond-below('md') {
                    font-weight: 600;
                }
            }
        }

        .price-and-discount {
            font-size: var(--font-size-nano);
            color: var(--black-500);
            margin-top: var(--size-nano);
            text-align: center;

            .strike {
                color: var(--brand-red);

                .base-price {
                    color: var(--black-500) !important;
                }
            }

            .base-discount {
                margin-left: var(--size-nano);
            }
        }
    }

    .discount-gap {
        height: 28px;
        width: 100%;
    }

    .coverage {
        margin-bottom: var(--size-small);
    }

    .description {
        height: 100%;
        margin-bottom: var(--size-small);
        color: var(--text-color-subtle);
        font-size: var(--font-size-tiny);
        line-height: 140%;
    }

    .buttons {
        width: 100%;

        .button {
            width: 100%;
            font-weight: normal;
            padding: 0 25px;
            border-radius: 10px;
        }

        .button-custom {
            color: var(--text-color-default);
            margin-top: var(--size-nano);

            .shield-icon {
                margin-right: 10px;
            }
        }
    }
}
</style>
