import VueApp from '@/Core/Routing/VueApp';
import Components from '@/Core/App/Interfaces/ComponentsInterface';
import { App } from 'vue';
import { createPinia } from 'pinia';
import { ComponentPublicInstance } from 'vue';
import Router from '@/Core/Routing/Router';
import GlobalDirectives from '@/Directives/Directives';
import AppComponent from '@/Apps/ActivePlus/App.vue';
import BackendRoute from '@/Core/Routing/Interfaces/BackendRouteInterface';
import StepperLayout from '@/Layouts/StepperLayout.vue';
import RouteStepper from '@/Components/Routing/RouteStepper/RouteStepper.vue';

import InsurancePage from '@/Apps/ActivePlus/Pages/InsurancePage.vue';
import InsuredObjectsPage from '@/Apps/ActivePlus/Pages/InsuredObjectsPage.vue';
import InsuredPersonsPage from '@/Apps/ActivePlus/Pages/InsuredPersonsPage.vue';
import SummaryAndPaymentPage from '@/Apps/ActivePlus/Pages/SummaryAndPaymentPage.vue';
import ActivePlusMini from '@/Components/Other/ProductBlock/Components/MiniPlans/ActivePlusMini.vue';
import CoveredPopupActivePlusMulti from '@/Components/Popups/CoveredPopup/CoveredPopupActivePlusMulti.vue';
import Popup from '@/Components/Popups/Popup/Popup.vue';
import ContentLoader from '@/Components/Loaders/ContentLoader/ContentLoader.vue';
import CustomForm from '@/Components/Inputs/CustomForm/CustomForm.vue';
import ProductBlock from '@/Components/Other/ProductBlock/ProductBlock.vue';
import ButtonWithCallback from '@/Components/Buttons/ButtonWithCallback/ButtonWithCallback.vue';
import TextWithTip from '@/Components/Tooltips/TextWithTip/TextWithTip.vue';
import PopupOpener from '@/Components/Popups/PopupOpener/PopupOpener.vue';
import Badge from '@/Components/Buttons/Badge/Badge.vue';
import ItemsListWithIcons from '@/Components/Lists/ItemsListWithIcons/ItemsListWithIcons.vue';
import LanguageSwitcher from '@/Components/Widgets/LanguageSwitcher/LanguageSwitcher.vue';
import Tooltipster from '@/Components/Tooltips/Tooltipster/Tooltipster.vue';
import ProductBlockInnerActivePlusPolicy from '@/Components/Other/ProductBlock/Components/ProductBlockInnerActivePlusPolicy.vue';
import ProductLayout from '@/Layouts/ProductLayout.vue';

const pages: Components = {
    InsurancePage: InsurancePage,
    InsuredObjectsPage: InsuredObjectsPage,
    InsuredPersonsPage: InsuredPersonsPage,
    SummaryAndPaymentPage: SummaryAndPaymentPage,
};

const components: Components = {
    AppProductLayout: ProductLayout,
    AppRouteStepper: RouteStepper,
    AppActivePlusMini: ActivePlusMini,
    AppCoveredPopupActivePlusMulti: CoveredPopupActivePlusMulti,
    AppPopup: Popup,
    AppContentLoader: ContentLoader,
    AppCustomForm: CustomForm,
    AppProductBlock: ProductBlock,
    AppButtonWithCallback: ButtonWithCallback,
    AppTextWithTip: TextWithTip,
    AppPopupOpener: PopupOpener,
    AppBadge: Badge,
    AppItemsListWithIcons: ItemsListWithIcons,
    AppLanguageSwitcher: LanguageSwitcher,
    AppTooltipster: Tooltipster,
    AppProductBlockInnerActivePlusPolicy: ProductBlockInnerActivePlusPolicy,
};

export default class {
    public app(selector: string, routes: BackendRoute[]): ComponentPublicInstance {
        const app: App = new VueApp(AppComponent)
            .withComponents({ ...pages, ...components })
            .withDirectives(GlobalDirectives)
            .create();
        app.use(new Router(app, routes).create());
        app.use(createPinia());

        return app.mount(selector);
    }
}
