import PropertyEnum from '@/Enums/PropertyEnum';
import Validation from '@/services/validation.service';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import moment from 'moment';
import Form from '@/assets/libraries/form/form';
import { useDefine } from '@/Composables/Define';
import { ref, Ref } from 'vue';
import AdditionalObject from '@/interfaces/property/additional.object.interface';

export default class InformationValidators {
    private form: Form = new Form();
    private insuredObjects: Ref<AdditionalObject[]> = ref([]);
    private isHouseSelected: Ref<boolean> = ref(false);
    private houseAreaLimit: Ref<number> = ref(PropertyEnum.Limits.HouseAreaMinLimit);
    private apartmentAreaLimit: Ref<number> = ref(PropertyEnum.Limits.ApartmentAreaMinLimit);
    private saunaAreaLimit: Ref<number> = ref(PropertyEnum.Limits.SaunaAreaMinLimit);
    private garageAreaLimit: Ref<number> = ref(PropertyEnum.Limits.GarageAreaMinLimit);
    private outhouseAreaLimit: Ref<number> = ref(PropertyEnum.Limits.OuthouseAreaMinLimit);
    private yearMinLimit: Ref<number> = ref(PropertyEnum.Limits.YearMinLimit);

    public init(
        form: Form,
        insuredObjects: Ref<AdditionalObject[]>,
        isHouseSelected: Ref<boolean>,
        houseAreaLimit: Ref<number>,
        apartmentAreaLimit: Ref<number>,
        saunaAreaLimit: Ref<number>,
        garageAreaLimit: Ref<number>,
        outhouseAreaLimit: Ref<number>,
        yearMinLimit: Ref<number>,
    ): void {
        this.form = form;
        this.insuredObjects = insuredObjects;
        this.isHouseSelected = isHouseSelected;
        this.houseAreaLimit = houseAreaLimit;
        this.apartmentAreaLimit = apartmentAreaLimit;
        this.saunaAreaLimit = saunaAreaLimit;
        this.garageAreaLimit = garageAreaLimit;
        this.outhouseAreaLimit = outhouseAreaLimit;
        this.yearMinLimit = yearMinLimit;
    }

    public additionalObjectValidator(fieldName: string, fieldIndex: number): object {
        return {
            isRequired: (): boolean => {
                return this.insuredObjects.value[fieldIndex].isVisible &&
                    this.isHouseSelected.value &&
                    this.form.field(fieldName + fieldIndex).isTouched
                    ? !this.form.field(fieldName + fieldIndex).isEmpty()
                    : true;
            },
        };
    }

    public propertyAreaValidator(): object {
        return {
            isRequired: () => {
                return !this.form.field('propertyArea').isEmpty();
            },
            isValueWithinLimit: (): boolean => {
                return this.isHouseSelected.value
                    ? parseFloat(this.form.field('propertyArea').value) >= this.houseAreaLimit.value
                    : parseFloat(this.form.field('propertyArea').value) >= this.apartmentAreaLimit.value;
            },
        };
    }

    public additionalObjectAreaValidator(fieldName: string, fieldIndex: number): object {
        return {
            isValueWithinLimit: (): boolean => {
                const selectedObjectType: string = this.form.field('additionalObjects' + fieldIndex).value;
                const fieldValue: number = this.form.field(fieldName + fieldIndex).value;
                let isValid: boolean = false;
                switch (selectedObjectType) {
                    case PropertyEnum.AdditionalObjectType.Sauna:
                        isValid = fieldValue >= this.saunaAreaLimit.value;
                        break;
                    case PropertyEnum.AdditionalObjectType.Garage:
                        isValid = fieldValue >= this.garageAreaLimit.value;
                        break;
                    case PropertyEnum.AdditionalObjectType.Outhouse:
                        isValid = fieldValue >= this.outhouseAreaLimit.value;
                        break;
                    default:
                }
                return this.isHouseSelected.value &&
                    this.insuredObjects.value[fieldIndex].isVisible &&
                    this.form.field(fieldName + fieldIndex).isTouched
                    ? isValid
                    : true;
            },
        };
    }

    public propertyAddressValidator(): object {
        return {
            isPostalRequired: (): boolean => {
                return this.form.field('propertyAddress').isTouched
                    ? this.isValueSetAndNotEmpty(this.form.field('propertyAddress').value.postCode)
                    : true;
            },
            isApartmentRequired: (): boolean => {
                return this.isHouseSelected.value
                    ? true
                    : this.isValueSetAndNotEmpty(this.form.field('propertyAddress').value.detailed)
                      ? true
                      : this.isValueSetAndNotEmpty(this.form.field('propertyAddress').value.apartment);
            },
        };
    }

    public buildYearValidator(): object {
        return {
            isValidYearWithinRange: (): boolean => {
                return this.form.field('buildOrRenovate').value === PropertyEnum.BuildingYear.Older &&
                    this.form.field('buildYear').isTouched
                    ? this.isValidBuildYearWithinRange(
                          'buildYear',
                          moment().subtract(PropertyEnum.BuildingYear.OffsetOlder, 'years').year(),
                      )
                    : true;
            },
        };
    }

    public renovateYearValidator(): object {
        return {
            isValidYearWithinRange: (): boolean => {
                return this.form.field('buildOrRenovate').value === PropertyEnum.BuildingYear.Older &&
                    this.form.field('renovateYear').isTouched
                    ? this.form.field('renovateYear').isEmpty() ||
                          this.isValidRenovateYearWithinRange(
                              'renovateYear',
                              Number(this.form.field('buildYear').value),
                          )
                    : true;
            },
        };
    }

    public additionalObjectBuildYearValidator(fieldIndex: number): object {
        return {
            isValidYearWithinRange: (): boolean => {
                return this.insuredObjects.value[fieldIndex].isVisible &&
                    this.isHouseSelected.value &&
                    this.form.field('buildOrRenovate' + fieldIndex).value === PropertyEnum.BuildingYear.Older &&
                    this.form.field('buildYear' + fieldIndex).isTouched
                    ? this.isValidBuildYearWithinRange(
                          'buildYear' + fieldIndex,
                          moment().subtract(PropertyEnum.BuildingYear.OffsetOlder, 'years').year(),
                      )
                    : true;
            },
        };
    }

    public additionalObjectRenovateYearValidator(fieldIndex: number): object {
        return {
            isValidYearWithinRange: (): boolean => {
                return this.insuredObjects.value[fieldIndex].isVisible &&
                    this.isHouseSelected.value &&
                    this.form.field('buildOrRenovate' + fieldIndex).value === PropertyEnum.BuildingYear.Older &&
                    this.form.field('renovateYear' + fieldIndex).isTouched
                    ? this.form.field('renovateYear' + fieldIndex).isEmpty() ||
                          this.isValidRenovateYearWithinRange(
                              'renovateYear' + fieldIndex,
                              Number(this.form.field('buildYear' + fieldIndex).value),
                          )
                    : true;
            },
        };
    }

    public yearIsGreaterThanCmsLimit(fieldName: string): boolean {
        return Number(this.form.field(fieldName).value) >= moment().subtract(this.yearMinLimit.value, 'years').year();
    }

    private isValueSetAndNotEmpty(value: LimitedVariant): boolean {
        return useDefine().isSet(value) ? value !== '' : false;
    }

    private isValidBuildYearWithinRange(fieldName: string, range: number): boolean {
        return (
            Validation.isValidYear(this.form.field(fieldName).value) &&
            Number(this.form.field(fieldName).value) <= range &&
            this.yearIsGreaterThanCmsLimit(fieldName)
        );
    }

    private isValidRenovateYearWithinRange(fieldName: string, range: number): boolean {
        return (
            Validation.isValidYear(this.form.field(fieldName).value) &&
            Number(this.form.field(fieldName).value) >= range &&
            this.yearIsGreaterThanCmsLimit(fieldName) &&
            Number(this.form.field(fieldName).value) <=
                moment().subtract(PropertyEnum.BuildingYear.OffsetOlder, 'years').year()
        );
    }
}
