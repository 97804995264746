import { reactive, ref, Ref, UnwrapNestedRefs } from 'vue';
import SpaFormStorageService from '@/services/SpaFormStorageService';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import { Program } from '@/Apps/SolarPanels/Interfaces/ProductProgram';
import { useStore } from '@/Composables/Store/Store';
import { StoreDefinition } from 'pinia';
import { SolarPanelsStore } from '@/Composables/Store/Types/SolarPanels';
import AddressFieldInterface from '@/interfaces/address.field.interface';
import RequestService from '@/services/request.service';
import Url from '@/Enums/UrlEnum';
import { AxiosResponse } from 'axios';
import { useDefine } from '@/Composables/Define';
import { default as ErrorService } from '@/services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import { SolarPanels } from '@/Apps/SolarPanels/Interfaces/SolarPanelsInterface';

interface FormFields {
    selectedProgram: Program | undefined;
    address: AddressFieldInterface | undefined;
    marketingConsents: DynamicDictionary;
    agreeTerms: boolean;
    holderEmail: string;
}

export default class Storage {
    public fields!: UnwrapNestedRefs<FormFields>;
    public products: Ref<SolarPanels[]> = ref([]);
    public store: StoreDefinition<string, SolarPanelsStore> = useStore().solarPanels();
    private static instance: Storage;
    private spaFormStorageService: SpaFormStorageService = SpaFormStorageService.getInstance();

    public static getInstance(): Storage {
        if (!Storage.instance) {
            Storage.instance = new Storage();
            Storage.instance.init();
        }

        return Storage.instance;
    }

    public updateRoute(): void {
        this.store().updateRoute();
    }

    public destroyRoute(): void {
        this.store().destroyRoute();
    }

    public selectedProduct(): SolarPanels {
        const result: SolarPanels | undefined = this.fields.selectedProgram
            ? this.products.value.find(
                  (program: SolarPanels): boolean => program.id === this.fields.selectedProgram!.programIc,
              )
            : undefined;
        if (!result) {
            throw 'Product not found';
        }

        return result;
    }

    public async fetchProducts(): Promise<void> {
        return this.products.value.length === 0
            ? RequestService.getInstance()
                  .get({
                      uri: Url.Ajax.solarPanelsProducts,
                  })
                  .then((response: AxiosResponse): void => {
                      if (useDefine().validResponse(response)) {
                          this.products.value = response.data.data.body.products;
                      } else {
                          throw response;
                      }
                  })
                  .catch((reason: DynamicDictionary): void => {
                      ErrorService.log(ErrorType.Error, 'Solar policy / fetchProducts', reason);
                      throw reason;
                  })
            : new Promise((resolve) => resolve());
    }

    private init(): void {
        this.fields = reactive(this.spaFormStorageService.formStorageFields());
        this.spaFormStorageService.applyDataProviderCallback((): FormFields => this.fields);
    }
}
