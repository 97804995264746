<script setup lang="ts">
import { Router, useRouter } from 'vue-router';
import OneBaseService from '@/services/OneBaseService';
import {
    type Component,
    computed,
    getCurrentInstance,
    nextTick,
    onMounted,
    reactive,
    ref,
    Ref,
    shallowReactive,
    ShallowUnwrapRef,
    UnwrapNestedRefs,
    watch,
} from 'vue';
import ClaimsTravelService from '@/Apps/ClaimsTravel/Services/ClaimsTravelService';
import ClaimsTravelStepUid from '@/Apps/ClaimsTravel/Enums/ClaimsTravelStepUid';
import { AccidentTypes } from '@/Apps/ClaimsTravel/Enums/AccidentTypes';
import InjuryAccident from '@/Apps/ClaimsTravel/Components/InjuryAccident.vue';
import TripDelayAccident from '@/Apps/ClaimsTravel/Components/TripDelayAccident.vue';
import TripInterruptionAccident from '@/Apps/ClaimsTravel/Components/TripInterruptionAccident.vue';
import LuggageAccident from '@/Apps/ClaimsTravel/Components/LuggageAccident.vue';
import OtherAccident from '@/Apps/ClaimsTravel/Components/OtherAccident.vue';
import { FormNames as AccidentDetailsFormNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentDetails/FormNames';
import { FieldNames } from '@/Apps/ClaimsTravel/Enums/Steps/AccidentDetails/FieldNames';
import Form from '@/assets/libraries/form/form';
import Option from '@/Apps/ClaimsTravel/Interfaces/Option';
import {
    BaseForms,
    DescribeEventFields,
    WhatHappenedFields,
} from '@/Apps/ClaimsTravel/Interfaces/Forms/AccidentDetails/Forms';
import { useDefine } from '@/Composables/Define';
import { useTranslate } from '@/Composables/Translate';
import ButtonWithCallbackParams from '@/Components/Buttons/ButtonWithCallback/Enums/button.params';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import ButtonIcon from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.enum';
import ButtonIconPosition from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.position.enum';
import FormField from '@/assets/libraries/form/form-field';
import Validation from '@/services/validation.service';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import { InputOption } from '@/interfaces/InputOptionInterface';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import ClaimsTravelObject from '@/Apps/ClaimsTravel/Interfaces/ClaimsTravelObject';
import ClaimsTravelObjectRisk from '@/Apps/ClaimsTravel/Interfaces/ClaimsTravelObjectRisk';
import TravelType from '@/Apps/ClaimsTravel/Interfaces/TravelType';
import { useClaimsTravelHtml } from '@/Apps/ClaimsTravel/Composables/ClaimsTravelHtml';
import ClaimsTravelFormFields from '@/Apps/ClaimsTravel/Interfaces/ClaimsTravelFormFields';
type FormNames = `${AccidentDetailsFormNames}`;

const router: Router = useRouter();
const { translateForType, applyTranslationType, type, translate } = useTranslate();
const { isSet } = useDefine();

const claimsTravelService: ClaimsTravelService = ClaimsTravelService.getInstance();

const Step: number = 2;
const isSubFlowVisible: Ref<boolean> = ref(false);

const subFlowComponent: Ref<{ clearForms: () => void; scrollToLastForm: () => void } | null> = ref(null);

const forms: ShallowUnwrapRef<{ [FormKey in keyof BaseForms]: Form<BaseForms[FormKey]> }> = shallowReactive(
    {},
) as ShallowUnwrapRef<{
    [FormKey in keyof BaseForms]: Form<BaseForms[FormKey]>;
}>;
const inputOptions: UnwrapNestedRefs<Record<FormNames, Option>> = reactive({}) as UnwrapNestedRefs<
    Record<FormNames, Option>
>;

const selectedPolicy: Ref<ClaimsTravelObject> = computed(() => {
    return claimsTravelService.storageFields.insuredObjects.find(
        (object) => object.id === claimsTravelService.fields.policy?.policy.object,
    )!;
});

const selectedPolicyRisks: Ref<ClaimsTravelObjectRisk[]> = computed(() => {
    return selectedPolicy.value.risks;
});

const selectedSubType: Ref<string | undefined> = computed(() => {
    return forms[AccidentDetailsFormNames.WhatHappened].field(FieldNames.WhatHappened).value.selected;
});

const accidentDescriptionKey: Ref<string | undefined> = computed(() => {
    return claimsTravelService.travelTypes.find(
        (travelType) => travelType.travelSubtype.toLowerCase() === selectedSubType.value,
    )?.eventDescriptionKey;
});

const selectedSubFlowComponent: Component | null = computed(() => {
    let component: Component | null = null;
    switch (claimsTravelService.fields.accidentType!.accidentType.selected) {
        case AccidentTypes.Injury:
            component = InjuryAccident;
            break;
        case AccidentTypes.TripDelay:
            component = TripDelayAccident;
            break;
        case AccidentTypes.TripInterruption:
            component = TripInterruptionAccident;
            break;
        case AccidentTypes.Luggage:
            component = LuggageAccident;
            break;
        case AccidentTypes.Other:
            component = OtherAccident;
            break;
    }

    return component;
});

function onCompleted(): void {
    router.push({ name: ClaimsTravelStepUid.UploadFiles });
}

function preparePanels(): void {
    Object.values(AccidentDetailsFormNames).forEach((panelName: FormNames) => {
        inputOptions[panelName] = reactive(
            new (class implements Option {
                public enabled: boolean = true;
                public passed: boolean = false;
                public visible: boolean = claimsTravelService.storageFields.formVisibility[panelName] ?? false;
                public value: LimitedVariant = '';
                public options: InputOption[] = [];
            })(),
        );
    });

    buildWhatHappenedOptions();
}

function proceedButtonParams(): ButtonWithCallbackParams {
    return {
        title: translated('proceed'),
        textColor: ButtonTextColor.White,
        backgroundColor: ButtonBackground.Red,
        icon: ButtonIcon.LongArrowRight,
        iconPosition: ButtonIconPosition.Right,
    };
}

function proceedToNextForm(formName: FormNames, nextFormName: FormNames | null = null): void {
    const nextPanel = nextFormName ? nextFormName : findNextFormName(formName);
    if (nextPanel) {
        inputOptions[nextPanel].visible = true;
        claimsTravelService.storageFields.formVisibility[nextPanel] = true;
        useClaimsTravelHtml().scrollToPanel(nextPanel);
    }
}

function lastFormName(): string | undefined {
    return Object.keys(inputOptions).findLast((form) => inputOptions[form as FormNames].visible);
}

function scrollToLastForm(): void {
    if (isSubFlowVisible.value) {
        subFlowComponent.value!.scrollToLastForm();
    } else {
        const lastForm = lastFormName();
        if (lastForm) {
            useClaimsTravelHtml().scrollToPanel(lastForm);
        }
    }
}

function findNextFormName(currentForm: FormNames): FormNames | undefined {
    const currentFormIndex = Object.keys(forms).findIndex((formName) => formName === currentForm);

    return Object.keys(forms).at(currentFormIndex + 1) as FormNames | undefined;
}

function buildWhatHappenedOptions(): void {
    const travelSubTypes: TravelType[] = claimsTravelService.travelTypes.filter(
        (travelType) =>
            travelType.travelType.toLowerCase() === claimsTravelService.fields.accidentType!.accidentType.selected,
    );

    inputOptions[AccidentDetailsFormNames.WhatHappened].options = travelSubTypes.map((subType) => {
        const isObjectDisabled = selectedPolicyRisks.value.some((risk) =>
            subType.travelSubtypeIncludedRisks.includes(risk.agrrisk),
        );
        const subTypeName = subType.travelSubtype.toLocaleLowerCase();

        return new InputOptionBuilder()
            .setValue(subTypeName)
            .setName(translated(subTypeName))
            .setTipster(translated(subTypeName + '_tooltip'), translated(subTypeName + '_tooltip_description'))
            .setCustom({
                errorMessage: isObjectDisabled
                    ? translated('this_accident_type_is_not_covered_by_selected_policy')
                    : null,
            })
            .build();
    });
}

function isPanelVisible(panel: FormNames): boolean {
    return inputOptions[panel].visible;
}

function setupForms(): void {
    const requiredValidator = { required: Validation.required };
    const requiredOptionValidator = {
        required: (value: { selected?: string }) => isSet(value?.selected) && value.selected !== '',
    };

    const whatHappenedForm: Form<WhatHappenedFields> = new Form({ useValidationV2: true });
    whatHappenedForm.addField(new FormField(FieldNames.WhatHappened, '', requiredOptionValidator));
    forms[AccidentDetailsFormNames.WhatHappened] = whatHappenedForm;

    const describeEventForm: Form<DescribeEventFields> = new Form({ useValidationV2: true });
    describeEventForm.addField(new FormField(FieldNames.DescribeEvent, '', requiredValidator));
    forms[AccidentDetailsFormNames.DescribeEvent] = describeEventForm;
}

function clearFormsAhead(currentForm: FormNames): void {
    const currentFormIndex = Object.keys(forms).findIndex((formName) => formName === currentForm);
    const nextFormKeys: FormNames[] = Object.keys(forms).slice(
        currentFormIndex + 1,
        Object.keys(forms).length,
    ) as FormNames[];
    nextFormKeys.forEach((formName: FormNames) => {
        clearForm(formName);
        inputOptions[formName].visible = false;
        claimsTravelService.storageFields.formVisibility[formName] = false;
    });
    subFlowComponent.value?.clearForms();
    isSubFlowVisible.value = false;
}

function clearForm(formName: FormNames): void {
    const form: Form<WhatHappenedFields> | Form<DescribeEventFields> = forms[formName];
    switch (formName) {
        case AccidentDetailsFormNames.WhatHappened: {
            const accidentDateForm = form as Form<WhatHappenedFields>;
            accidentDateForm.field(FieldNames.WhatHappened).setValue({ selected: '', extra: '' });
            break;
        }
        case AccidentDetailsFormNames.DescribeEvent: {
            const accidentDateForm = form as Form<DescribeEventFields>;
            accidentDateForm.field(FieldNames.DescribeEvent).setValue('');
            break;
        }
    }
    storeForm(formName);
}

function markFormsAsReady(): void {
    Object.values(forms).forEach((form) => form.setReady());
}

function translated(stringId: string): string {
    return translateForType(stringId, type());
}

function onWhatHappenedProceedClick(): void {
    const form = forms[AccidentDetailsFormNames.WhatHappened];
    form.submitAttempt().then(() => {
        if (form.isValid()) {
            proceedToNextForm(AccidentDetailsFormNames.WhatHappened);
        }
    });
}

function onDescribeEventProceedClick(): void {
    const form = forms[AccidentDetailsFormNames.DescribeEvent];
    form.submitAttempt().then(() => {
        if (form.isValid()) {
            isSubFlowVisible.value = true;
            claimsTravelService.storageFields.isSubFlowVisible = true;
        }
    });
}

function storeForm(formName: FormNames): void {
    return claimsTravelService.storeForm(formName as keyof ClaimsTravelFormFields, forms[formName]);
}

function onFormInputChange(formName: FormNames): void {
    clearFormsAhead(formName);
    storeForm(formName);
}

watch(subFlowComponent, () => {
    if (subFlowComponent.value !== null) {
        subFlowComponent.value.scrollToLastForm();
    }
});

onMounted(() => {
    OneBaseService.getInstance().applySpa(getCurrentInstance());
    applyTranslationType('claims_travel');
    setupForms();
    claimsTravelService.restoreForms(forms);
    isSubFlowVisible.value = claimsTravelService.storageFields.isSubFlowVisible;

    setTimeout(() => {
        markFormsAsReady();
        preparePanels();
        scrollToLastForm();
    });
});
</script>

<template>
    <div class="container horizontal-spacing">
        <app-custom-form
            v-if="forms[AccidentDetailsFormNames.WhatHappened]?.isReady()"
            :form="forms[AccidentDetailsFormNames.WhatHappened]"
            class="form"
            @change="storeForm(AccidentDetailsFormNames.WhatHappened)"
        >
            <div class="whiteboard-panel whiteboard-panel-margin">
                <button class="back back-margin" :href="claimsTravelService.initialStepUrl">
                    <img src="images/one/arrow-left.svg" alt="back" />
                    <span>{{ translate('back_button') }}</span>
                </button>
                <label>{{ translated('accident_data') }}</label>
                <div
                    class="whiteboard"
                    :data-type="'whiteboard-0'"
                    :data-scroll="AccidentDetailsFormNames.WhatHappened"
                >
                    <div class="inputs row">
                        <div class="input-block">
                            <h4>{{ translated('what_happened_during_trip') }}</h4>
                            <app-options-smart-list
                                :options="inputOptions[AccidentDetailsFormNames.WhatHappened].options"
                                :type="'radio'"
                                :option-class="'filled'"
                                :show-error-borders="false"
                                :skip-options-change-form-reset="true"
                                :form-field="
                                    forms[AccidentDetailsFormNames.WhatHappened].field(FieldNames.WhatHappened)
                                "
                                @change="onFormInputChange(AccidentDetailsFormNames.WhatHappened)"
                            >
                            </app-options-smart-list>
                        </div>
                    </div>
                    <app-button-with-callback
                        class="button"
                        v-bind="proceedButtonParams()"
                        @click="onWhatHappenedProceedClick"
                    />
                </div>
            </div>
        </app-custom-form>
        <app-custom-form
            v-if="forms[AccidentDetailsFormNames.DescribeEvent]?.isReady()"
            :form="forms[AccidentDetailsFormNames.DescribeEvent]"
            class="form"
            @change="storeForm(AccidentDetailsFormNames.DescribeEvent)"
        >
            <div class="whiteboard-panel">
                <div
                    v-if="isPanelVisible(AccidentDetailsFormNames.DescribeEvent)"
                    class="whiteboard"
                    :data-type="'whiteboard-1'"
                    :data-scroll="AccidentDetailsFormNames.DescribeEvent"
                >
                    <div class="inputs row">
                        <div class="input-block">
                            <h4>{{ translated('describe_event') }}</h4>
                            <div v-if="accidentDescriptionKey" class="description">
                                {{ translated(accidentDescriptionKey) }}
                            </div>
                            <app-input-textarea
                                :form-field="
                                    forms[AccidentDetailsFormNames.DescribeEvent].field(FieldNames.DescribeEvent)
                                "
                                :placeholder="translated('accident_description_placeholder')"
                                @change="onFormInputChange(AccidentDetailsFormNames.DescribeEvent)"
                            >
                            </app-input-textarea>
                        </div>
                    </div>
                    <app-button-with-callback
                        class="button"
                        v-bind="proceedButtonParams()"
                        @click="onDescribeEventProceedClick"
                    />
                </div>
            </div>
        </app-custom-form>
        <component
            :is="selectedSubFlowComponent"
            v-if="selectedSubFlowComponent && isSubFlowVisible"
            ref="subFlowComponent"
            :sub-type="selectedSubType!"
            @completed="onCompleted"
        ></component>
    </div>
</template>

<style scoped lang="scss">
.form {
    .button-with-callback {
        height: 52px;
    }

    .description {
        color: var(--black-500);
        margin-bottom: var(--size-small);
    }
}
</style>
