<script setup lang="ts">
import Form from '@/assets/libraries/form/form';
import { getCurrentInstance, markRaw, onMounted, reactive, ref, Ref, UnwrapNestedRefs } from 'vue';
import RentersService from '@/Apps/Renters/Services/RentersService';
import { useTranslate } from '@/Composables/Translate';
import { Router, useRouter } from 'vue-router';
import { Renters } from '@/Apps/Renters/Interfaces/RentersInterface';
import FormField from '@/assets/libraries/form/form-field';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import Value from '@/assets/libraries/form/value';
import AdditionalOption from '@/Components/Lists/AdditionalOptionsList/Interfaces/RentersOptionInterface';
import InsurancePlan from '@/Components/Widgets/InsurancePlanWidget/Interfaces/InsurancePlanInterface';
import InsurancePlanBuilder from '@/Components/Widgets/InsurancePlanWidget/Builders/InsurancePlanBuilder';
import InsurancePlanItem from '@/Components/Widgets/InsurancePlanWidget/Interfaces/InsurancePlanItemInterface';
import { CoveragePlan } from '@/interfaces/resources/MovableProperties/CoveragePlanInterface';
import { CoverageRisk } from '@/interfaces/resources/MovableProperties/CoverageRiskInterface';
import RentersOptionBuilder from '@/Components/Lists/AdditionalOptionsList/Builders/RentersOptionBuilder';
import InsurancePlanItemBuilder from '@/Components/Widgets/InsurancePlanWidget/Builders/InsurancePlanItemBuilder';
import { useNumbers } from '@/Composables/Numbers';
import PopupService from '@/services/custom.popup.service';
import StepsGuard from '@/Apps/Renters/Services/StepsGuard';
import { useFormatter } from '@/Composables/Formatter';
import OneBaseService from '@/services/OneBaseService';
import AppCustomForm from '@/Components/Inputs/CustomForm/CustomForm.vue';
import AppAdditionalOptionsList from '@/Components/Lists/AdditionalOptionsList/AdditionalOptionsList.vue';
import AppInsurancePlanWidget from '@/Components/Widgets/InsurancePlanWidget/InsurancePlanWidget.vue';

const { sparsePrice } = useFormatter();
const form: Form<{ additionalOptions: DynamicDictionary }> = new Form({ useValidationV2: true });
const rentersService: RentersService = RentersService.getInstance();
const { translate, translateForType } = useTranslate();
const TranslationType: string = 'renters';
const viewIsReady: Ref<boolean> = ref(false);
const popupService: PopupService = PopupService.getInstance();
const router: Router = useRouter();
const additionalOptions: Ref<AdditionalOption[]> = ref([]);
const ExcludedRisks: string[] = ['ALL_RISKS', 'DEDUCTIBLE'];
const insurancePlan: UnwrapNestedRefs<InsurancePlan> = reactive({
    title: '',
    priceTitle: '',
    price: 0,
    discount: 0,
    items: [],
    paymentFrequency: '',
});
const insurancePlanPrice: Ref<number> = ref(0);
const insurancePlanItems: Ref<InsurancePlanItem[]> = ref([]);

onMounted(() => {
    OneBaseService.getInstance().applySpa(getCurrentInstance());
    rentersService.updateRoute();
    StepsGuard.getInstance(rentersService).init();
    setupForm();
    restoreValues();
    rentersService.fetchProducts().then((): void => {
        buildAdditionalOptions(rentersService.selectedProduct());
        buildInsurancePlanItems();
        calculatePrice();
        buildInsurancePlan();
        patchAllOptions();
        viewIsReady.value = true;
    });
});

function setupForm(): void {
    form.addField(new FormField('additionalOptions'));
    form.setReady();
}

function selectedProductCoverage(): CoveragePlan {
    return rentersService.selectedProduct().coveragePlans[coverageId()];
}

function restoreValues(): void {
    form.restoreValues({
        additionalOptions: rentersService.fields.additionalOptions,
    });
}

function storeValues(): void {
    rentersService.fields.additionalOptions = form.field('additionalOptions').value;
}

function programTitle(): string {
    return rentersService.selectedProduct().id === 'RENTER'
        ? 'program_renters_insurance'
        : 'program_renters+_insurance';
}

function coverageSum(): number {
    return rentersService.selectedProduct().coveragePlans[coverageId()].insuredSum;
}

function coveragePrice(): number {
    return rentersService.selectedProduct().coveragePlans[coverageId()].price;
}

function buildInsurancePlan(): void {
    Object.assign(
        insurancePlan,
        new InsurancePlanBuilder()
            .withTitle(translateForType('my_policy', 'renters'))
            .withPrice(insurancePlanPrice.value)
            .withPriceTitle(translateForType('your_monthly_payment', 'renters'))
            .withItems(insurancePlanItems.value)
            .withPaymentFrequency('&nbsp;' + translate('btar_policy_price_text'))
            .build(),
    );
}

function buildInsurancePlanItems(): void {
    insurancePlanItems.value = [];
    addSelectedProductToItems();
    activeRisks().forEach((risk: CoverageRisk): void => {
        insurancePlanItems.value.push(
            new InsurancePlanItemBuilder()
                .withTitle(translateForType(risk.id + '_MAIN', 'renters'))
                .withPrice(risk.price)
                .build(),
        );
    });
}

function addSelectedProductToItems(): void {
    const storedProduct: Renters = rentersService.selectedProduct();
    const coveragePlan: CoveragePlan | undefined = selectedProductCoverage();
    if (storedProduct && coveragePlan) {
        insurancePlanItems.value.push(
            new InsurancePlanItemBuilder()
                .withTitle(translateForType(storedProduct.id.toLowerCase(), 'renters'))
                .withPrice(Number(coveragePlan.price))
                .build(),
        );
    }
}

function activeRisks(): CoverageRisk[] {
    const storedOptions: DynamicDictionary = form.field('additionalOptions').value;

    return rentersService.selectedProduct().risks.filter((risk: CoverageRisk): boolean => storedOptions[risk.id]);
}

function buildAdditionalOptions(product: Renters): void {
    const storedOptions: DynamicDictionary = form.field('additionalOptions').value;
    product.risks.forEach((risk: CoverageRisk): void => {
        if (isIncludedRisk(risk) && !ExcludedRisks.some((excluded: string): boolean => excluded === risk.id)) {
            additionalOptions.value.push(
                new RentersOptionBuilder()
                    .withName(translateForType(risk.id, TranslationType))
                    .withCoverageRisk(markRaw(risk))
                    .withPaymentFrequency(' ' + translate('btar_policy_price_text'))
                    .withState(storedOptions[risk.id] ?? false)
                    .build(),
            );
        }
    });
}

function calculatePrice(): void {
    const optionsAdditional: DynamicDictionary = form.field('additionalOptions').value;
    const coveragePlan: CoveragePlan | undefined = selectedProductCoverage();

    const prices: number[] = [];
    if (coveragePlan) {
        prices.push(coveragePlan.price);
        if (new Value(optionsAdditional).isNotEmpty()) {
            Object.keys(optionsAdditional).forEach((option: string): void => {
                if (optionsAdditional[option]) {
                    const selectedRisk: CoverageRisk | undefined = rentersService
                        .selectedProduct()
                        .risks.find((risk: CoverageRisk): boolean => risk.id === option);
                    if (selectedRisk) {
                        prices.push(selectedRisk.price);
                    }
                }
            });
        }
    }
    insurancePlanPrice.value = useNumbers().arraySum(prices);
}

function isIncludedRisk(risk: CoverageRisk): boolean {
    return isAdditionalRiskWithPrice(risk);
}

function isAdditionalRiskWithPrice(risk: CoverageRisk): boolean {
    return risk.isAdditional && risk.price > 0;
}

function onAdditionalOptionMounted(optionEmit: DynamicDictionary): void {
    refreshAdditionalOptions(optionEmit, false);
}

function onAdditionalOptionToggle(optionEmit: DynamicDictionary): void {
    refreshAdditionalOptions(optionEmit, true);
}

function refreshAdditionalOptions(optionEmit: DynamicDictionary, stashValues: boolean = false): void {
    Object.keys(optionEmit).forEach((key: string): void => {
        const tempValue: DynamicDictionary = form.field('additionalOptions').value;
        if (new Value(tempValue).isNotEmpty()) {
            tempValue[key] = optionEmit[key];
            form.field('additionalOptions').setValue(tempValue);
        } else {
            form.field('additionalOptions').setValue({
                ...tempValue,
                optionEmit,
            });
        }
    });
    calculatePrice();
    buildInsurancePlanItems();
    buildInsurancePlan();
    if (stashValues) {
        storeValues();
    }
}

function patchAllOptions(): void {
    let tempValue: DynamicDictionary | undefined = JSON.parse(JSON.stringify(form.field('additionalOptions').value));
    if (tempValue) {
        additionalOptions.value.forEach((option: AdditionalOption): void => {
            tempValue = { [option.risk.id]: option.enabled.value };
        });
        form.field('additionalOptions').patch(tempValue);
    }
    storeValues();
}

function onInsuranceWidgetContinue(): void {
    prepareSubmit();
    router.push({ name: 'renters-address' });
    popupService.hide();
}

function prepareSubmit(): void {
    rentersService.fields.additionalOptions = form.field('additionalOptions').value;
}

function coverageId(): number {
    return Number(rentersService.fields.coverage);
}
</script>

<template>
    <div v-if="viewIsReady" class="step-container">
        <app-custom-form v-if="form.isReady()" :form="form" class="form">
            <section class="header">
                <h2 class="title">{{ translateForType('additional_options_title', TranslationType) }}</h2>
                <div class="description">{{ translateForType('additional_options_description', TranslationType) }}</div>
            </section>
            <div class="summary-form">
                <div class="widgets-container">
                    <div class="left-side">
                        <div class="policy-plan">
                            <div class="plan-title">
                                <span>{{ translateForType(programTitle(), TranslationType) }}</span>
                                <span
                                    >&nbsp;{{ coveragePrice() }}<span class="price-text"></span>
                                    {{ translate('btar_policy_price_text') }}</span
                                >
                            </div>
                            <div class="coverage">
                                <img class="" src="images/one/checkmark-thin.svg" width="24" height="24" alt="" />
                                <span
                                    >{{ sparsePrice(coverageSum(), true) }}&nbsp;&euro;&nbsp;{{
                                        translateForType('amount', TranslationType)
                                    }}</span
                                >
                            </div>
                        </div>
                        <app-additional-options-list
                            :form-field="form.field('additionalOptions')"
                            :options="additionalOptions"
                            @option-mounted="onAdditionalOptionMounted($event)"
                            @option-toggle="onAdditionalOptionToggle($event)"
                        >
                        </app-additional-options-list>
                    </div>
                    <div class="right-side">
                        <app-insurance-plan-widget
                            :insurance-plan="insurancePlan"
                            @insurance-widget-continue="onInsuranceWidgetContinue"
                        >
                        </app-insurance-plan-widget>
                    </div>
                </div>
            </div>
        </app-custom-form>
    </div>
</template>

<style lang="scss" scoped>
.step-container {
    width: 100%;

    .header {
        padding: 0 var(--size-small);

        @include respond-above('sm') {
            padding: 0 var(--size-big);
        }

        .title,
        .description {
            text-align: center;
        }

        .title {
            font-size: var(--font-size-big);
        }
    }

    .summary-form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--size-small);
        margin-top: var(--size-big);

        @include respond-above('sm') {
            gap: var(--size-normal);
            align-items: center;
        }

        .widgets-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: var(--size-normal);

            :deep(.item-zero-price) {
                white-space: nowrap;
            }

            .left-side {
                display: flex;
                flex-direction: column;
                gap: var(--size-small);

                .policy-plan {
                    padding: var(--size-small);
                    border: 2px solid var(--component-color-border-active);
                    background: linear-gradient(
                            0deg,
                            var(--system-color-success-light) 0%,
                            var(--system-color-success-light) 100%
                        ),
                        var(--white);
                    border-radius: 8px;

                    @include respond-above('sm') {
                        width: 440px;
                    }

                    .plan-title {
                        display: flex;
                        justify-content: space-between;
                        font-weight: bold;
                        font-size: var(--font-size-small);

                        .price-text {
                            font-size: var(--font-size-nano);
                            font-weight: initial;
                        }
                    }

                    .coverage {
                        font-size: var(--font-size-nano);
                        color: var(--text-color-link);
                        margin-top: var(--size-nano);
                    }
                }
            }

            @include respond-above('sm') {
                gap: var(--size-big);
                flex-direction: row;
            }
        }
    }

    .full-width {
        width: 100%;
    }

    .half-children {
        .input {
            @include respond-above('sm') {
                width: 50%;
            }
        }
    }

    .flex {
        display: flex;
        gap: var(--size-small);

        &.column {
            flex-direction: column;
        }

        &.mobile-column {
            flex-direction: column;

            @include respond-above('sm') {
                flex-direction: row;
            }
        }
    }
}
</style>
