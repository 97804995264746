<script setup lang="ts">
import RecurringPaymentBanklink from '@/Enums/RecurringPaymentBanklinkEnum';
import AppCountry from '@/assets/libraries/app/app-country';
import PolicyDetails from '@/Components/Policies/PolicyDetails/Services/PolicyDetailsService';
import AddCardParams from '@/Components/Policies/PolicyDetails/Interfaces/AddCardParamsInterface';
import Popup from '@/services/popup.service';
import PopupType from '@/Enums/PopupTypeEnum';
import Url from '@/Enums/UrlEnum';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import Error from '@/services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import { AxiosParams, useAxios } from '@/Composables/Axios';
import AppCreditCardWidget from '@/Components/CreditCards/CreditCardWidget/CreditCardWidget.vue';
import PopupService from '@/services/custom.popup.service';
import { PropType } from 'vue';
import PolicyDetailsPaymentMethodContent from '@/Components/Policies/PolicyDetailsPaymentMethod/Interfaces/PolicyDetailsPaymentMethodContentInterface';
import OnePopup from '@/assets/libraries/popups/one.popup';
import { useNavigate } from '@/Composables/Navigate';

const props = defineProps({
    content: {
        type: Object as PropType<PolicyDetailsPaymentMethodContent>,
        default: () => {
            return new (class implements PolicyDetailsPaymentMethodContent {
                public cardNumbers: string = '';
                public cardStatus: string = '';
            })();
        },
    },
});

const request: AxiosParams = useAxios();

function onUpdatePaymentCard(): void {
    const bankLinkId: number = RecurringPaymentBanklink.ByCountry[new AppCountry().iso()];
    const policyDetails: PolicyDetails = PolicyDetails.getInstance();
    const params: AddCardParams = {
        banklinkId: bankLinkId,
        policyType: policyDetails.currentAgreement.type,
        agreementId: policyDetails.currentAgreement.id ?? '',
        agreementNumber: policyDetails.currentAgreement.agreementNumber ?? '',
        numberPart: null,
        extra: null,
    };
    PopupService.getInstance().show(new OnePopup().withType().loading);
    request
        .post(Url.Ajax.dashboardUpdatePaymentCard, params)
        .then((value: DynamicDictionary) => {
            if (value.data.data.status === 'OK') {
                useNavigate().navigate(Url.SubscriptionApi.recurringRegisterWithoutPayment);
            } else {
                throw value.data.errors.code;
            }
        })
        .catch((reason: DynamicDictionary) => {
            Error.getInstance().show(ErrorType.Error, 'AppPolicyDetailsPaymentMethod::onUpdatePaymentCard', reason);
        })
        .finally(() => {
            Popup.getInstance().showPopup(PopupType.None);
        });
}
</script>

<template>
    <div class="policy-details-payment-method">
        <app-credit-card-widget
            :class="'full-width'"
            :card-numbers="content.cardNumbers"
            :card-status="content.cardStatus"
            @card-widget-submit="onUpdatePaymentCard"
        ></app-credit-card-widget>
    </div>
</template>
