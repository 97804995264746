<script setup lang="ts">
import ClaimsMtplService from '@/Apps/ClaimsMtpl/Services/ClaimsMtplService';
import Form from '@/assets/libraries/form/form';
import { computed, getCurrentInstance, onMounted, reactive, ref, Ref, UnwrapNestedRefs } from 'vue';
import FormField from '@/assets/libraries/form/form-field';
import { useTranslate } from '@/Composables/Translate';
import { InputOption } from '@/interfaces/InputOptionInterface';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import AccidentDetailsPanelsNames from '@/Apps/ClaimsMtpl/Enums/AccidentDetailsPanelsNamesEnum';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import { useStrings } from '@/Composables/Strings';
import OneBaseService from '@/services/OneBaseService';
import OneBase from '@/interfaces/OneBaseInterface';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import ButtonIcon from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.enum';
import ButtonIconPosition from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.position.enum';
import { useClaimsMtplHtml } from '@/Apps/ClaimsMtpl/Composables/ClaimsMtplHtml';
import ButtonWithCallbackParams from '@/Components/Buttons/ButtonWithCallback/Enums/button.params';
import Translations from '@/services/translations.service';
import Value from '@/assets/libraries/form/value';
import { useDefine } from '@/Composables/Define';
import { Router, useRouter } from 'vue-router';
import ClaimsMtplOption from '@/Apps/ClaimsMtpl/Interfaces/ClaimsMtplOptionInterface';
import ClaimsMtplOptions from '@/Apps/ClaimsMtpl/Interfaces/ClaimsMtplOptionsInterface';
import { useAxios } from '@/Composables/Axios';
import MtplClaimsAjaxCalls from '@/Apps/ClaimsMtpl/Enums/MtplClaimsAjaxCallsEnum';
import { AxiosResponse } from 'axios';
import ErrorType from '@/Enums/ErrorTypeEnum';
import ClaimsMtplFormFields from '@/Apps/ClaimsMtpl/Classes/ClaimsMtplFormFields';
import GuardsService from '@/Apps/ClaimsMtpl/Services/GuardsService';
import MtplClaimsStepUid from '@/Apps/ClaimsMtpl/Enums/MtplClaimsStepUidEnum';

const btaBase: OneBase = OneBaseService.getInstance();
const claimsMtplService: ClaimsMtplService = ClaimsMtplService.getInstance();

const router: Router = useRouter();
const { translate, translateForType } = useTranslate();
const { isSet, validResponse } = useDefine();
const { scrollToPanel } = useClaimsMtplHtml();

const form: Form = new Form();
const inputOptions: UnwrapNestedRefs<ClaimsMtplOptions> = reactive({});
const accidentDescriptionText: Ref<string> = ref('');
const canClearFormsAhead: Ref<boolean> = ref(false);

const Step: number = 2;
const ExtraDetailsStepId: number = 3;
const panelsToEnable: { [key: string]: string[] } = {
    guilty: [AccidentDetailsPanelsNames.WhereAccidentHappened, AccidentDetailsPanelsNames.AccidentDescription],
    victim: [
        AccidentDetailsPanelsNames.WhereAccidentHappened,
        AccidentDetailsPanelsNames.AccidentDescription,
        AccidentDetailsPanelsNames.DidGuiltyLeaveSceneBeforePolice,
    ],
    direct_claim: [AccidentDetailsPanelsNames.WhereAccidentHappened, AccidentDetailsPanelsNames.AccidentDescription],
};

const claimType: Ref<string> = computed(() => {
    return claimsMtplService.fields.typeOfClaim!.selected;
});

const damageTypes: Ref<string[]> = computed(() => {
    const result: DynamicDictionary = claimsMtplService.fields.whatWasDamaged!;
    return String(result) !== '' ? result.selected.split('@#@') : [];
});

const accidentDetailsPanelsNames: Ref<typeof AccidentDetailsPanelsNames> = computed(() => {
    return AccidentDetailsPanelsNames;
});

const isFoundElectronically: Ref<boolean> = computed(() => {
    return (
        claimsMtplService.fields.weFoundElectronically!.selected === 'yes' ||
        claimsMtplService.fields.vehicleProceedWithAgreedStatement!.selected === 'yes'
    );
});

const isPoliceReport: Ref<boolean> = computed(() => {
    return claimsMtplService.fields.howWasInvolvedHandled!.selected === 'police_protocol';
});

const canProceedToNextStep: Ref<boolean> = computed(() => {
    let result: boolean = true;
    Object.keys(inputOptions).forEach((panelKey: string) => {
        if (inputOptions[panelKey].enabled && !inputOptions[panelKey].passed) {
            result = false;
        }
    });

    return result;
});

const shouldFetchDescriptionText: Ref<boolean> = computed(() => {
    return damageTypes.value.length > 0;
});

function applyPassedOnOption(option: string, passed: boolean): void {
    inputOptions[option].passed = passed;
}

function onProceed(panel: string): void {
    const page: string = claimsMtplService.isExtraDetailsPageVisible()
        ? MtplClaimsStepUid.ExtraDetails
        : MtplClaimsStepUid.UploadFiles;
    applyPassedOnOption(panel, true);
    GuardsService.getInstance().applyStepValidity(ExtraDetailsStepId, true, true);
    !isLastPanel(panel) ? showNextPanel(panel) : router.push({ name: page });
}

function showNextPanel(panel: string, initial: boolean = false): void {
    initial ? showNextPanelInitial() : showNextPanelRegular(panel);
}

function showNextPanelInitial(): void {
    const firstEnabledPanelKey: string | undefined = Object.keys(inputOptions).find((key) => inputOptions[key].enabled);
    applyVisibleOnOption(firstEnabledPanelKey ?? '', true);
}

function showNextPanelRegular(panel: string): void {
    const nextEnabledPanel: string = nextEnabledPanelName(panel);
    applyVisibleOnOption(nextEnabledPanel, true);
    scrollToPanel(nextEnabledPanel);
}

function scrollToLastPanel(): void {
    let lastVisiblePanel: string = '';
    Object.keys(inputOptions).forEach((panelKey: string, index: number) => {
        if (inputOptions[panelKey].enabled && inputOptions[panelKey].visible && index !== 0) {
            lastVisiblePanel = panelKey;
        }
    });
    if (lastVisiblePanel !== '') {
        useClaimsMtplHtml().scrollToPanel(lastVisiblePanel);
    }
}

function applyVisibleOnOption(option: string, visible: boolean): void {
    if (optionIsPresent(option)) {
        inputOptions[option].visible = visible;
    }
}

function optionIsPresent(option: string): boolean {
    return isSet(inputOptions[option]);
}

function nextEnabledPanelName(panel: string): string {
    let result: string = '';
    let nextTargetPanel: boolean = false;
    Object.keys(inputOptions).forEach((key: string): void => {
        if (nextTargetPanel && inputOptions[key].enabled) {
            result = key;
            nextTargetPanel = false;
        }
        if (key === panel) {
            nextTargetPanel = true;
        }
    });

    return result;
}

function isVisiblePanel(panel: string): boolean {
    return inputOptions[panel].enabled && inputOptions[panel].visible;
}

function isLastPanel(panel: string): boolean {
    const lastPanelKey: string | undefined = Object.keys(inputOptions)
        .reverse()
        .find((key) => inputOptions[key].enabled);

    return lastPanelKey === panel;
}

function isFirstVisiblePanel(panel: string): boolean {
    const firstPanelKey: string | undefined = Object.keys(inputOptions).find((key) => inputOptions[key].enabled);

    return firstPanelKey === panel;
}

function proceedButtonIsVisible(panel: string): boolean {
    let result: boolean;
    if (isLastPanel(panel)) {
        result = inputOptions[panel].enabled && inputOptions[panel].visible;
    } else {
        result = inputOptions[panel].enabled && inputOptions[panel].visible && !inputOptions[panel].passed;
    }

    return result;
}

function onFormPanelInputChange(panel: string): void {
    transformAndApplyValueOnOptions(panel);
    let clear: boolean = false;
    Object.keys(inputOptions).forEach((key: string): void => {
        if (clear) {
            applyResetOnOption(key);
        }
        if (key === panel) {
            clear = true;
            applyPassedOnOption(key, false);
        }
        if (isLastPanel(panel)) {
            applyPassedOnOption(key, true);
        }
    });
    GuardsService.getInstance().applyStepValidity(Step, canProceedToNextStep.value, true);
    ClaimsMtplService.getInstance().clearFormsAhead(Step);
}

function applyResetOnOption(option: string): void {
    applyValueOnOption(option, '');
    applyPassedOnOption(option, false);
    applyVisibleOnOption(option, false);
    form.field(option).clear().then();
}

function transformAndApplyValueOnOptions(field: string): void {
    const resultValue: LimitedVariant = form.field(field).value;
    applyValueOnOption(field, resultValue);
}

function applyValueOnOption(option: string, newValue: LimitedVariant): void {
    if (optionIsPresent(option)) {
        inputOptions[option].value = newValue;
    }
}

function applyOptionsOnOption(option: string, options: InputOption[]): void {
    if (optionIsPresent(option)) {
        inputOptions[option].options = options;
    }
}

function proceedButton(fieldName: string): ButtonWithCallbackParams {
    return {
        title: proceedButtonText(fieldName),
        textColor: ButtonTextColor.White,
        backgroundColor: ButtonBackground.Red,
        icon: ButtonIcon.LongArrowRight,
        iconPosition: ButtonIconPosition.Right,
    };
}

function proceedButtonText(fieldName: string): string {
    let text: string;
    switch (fieldName) {
        case AccidentDetailsPanelsNames.WhereAccidentHappened:
            text = form.field(AccidentDetailsPanelsNames.WhereAccidentHappened).isValid
                ? localized('proceed')
                : localized('where_accident_happened_button');
            break;
        default:
            text = localized('proceed');
            break;
    }

    return text;
}

function preparePanels(): void {
    buildPanels();
    enablePanels(panelsToEnable[claimType.value]);
}

function buildPanels(): void {
    Object.keys(AccidentDetailsPanelsNames).forEach((key: string) => {
        const panelName: string = AccidentDetailsPanelsNames[key as keyof AccidentDetailsPanelsNames];
        inputOptions[panelName] = reactive(
            new (class implements ClaimsMtplOption {
                public enabled: boolean = false;
                public passed: boolean = false;
                public visible: boolean = false;
                public value: LimitedVariant = '';
                public options: InputOption[] = [];
            })(),
        );
    });
}

function enablePanels(panelsNames: string[]): void {
    panelsNames.forEach((panelName) => {
        const panel: ClaimsMtplOption = inputOptions[panelName];
        switch (panelName) {
            case AccidentDetailsPanelsNames.WhereAccidentHappened:
                panel.enabled = !isFoundElectronically.value;
                panel.visible = !isFoundElectronically.value;
                break;
            case AccidentDetailsPanelsNames.DidGuiltyLeaveSceneBeforePolice:
                panel.enabled =
                    isPoliceReport.value && !isFoundElectronically.value && btaBase.settings.localeIso() === 'LV';
                break;
            default:
                panel.enabled = true;
                break;
        }
    });
    showNextPanel('', true);
}

function setupForm(): void {
    const validators: (string | Record<string, any>)[] = [
        'required',
        accidentDescriptionValidator(),
        didGuiltyLeaveValidator(),
    ];
    Object.keys(inputOptions).forEach((field: string, index: number) => {
        form.addField(new FormField(field, '', validators[index]));
    });
    form.setReady();
}

function patchForm(): void {
    if (damageTypes.value.includes('property')) {
        form.field(AccidentDetailsPanelsNames.WhereAccidentHappened).patch({
            ...claimsMtplService.fields.propertyLocation!,
        });
    }
}

function buildStaticOptions(): void {
    buildDidGuiltyLeaveSceneBeforePoliceOptions();
}

function buildDidGuiltyLeaveSceneBeforePoliceOptions(): void {
    const options: InputOption[] = [
        new InputOptionBuilder().setName(translate('btar_yes')).setValue(true).build(),
        new InputOptionBuilder().setName(translate('btar_no')).setValue(false).build(),
    ];
    applyOptionsOnOption(AccidentDetailsPanelsNames.DidGuiltyLeaveSceneBeforePolice, options);
}

function fetchAccidentDescriptionText(): void {
    const params: DynamicDictionary = {
        claimType: claimType.value,
        damageTypes: damageTypes.value,
    };
    useAxios()
        .get(MtplClaimsAjaxCalls.AccidentDescriptionText, { params })
        .then((response: AxiosResponse<DynamicDictionary>) => {
            if (validResponse(response)) {
                accidentDescriptionText.value =
                    response.data.data.body.descriptionKeys.length > 1
                        ? localized('multi_event')
                        : localized(response.data.data.body.descriptionKeys);
            } else {
                btaBase.error.show(ErrorType.Error, 'fetchAccidentDescriptionText', 'claims_mtpl_invalid_data');
            }
        })
        .catch((reason: LimitedVariant): void => {
            btaBase.error.show(ErrorType.Error, 'findDamageType', reason as DynamicDictionary);
        });
}

function accidentDescriptionValidator(): object {
    return {
        describeEventValid: (value: string) => {
            return form.field('accidentDescription').isDirty ? !accidentDescriptionProceedIsDisabled(value) : false;
        },
    };
}

function didGuiltyLeaveValidator(): object {
    return {
        didGuiltyLeaveValid: (value: string) => {
            return form.field(AccidentDetailsPanelsNames.DidGuiltyLeaveSceneBeforePolice).isDirty
                ? isSet(form.field(AccidentDetailsPanelsNames.DidGuiltyLeaveSceneBeforePolice).value.selected) &&
                      form.field(AccidentDetailsPanelsNames.DidGuiltyLeaveSceneBeforePolice).value.selected !== ''
                : false;
        },
    };
}

function accidentDescriptionProceedIsDisabled(descriptionText: string): boolean {
    const multibyteLength: number = useStrings().multibyteLength(descriptionText);
    const minLength: number = btaBase.settings.claimsSettings().mtpl.descriptionFieldMinLength;
    const maxLength: number = btaBase.settings.claimsSettings().mtpl.descriptionFieldMaxLength;
    const valueLengthOutOfBounds: boolean = multibyteLength < minLength || multibyteLength > maxLength;
    const valueHasInvalidText: boolean = !useStrings().isValidWordString(descriptionText);

    return [valueLengthOutOfBounds, valueHasInvalidText].some((value: boolean): boolean => value);
}

function storeFormToService(): void {
    Object.keys(AccidentDetailsPanelsNames).forEach((field: string) => {
        const keyName: string = AccidentDetailsPanelsNames[field as keyof AccidentDetailsPanelsNames];
        claimsMtplService.fields[keyName as keyof ClaimsMtplFormFields] = form.field(keyName).value;
    });
}

function restoreValues(): void {
    const storedValues: DynamicDictionary = claimsMtplService.fields;
    Object.keys(inputOptions).forEach((field: string) => {
        if (isSet(storedValues[field]) && new Value(storedValues[field]).isNotEmpty()) {
            form.field(field).patch(storedValues[field]);
            restorePanels(field);
        }
    });
}

function restorePanels(panel: string): void {
    transformAndApplyValueOnOptions(panel);
    applyPassedOnOption(panel, true);
    showNextPanel(panel);
}

function localized(stringUid: string): string {
    return translateForType(stringUid, Translations.getInstance().type);
}

preparePanels();

onMounted(() => {
    OneBaseService.getInstance().applySpa(getCurrentInstance());
    setupForm();
    patchForm();
    form.validate().then(() => {
        GuardsService.getInstance().applyStepValidity(Step, canProceedToNextStep.value);
        scrollToLastPanel();
    });
    if (shouldFetchDescriptionText.value) {
        fetchAccidentDescriptionText();
    }
    buildStaticOptions();
    restoreValues();
});

defineExpose({
    claimsMtplService,
    inputOptions,
    accidentDetailsPanelsNames,
    form,
});
</script>

<template>
    <div class="container horizontal-spacing">
        <app-custom-form v-if="form.isReady()" :form="form" class="form" @change="storeFormToService()">
            <div class="whiteboard-panel whiteboard-panel-margin">
                <router-link
                    v-if="isFirstVisiblePanel(AccidentDetailsPanelsNames.WhereAccidentHappened)"
                    v-slot="{ navigate }"
                    class="back back-margin"
                    :to="{
                        name: claimsMtplService.isDamagePageVisible()
                            ? MtplClaimsStepUid.Damage
                            : MtplClaimsStepUid.AccidentData,
                    }"
                >
                    <button @click="navigate">
                        <img src="images/one/arrow-left.svg" alt="back" />
                        <span>{{ translate('back_button') }}</span>
                    </button>
                </router-link>
                <label v-if="isFirstVisiblePanel(AccidentDetailsPanelsNames.WhereAccidentHappened)">
                    {{ localized('accident_details') }}
                </label>
                <div
                    v-if="isVisiblePanel(AccidentDetailsPanelsNames.WhereAccidentHappened)"
                    class="whiteboard"
                    data-type="whiteboard-map"
                    :class="{
                        'whiteboard-margin': isFirstVisiblePanel(AccidentDetailsPanelsNames.WhereAccidentHappened),
                    }"
                    :data-scroll="AccidentDetailsPanelsNames.WhereAccidentHappened"
                >
                    <h4 class="title">{{ localized('where_accident_happened_title') }}</h4>
                    <app-map-with-address
                        :label="localized('accident_address')"
                        :skip-options-change-form-reset="!canClearFormsAhead"
                        :custom-search-placeholder="localized('accident_address_placeholder')"
                        :form-field="form.field(AccidentDetailsPanelsNames.WhereAccidentHappened)"
                        @change="onFormPanelInputChange(AccidentDetailsPanelsNames.WhereAccidentHappened)"
                    >
                    </app-map-with-address>
                    <app-button-with-callback
                        v-if="proceedButtonIsVisible(AccidentDetailsPanelsNames.WhereAccidentHappened)"
                        class="button"
                        data-type="whiteboard-map-proceed"
                        v-bind="proceedButton(AccidentDetailsPanelsNames.WhereAccidentHappened)"
                        :disabled="!form.field(AccidentDetailsPanelsNames.WhereAccidentHappened).isValid"
                        @button-callback-click="onProceed(AccidentDetailsPanelsNames.WhereAccidentHappened)"
                    >
                    </app-button-with-callback>
                </div>
            </div>
            <div class="whiteboard-panel">
                <router-link
                    v-if="isFirstVisiblePanel(AccidentDetailsPanelsNames.AccidentDescription)"
                    v-slot="{ navigate }"
                    class="back back-margin"
                    :to="{ name: MtplClaimsStepUid.Damage }"
                >
                    <button @click="navigate">
                        <img src="images/one/arrow-left.svg" alt="back" />
                        <span>{{ translate('back_button') }}</span>
                    </button>
                </router-link>
                <label v-if="isFirstVisiblePanel(AccidentDetailsPanelsNames.AccidentDescription)">
                    {{ localized('mtpl_claim_accident_details') }}
                </label>
                <div
                    v-if="isVisiblePanel(AccidentDetailsPanelsNames.AccidentDescription)"
                    class="whiteboard"
                    :class="{
                        'whiteboard-margin': isFirstVisiblePanel(AccidentDetailsPanelsNames.AccidentDescription),
                    }"
                    :data-scroll="AccidentDetailsPanelsNames.AccidentDescription"
                >
                    <h4 class="title">{{ localized('accident_description_title') }}</h4>
                    <div
                        v-if="accidentDescriptionText !== ''"
                        class="description"
                        v-html="accidentDescriptionText"
                    ></div>
                    <app-input-textarea
                        :skip-options-change-form-reset="!canClearFormsAhead"
                        :form-field="form.field(AccidentDetailsPanelsNames.AccidentDescription)"
                        :placeholder="localized('accident_description_placeholder')"
                        @change="onFormPanelInputChange(AccidentDetailsPanelsNames.AccidentDescription)"
                    >
                    </app-input-textarea>
                    <app-button-with-callback
                        v-if="proceedButtonIsVisible(AccidentDetailsPanelsNames.AccidentDescription)"
                        class="button"
                        data-type="map-description-submit"
                        v-bind="proceedButton(AccidentDetailsPanelsNames.AccidentDescription)"
                        :disabled="!form.field(AccidentDetailsPanelsNames.AccidentDescription).isValid"
                        @button-callback-click="onProceed(AccidentDetailsPanelsNames.AccidentDescription)"
                    >
                    </app-button-with-callback>
                </div>
            </div>
            <div class="whiteboard-panel">
                <div
                    v-if="isVisiblePanel(AccidentDetailsPanelsNames.DidGuiltyLeaveSceneBeforePolice)"
                    class="whiteboard"
                    :data-scroll="AccidentDetailsPanelsNames.DidGuiltyLeaveSceneBeforePolice"
                >
                    <h4 class="title">{{ localized('did_guilty_leave_scene_before_police_title') }}</h4>
                    <app-options-list
                        :skip-options-change-form-reset="!canClearFormsAhead"
                        :form-field="form.field(AccidentDetailsPanelsNames.DidGuiltyLeaveSceneBeforePolice)"
                        :options="inputOptions[AccidentDetailsPanelsNames.DidGuiltyLeaveSceneBeforePolice].options"
                        :option-class="'filled'"
                        :required="true"
                        :show-error-borders="false"
                        :type="'radio'"
                        @change="onFormPanelInputChange(AccidentDetailsPanelsNames.DidGuiltyLeaveSceneBeforePolice)"
                    >
                    </app-options-list>
                    <app-button-with-callback
                        v-if="proceedButtonIsVisible(AccidentDetailsPanelsNames.DidGuiltyLeaveSceneBeforePolice)"
                        class="button"
                        v-bind="proceedButton(AccidentDetailsPanelsNames.DidGuiltyLeaveSceneBeforePolice)"
                        :disabled="!form.field(AccidentDetailsPanelsNames.DidGuiltyLeaveSceneBeforePolice).isValid"
                        @button-callback-click="onProceed(AccidentDetailsPanelsNames.DidGuiltyLeaveSceneBeforePolice)"
                    >
                    </app-button-with-callback>
                </div>
            </div>
        </app-custom-form>
    </div>
</template>

<style lang="scss" scoped>
.form {
    display: flex;
    flex-direction: column;
    padding: 0 !important;

    .input:not(:last-child) {
        margin-bottom: var(--size-normal);
    }

    .row {
        display: flex;
        flex-direction: row;

        .input:not(:last-child) {
            margin-right: var(--size-normal);
        }
    }

    .button {
        margin-top: var(--size-medium);
        padding: 0 var(--size-medium);

        .icon-right {
            margin-left: var(--size-nano);
        }
    }

    .button-with-callback {
        height: 52px;
    }

    .description {
        color: var(--black-500);
        margin-bottom: var(--size-small);
    }

    :deep(.map-with-address) {
        .search-container {
            .google-search-suggestions {
                .geo {
                    svg {
                        .pin {
                            fill: var(--black-500);
                        }
                    }
                }
            }
        }
    }
}
</style>
