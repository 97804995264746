<script setup lang="ts">
import { Router, useRouter } from 'vue-router';
import { useTranslate } from '@/Composables/Translate';
import AppSvg from '@/Components/Other/Svg/Svg.vue';

const router: Router = useRouter();
const { translate } = useTranslate();
</script>

<template>
    <button @click="router.back()">
        <app-svg src="images/one/arrow-left.svg"></app-svg>
        <span>{{ translate('back_button') }}</span>
    </button>
</template>

<style scoped lang="scss">
button {
    display: flex;
    column-gap: 12px;
    align-items: center;
    color: var(--text-color-link);
    font-size: var(--size-tiny);

    &:hover {
        color: var(--text-color-link-hover);

        :deep(svg) {
            path {
                stroke: var(--text-color-link-hover);
            }
        }
    }
}
</style>
