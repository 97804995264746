<script setup lang="ts">
import Form from '@/assets/libraries/form/form';
import { getCurrentInstance, onMounted } from 'vue';
import Storage from '@/Apps/ActivePlus/Services/Storage';
import { useTranslate } from '@/Composables/Translate';
import { Router, useRouter } from 'vue-router';
import StepsGuard from '@/Apps/ActivePlus/Services/StepsGuard';
import OneBaseService from '@/services/OneBaseService';
import AppCustomForm from '@/Components/Inputs/CustomForm/CustomForm.vue';

const { translate } = useTranslate();
const form: Form = new Form();
const router: Router = useRouter();
const storage: Storage = Storage.getInstance();

onMounted(() => {
    OneBaseService.getInstance().applySpa(getCurrentInstance());
    storage.updateRoute();
    StepsGuard.getInstance(storage).init();
    setupForm();
});

function setupForm(): void {
    form.setReady();
}
</script>

<template>
    <div class="container">
        <app-custom-form v-if="form.isReady()" :form="form" class="form">
            <div>Dummy</div>
        </app-custom-form>
    </div>
</template>
