<script lang="ts">
import { computed, defineComponent, nextTick, reactive, ref, Ref, UnwrapNestedRefs } from 'vue';
import OneBaseService from '@/services/OneBaseService';
import Form from '@/assets/libraries/form/form';
import { useTranslate } from '@/Composables/Translate';
import { Subscription } from 'rxjs';
import InsuredPerson from '@/interfaces/insured.person.interface';
import { InputOption } from '@/interfaces/InputOptionInterface';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import ClaimsEnum from '@/Enums/ClaimsEnum';
import Translations from '@/services/translations.service';
import { useStepsSubmitter } from '@/Composables/StepsSubmitter';
import FormField from '@/assets/libraries/form/form-field';
import Validation from '@/services/validation.service';
import AppCountry from '@/assets/libraries/app/app-country';
import Sanitizer from '@/services/sanitizer.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import Method from '@/Enums/MethodEnum';
import Url from '@/Enums/UrlEnum';
import type { SanitizerCallback } from '@/assets/libraries/form/form-field';
import { useDefine } from '@/Composables/Define';
import moment from 'moment/moment';
import ExtractDataService from '@/services/extract.data.service';
import CascoClaimsPanelsNames from '@/Enums/CascoClaimsPanelsNamesEnum';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const { isSet } = useDefine();
        const stepsSubmitter = useStepsSubmitter();
        const { translateForType } = useTranslate();

        const CurrentStep: number = 6;
        const NextStep: number = CurrentStep + 1;
        const Facility: string = 'one-claims-casco';
        const ThankYouStepFacility: string = 'claims-thank-you';
        const TimestampDivider: number = 1000;
        const DamageTypeGlass: string = 'GLASS';

        const form: Form = new Form();
        const formIsReady: Ref<boolean> = ref(false);
        const thankYouStepUrl: Ref<string> = ref('');
        let insuredPerson: UnwrapNestedRefs<InsuredPerson> = reactive(
            new (class implements InsuredPerson {
                public bankAccount: string = '';
                public birthDate: string | number = '';
                public companyName: string = '';
                public companyType: string = '';
                public currency: string = '';
                public driversLicense: string = '';
                public email: string = '';
                public firstName: string = '';
                public identityNumber: string = '';
                public isLegal: boolean = false;
                public isResident: boolean = false;
                public lastName: string = '';
                public personId: string = '';
                public phone: string = '';
                public phoneCode: string = '';
                public policyHolder: string = '';
                public registrationNumber: string = '';
                public residenceCountryIso: string = '';
            })(),
        );

        const beneficiaryOptions: Ref<InputOption[]> = computed(() => {
            return [
                new InputOptionBuilder()
                    .setValue('userBeneficiary')
                    .setName(translation('claims_casco_beneficiary_type_me'))
                    .build(),
                new InputOptionBuilder()
                    .setValue('otherBeneficiary')
                    .setName(translation('claims_casco_beneficiary_type_other'))
                    .build(),
            ];
        });

        const personTypeOptions: Ref<InputOption[]> = computed(() => {
            return [
                new InputOptionBuilder()
                    .setValue('privateResident')
                    .setName(translation('claims_casco_person_type_resident'))
                    .build(),
                new InputOptionBuilder()
                    .setValue('privateNonResident')
                    .setName(translation('claims_casco_person_type_non_resident'))
                    .build(),
                new InputOptionBuilder()
                    .setValue('legal')
                    .setName(translation('claims_casco_person_type_legal'))
                    .build(),
            ];
        });

        const currentUserIsInsuredPerson: Ref<boolean> = computed(() => {
            return btaBase.user.current.personCode === insuredPerson.identityNumber;
        });

        const currentUserIsPolicyHolder: Ref<boolean> = computed(() => {
            return btaBase.user.current.personCode === insuredPerson.policyHolder;
        });

        const showPrivatePersonFields: Ref<boolean> = computed(() => {
            return currentUserIsBeneficiary.value;
        });

        const currentUserIsBeneficiary: Ref<boolean> = computed(() => {
            return form.field('beneficiaryType').value === 'userBeneficiary';
        });

        const showNonResidentFields: Ref<boolean> = computed(() => {
            return otherPersonIsBeneficiary.value && isNonResident.value;
        });

        const showLegalPersonFields: Ref<boolean> = computed(() => {
            return otherPersonIsBeneficiary.value && isLegalPerson.value;
        });

        const isPrivatePerson: Ref<boolean> = computed(() => {
            return form.field('personType').value === 'privateResident';
        });

        const isLegalPerson: Ref<boolean> = computed(() => {
            return form.field('personType').value === 'legal';
        });

        const otherPersonIsBeneficiary: Ref<boolean> = computed(() => {
            return !currentUserIsBeneficiary.value;
        });

        const isNonResident: Ref<boolean> = computed(() => {
            return form.field('personType').value === 'privateNonResident';
        });

        const isResident: Ref<boolean> = computed(() => {
            return !isNonResident.value;
        });

        const lockUserFields: Ref<boolean> = computed(() => {
            return currentUserIsInsuredPerson.value && currentUserIsBeneficiary.value;
        });

        const showPrivateOtherFields: Ref<boolean> = computed(() => {
            return !currentUserIsBeneficiary.value && isPrivatePerson.value;
        });

        const showResidentBirthDate: Ref<boolean> = computed(() => {
            return policyHolderCodeIsAnonymous.value;
        });

        const policyHolderCodeIsAnonymous: Ref<boolean> = computed(() => {
            const holderCodeField: FormField = form.field('beneficiaryIdentityNumber');
            const holderCode: string = String(holderCodeField.value);

            return holderCodeField.isNotEmpty() && Validation.isValidPersonCodeWithoutDate(holderCode);
        });

        const identityNumberSanitizer: Ref<Function> = computed(() => {
            const useCountry: boolean =
                isNonResident.value &&
                otherPersonIsBeneficiary.value &&
                form.field('beneficiaryCountry').value.iso !== new AppCountry().iso();

            return useCountry ? Sanitizer.clearPersonCode : Sanitizer.cleanPersonCode;
        });

        function translation(key: string): string {
            return translateForType(key, Translations.getInstance().type);
        }

        function setupForm(): void {
            Object.keys(btaBase.cmsFields).forEach((field: string): void => {
                form.addField(new FormField(btaBase.cmsFields[field].name, ''));
            });
            form.setReady();
            formIsReady.value = true;
        }

        function onAppReady(): void {
            btaBase.dynamicStepper.applyEnabled(btaBase.userStorage.stepStorageData.dynamicStepper);
            patchDefaultBeneficiaryTypes();
            patchBeneficiaryFields();
            applyFormValidationAndSanitization();
        }

        function applyInsuredPerson(value: string): void {
            insuredPerson = JSON.parse(value);
        }

        function applyThankYouStepUrl(value: string): void {
            thankYouStepUrl.value = value;
        }

        function onBeneficiaryTypeChange(): void {
            nextTick(() => {
                if (form.field('personType').isEmpty()) {
                    form.field('personType').patch(personTypeOptions.value[0].value);
                }
                if (currentUserIsBeneficiary.value) {
                    patchBeneficiaryFields();
                    form.field('personType').patch(personTypeOptions.value[0].value);
                } else {
                    const fieldNames: string[] = [
                        'beneficiaryFirstName',
                        'beneficiaryLastName',
                        'beneficiaryIdentityNumber',
                        'beneficiaryEmail',
                        'beneficiaryPhone',
                        'beneficiaryAccountNumber',
                    ];
                    Promise.all(
                        fieldNames
                            .filter((fieldName: string): boolean => form.field(fieldName).isTouched)
                            .map((fieldName: string): Promise<void> => form.field(fieldName).clear()),
                    ).then(() => resetForm());
                    resetPhoneCountry();
                }
            });
        }

        function onPersonTypeChange(): void {
            resetForm();
            addCustomSanitizers();
        }

        function onSubmitStep(): void {
            resetForm();
            touchAndRequireNecessaryFields();
            form.validate().then((): void => {
                if (form.isValid()) {
                    if (nonResidentHasValidCountry()) {
                        submit();
                    } else {
                        showErrorForInvalidNonResidentCountry();
                    }
                }
            });
        }

        function submit(): void {
            prepareSubmit();
            if (otherPersonIsBeneficiary.value) {
                stepsSubmitter.proceedStep('', 0);
            } else {
                const thankYouStepStorageNumber: number = 8;
                stepsSubmitter.saveParamsToStorageWithStep(thankYouStepStorageNumber, ThankYouStepFacility).then(() => {
                    useThankYouStepUrlToProceed();
                    stepsSubmitter.submitMethod(Method.Post);
                    stepsSubmitter.submitStep(Url.Ajax.claimsCascoSubmitClaim);
                });
            }
        }

        function resetPhoneCountry(): void {
            form.field('beneficiaryPhone').patch({
                code: new AppCountry().iso(),
                iso: new AppCountry().iso(),
                country: btaBase.settings.phoneCode(),
                phone: '',
            });
            form.field('beneficiaryPhone').markAsUntouched();
        }

        function useThankYouStepUrlToProceed(): void {
            stepsSubmitter.applyStepUrls(thankYouStepUrl.value, stepsSubmitter.previousStep());
        }

        function nonResidentHasValidCountry(): boolean {
            return isNonResident.value && otherPersonIsBeneficiary.value
                ? form.field('beneficiaryCountry').value.iso !== new AppCountry().iso()
                : true;
        }

        function showErrorForInvalidNonResidentCountry(): void {
            btaBase.error.show(ErrorType.Error, Facility, 'cannot choose this country for non resident');
        }

        function patchBeneficiaryFields(): void {
            if (currentUserIsInsuredPerson.value && currentUserIsBeneficiary.value) {
                form.field('beneficiaryFirstName').patch(btaBase.user.current.firstname);
                form.field('beneficiaryLastName').patch(btaBase.user.current.lastname);
                form.field('beneficiaryIdentityNumber').patch(btaBase.user.current.personCode);
                form.field('beneficiaryEmail').patch(btaBase.user.current.email);
                form.field('beneficiaryAccountNumber').patch(btaBase.user.current.bank);
                form.field('beneficiaryPhone').patch({
                    code: btaBase.user.current.code,
                    iso: btaBase.user.current.code,
                    country: btaBase.user.current.phoneCode,
                    phone: btaBase.user.current.phone,
                });
            }
            if (!currentUserIsInsuredPerson.value && currentUserIsPolicyHolder.value) {
                form.field('beneficiaryFirstName').patch(insuredPerson.firstName!);
                form.field('beneficiaryLastName').patch(insuredPerson.lastName!);
                form.field('beneficiaryIdentityNumber').patch(insuredPerson.identityNumber!);
            }
            if (insuredPerson.isLegal) {
                if (form.field('beneficiaryCompanyName').isEmpty()) {
                    form.field('beneficiaryCompanyName').patch(insuredPerson.companyName!);
                }
                if (form.field('beneficiaryRegistrationNumber').isEmpty()) {
                    form.field('beneficiaryRegistrationNumber').patch(insuredPerson.registrationNumber!);
                }
            }
        }

        function addCustomSanitizers(): void {
            form.field('beneficiaryIdentityNumber').addSanitizer(identityNumberSanitizer.value as SanitizerCallback);
        }

        function applyFormValidationAndSanitization(): void {
            addCustomValidators();
            addDefaultSanitizers();
            addCustomSanitizers();
        }

        function addDefaultSanitizers(): void {
            form.field('beneficiaryFirstName').addSanitizer(Sanitizer.cleanName);
            form.field('beneficiaryLastName').addSanitizer(Sanitizer.cleanName);
            form.field('beneficiaryRegistrationNumber').addSanitizer(Sanitizer.cleanCompanyRegistrationNumber);
        }

        function addCustomValidators(): void {
            form.field('beneficiaryBirthDate').addValidators(birthDateValidator());
            form.field('beneficiaryCompanyName').addValidators(companyNameValidator());
            form.field('beneficiaryCompanyType').addValidators(companyTypeValidator());
            form.field('beneficiaryEmail').addValidators(emailValidator());
            form.field('beneficiaryFirstName').addValidators(firstNameValidator());
            form.field('beneficiaryLastName').addValidators(lastNameValidator());
            form.field('beneficiaryIdentityNumber').addValidators(identityNumberValidator());
            form.field('beneficiaryPhone').addValidators(phoneValidator());
            form.field('beneficiaryRegistrationNumber').addValidators(registrationNumberValidator());
            form.field('beneficiaryCountry').addValidators(countryValidator());
        }

        function birthDateValidator(): object {
            return {
                validBirthDate: () => {
                    return isNonResident.value && form.field('beneficiaryBirthDate').isTouched
                        ? form.field('beneficiaryBirthDate').value !== ''
                        : true;
                },
            };
        }

        function companyNameValidator(): object {
            return {
                validCompanyName: () => {
                    return isLegalPerson.value && form.field('beneficiaryCompanyName').isTouched
                        ? form.field('beneficiaryCompanyName').value !== ''
                        : true;
                },
            };
        }

        function companyTypeValidator(): object {
            return {
                validCompanyType: () => {
                    return isLegalPerson.value && !form.field('beneficiaryCompanyType').isEmpty()
                        ? form.field('beneficiaryCompanyType').value !== ''
                        : true;
                },
            };
        }

        function emailValidator(): object {
            return {
                validEmail: () => {
                    return form.field('beneficiaryEmail').isTouched
                        ? form.field('beneficiaryEmail').value !== ''
                        : true;
                },
            };
        }

        function firstNameValidator(): object {
            return {
                validFirstName: (value: string) => {
                    return form.field('beneficiaryFirstName').isTouched ? Validation.isValidCaption(value) : true;
                },
            };
        }

        function lastNameValidator(): object {
            return {
                validLastName: (value: string) => {
                    return form.field('beneficiaryLastName').isTouched ? Validation.isValidCaption(value) : true;
                },
            };
        }

        function identityNumberValidator(): object {
            return {
                validIdentityNumber: () => {
                    return form.field('beneficiaryIdentityNumber').isTouched ? isValidIdentityNumber() : true;
                },
            };
        }

        function phoneValidator(): object {
            return {
                validPhone: () => {
                    return form.field('beneficiaryPhone').isTouched
                        ? form.field('beneficiaryPhone').value !== ''
                        : true;
                },
            };
        }

        function registrationNumberValidator(): object {
            return {
                validRegistrationNumber: () => {
                    return form.field('beneficiaryRegistrationNumber').isTouched
                        ? form.field('beneficiaryRegistrationNumber').value !== ''
                        : true;
                },
            };
        }

        function countryValidator(): object {
            return {
                validCountry: (): boolean => {
                    return form.field('beneficiaryCountry').isTouched
                        ? form.field('beneficiaryCountry').value !== ''
                        : true;
                },
            };
        }

        function isValidIdentityNumber(): boolean {
            const useCountry: boolean = isNonResident.value && otherPersonIsBeneficiary.value;
            return useCountry
                ? Validation.isValidNaturalPersonCode(
                      form.field('beneficiaryIdentityNumber').value,
                      form.field('beneficiaryCountry').value.iso,
                  )
                : Validation.isValidNaturalPersonCode(form.field('beneficiaryIdentityNumber').value);
        }

        function resetForm(): void {
            form.fields().forEach((field: FormField): void => {
                field.clearValidators();
                field.markAsUntouched();
            });
        }

        function touchAndRequireNecessaryFields(): void {
            const newRequiredFields: string[] = requiredFields();
            newRequiredFields.forEach((field: string): void => {
                form.field(field).addValidators('required');
                form.field(field).touch();
            });
        }

        function requiredFields(): string[] {
            const fields: string[] = [
                btaBase.cmsFields.beneficiaryEmail.name,
                btaBase.cmsFields.beneficiaryPhone.name,
                btaBase.cmsFields.beneficiaryAccountNumber.name,
            ];
            if (showPrivatePersonFields.value || showPrivateOtherFields.value) {
                fields.push(
                    btaBase.cmsFields.beneficiaryFirstName.name,
                    btaBase.cmsFields.beneficiaryLastName.name,
                    btaBase.cmsFields.beneficiaryIdentityNumber.name,
                );
                if (otherPersonIsBeneficiary.value && showResidentBirthDate.value) {
                    fields.push(btaBase.cmsFields.beneficiaryBirthDate.name);
                }
            }
            if (showNonResidentFields.value) {
                fields.push(
                    btaBase.cmsFields.beneficiaryFirstName.name,
                    btaBase.cmsFields.beneficiaryLastName.name,
                    btaBase.cmsFields.beneficiaryIdentityNumber.name,
                    btaBase.cmsFields.beneficiaryBirthDate.name,
                    btaBase.cmsFields.beneficiaryCountry.name,
                );
            }
            if (showLegalPersonFields.value) {
                fields.push(
                    btaBase.cmsFields.beneficiaryCompanyName.name,
                    btaBase.cmsFields.beneficiaryCompanyType.name,
                    btaBase.cmsFields.beneficiaryRegistrationNumber.name,
                );
            }

            return fields;
        }

        function patchDefaultBeneficiaryTypes(): void {
            if (form.field('beneficiaryType').isEmpty()) {
                switch (true) {
                    case insuredPerson.isLegal:
                        patchLegalType();
                        break;
                    case !insuredPerson.isLegal && !currentUserIsInsuredPerson.value:
                        patchPrivateType();
                        break;
                    case currentUserIsInsuredPerson.value:
                        patchDefaultType();
                        break;
                    default:
                        break;
                }
            }
        }

        function patchDefaultType(): void {
            form.field('beneficiaryType').patch('userBeneficiary');
        }

        function patchLegalType(): void {
            form.field('beneficiaryType').patch('otherBeneficiary');
            form.field('personType').patch('legal');
        }

        function patchPrivateType(): void {
            form.field('beneficiaryType').patch('otherBeneficiary');
            form.field('personType').patch('privateResident');
        }

        function prepareSubmit(): void {
            stepsSubmitter.addSubmitCustomParam('nextStep', NextStep);
            stepsSubmitter.addSubmitCustomParam('facility', Facility);
            stepsSubmitter.addSubmitParams(btaBase.userStorage.stepStorageData, true);
            stepsSubmitter.addSubmitParam('claimType', 'transport', false);
            stepsSubmitter.addSubmitParam('uploader', 'claims-casco', false);
            stepsSubmitter.addSubmitParam('applicant', applicant(), true);
            stepsSubmitter.addSubmitParam('beneficiary', beneficiary(), true);
            stepsSubmitter.addSubmitParam('filesUploaded', hasUploadedFiles(), true);
        }

        function hasUploadedFiles(): string {
            return (
                isSet(btaBase.userStorage.stepStorageData.filesUploaded) &&
                btaBase.userStorage.stepStorageData.filesUploaded
            );
        }

        function applicant(): InsuredPerson {
            return {
                identityNumber: btaBase.user.current.personCode,
                firstName: btaBase.user.current.firstname,
                lastName: btaBase.user.current.lastname,
                companyName: '',
                companyType: '',
                registrationNumber: '',
                isLegal: false,
                isResident: true,
                residenceCountryIso: new AppCountry().iso(),
                birthDate: btaBase.user.current.birthDate,
                email: btaBase.user.current.email,
                phoneCode: btaBase.user.current.phoneCode,
                phone: btaBase.user.current.phone,
                bankAccount: form.field('beneficiaryAccountNumber').value,
            };
        }

        function beneficiary(): InsuredPerson {
            return {
                identityNumber: form.field('beneficiaryIdentityNumber').value,
                firstName: form.field('beneficiaryFirstName').value,
                lastName: form.field('beneficiaryLastName').value,
                companyName: form.field('beneficiaryCompanyName').value,
                companyType: companyType(),
                registrationNumber: form.field('beneficiaryRegistrationNumber').value,
                isLegal: isLegalPerson.value,
                isResident: isResident.value,
                residenceCountryIso: residenceCountryIso(),
                birthDate: beneficiaryBirthDate(),
                email: form.field('beneficiaryEmail').value,
                phoneCode: form.field('beneficiaryPhone').value.country,
                phone: form.field('beneficiaryPhone').value.phone,
                bankAccount: form.field('beneficiaryAccountNumber').value,
            };
        }

        function residenceCountryIso(): string {
            return isResident.value ? new AppCountry().iso() : form.field('beneficiaryCountry').value.iso;
        }

        function companyType(): string {
            return form.field('beneficiaryCompanyType').isEmpty() ? '' : form.field('beneficiaryCompanyType').value.iso;
        }

        function beneficiaryBirthDate(): string {
            let birthDate: string = moment().format();
            if (currentUserIsBeneficiary.value) {
                birthDate = btaBase.user.current.birthDate;
            } else {
                if (!isLegalPerson.value) {
                    birthDate =
                        isResident.value && !showResidentBirthDate.value
                            ? moment(
                                  new ExtractDataService().birthDateFromPersonCode(
                                      form.field('beneficiaryIdentityNumber').value,
                                  ),
                              ).format()
                            : moment(form.field('beneficiaryBirthDate').value).format();
                }
            }

            return birthDate;
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                thankYouStepUrl,
                insuredPerson,
                beneficiaryOptions,
                personTypeOptions,
                form,
                formIsReady,
                currentUserIsInsuredPerson,
                currentUserIsPolicyHolder,
                showPrivatePersonFields,
                currentUserIsBeneficiary,
                showNonResidentFields,
                showLegalPersonFields,
                isPrivatePerson,
                isLegalPerson,
                otherPersonIsBeneficiary,
                isNonResident,
                isResident,
                lockUserFields,
                showPrivateOtherFields,
                showResidentBirthDate,
                policyHolderCodeIsAnonymous,
                identityNumberSanitizer,
                TimestampDivider,
                DamageTypeGlass,
                setupForm,
                onAppReady,
                translation,
                applyInsuredPerson,
                applyThankYouStepUrl,
                onBeneficiaryTypeChange,
                onPersonTypeChange,
                onSubmitStep,
                submit,
                resetPhoneCountry,
                useThankYouStepUrlToProceed,
                nonResidentHasValidCountry,
                showErrorForInvalidNonResidentCountry,
                patchBeneficiaryFields,
                addCustomSanitizers,
                applyFormValidationAndSanitization,
                addDefaultSanitizers,
                addCustomValidators,
                resetForm,
                hasUploadedFiles,
            },
        };
    },

    mounted() {
        this.applyApp(this);
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        Translations.getInstance().addType(ClaimsEnum.TranslationType);
        this.setupForm();
        const onAppIsPreparedAndReady: Subscription = this.onAppIsPreparedAndReady.subscribe(() => {
            this.onAppReady();
            onAppIsPreparedAndReady.unsubscribe();
        });
    },
});
</script>
