<script lang="ts">
import { computed, defineComponent, ref, Ref, toRaw } from 'vue';
import OneBaseService from '@/services/OneBaseService';
import Form from '@/assets/libraries/form/form';
import Translations from '@/services/translations.service';
import { useTranslate } from '@/Composables/Translate';
import SubmitterUrls from '@/services/SubmitterUrls.service';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import { useDefine } from '@/Composables/Define';
import Error from '@/services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import { useStepsSubmitter } from '@/Composables/StepsSubmitter';
import { InsuredObjectRisk } from '@/interfaces/one_policy/insured.object.risk.interface';
import AccidentTypeEnum from '@/Enums/ClaimsAccidentAccidentTypeEnum';
import Url from '@/Enums/UrlEnum';
import { AxiosResponse } from 'axios';
import { useAxios } from '@/Composables/Axios';
import { Subscription } from 'rxjs';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const { translateForType } = useTranslate();
        const { isSet } = useDefine();
        const stepsSubmitter = useStepsSubmitter();

        const CurrentStep: number = 4;
        const Facility: string = 'one-claims-accident';
        const uploader: string = 'claims-accident';

        const form: Form = new Form();
        const formIsReady: Ref<boolean> = ref(false);
        const filesUploader: Ref<DynamicDictionary | null> = ref(null);
        const renew: Ref<boolean> = ref(false);

        const uploadCategories: Ref<string[]> = ref([]);
        const riskIc: Ref<string> = ref('');
        const injuryType: Ref<string> = ref('');
        const risksOfSelectedPolicy: Ref<InsuredObjectRisk[]> = ref([]);
        const payoutTable: Ref<string> = ref('');
        const injuredParts: Ref<InjuredParts[]> = ref([]);
        const expenses: Ref<string[]> = ref([]);

        const uploadInProgress: Ref<boolean> = computed(() => {
            return filesUploader.value ? !filesUploader.value.uploadsSuccessful : true;
        });

        const uploaderIsReady: Ref<boolean> = computed(() => {
            return filesUploader.value ? filesUploader.value.componentIsReady : false;
        });

        const isUploaded: Ref<boolean> = computed(() => {
            return filesUploader.value
                ? !filesUploader.value.filesNotUploaded && filesUploader.value.uploadsSuccessful
                : false;
        });

        const documentsListDescription: Ref<string[]> = computed(() => {
            const result: string[] = [];
            uploadCategories.value.forEach((category: string) => {
                if (category !== 'claims_accident_damages_photos_and_other_documents') {
                    result.push(translateForType(category, Translations.getInstance().type));
                }
            });

            return result;
        });

        function setupStepData(): void {
            const decodedJson: DynamicDictionary = toRaw(btaBase.userStorage);
            riskIc.value = decodedJson.stepStorage.riskIc;
            risksOfSelectedPolicy.value = decodedJson.stepStorage.risksOfSelectedPolicy;
            if (decodedJson.stepStorage.type === AccidentTypeEnum.Injuries) {
                injuryType.value = decodedJson.stepStorage.injuryType.selected;
                injuredParts.value = decodedJson.stepStorage.injuredParts as InjuredParts[];
                const injuries: InsuredObjectRisk = risksOfSelectedPolicy.value.find(
                    (risk: InsuredObjectRisk) => risk.agrrisk === riskIc.value,
                ) as InsuredObjectRisk;
                if (isSet(injuries) && isSet(injuries.payouttable)) {
                    payoutTable.value = injuries.payouttable as string;
                }
                if (isSet(decodedJson.stepStorage.expenses) && isSet(decodedJson.stepStorage.expenses.selected)) {
                    const selectedExpenses: string = decodedJson.stepStorage.expenses.selected as string;
                    if (selectedExpenses !== '') {
                        expenses.value = selectedExpenses.split('@#@');
                    }
                }
            }
            fetchUploadCategories();
        }

        function fetchUploadCategories(): void {
            const injuredPartData: string = injuredParts.value.length > 0 ? JSON.stringify(injuredParts.value) : '';
            const expensesData: string = expenses.value.length > 0 ? JSON.stringify(expenses.value) : '';
            const params: Record<string, number | string> = {
                riskIc: riskIc.value,
                injuryType: injuryType.value,
                injuredParts: injuredPartData,
                expenses: expensesData,
                payoutTable: payoutTable.value,
            };
            useAxios()
                .get(Url.AccidentClaims.uploadCategories, { params })
                .then((response: AxiosResponse) => {
                    uploadCategories.value = response.data.data.body.uploadCategories as string[];
                })
                .catch((reason: string | DynamicDictionary) => {
                    btaBase.error.show(ErrorType.Error, 'Accident claims upload categories fetch', reason);
                });
        }

        function setupForm(): void {
            form.setReady();
            formIsReady.value = true;
        }

        function onSubmitStep(): void {
            submit();
        }

        function submit(): void {
            (filesUploader.value as DynamicDictionary).touch();
            prepareSubmit();
            stepsSubmitter.proceedStep('', 0);
        }

        function prepareSubmit(): void {
            stepsSubmitter.addSubmitCustomParams(btaBase.userStorage.stepStorageData);
            stepsSubmitter.addSubmitCustomParam('nextStep', btaBase.nextStep());
            stepsSubmitter.addSubmitCustomParam('facility', Facility);
            stepsSubmitter.addSubmitCustomParam('filesUploaded', isUploaded.value);
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                form,
                formIsReady,
                filesUploader,
                renew,
                uploader,
                isUploaded,
                uploadInProgress,
                documentsListDescription,
                uploaderIsReady,
                setupForm,
                onSubmitStep,
                submit,
                prepareSubmit,
                setupStepData,
                fetchUploadCategories,
            },
        };
    },

    mounted() {
        this.applyApp(this);
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        this.setupForm();
        Translations.getInstance().addType('claims_accident');
        this.dynamicStepper.enableAll();
        const onAppIsPreparedAndReady: Subscription = this.onAppIsPreparedAndReady.subscribe((): void => {
            this.setupStepData();
            onAppIsPreparedAndReady.unsubscribe();
        });
    },
});

interface InjuredParts {
    type: string;
    name: string;
    risk_ic: string;
    subrisk: string;
    selected?: boolean;
}
</script>
