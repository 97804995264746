<script setup lang="ts">
import FormField from '@/assets/libraries/form/form-field';
import SettingsService from '@/services/settings.service';
import Sanitizer from '@/services/sanitizer.service';
import Validation from '@/services/validation.service';
import { onMounted } from 'vue';
import Translations from '@/services/translations.service';
import AppInputText from '@/Components/Inputs/InputText/InputText.vue';
import AppInputError from '@/Components/Inputs/InputError/InputError.vue';

const props = defineProps({
    placeholder: { type: String, default: () => '' },
    formField: { type: FormField<string>, default: () => new FormField('') },
    label: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    dataStoreDisabled: { type: Boolean, default: false },
    useDefaultSanitizer: { type: Boolean, default: true },
});

const emit = defineEmits(['change', 'input']);

const settings: SettingsService = SettingsService.getInstance();
const translations: Translations = Translations.getInstance();

onMounted((): void => {
    if (props.useDefaultSanitizer) {
        props.formField.addSanitizer(Sanitizer.cleanEmail);
    }
    props.formField.addValidators(baseValidators());
    if (isEmailRestrictionEnabled()) {
        props.formField.addValidators(restrictedEmailValidators());
    }
});

function onInput(value: string): void {
    emit('input', value);
}

function onChange(value: string): void {
    emit('change', value);
}

function baseValidators(): object {
    return {
        isValidEmail: Validation.isValidEmail,
    };
}

function restrictedEmailValidators(): object {
    return {
        isValidRestrictedEmail: Validation.isValidRestrictedEmail,
    };
}

function isEmailRestrictionEnabled(): boolean {
    return settings.restrictedEmailsEnabled();
}
</script>

<template>
    <app-input-text
        :id="formField.name"
        v-lowercase
        :class="'input-email'"
        :required="required"
        :name="formField.name"
        :form-field="formField"
        :label="label"
        :autocomplete="'email'"
        :disabled="disabled"
        :placeholder="placeholder"
        :data-store-disabled="dataStoreDisabled"
        @input="onInput($event)"
        @change="onChange($event)"
    >
        <template #app-tooltipster>
            <slot name="app-tooltipster"></slot>
        </template>
        <template #error>
            <app-input-error v-if="formField.hasError('isValidEmail')">
                {{ translations.localized('input_error_invalid_email') }}
            </app-input-error>
            <app-input-error v-else-if="formField.hasError('isValidRestrictedEmail')">
                {{ translations.localized('input_error_restricted_email') }}
            </app-input-error>
            <slot name="error"></slot>
        </template>
    </app-input-text>
</template>

<style lang="scss" scoped>
.input-email {
    scroll-margin-top: 4em;

    @include respond-below('md') {
        width: 100%;
    }
}
</style>
