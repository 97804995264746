<script setup lang="ts">
import { computed, onMounted, PropType, ref, Ref } from 'vue';
import { useTranslate } from '@/Composables/Translate';
import { useDefine } from '@/Composables/Define';
import { useHtml } from '@/Composables/Html';
import { usePrice } from '@/Composables/Price';
import { CoverageRisk } from '@/Apps/ActivePlus/Interfaces/CoverageRiskInterface';
import ImageTagAttributes from '@/interfaces/image.tag.attributes';
import { CoveragePlan } from '@/Apps/ActivePlus/Interfaces/CoveragePlanInterface';
import IconSide from '@/Components/Tooltips/TextWithTip/IconSide.enum';
import CoveredPopupAutomationIterator from '@/Components/Popups/CoveredPopup/Classes/CoveredPopupAutomationIterator';
import ButtonWithCallbackParams from '@/Components/Buttons/ButtonWithCallback/Enums/button.params';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonIconColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.color.enum';
import { ActivePlus } from '@/Apps/ActivePlus/Interfaces/ActivePlusInterface';

export interface CoveredRisks {
    id: string;
    insuredSum: number[];
    isFeatured: boolean[];
    isCovered: boolean[];
}

export interface CoverageProgram {
    ic: string;
    risks: CoveredRisks[];
    sumInsured: number[];
}

const props = defineProps({
    title: { type: String, default: '' },
    policies: { type: Array as PropType<ActivePlus[]>, default: () => [] },
    excludedRisks: { type: Array, default: () => [] },
    coveragePlanKey: { type: Number, default: 0 },
    translationType: { type: String, default: '' },
});

const emit = defineEmits(['selected', 'blur', 'close']);

const { translate, translateForType } = useTranslate();
const { isSet } = useDefine();
const { imgTag } = useHtml();
const { price } = usePrice();

const DefaultInsuranceTerritory: string = 'EUROPE';
const plans: Ref<CoverageProgram[]> = ref([]);
const insuranceTerritory: Ref<string[]> = ref([]);

const buttonParams: Ref<ButtonWithCallbackParams> = computed(() => {
    return {
        backgroundColor: ButtonBackground.Red,
        textColor: ButtonTextColor.White,
        iconColor: ButtonIconColor.White,
    };
});

onMounted((): void => {
    init();
    CoveredPopupAutomationIterator.init();
});

function init(): void {
    setupRisks();
}

function setupRisks(): void {
    plans.value = mergeInsurancePlans();
}

function mergeInsurancePlans(): CoverageProgram[] {
    const aggregatedCoveragePlans: Record<string, CoverageProgram> = {};
    props.policies.forEach((policy) => {
        insuranceTerritory.value.push(policy.params.insuranceTerritoryIc);
        policy.coveragePlans.forEach((coveragePlan: CoveragePlan) => {
            if (!aggregatedCoveragePlans[coveragePlan.ic]) {
                aggregatedCoveragePlans[coveragePlan.ic] = {
                    ic: coveragePlan.ic,
                    sumInsured: [],
                    risks: [],
                };
            }
            aggregatedCoveragePlans[coveragePlan.ic].sumInsured.push(coveragePlan.sumInsured);
            coveragePlan.risks.forEach((risk: CoverageRisk) => {
                const existingRisk = aggregatedCoveragePlans[coveragePlan.ic].risks.find(
                    (coveredRisks: CoveredRisks) => coveredRisks.id === risk.id,
                );
                if (!existingRisk) {
                    aggregatedCoveragePlans[coveragePlan.ic].risks.push({
                        id: risk.id,
                        insuredSum: [risk.insuredSum],
                        isFeatured: [risk.isFeatured],
                        isCovered: [risk.isWhatCovered],
                    });
                } else {
                    existingRisk.insuredSum.push(risk.insuredSum);
                    existingRisk.isFeatured.push(risk.isFeatured);
                    existingRisk.isCovered.push(risk.isWhatCovered);
                }
            });
        });
    });

    return Object.values(aggregatedCoveragePlans);
}

function riskStatus(risk: CoveredRisks, index: number): string {
    return !risk.isCovered[index] ? risk.insuredSum[index] + ' &euro;' : `${iconContent(checkmarkIconAttributes())}`;
}

function insuranceTerritoryStatus(territory: string): string {
    return territory === DefaultInsuranceTerritory
        ? `${iconContent(checkmarkIconAttributes())}`
        : `${iconContent(crossIconAttributes())}`;
}

function checkmarkIconAttributes(): ImageTagAttributes {
    return {
        class: 'item icon checkmark',
        src: 'images/one/checkmark-thin.svg',
        width: 24,
        height: 24,
    };
}

function crossIconAttributes(): ImageTagAttributes {
    return {
        class: 'item icon',
        src: 'images/one/cross-icon.svg',
        width: 14,
        height: 14,
    };
}

function iconContent(iconAttributes: ImageTagAttributes): string {
    return useHtml().imgTag(iconAttributes);
}

function onSelectedClick(product: string): void {
    emit('selected', [product]);
}

function close(): void {
    emit('close');
}

function priceWithCents(value: number): string {
    return usePrice().formattedCurrency(value);
}
</script>

<template>
    <div class="covered-popup-multiple">
        <div class="coverage-title">{{ translateForType(title, translationType) }}</div>
        <div class="insurance-plans">
            <div v-for="(plan, index) in props.policies" :key="index" class="plan">
                <p class="plan-title">{{ translateForType(plan.id, translationType) }}</p>
                <p class="price">
                    {{
                        translateForType('covered_price_from', translationType, {
                            '%PRICE%': priceWithCents(plan.price),
                        })
                    }}
                </p>
                <app-button-with-callback
                    :title="translateForType('covered_select', 'components')"
                    v-bind="buttonParams"
                    @button-callback-click="onSelectedClick(plan.id)"
                >
                </app-button-with-callback>
            </div>
        </div>
        <div class="covered-information">
            <div class="coverage-text">
                <div class="title">{{ translateForType('insurance_coverage_title', translationType) }}</div>
                <div class="description">{{ translateForType('insurance_coverage_description', translationType) }}</div>
            </div>
            <div v-for="(policy, index) in plans" :key="index">
                <div class="title grey-background">
                    <p class="coverage-risk-title">
                        {{ translateForType(policy.ic + '_title', translationType) }}
                    </p>
                </div>
                <div class="sub-title bold">{{ translateForType('insurance_total_sum', translationType) }}</div>
                <div
                    :id="'covered-block-active-plus-' + CoveredPopupAutomationIterator.blockIndexIterate()"
                    class="status"
                >
                    <div
                        v-for="(value, itemIndex) in policy.sumInsured"
                        :id="
                            'covered-block-active-plus-item-' +
                            CoveredPopupAutomationIterator.blockIndex() +
                            '-' +
                            itemIndex
                        "
                        :key="itemIndex"
                        class="item"
                    >
                        <div v-html="value + ' &euro;'"></div>
                    </div>
                </div>
                <template v-for="(risk, riskIndex) in policy.risks" :key="riskIndex">
                    <app-text-with-tip
                        class="sub-title"
                        :info-icon-side="IconSide.Before"
                        :title="translateForType('risk_' + risk.id + '_detailed', translationType)"
                        :tip-description="translateForType(risk.id + '_detailed_tooltip', translationType)"
                    >
                    </app-text-with-tip>
                    <div
                        :id="'covered-block-active-plus-' + CoveredPopupAutomationIterator.blockIndexIterate()"
                        class="status"
                    >
                        <div
                            v-for="(value, itemIndex) in risk.insuredSum"
                            :id="
                                'covered-block-active-plus-item-' +
                                CoveredPopupAutomationIterator.blockIndex() +
                                '-' +
                                itemIndex
                            "
                            :key="itemIndex"
                            class="item"
                        >
                            <div v-html="riskStatus(risk, itemIndex)"></div>
                        </div>
                    </div>
                </template>
            </div>
            <div>
                <div class="title grey-background">
                    <p class="coverage-risk-title">
                        {{ translateForType('insurance_territory_title', translationType) }}
                    </p>
                </div>
                <app-text-with-tip
                    class="sub-title"
                    :info-icon-side="IconSide.Before"
                    :title="translateForType('territory_europe_detailed', translationType)"
                    :tip-description="translateForType('territory_europe_detailed_tooltip', translationType)"
                >
                </app-text-with-tip>
                <div
                    :id="'covered-block-active-plus-' + CoveredPopupAutomationIterator.blockIndexIterate()"
                    class="status"
                >
                    <div
                        v-for="(value, index) in insuranceTerritory"
                        :id="
                            'covered-block-active-plus-item-' +
                            CoveredPopupAutomationIterator.blockIndex() +
                            '-' +
                            index
                        "
                        :key="index"
                        class="item"
                    >
                        <div v-html="insuranceTerritoryStatus(value)"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.covered-popup-multiple {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .insurance-plans {
        width: 100%;
        min-height: 235px;
        max-height: 235px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(95px, 1fr));
        align-items: flex-end;
        background: var(--background-light);
        padding: var(--size-small);
        gap: var(--size-nano);
        border-top-left-radius: var(--size-tiny);
        border-top-right-radius: var(--size-tiny);

        @include respond-above('sm') {
            padding: var(--size-big);
            min-height: 277px;
            gap: var(--size-big);
            grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
        }

        .plan {
            max-height: 117px;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-self: center;
            justify-content: center;
            align-items: center;

            @include respond-above('sm') {
                margin-bottom: 0;
            }

            .plan-title {
                text-align: center;
                font-size: var(--size-small);
                font-weight: 700;
                color: var(--text-color-default);
            }

            .price {
                font-weight: 500;
                color: var(--text-color-default);
                text-align: center;
            }

            button {
                width: 100%;
                height: 100%;
                margin-top: var(--size-small);
                min-height: 52px;
            }
        }
    }

    .covered-information {
        display: grid;
        padding: 0 var(--size-small) var(--size-small);
        width: 100%;
        overflow-y: auto;

        @include respond-above('sm') {
            padding: var(--size-small) var(--size-small) 0 var(--size-big);
        }

        .coverage-text {
            .title {
                font-size: var(--size-normal);
                margin-bottom: var(--size-nano) !important;
            }

            .description {
                text-align: left;
            }
        }

        .coverage-amount {
            .amount {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }

        .status {
            border-top: 1px solid var(--black-100);
            border-bottom: 1px solid var(--black-100);
            min-height: 46px;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
            justify-content: center;
            align-items: stretch;

            .item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-right: 1px solid var(--black-100);
                padding: 10px;

                div {
                    .amount {
                        color: var(--text-color-subtle);
                    }

                    .icon {
                        .checkmark {
                            height: 11px;
                            width: 16px;
                        }
                    }
                }
            }

            .item:last-child {
                border-right: none;
            }
        }

        .title {
            border-bottom: none;
            padding-bottom: 0;

            .coverage-risk-title {
                color: var(--text-color-default);
                font-size: 18px;
                padding: var(--size-tiny) var(--size-normal);
                font-weight: 700;
            }
        }
    }

    .popup-title {
        position: absolute;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--black-100);
    }

    .coverage-title {
        position: absolute;
        max-width: 200px;
        top: 30px;
        left: 25px;
        font-size: var(--size-normal);
        font-weight: 700;

        @include respond-above('sm') {
            top: 50px;
            left: var(--size-big);
            max-width: 600px;
        }
    }

    .title {
        margin: var(--size-small) 0 0 !important;
        border-bottom: 1px solid var(--black-100);
        padding-bottom: var(--size-small);
        font-size: var(--size-small);
        font-weight: 700;
    }

    :deep(.text-with-tip) {
        .inline {
            .sub-title {
                font-weight: 500;
            }
        }
    }

    .sub-title {
        font-size: var(--size-tiny);
        margin: 16px 0 !important;
    }

    .bold {
        font-weight: 600;
    }

    .grey-background {
        background-color: var(--teal-50);
    }
}
</style>
