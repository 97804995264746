<script setup lang="ts"></script>

<template>
    <div v-if="$slots.default" class="input-error">
        <slot></slot>
    </div>
</template>

<style lang="scss" scoped>
.input-error {
    display: flex;
    font-size: var(--font-size-pico);
    color: var(--brand-red);
    line-height: var(--size-nano);
}
</style>
