<script setup lang="ts">
import { useTranslate } from '@/Composables/Translate';
import { Router, useRouter } from 'vue-router';
import OneBaseService from '@/services/OneBaseService';
import TwoWayCommunication from '@/Apps/TwoWayCommunication/Services/TwoWayCommunicationService';
import Form from '@/assets/libraries/form/form';
import { computed, getCurrentInstance, onMounted, Ref } from 'vue';
import TwoWayFormFields from '@/Apps/TwoWayCommunication/Classes/TwoWayFormFields';
import TwoWayGuardsService from '@/Apps/TwoWayCommunication/Services/TwoWayGuardsService';
import TwoWaySteps from '@/Apps/TwoWayCommunication/Enums/TwoWayStepsEnum';
import SpaUserStorage from '@/services/SpaUserStorageService';
import TwoWayCommunicationsBlock from '@/Apps/TwoWayCommunication/Interfaces/TwoWayCommunicationsBlockInterface';
import { useDefine } from '@/Composables/Define';
import TwoWaySupportType from '@/Apps/TwoWayCommunication/Enums/TwoWaySupportTypeEnum';

const { translateForType, applyTranslationType, type } = useTranslate();
const { validResponse } = useDefine();
const router: Router = useRouter();

const twoWayCommunicationService: TwoWayCommunication = TwoWayCommunication.getInstance();
const spaUserStorage: SpaUserStorage = SpaUserStorage.getInstance();

const Step: number = 1;

const form: Form = new Form();

const canProceedToNextStep: Ref<boolean> = computed(() => {
    return true;
});

const communications: Ref<TwoWayCommunicationsBlock[]> = computed(() => {
    return twoWayCommunicationService.communicationsById(twoWayCommunicationService.storageFields.communicationId);
});

const noRepliesForCurrentCommunication: Ref<boolean> = computed(() => {
    let result: boolean = true;
    communications.value.forEach((communication: TwoWayCommunicationsBlock) => {
        if (!communication.isAuthenticatedPersonAuthor) {
            result = false;
        }
    });

    return result;
});

function setupForm(): void {
    spaUserStorage.setupForm();
}

function translated(stringId: string): string {
    return translateForType(stringId, type());
}

function onBackClick(): void {
    twoWayCommunicationService.storageFields.communicationId = '';
    router.push({ name: TwoWaySteps.Communication });
}

function prepareCommunicationId(): void {
    const parts: string[] = String(window.location.href).split('/');
    twoWayCommunicationService.storageFields.communicationId = parts.length > 1 ? parts[parts.length - 1] : '';
    if (
        twoWayCommunicationService.storageFields.communicationId === '' ||
        twoWayCommunicationService.storageFields.communicationId === TwoWaySteps.Communication
    ) {
        router.push({ name: TwoWaySteps.Communication });
    } else {
        twoWayCommunicationService.markAsRead();
    }
}

function onReplyClick(item: TwoWayCommunicationsBlock): void {
    twoWayCommunicationService.storageFields.newMessageResponseId = item.id;
    twoWayCommunicationService.storageFields.supportType = TwoWaySupportType.Reply;
    twoWayCommunicationService.storageFields.addOrReplyType = twoWayCommunicationService.typeByIc(item.type);
    twoWayCommunicationService.storageFields.isReply = true;
    router.push({ name: TwoWaySteps.CommunicationNew });
}

function onAddNewMessage(): void {
    if (communications.value.length > 0) {
        twoWayCommunicationService.storageFields.addOrReplyType = twoWayCommunicationService.typeByIc(
            communications.value[0].type,
        );
    }
    twoWayCommunicationService.storageFields.isAddNewMessage = true;
    router.push({ name: TwoWaySteps.CommunicationNew });
}

onMounted(() => {
    OneBaseService.getInstance().applySpa(getCurrentInstance());
    applyTranslationType('communications');
    spaUserStorage.init(TwoWayFormFields, twoWayCommunicationService.storageFields);
    setupForm();
    twoWayCommunicationService.fetchCommunicationsList();
    prepareCommunicationId();
    form.validate().then((): void => {
        TwoWayGuardsService.getInstance().applyStepValidity(Step, canProceedToNextStep.value);
    });
});

defineExpose({
    twoWayCommunicationService,
});
</script>

<template>
    <div class="two-way-communication full-width horizontal-spacing horizontal-spacing-desktop">
        <div class="wrapper full-width">
            <app-custom-form
                v-if="spaUserStorage.isReady()"
                :form="spaUserStorage.newForm('two-way-new')"
                class="form full-width"
                @change="spaUserStorage.storeForm()"
            >
                <div class="single-communication full-width">
                    <div class="communication-header">
                        <button
                            data-v-ff6cde2a=""
                            class="back back-margin full-width block-wide"
                            @click="onBackClick()"
                        >
                            <img data-v-ff6cde2a="" src="images/one/arrow-left.svg" alt="back" />
                            <span data-v-ff6cde2a="">{{ translated('back') }}</span>
                        </button>
                        <div class="title full-width block-wide">{{ translated('communication_details') }}</div>
                    </div>
                    <div class="content">
                        <div class="full-width block-wide">
                            <app-message
                                v-for="(item, index) in communications"
                                :id="item.id"
                                :key="index"
                                :author="twoWayCommunicationService.communicationAuthor(item)"
                                :date="item.createdAt"
                                :title="item.title"
                                :body="twoWayCommunicationService.communicationBody(item)"
                                :is-new="!item.read"
                                :is-bta="twoWayCommunicationService.communicationIsBta(item)"
                                :attachments="item.attachments"
                                :action="!item.isAuthenticatedPersonAuthor ? 'reply' : ''"
                                @click="onReplyClick(item)"
                            >
                            </app-message>
                            <div
                                v-if="noRepliesForCurrentCommunication"
                                class="need-more-block"
                                @click="onAddNewMessage()"
                            >
                                <span>
                                    <svg
                                        class="icon"
                                        width="27"
                                        height="27"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M15.1038 4.66848C15.3158 4.45654 15.5674 4.28843 15.8443 4.17373C16.1212 4.05903 16.418 4 16.7177 4C17.0174 4 17.3142 4.05903 17.5911 4.17373C17.868 4.28843 18.1196 4.45654 18.3315 4.66848C18.5435 4.88041 18.7116 5.13201 18.8263 5.40891C18.941 5.68582 19 5.9826 19 6.28232C19 6.58204 18.941 6.87882 18.8263 7.15573C18.7116 7.43263 18.5435 7.68423 18.3315 7.89617L7.43807 18.7896L3 20L4.21038 15.5619L15.1038 4.66848Z"
                                            stroke="#007a76"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <rect x="10" y="19" width="11" height="1.5" rx="0.75" fill="#007a76" />
                                        <path d="M13 6L17 10" stroke="#007a76" stroke-width="1.5" />
                                    </svg>
                                    {{ translated('add_new_message') }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </app-custom-form>
        </div>
    </div>
</template>

<style scoped lang="scss">
.two-way-communication {
    .wrapper {
        .form {
            .single-communication {
                .content {
                    margin-bottom: 90px;

                    .message {
                        margin-bottom: var(--size-nano);
                    }

                    .need-more-block {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: var(--size-normal);
                        font-size: var(--font-size-small);
                        font-weight: 700;
                        @include respond-below('xs') {
                            flex-direction: column;
                            gap: var(--size-nano);
                            align-items: start;
                            padding: var(--size-normal);
                        }

                        span {
                            font-size: var(--font-size-tiny);
                            font-weight: 600;
                            color: var(--text-color-link);
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            gap: var(--size-nano);

                            &:hover {
                                color: var(--text-color-link-hover);

                                .icon {
                                    path {
                                        stroke: var(--text-color-link-hover);
                                    }

                                    rect {
                                        fill: var(--text-color-link-hover);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
