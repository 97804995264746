<script setup lang="ts">
import { useTranslate } from '@/Composables/Translate';
import { Router, useRouter } from 'vue-router';
import OneBaseService from '@/services/OneBaseService';
import TwoWayCommunication from '@/Apps/TwoWayCommunication/Services/TwoWayCommunicationService';
import { computed, getCurrentInstance, onMounted, ref, Ref } from 'vue';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import TwoWayFormFields from '@/Apps/TwoWayCommunication/Classes/TwoWayFormFields';
import TwoWayGuardsService from '@/Apps/TwoWayCommunication/Services/TwoWayGuardsService';
import ButtonsWithCallbackBuilder from '@/Components/Buttons/ButtonWithCallback/Builders/ButtonsWithCallbackBuilder';
import ButtonIcon from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.enum';
import ButtonWithCallbackParams from '@/Components/Buttons/ButtonWithCallback/Enums/button.params';
import TwoWaySteps from '@/Apps/TwoWayCommunication/Enums/TwoWayStepsEnum';
import SpaUserStorage from '@/services/SpaUserStorageService';
import TwoWayCommunicationsBlock from '@/Apps/TwoWayCommunication/Interfaces/TwoWayCommunicationsBlockInterface';

const { translateForType, applyTranslationType, type } = useTranslate();
const router: Router = useRouter();
const twoWayCommunicationService: TwoWayCommunication = TwoWayCommunication.getInstance();
const spaUserStorage: SpaUserStorage = SpaUserStorage.getInstance();

const Step: number = 0;

const infoWithActionsButtons: Ref<ButtonWithCallbackParams[]> = ref([]);

const canProceedToNextStep: Ref<boolean> = computed(() => {
    return true;
});

function setupForm(): void {
    spaUserStorage.setupForm();
}

function translated(stringId: string): string {
    return translateForType(stringId, type());
}

function buildContacts(): void {
    infoWithActionsButtons.value = ButtonsWithCallbackBuilder.getInstance()
        .reset()
        .newButton()
        .withTitle(translated('new_communication'))
        .withIcon(ButtonIcon.Edit)
        .withCallback((params: DynamicDictionary): void => {
            router.push({ name: TwoWaySteps.CommunicationNew });
        })
        .build();
}

function openCommunication(block: TwoWayCommunicationsBlock): void {
    router.push({ name: TwoWaySteps.CommunicationSingle, params: { any: block.id } });
}

onMounted(() => {
    OneBaseService.getInstance().applySpa(getCurrentInstance());
    applyTranslationType('communications');
    spaUserStorage.init(TwoWayFormFields, twoWayCommunicationService.storageFields);
    setupForm();
    buildContacts();
    twoWayCommunicationService.fetchCommunicationsList();
    spaUserStorage.form.validate().then((): void => {
        TwoWayGuardsService.getInstance().applyStepValidity(Step, canProceedToNextStep.value);
    });
});

defineExpose({
    twoWayCommunicationService,
});
</script>

<template>
    <div class="two-way-communication full-width horizontal-spacing horizontal-spacing-desktop">
        <div class="wrapper full-width">
            <app-custom-form
                v-if="spaUserStorage.isReady()"
                :form="spaUserStorage.newForm('two-way-new')"
                class="form"
                @change="spaUserStorage.storeForm()"
            >
                <div class="single-communication full-width">
                    <div class="communication-header">
                        <div class="title full-width">{{ translated('my_communications') }}</div>
                    </div>
                    <div class="content">
                        <div class="communications-list full-width">
                            <div class="messages">
                                <app-message
                                    v-for="(item, index) in twoWayCommunicationService.communications()"
                                    :id="item.id"
                                    :key="index"
                                    :author="twoWayCommunicationService.communicationAuthor(item)"
                                    :date="item.createdAt"
                                    :title="item.title"
                                    :body="twoWayCommunicationService.communicationShortBody(item)"
                                    :is-new="!item.read"
                                    :is-bta="twoWayCommunicationService.communicationIsBta(item)"
                                    :action="'view'"
                                    @click="openCommunication(item)"
                                >
                                </app-message>
                                <div
                                    v-if="twoWayCommunicationService.communications().length === 0"
                                    class="no-messages"
                                >
                                    {{ translated('no_messages_yet') }}
                                </div>
                            </div>
                            <div class="communication-header communication-header-device">
                                <div class="title full-width">{{ translated('my_communications') }}</div>
                            </div>
                            <div class="contacts">
                                <app-info-with-actions
                                    :title="translated('contact_bta')"
                                    :sub-title="translated('need_help')"
                                    :buttons="infoWithActionsButtons"
                                ></app-info-with-actions>
                            </div>
                        </div>
                    </div>
                </div>
            </app-custom-form>
        </div>
    </div>
</template>

<style scoped lang="scss">
.two-way-communication {
    .wrapper {
        .form {
            width: 100%;

            .single-communication {
                .communication-header {
                    .title {
                        @include respond-below('lg') {
                            display: none;
                        }
                    }
                }

                .communication-header.communication-header-device {
                    .title {
                        display: flex;
                        margin-bottom: 0;
                        @include respond-above('lg') {
                            display: none;
                        }
                    }
                }

                .content {
                    .communications-list {
                        display: flex;
                        gap: 38px;

                        @include respond-below('lg') {
                            flex-direction: column-reverse;
                            gap: var(--size-normal);
                        }

                        .messages {
                            width: 100%;

                            .message {
                                margin-bottom: var(--size-nano);
                            }

                            .no-messages {
                                color: var(--text-color-subtlest);
                            }
                        }

                        .contacts {
                            max-width: 420px;
                        }
                    }
                }
            }
        }
    }
}
</style>
