<script lang="ts">
import { defineComponent } from 'vue';
import OneBaseService from '@/services/OneBaseService';
import OneBase from '@/interfaces/OneBaseInterface';
import DataLayerFacilities from '@/Enums/DataLayerFacilitiesEnum';
import GetInTouchModule from '@/Modules/GetInTouch/GetInTouch.vue';
import VueModule from '@/Core/App/VueModule';

export default defineComponent({
    setup() {
        const btaBase: OneBase = OneBaseService.getInstance();

        return {
            ...btaBase,
        };
    },

    mounted() {
        this.applyApp(this);
        this.setFacility(DataLayerFacilities.HealthInsurance);
        this.initBtaBase();
        if (this.captcha.isEnabled()) {
            this.captcha.renderCaptcha();
        }
    },
});
</script>
