import Form from '@/assets/libraries/form/form';

export default class Validators {
    private static instance: Validators;
    private form: Form = new Form();

    public static getInstance(): Validators {
        if (!Validators.instance) {
            Validators.instance = new Validators();
        }

        return Validators.instance;
    }

    public init(form: Form<any>): void {
        this.form = form;
    }

    public hasValidForm(): boolean {
        return this.form.isValid();
    }
}
