import AppMobileDetect from 'mobile-device-detect';
import TwoWayModalScreen from '@/Apps/TwoWayCommunication/Components/TwoWayModalScreen.vue';
import ButtonsComponents from '@/Components/Buttons/Components';
import ClaimsComponents from '@/Components/Claims/Components';
import CreditCardsComponents from '@/Components/CreditCards/Components';
import ExpandablesComponents from '@/Components/Expandables/Components';
import ContainersComponents from '@/Components/Containers/Components';
import FilesComponents from '@/Components/Files/Components';
import InputsComponents from '@/Components/Inputs/Components';
import ListsComponents from '@/Components/Lists/Components';
import LoadersComponents from '@/Components/Loaders/Components';
import MapsComponents from '@/Components/Maps/Components';
import ModalsComponents from '@/Components/Modals/Components';
import OptionsComponents from '@/Components/Options/Components';
import OtherComponents from '@/Components/Other/Components';
import PoliciesComponents from '@/Components/Policies/Components';
import PopupsComponents from '@/Components/Popups/Components';
import RoutingComponents from '@/Components/Routing/Components';
import StepsComponents from '@/Components/Steps/Components';
import TooltipsComponents from '@/Components/Tooltips/Components';
import TwoWayCommunicationComponents from '@/Components/TwoWayCommunication/Components';
import WidgetsComponents from '@/Components/Widgets/Components';
import Components from '@/Core/App/Interfaces/ComponentsInterface';

export default {
    isMobile: AppMobileDetect,

    AppTwoWayModalScreen: TwoWayModalScreen,
    ...ButtonsComponents,
    ...ClaimsComponents,
    ...CreditCardsComponents,
    ...ContainersComponents,
    ...ExpandablesComponents,
    ...FilesComponents,
    ...InputsComponents,
    ...ListsComponents,
    ...LoadersComponents,
    ...MapsComponents,
    ...ModalsComponents,
    ...OptionsComponents,
    ...OtherComponents,
    ...PoliciesComponents,
    ...PopupsComponents,
    ...RoutingComponents,
    ...StepsComponents,
    ...TooltipsComponents,
    ...TwoWayCommunicationComponents,
    ...WidgetsComponents,
} as Components;
