<script setup lang="ts">
import { computed, nextTick, onMounted, PropType, ref, Ref, toRaw, watch } from 'vue';
import FormField from '@/assets/libraries/form/form-field';
import Form from '@/assets/libraries/form/form';
import { useTranslate } from '@/Composables/Translate';
import Validation from '@/services/validation.service';
import User from '@/services/user.service';
import Value from '@/assets/libraries/form/value';
import NotLoggedUserPersonDetails from '@/Components/Widgets/PersonDetails/Interfaces/FirstUserPersonDetailsInterface';
import PersonCodeValidator from '@/Validators/PersonCodeValidator';
import SpaUserStorage from '@/services/SpaUserStorageService';
import { InputOption } from '@/interfaces/InputOptionInterface';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import Sanitizer from '@/services/sanitizer.service';
import { useDefine } from '@/Composables/Define';
import moment from 'moment/moment';
import AppCountry from '@/assets/libraries/app/app-country';
import IbanValidator from '@/Validators/IbanValidator';
import Phone from '@/interfaces/PhoneInterface';
import PersonDetails from '@/interfaces/PersonDetailsInterface';
import Country from '@/interfaces/country.interface';
import PhoneType from '@/Enums/PhoneTypeEnum';

const { translateForType } = useTranslate();

const props = defineProps({
    formField: {
        type: Object as PropType<FormField<PersonDetails>>,
        default: () => new FormField(''),
    },
    translationType: { type: String, default: () => 'components' },
    firstUserPersonDetails: {
        type: Object as PropType<NotLoggedUserPersonDetails>,
        default: () => {
            return new (class implements NotLoggedUserPersonDetails {
                public name: string = '';
                public surname: string = '';
                public personCode: string = '';
                public country: Country = new (class implements Country {
                    public iso: string = '';
                    public phoneCode: string = '';
                    public code: string = '';
                    public name: string = '';
                })();
                public birthDate: string = '';
            })();
        },
    },
    titleText: { type: String, default: () => 'insured_person_title' },
    policyHolderText: { type: String, default: () => 'insured_person' },
    isResidentText: { type: String, default: () => 'resident' },
    withBankAccountNumber: { type: Boolean, default: false },
    bankAccountCurrency: {
        type: Array as PropType<string[]>,
        default: () => {
            return ['eur', 'usd'];
        },
    },
    maxWidth: { type: Number, default: 800 },
});

const user: User = User.getInstance();
const spaUserStorage: SpaUserStorage = SpaUserStorage.getInstance();

const balticCountries: string[] = ['EE', 'LT', 'LV'];

const emit = defineEmits(['change']);

const form: Form = new Form();

const allowPolicyHolderWatch: Ref<boolean> = ref(false);

const isPolicyHolder: Ref<boolean> = computed(() => {
    return form.field('isPolicyHolder').value;
});

const isResident: Ref<boolean> = computed(() => {
    return form.field('isResident').value;
});

const phoneNumberType: Ref<string> = computed(() => {
    let result: string = '';
    const value: Phone = form.field('phone').value;
    if (useDefine().isSet(value.phone)) {
        if (Validation.isValidMobilePhone(value.phone)) {
            result = PhoneType.Mobile;
        } else if (Validation.isValidFixedPhone(value.phone)) {
            result = PhoneType.Fixed;
        }
    }

    return result;
});

const needToShowBirthDate: Ref<boolean> = computed(() => {
    return (
        ((!isResident.value && !balticCountries.includes(props.firstUserPersonDetails.country.iso)) ||
            (isResident.value && personCodeIsAnonymous.value)) &&
        !isPolicyHolder.value
    );
});

const currencyOptions: Ref<InputOption[]> = computed(() => {
    const result: InputOption[] = [];
    props.bankAccountCurrency.forEach((currency: string) => {
        result.push(new InputOptionBuilder().setName(currency).setValue(currency).build());
    });

    return result;
});

const personCodeIsAnonymous: Ref<boolean> = computed(() => {
    return new PersonCodeValidator().isValidPersonCodeWithoutDate(
        form.field('personCode').value,
        new AppCountry().iso(),
    );
});

const isLogged: Ref<boolean> = computed(() => {
    return user.isLogged();
});

const needToShowIsPolicyHolder: Ref<boolean> = computed(() => {
    return !isLogged.value;
});

const needToShowIsResident: Ref<boolean> = computed(() => {
    return !isLogged.value && !isPolicyHolder.value;
});

const needToShowCountry: Ref<boolean> = computed(() => {
    return !isResident.value && !isPolicyHolder.value;
});

const lockUserFields: Ref<boolean> = computed(() => {
    return isLogged.value || isPolicyHolder.value;
});

function translated(key: string): string {
    return translateForType(key, props.translationType);
}

function setupForm(): void {
    form.addField(new FormField('isPolicyHolder', ''));
    form.addField(new FormField('isResident', ''));
    form.addField(new FormField('name', '', nameValidator(), Sanitizer.cleanName));
    form.addField(new FormField('surname', '', surnameValidator(), Sanitizer.cleanName));
    form.addField(new FormField('personCode', '', personCodeValidator(), clearPersonCode));
    form.addField(new FormField('birthDate', '', dateValidator()));
    form.addField(new FormField('country', '', countryValidator()));
    form.addField(new FormField('email', '', 'required'));
    form.addField(new FormField('phone', '', 'required'));
    form.addField(new FormField('account', '', accountValidator()));
    form.addField(new FormField('currency', '', currencyValidator()));
    form.setReady();
}

function clearPersonCode(value: string): string {
    let result: string = value;
    if (isResident.value) {
        result = Sanitizer.clearPersonCode(value);
    }

    return result;
}

function nameValidator(): object {
    return {
        nameIsValid: (value: string) => {
            let result: boolean = true;
            if (!lockUserFields.value) {
                result = form.field('name').isTouched ? Validation.isValidCaption(value) : true;
            }

            return result;
        },
    };
}

function surnameValidator(): object {
    return {
        surnameIsValid: (value: string) => {
            let result: boolean = true;
            if (!lockUserFields.value) {
                result = form.field('surname').isTouched ? Validation.isValidCaption(value) : true;
            }

            return result;
        },
    };
}

function personCodeValidator(): object {
    return {
        personCodeIsValid: (value: string) => {
            let result: boolean = true;
            if (!lockUserFields.value && form.field('personCode').isTouched) {
                if (!isResident.value) {
                    if (
                        useDefine().isSet(props.firstUserPersonDetails.country.code) &&
                        balticCountries.includes(props.firstUserPersonDetails.country.iso)
                    ) {
                        result = new PersonCodeValidator().isIdentityNumberValid(
                            value,
                            props.firstUserPersonDetails.country.iso,
                        );
                    } else {
                        result = value.length >= 3;
                    }
                } else {
                    result = new PersonCodeValidator().isIdentityNumberValid(value, new AppCountry().iso());
                }
            }

            return result;
        },
    };
}

function countryValidator(): object {
    return {
        countryIsValid: (value: string) => {
            let result: boolean = false;
            if (needToShowCountry.value) {
                const countryValue: Country = toRaw(value) as unknown as Country;
                if (useDefine().isSet(countryValue.iso) && form.field('country').isTouched) {
                    result = countryValue.iso !== '';
                }
            } else {
                result = true;
            }

            return result;
        },
    };
}

function dateValidator(): object {
    return {
        countryIsValid: (value: string) => {
            let result: boolean = false;
            if (needToShowBirthDate.value) {
                const dateFromForm: Date | '' = value as Date | '';
                if (dateFromForm !== '') {
                    result = moment(dateFromForm).isValid();
                }
            } else {
                result = true;
            }

            return result;
        },
    };
}

function accountValidator(): object {
    return {
        accountIsValid: (value: string) => {
            let result: boolean = true;
            if (props.withBankAccountNumber) {
                if (value === '') {
                    value = ' ';
                }
                result = new IbanValidator().isValidIBANNumber(value);
            }

            return result;
        },
    };
}

function currencyValidator(): object {
    return {
        accountIsValid: (value: string) => {
            let result: boolean = true;
            if (props.withBankAccountNumber) {
                result = value !== '';
            }

            return result;
        },
    };
}

function prepareFormField(): void {
    props.formField.addValidators({
        childrenAreValid: () => form.isValid(),
    });
    props.formField.onClear.subscribe(() => {
        applyValuesToChildFields();
    });
    props.formField.onPatch.subscribe(() => {
        applyValuesToChildFields();
    });
}

function applyDefaultValues(): void {
    if (!isLogged.value) {
        form.field('isPolicyHolder').setValue(true, true);
        form.field('isResident').setValue(false, false);
        form.field('name').setValue(props.firstUserPersonDetails.name, true);
        form.field('surname').setValue(props.firstUserPersonDetails.surname, true);
        form.field('personCode').setValue(props.firstUserPersonDetails.personCode, true);
    } else {
        form.field('isPolicyHolder').setValue(true, true);
        form.field('isResident').setValue(true, false);
        form.field('name').setValue(user.current.firstname, true);
        form.field('surname').setValue(user.current.lastname, true);
        form.field('personCode').setValue(user.current.personCode, true);
        form.field('email').setValue(user.current.email, true);
        form.field('phone').patch({
            country: user.current.phoneCode,
            phone: user.current.phone,
            ic: '',
        });
    }
}

function applyValuesToChildFieldsLegacy(): void {
    applyValuesToChildFields();
}

function applyValuesToChildFields(): void {
    const value = toRaw(props.formField.value);
    if (!new Value(value).isEmpty()) {
        form.field('isPolicyHolder').patch(value.isPolicyHolder);
        form.field('isResident').patch(value.isResident);
        form.field('name').patch(value.name || '');
        form.field('surname').patch(value.surname || '');
        form.field('personCode').patch(value.personCode || '');
        form.field('birthDate').patch(value.birthDate || '');
        form.field('country').patch(value.country || '');
        form.field('email').patch(value.email || '');
        form.field('phone').patch(value.phone || '');
        form.field('account').patch(value.account || '');
        form.field('currency').patch(value.currency || '');
    }
    applyValues();
    nextTick(() => {
        allowPolicyHolderWatch.value = true;
    });
}

function onChildrenChange(): void {
    if (allowPolicyHolderWatch.value) {
        applyValues();
    }
}

function applyValues(): void {
    props.formField.setValue({
        isPolicyHolder: form.field('isPolicyHolder').value || false,
        isResident: form.field('isResident').value || false,
        name: form.field('name').value || '',
        surname: form.field('surname').value || '',
        personCode: form.field('personCode').value || '',
        birthDate: form.field('birthDate').value || '',
        country: form.field('country').value || '',
        email: form.field('email').value || '',
        phone: form.field('phone').value || '',
        phoneType: phoneNumberType.value,
        account: form.field('account').value || '',
        currency: form.field('currency').value || '',
    });
    props.formField.validate();
    props.formField.touch();
    emit('change', props.formField.value);
}

watch(
    () => props.formField.restoreProcessIsFinished,
    () => {
        applyValuesToChildFieldsLegacy();
    },
);

watch(
    () => isPolicyHolder.value,
    (newValue: boolean) => {
        if (allowPolicyHolderWatch.value) {
            if (newValue) {
                form.field('name').setValue(props.firstUserPersonDetails.name);
                form.field('surname').setValue(props.firstUserPersonDetails.surname);
                form.field('personCode').setValue(props.firstUserPersonDetails.personCode);
            } else {
                form.field('name').clear();
                form.field('surname').clear();
                form.field('personCode').clear();
            }
            applyValues();
        }
    },
);

onMounted((): void => {
    setupForm();
    prepareFormField();
    applyDefaultValues();
    if (spaUserStorage.restoreProcessIsFinished.value) {
        applyValuesToChildFields();
    }
});
</script>

<template>
    <div
        v-if="form.isReady()"
        :id="formField.name"
        class="person-details"
        :style="{ maxWidth: maxWidth + 'px' }"
        :class="{ ...formField.classes() }"
        :data-store="formField.name"
        :data-store-value="JSON.stringify(formField.value)"
    >
        <app-whiteboard>
            <div v-if="titleText !== ''" class="title">{{ translated(titleText) }}</div>
            <div class="person-details-person">
                <app-input-checkbox
                    v-if="needToShowIsPolicyHolder"
                    class="policy-holder"
                    :data-store-disabled="true"
                    :form-field="form.field('isPolicyHolder')"
                    @change="onChildrenChange"
                >
                    {{ translated(props.policyHolderText) }}
                </app-input-checkbox>
                <app-input-checkbox
                    v-if="needToShowIsResident"
                    class="resident"
                    :data-store-disabled="true"
                    :form-field="form.field('isResident')"
                    @change="onChildrenChange"
                >
                    {{ translated(props.isResidentText) }}
                </app-input-checkbox>
            </div>
            <div class="person-details-base">
                <app-input-text
                    class="name"
                    :label="translated('name')"
                    :disabled="lockUserFields"
                    :form-field="form.field('name')"
                    :placeholder="translated('name_placeholder')"
                    :autocomplete="'given-name'"
                    :data-store-disabled="true"
                    @change="onChildrenChange"
                >
                </app-input-text>
                <app-input-text
                    class="surname"
                    :label="translated('surname')"
                    :disabled="lockUserFields"
                    :form-field="form.field('surname')"
                    :placeholder="translated('surname_placeholder')"
                    :autocomplete="'family-name'"
                    :data-store-disabled="true"
                    @change="onChildrenChange"
                >
                </app-input-text>
                <app-input-text
                    class="person-code"
                    :label="translated('person_code')"
                    :disabled="lockUserFields"
                    :form-field="form.field('personCode')"
                    :placeholder="translated('person_code_placeholder')"
                    :autocomplete="'disabled'"
                    :data-store-disabled="true"
                    @change="onChildrenChange"
                >
                </app-input-text>
            </div>
            <div class="person-details-non-resident">
                <app-input-date
                    v-if="needToShowBirthDate"
                    class="date"
                    :label="translated('birth_date')"
                    :delimiter="'.'"
                    :allow-invalid-value="true"
                    :form-field="form.field('birthDate')"
                    :data-store-disabled="true"
                    @change="onChildrenChange"
                >
                </app-input-date>
                <app-input-country
                    v-if="needToShowCountry"
                    class="country"
                    :label="translated('country')"
                    :include-default-country="false"
                    :patch-default-country="false"
                    :placeholder="translated('country_placeholder')"
                    :form-field="form.field('country')"
                    :data-store-disabled="true"
                    @change="onChildrenChange"
                >
                </app-input-country>
                <div v-if="!needToShowCountry || !needToShowBirthDate" class="gap-control"></div>
            </div>
            <div class="person-details-contacts">
                <app-input-email
                    class="email"
                    :label="translated('email')"
                    :placeholder="translated('email_placeholder')"
                    :form-field="form.field('email')"
                    :data-store-disabled="true"
                    @change="onChildrenChange"
                >
                </app-input-email>
                <app-phone-with-country
                    class="phone"
                    :label="translated('phone')"
                    :placeholder="translated('phone_placeholder')"
                    :disable-error-text="true"
                    :mobile-mode-enabled="true"
                    :form-field="form.field('phone')"
                    :data-store-disabled="true"
                    @change="onChildrenChange"
                >
                </app-phone-with-country>
            </div>
            <div v-if="withBankAccountNumber" class="person-details-bank">
                <app-input-bank-account
                    class="account"
                    :label="translated('account')"
                    :placeholder="translated('account_placeholder')"
                    :form-field="form.field('account')"
                    :data-store-disabled="true"
                    @change="onChildrenChange"
                >
                </app-input-bank-account>
                <app-input-radio
                    class="currency"
                    :label="translated('currency')"
                    :options="currencyOptions"
                    :form-field="form.field('currency')"
                    :data-store-disabled="true"
                    @change="onChildrenChange"
                >
                </app-input-radio>
            </div>
        </app-whiteboard>
    </div>
</template>

<style scoped lang="scss">
.person-details {
    width: 100%;

    .title {
        font-size: var(--font-size-small);
        font-weight: 700;
        line-height: var(--line-height-accented);
        margin-bottom: var(--size-small);
    }

    .person-details-person {
        .policy-holder,
        .resident {
            margin-bottom: var(--size-normal);
        }

        :deep(.checkbox) {
            label {
                font-size: var(--font-size-tiny);
                font-weight: 600;
                color: var(--component-color-text-value);
            }
        }
    }

    .person-details-base {
        display: flex;
        justify-content: space-between;
        gap: var(--size-small);

        .name,
        .surname,
        .person-code {
            margin-bottom: var(--size-normal);
        }

        @include respond-below('md') {
            flex-direction: column;
            gap: 0;

            .name,
            .surname,
            .person-code {
                max-width: 100%;
            }
        }
    }

    .person-details-non-resident {
        display: flex;
        justify-content: space-between;
        gap: var(--size-small);

        .date {
            :deep(.wrapper) {
                padding: 0 30px 0 20px;
            }
        }

        .date,
        .country {
            margin-bottom: var(--size-normal);
            width: 50%;

            :deep(.wrapper) {
                height: 52px;
                justify-content: flex-start;
            }
        }

        .country {
            :deep(.label) {
                p {
                    color: var(--component-color-text-value);
                }
            }
        }

        .gap-control {
            width: 50%;
        }

        @include respond-below('md') {
            flex-direction: column;
            gap: 0;

            .date,
            .country {
                width: 100%;
            }

            .gap-control {
                width: 0;
            }
        }
    }

    .person-details-contacts {
        display: flex;
        justify-content: space-between;
        gap: var(--size-small);

        .email,
        .phone {
            margin-bottom: var(--size-normal);
            width: 50%;
        }

        @include respond-below('md') {
            flex-direction: column;
            gap: 0;

            .email,
            .phone {
                width: 100%;
            }
        }
    }

    .person-details-bank {
        display: flex;
        justify-content: space-between;
        gap: var(--size-small);

        .account,
        .currency {
            margin-bottom: var(--size-normal);
            width: 50%;
        }

        .currency {
            :deep(.label) {
                p {
                    color: var(--component-color-text-value);
                }
            }

            :deep(.buttons) {
                display: flex;
                gap: var(--size-small);
            }
        }

        @include respond-below('md') {
            flex-direction: column;
            gap: 0;

            .account,
            .currency {
                width: 100%;
            }
        }
    }
}
</style>
