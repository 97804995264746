import AppStepperBelt from '@/Components/Routing/StepperBelt/StepperBelt.vue';
import AppRouteStepper from '@/Components/Routing/RouteStepper/RouteStepper.vue';
import Components from '@/Core/App/Interfaces/ComponentsInterface';
import BackButton from '@/Components/Routing/BackButton/BackButton.vue';

export default {
    AppBackButton: BackButton,
    AppRouteStepper: AppRouteStepper,
    AppStepperBelt: AppStepperBelt,
} as Components;
