<script lang="ts">
import { defineComponent } from 'vue';
import OneBaseService from '@/services/OneBaseService';
import OneBase from '@/interfaces/OneBaseInterface';
import ClaimsTransportSubmissionFormModule from '@/Modules/ClaimsSubmissionForm/SubmissionFormTransport.vue';
import VueModule from '@/Core/App/VueModule';
import Error from '@/services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import TransferStateService from '@/Core/ServerState/TransferStateService';

export default defineComponent({
    setup() {
        const btaBase: OneBase = OneBaseService.getInstance();

        const claimsTransportSubmissionFormModule = new VueModule(ClaimsTransportSubmissionFormModule).mount();

        function onSubmitStep(): void {
            btaBase.captcha
                .executeCaptcha(submit)
                .then()
                .catch((reason: string) => {
                    Error.log(ErrorType.Error, 'onSubmitStep', reason, true);
                });
        }

        function submit(): void {
            btaBase.navigate(TransferStateService.getInstance().get('nextStepUrl'));
        }

        return {
            ...btaBase,
            ...{
                claimsTransportSubmissionFormModule,
                onSubmitStep,
                submit,
            },
        };
    },

    mounted() {
        this.applyApp(this);
        this.initBtaBase();

        this.claimsTransportSubmissionFormModule.create();
        this.claimsTransportSubmissionFormModule.beforeModuleMounted();
        this.claimsTransportSubmissionFormModule.moduleMounted();
    },
});
</script>
