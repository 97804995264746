<script setup lang="ts">
import { onMounted, Ref, ref, markRaw } from 'vue';
import Form from '@/assets/libraries/form/form';
import FormField from '@/assets/libraries/form/form-field';
import AppRepeatable from '@/Components/Other/Repeatable/Repeatable.vue';
import AppCustomForm from '@/Components/Inputs/CustomForm/CustomForm.vue';
import AppInputText from '@/Components/Inputs/InputText/InputText.vue';
import AppInputNumber from '@/Components/Inputs/InputNumber/InputNumber.vue';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import AppButtonWithCallback from '@/Components/Buttons/ButtonWithCallback/ButtonWithCallback.vue';
import AppRepeatableWhiteboardItem from '@/Components/Other/Repeatable/RepeatableWhiteboardItem.vue';
import Validation from '@/services/validation.service';
import { FormHelperParams, useFormHelper } from '@/Composables/FormHelper';

interface StoredItem {
    formName: string;
    model: string;
    count: number;
}

interface ExampleStoredFields {
    items: StoredItem[];
}

interface ExampleForm {
    model: string;
    count: number;
}

interface ExampleItem {
    id: string;
    form: Form<ExampleForm>;
}

const storedFields: Ref<ExampleStoredFields> = ref({
    items: [
        { formName: 'b4198dfe-d8b1-424a-ab81-6a0bd2708d97', model: 'model-1', count: 1 },
        { formName: '22cad43c-0388-4440-9b7c-70506c707458', model: 'model-2', count: 4 },
    ],
});
const formHelper: FormHelperParams = useFormHelper();
const items: Ref<ExampleItem[]> = ref([]);

onMounted(() => {
    restoreValues();
});

function restoreValues(): void {
    items.value = storedFields.value.items.map((storedItem: StoredItem): ExampleItem => {
        const item: ExampleItem = createItemFactory(storedItem.formName);
        item.form.restoreValues({
            model: storedItem.model,
            count: storedItem.count,
        });
        return item;
    });
}

function storeValues(): void {
    storedFields.value.items = items.value.map((item: ExampleItem): StoredItem => {
        return {
            formName: item.form.name,
            model: item.form.field('model').value,
            count: item.form.field('count').value,
        } as StoredItem;
    });
}

function createItemFactory(id: string): ExampleItem {
    const form: Form<ExampleForm> = new Form(id, { useValidationV2: true });
    form.addField(new FormField('model').addValidators({ required: Validation.required }));
    form.addField(new FormField('count').addValidators({ required: Validation.required }));
    form.setReady();
    return { id: id, form: markRaw(form) };
}

function onRemove(item: ExampleItem): void {
    item.form.destroy();
}

function submit(): void {
    const forms: Form<ExampleForm>[] = items.value.map((item: ExampleItem): Form<ExampleForm> => item.form);
    formHelper.submitAttemptMultiple(forms).then(() => {
        if (formHelper.isMultipleFormsValid(forms)) {
            storeValues();
            alert('SUBMITTED: ' + JSON.stringify(storedFields.value));
        }
    });
}
</script>

<template>
    <app-repeatable v-model:items="items" :min="1" :max="5" :factory="createItemFactory" @remove="onRemove">
        <template #default="{ item, index, repeatable }">
            <app-repeatable-whiteboard-item
                :title="'Item #' + (index + 1)"
                :add-button-text="'Add object'"
                :item="item"
                :repeatable="repeatable"
            >
                <app-custom-form class="item-form" :form="item.form">
                    <app-input-text :label="'Model'" :form-field="item.form.field('model')"></app-input-text>
                    <app-input-number :label="'Count'" :form-field="item.form.field('count')"></app-input-number>
                </app-custom-form>
            </app-repeatable-whiteboard-item>
        </template>
    </app-repeatable>
    <app-button-with-callback
        class="big"
        :title="'Submit'"
        :text-color="ButtonTextColor.White"
        :background-color="ButtonBackground.Red"
        @button-callback-click="submit"
    >
    </app-button-with-callback>
</template>

<style lang="scss" scoped>
.item-form {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-gap: 20px;
    padding: 0;

    > .input {
        margin: 0 !important;
    }

    @include respond-above('sm') {
        grid-template-columns: repeat(2, 1fr);
    }
}
</style>
