import MomentBuilder from '@/assets/libraries/Date/Builders/MomentBuilder';
import { useStrings } from '@/Composables/Strings';
import SettingsService from '@/services/settings.service';

export default class OneDate {
    /**
     * @param value Date or date string in OneDate.supportedInitialFormats()
     * @return OneDate.shortFormat() date based on One locale iso
     */
    public static short(value: string | Date): string {
        return new MomentBuilder().withInitialDate(value).withOneLocale().build().format(OneDate.shortFormat());
    }

    /**
     * @param value Date or date string in OneDate.supportedInitialFormats()
     * @return OneDate.mediumFormat() date in page language
     */
    public static medium(value: string | Date): string {
        return useStrings().capitalize(
            new MomentBuilder().withInitialDate(value).withPageLocale().build().format(OneDate.mediumFormat()),
        );
    }

    /**
     * @param value Date or date string in OneDate.supportedInitialFormats()
     * @return OneDate.mediumFormat() date in page language
     */
    public static mediumLong(value: string | Date): string {
        const result: string = new MomentBuilder()
            .withInitialDate(value)
            .withPageLocale()
            .build()
            .format(OneDate.mediumLongFormat());

        return MomentBuilder.mappedLanguage() === 'en' ? useStrings().capitalizeAll(result) : result;
    }

    /**
     * @param value Date or date string in OneDate.supportedInitialFormats()
     * @return OneDate.longFormat() date based on One locale iso
     */
    public static long(value: string | Date): string {
        return new MomentBuilder().withInitialDate(value).withOneLocale().build().format(OneDate.longFormat());
    }

    /**
     * @param value Date or date string in OneDate.supportedInitialFormats()
     * @param format any custom format
     * @return Custom format date based on One locale iso
     */
    public static custom(value: string | Date, format: string): string {
        return new MomentBuilder().withInitialDate(value).withOneLocale().build().format(format);
    }

    /**
     * @param value Date or date string in OneDate.supportedInitialFormats()
     * @return Custom format date based on One locale iso
     */
    public static iris(value: string | Date): string {
        return new MomentBuilder().withInitialDate(value).withPageLocale().build().format(OneDate.irisFormat());
    }

    /**
     * @param value Date or date string in OneDate.supportedInitialFormats()
     * @return Custom format date based on One locale iso
     */
    public static irisTimestamp(value: string | Date): string {
        return new MomentBuilder()
            .withInitialDate(value)
            .withPageLocale()
            .build()
            .format(OneDate.irisTimestampFormat());
    }

    public static shortFormat(): string {
        return SettingsService.getInstance().dateFormat().short;
    }

    public static mediumFormat(): string {
        return SettingsService.getInstance().dateFormat() ? SettingsService.getInstance().dateFormat().medium : '';
    }

    public static mediumLongFormat(): string {
        return SettingsService.getInstance().dateFormat().mediumLong;
    }

    public static longFormat(): string {
        return SettingsService.getInstance().dateFormat().long;
    }

    public static irisFormat(): string {
        return SettingsService.getInstance().dateFormat().iris;
    }

    public static irisTimestampFormat(): string {
        return SettingsService.getInstance().dateFormat().irisTimestamp;
    }

    public static supportedInitialFormats(): string[] {
        return [
            'YYYY/MM/DD',
            'YYYY/MM/DD HH:mm',
            'YYYY.MM.DD',
            'YYYY.MM.DD HH:mm',
            'YYYY-MM-DD',
            'YYYY-MM-DD HH:mm',
            'DD/MM/YYYY',
            'DD/MM/YYYY HH:mm',
            'DD.MM.YYYY',
            'DD.MM.YYYY HH:mm',
            'DD-MM-YYYY',
            'DD-MM-YYYY HH:mm',
            'MMM YYYY',
            'DD MMM YYYY',
        ];
    }
}
