<script setup lang="ts">
import { computed, onMounted, PropType, Ref, watch } from 'vue';
import FormField from '@/assets/libraries/form/form-field';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import { useTranslate } from '@/Composables/Translate';
import CssClass from '@/Enums/CssClassEnum';
import { useDefine } from '@/Composables/Define';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import { InputOption } from '@/interfaces/InputOptionInterface';

const props = defineProps({
    label: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    formField: { type: Object as PropType<FormField<DynamicDictionary>>, default: () => new FormField('') },
    options: { type: Array as PropType<InputOption[]>, default: () => [] },
    type: { type: String as PropType<'checkbox' | 'radio'>, default: 'checkbox' },
    optionClass: { type: String as PropType<'filled' | ''>, default: '' },
    maxSelect: { type: Number, default: 0 },
    minCharacters: { type: Number, default: 3 },
    showErrorBorders: { type: Boolean, default: true },
    customLabels: { type: Object, default: () => {} },
    required: { type: Boolean, default: false },
    dataStoreDisabled: { type: Boolean, default: false },
    skipOptionsChangeFormReset: { type: Boolean, default: false },
});

const emit = defineEmits(['change']);

const { translate } = useTranslate();
const { isSet } = useDefine();

const checkboxValuesSeparator: string = ':';

const invalidClass: Ref<string> = computed(() => {
    return props.formField.classes().invalid ? CssClass.Invalid : '';
});

const combinedCheckboxValues: Ref<string[]> = computed(() => {
    if (props.formField.isEmpty()) {
        prepareValueForEmptyCheckbox();
    }

    return props.formField.value.selected === '' ? [] : props.formField.value.selected.split(checkboxValuesSeparator);
});

const isCheckbox: Ref<boolean> = computed(() => {
    return props.type === 'checkbox';
});

watch(
    () => props.formField.value,
    (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
            if (props.formField.isEmpty()) {
                select(newValue.selected);
            }
            emit('change', newValue, oldValue);
        }
    },
);

watch(
    () => props.options,
    (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue) && !props.skipOptionsChangeFormReset) {
            props.formField.setValue({
                selected: props.formField.value.selected ?? '',
                customText: customTextValues(),
            });
        }
    },
);

onMounted(() => {
    prepareFormValue();
    props.formField.addValidators(validators());
});

function onOptionClick(option: InputOption): void {
    if (!option.disabled) {
        select(String(option.value));
    }
}

function itemClasses(selected: InputOption): string {
    const result: string[] = ['options-list-' + props.type, !props.showErrorBorders ? 'omit-red-borders' : ''];
    if (itemIsSelected(selected)) {
        result.push(CssClass.Selected);
    }
    if (itemIsDisabled(selected)) {
        result.push(CssClass.Disabled);
    }
    if (invalidClass.value !== '') {
        result.push(CssClass.Invalid);
    }
    if (selected.hidden) {
        result.push(CssClass.Invisible);
    }
    if (props.optionClass !== '') {
        result.push(props.optionClass);
    }

    return result.join(' ');
}

function itemIsSelected(selected: InputOption): boolean {
    let result: boolean = false;
    if (isCheckbox.value && hasCheckboxValue(String(selected.value))) {
        result = true;
    } else if (String(selected.value) === props.formField.value.selected) {
        result = true;
    }

    return result;
}

function itemIsDisabled(option: InputOption): boolean {
    return (option.disabled || maxValuesIsReached()) && !itemIsSelected(option);
}

function select(value: string): void {
    props.formField.setValue({
        selected: isCheckbox.value ? checkboxValue(value) : String(value),
        customText: props.formField.value.customText,
    });
    props.formField.markAsTouched();
    props.formField.markAsDirty();
}

function customPlaceholder(option: InputOption): string {
    let result: string;
    if (props.customLabels && isSet(props.customLabels[option.value as string])) {
        result = translate(props.customLabels[option.value as string]);
    } else {
        result = translate(option.value + '_report');
    }

    return result;
}

function customTextValues(): DynamicDictionary {
    const values: DynamicDictionary = {};
    props.options.forEach((option: InputOption) => {
        if (option.custom === true) {
            values[option.value as string] = '';
        }
    });

    return values;
}

function prepareValueForEmptyCheckbox(): void {
    props.formField.setValue({
        selected: '',
        customText: customTextValues(),
    });
}

function prepareFormValue(): void {
    if (!isSet(props.formField.value.customText)) {
        let value: LimitedVariant = '';
        if (isSet(props.formField.value.selected)) {
            value = props.formField.value.selected;
        }
        props.formField.setValue({
            selected: value,
            customText: customTextValues(),
        });
    }
}

function checkboxValue(value: string): string {
    const separator: string = ':';
    const checkBoxValues: string[] = combinedCheckboxValues.value;
    if (!checkBoxValues.includes(value) && maxValuesNotReached()) {
        checkBoxValues.push(value);
    } else if (checkBoxValues.includes(value)) {
        const index: number = checkBoxValues.indexOf(value);
        if (index > -1) {
            checkBoxValues.splice(index, 1);
        }
    }

    return checkBoxValues.join(separator);
}

function maxValuesNotReached(): boolean {
    return (props.maxSelect > 0 && combinedCheckboxValues.value.length < props.maxSelect) || props.maxSelect === 0;
}

function maxValuesIsReached(): boolean {
    return !maxValuesNotReached();
}

function hasCheckboxValue(value: string): boolean {
    return String(props.formField.value.selected).includes(value);
}

function validators(): object {
    return {
        isValid: () => {
            let customTextIsValidOrNotUsed: boolean = true;
            if (props.options) {
                props.options.forEach((option: InputOption) => {
                    if (option.custom) {
                        const values: string[] = isCheckbox.value
                            ? String(props.formField.value.selected).split(checkboxValuesSeparator)
                            : [props.formField.value.selected];
                        values.forEach((value: string) => {
                            if (String(option.value) === value) {
                                if (
                                    typeof props.formField.value.customText === 'object' &&
                                    props.formField.value.customText[option.value as string] &&
                                    props.formField.value.customText[option.value as string].length <
                                        props.minCharacters
                                ) {
                                    customTextIsValidOrNotUsed = false;
                                }
                            }
                        });
                    }
                });
            }

            return props.formField.value.selected === '' || customTextIsValidOrNotUsed;
        },
    };
}
</script>

<template>
    <div
        :id="formField.name"
        class="options-list"
        :class="{ ...formField.classes(), disabled: disabled }"
        :data-store="dataStoreDisabled ? '' : formField.name"
        :data-store-value="dataStoreDisabled ? '' : JSON.stringify(formField.value)"
    >
        <div v-if="label" class="label informative">
            <p>{{ label }}<span v-if="required" class="asterisk">&#42;</span></p>
            <slot name="app-tooltipster"></slot>
        </div>
        <template v-for="(option, index) in options" :key="index">
            <div v-if="option.separatorText" class="separator-text">{{ option.separatorText }}</div>
            <div class="options-list-option" :class="itemClasses(option)">
                <button
                    class="options-list-panel"
                    :class="invalidClass"
                    :data-type="formField.name + '-button-' + index"
                    :data-index="index"
                    @click="onOptionClick(option)"
                >
                    <span v-if="isCheckbox" class="checkbox-icon">
                        <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1 5.8L4.42857 9L13 1"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </span>
                    <span v-if="!isCheckbox" class="radio-icon"></span>
                    {{ option.name }}
                </button>
                <div v-if="option.custom === true && itemIsSelected(option)" class="options-list-textarea-container">
                    <textarea
                        v-if="formField.value.customText"
                        :id="formField.name + '-customText-' + index"
                        v-model="formField.value.customText[option.value as string | number]"
                        class="options-list-textarea"
                        :name="formField.name + '-customText-' + index"
                        :rows="4"
                        :placeholder="customPlaceholder(option)"
                    >
                    </textarea>
                </div>
            </div>
        </template>
    </div>
</template>

<style lang="scss" scoped>
.options-list {
    width: 100%;

    .label {
        &.informative {
            margin-bottom: 21px;
            font-size: var(--font-size-small);
            font-weight: 600;
            color: var(--text-color-default);
        }
    }

    &-option {
        position: relative;
        width: 100%;
        min-height: 52px;
        margin-bottom: var(--size-nano);
        background-color: var(--component-color-background-base);
        border: 2px solid transparent;
        border-radius: 8px;
        transition: border-color 0.6s;

        &:focus:not(.disabled, .selected),
        &:hover:not(.disabled, .selected) {
            border-color: var(--brand-teal);
        }

        &:not(:focus, :hover, .disabled, .selected, .invalid)::before {
            content: '';
            position: absolute;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            border: 1px solid var(--component-color-border-default);
            border-radius: 8px;
            margin-top: -1px;
            margin-left: -1px;
        }
    }

    &-panel {
        position: relative;
        width: 100%;
        text-align: left;
        font-size: var(--font-size-tiny);
        line-height: var(--line-height-accented);
        font-weight: 600;
        color: var(--text-color-default);
        padding: 13px 22px 13px 55px;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -12px;
            margin-left: 14px;
            width: 24px;
            height: 24px;
            border-radius: 4px;
            border: 2px solid var(--component-color-border-default);
        }

        .checkbox-icon {
            display: none;
            position: absolute;
            left: 19px;
            top: 50%;
            margin-top: -12px;

            svg {
                min-height: 24px;
            }
        }

        .radio-icon {
            display: none;
            position: absolute;
            top: 50%;
            margin-top: -6px;
            left: 20px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: var(--component-color-background-base);
        }
    }

    &-option:focus-within:not(.disabled) {
        border-color: var(--brand-teal);

        &::before {
            display: none;
        }
    }

    &-radio {
        .options-list-panel {
            &::before {
                border-radius: 50%;
            }
        }
    }

    .selected {
        border-color: var(--text-color-link);

        .options-list-panel {
            &::before {
                border-color: var(--text-color-link);
                background-color: var(--text-color-link);
            }

            .checkbox-icon {
                display: block;
            }

            .radio-icon {
                display: block;
                background-color: var(--text-color-link);
            }
        }

        &.options-list-radio {
            .options-list-panel {
                &::before {
                    background-color: var(--component-color-background-base);
                }
            }
        }
    }

    .separator-text {
        color: var(--text-color-subtlest);
        align-self: center;
        margin: var(--size-small) 0;
        font-weight: 500;
    }

    .options-list-option.disabled {
        background-color: var(--background-light);

        .options-list-panel {
            color: var(--black-200);

            .radio-icon,
            .checkbox-icon {
                display: none;
            }
        }
    }

    .options-list-option.invisible {
        visibility: hidden;
        height: 0;
        min-height: 0;
        margin: 0;
        padding: 0;
    }

    &-textarea-container {
        padding: 0 15px 15px;
        width: 100%;
        height: 119px;
    }

    &-textarea {
        width: 100%;
        height: 104px;
        padding: var(--size-small) 30px var(--size-small) var(--size-small);
        background-color: var(--background-light);
        border: 1px solid var(--black-200);
        border-radius: 4px;
        font-weight: 500;
        font-size: var(--font-size-nano);
        color: var(--text-subtlest);
        mix-blend-mode: normal;
        font-family: var(--text-font-stack);
        resize: none;

        &::placeholder {
            opacity: 0.56;
            color: var(--black-500);
        }

        &:hover {
            @include input-hover;
        }

        &:focus {
            @include input-focus;
        }
    }

    &.invalid {
        > .options-list-textarea {
            border-color: var(--brand-red);
            outline-color: var(--brand-red);
        }

        .options-list-option:not(.omit-red-borders) {
            border-color: var(--brand-red);
        }

        .omit-red-borders:not(:focus, :hover, .disabled, .selected)::before {
            content: '';
            position: absolute;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            border: 1px solid var(--component-color-border-default);
            border-radius: 8px;
            margin-top: -1px;
            margin-left: -1px;
        }
    }

    .options-list-option.filled {
        .options-list-panel {
            padding: 20px 20px 20px 64px;

            &::before {
                margin-left: 20px;
            }
        }

        &.selected {
            background-color: var(--system-color-success-light);

            .options-list-panel {
                &::before {
                    background-color: var(--system-color-success-dark);
                }

                .radio-icon {
                    margin-top: -4px;
                    width: var(--size-pico);
                    height: var(--size-pico);
                    left: 28px;
                    background-color: var(--background-light);
                }
            }
        }
    }
}
</style>
