import { isMobileOnly, isIOS } from 'mobile-device-detect';

export default class AppBrowser {
    private httpsProtocolPattern: RegExp = /https:/i;

    public userAgent(): string {
        return navigator.userAgent;
    }

    public isIos(): boolean {
        return isIOS;
    }

    public isDevice(): boolean {
        return isMobileOnly;
    }

    public isDesktop(): boolean {
        return !this.isDevice();
    }

    public isHttps(): boolean {
        return !!location.protocol.match(this.httpsProtocolPattern);
    }
}
