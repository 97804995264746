import { Router, RouteLocationNormalized, RouteLocationNormalizedLoaded, useRouter } from 'vue-router';
import Storage from '@/Apps/ActivePlus/Services/Storage';
import { useScroll } from '@/Composables/Scroll';

export default class StepsGuard {
    private static instance: StepsGuard;
    private router: Router = useRouter();
    private storage: Storage;

    private constructor(storage: Storage) {
        this.storage = storage;
    }

    public static getInstance(storage: Storage): StepsGuard {
        if (!StepsGuard.instance) {
            StepsGuard.instance = new StepsGuard(storage);
        }

        return StepsGuard.instance;
    }

    public init(): void {
        this.preventRouteDirectAccess();
        this.hideAdditionalModules();
        this.router.afterEach((_to: RouteLocationNormalized, _from: RouteLocationNormalizedLoaded): void => {
            useScroll().scrollToTop().then();
        });
    }

    public preventRouteDirectAccess(): void {}

    private hideAdditionalModules(): void {}
}
