import { ref, Ref } from 'vue';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import VueModule from '@/Core/App/VueModule';
import GetInTouchModule from '@/Modules/GetInTouch/GetInTouch.vue';

export default class GetInTouchModuleService {
    private static instance: GetInTouchModuleService;

    public static getInTouchModuleContactless: string = 'getInTouchModuleContactless';
    public static getInTouchModuleCampaign: string = 'getInTouchModuleCampaign';
    public static getInTouchModuleLottery: string = 'getInTouchModuleLottery';

    public getInTouchModuleDefault: Ref<DynamicDictionary | null> = ref(null);
    public getInTouchModuleCampaign: Ref<DynamicDictionary | null> = ref(null);
    public getInTouchModuleLottery: Ref<DynamicDictionary | null> = ref(null);
    public getInTouchModuleContactless: Ref<DynamicDictionary | null> = ref(null);
    public getInTouchModulePromo: Ref<DynamicDictionary | null> = ref(null);

    private constructor() {}

    public static getInstance(): GetInTouchModuleService {
        if (!GetInTouchModuleService.instance) {
            GetInTouchModuleService.instance = new GetInTouchModuleService();
        }

        return GetInTouchModuleService.instance;
    }

    public init(): void {
        this.getInTouchModuleDefault.value = new VueModule(GetInTouchModule).mount();
        this.getInTouchModuleDefault.value.create('getInTouchModuleDefault');
        this.getInTouchModuleDefault.value.beforeModuleMounted();
        this.getInTouchModuleDefault.value.moduleMounted();
        this.getInTouchModuleDefault.value.enableScrollToMode();

        this.getInTouchModuleCampaign.value = new VueModule(GetInTouchModule).mount();
        this.getInTouchModuleCampaign.value.create('getInTouchModuleCampaign');
        this.getInTouchModuleCampaign.value.beforeModuleMounted();
        this.getInTouchModuleCampaign.value.moduleMounted();
        this.getInTouchModuleCampaign.value.enableScrollToMode();

        this.getInTouchModuleLottery.value = new VueModule(GetInTouchModule).mount();
        this.getInTouchModuleLottery.value.create('getInTouchModuleLottery');
        this.getInTouchModuleLottery.value.beforeModuleMounted();
        this.getInTouchModuleLottery.value.moduleMounted();
        this.getInTouchModuleLottery.value.enableScrollToMode();

        this.getInTouchModuleContactless.value = new VueModule(GetInTouchModule).mount();
        this.getInTouchModuleContactless.value.create('getInTouchModuleContactless');
        this.getInTouchModuleContactless.value.beforeModuleMounted();
        this.getInTouchModuleContactless.value.moduleMounted();
        this.getInTouchModuleContactless.value.enableScrollToMode();

        this.getInTouchModulePromo.value = new VueModule(GetInTouchModule).mount();
        this.getInTouchModulePromo.value.create('getInTouchModulePromo');
        this.getInTouchModulePromo.value.beforeModuleMounted();
        this.getInTouchModulePromo.value.moduleMounted();
        this.getInTouchModulePromo.value.enableScrollToMode();
    }
}
