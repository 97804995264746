import 'vite/modulepreload-polyfill';
import 'moment/dist/locale/lv';
import 'moment/dist/locale/lt';
import 'moment/dist/locale/et';
import 'moment/dist/locale/en-gb';
import 'moment/dist/locale/ru';
import { App } from 'vue';
import { createPinia } from 'pinia';
import BackendRoute from '@/Core/Routing/Interfaces/BackendRouteInterface';
import Components from '@/Core/App/Interfaces/ComponentsInterface';
import VueApp from '@/Core/Routing/VueApp';
import { ComponentPublicInstance } from 'vue';
import Router from '@/Core/Routing/Router';
import GlobalDirectives from '@/Directives/Directives';
import AppComponent from '@/Apps/PayByLink/Pay/App.vue';
import DetailsPage from '@/Apps/PayByLink/Pay/Pages/DetailsPage.vue';
import DetailsLithuaniaPage from '@/Apps/PayByLink/Pay/Pages/DetailsLithuaniaPage.vue';
import EditOfferPage from '@/Apps/PayByLink/Pay/Pages/EditOfferPage.vue';
import EditOfferLithuaniaPage from '@/Apps/PayByLink/Pay/Pages/EditOfferLithuaniaPage.vue';
import SummaryPage from '@/Apps/PayByLink/Pay/Pages/SummaryPage.vue';
import SummaryLithuaniaPage from '@/Apps/PayByLink/Pay/Pages/SummaryLithuaniaPage.vue';
import StepperLayout from '@/Layouts/StepperLayout.vue';
import RouteStepper from '@/Components/Routing/RouteStepper/RouteStepper.vue';
import InputText from '@/Components/Inputs/InputText/InputText.vue';
import InputNumber from '@/Components/Inputs/InputNumber/InputNumber.vue';
import InputCheckbox from '@/Components/Inputs/InputCheckbox/InputCheckbox.vue';
import Popup from '@/Components/Popups/Popup/Popup.vue';
import Tooltipster from '@/Components/Tooltips/Tooltipster/Tooltipster.vue';
import ContentLoader from '@/Components/Loaders/ContentLoader/ContentLoader.vue';
import CustomForm from '@/Components/Inputs/CustomForm/CustomForm.vue';
import LanguageSwitcher from '@/Components/Widgets/LanguageSwitcher/LanguageSwitcher.vue';
import InputRadio from '@/Components/Inputs/InputRadio/InputRadio.vue';
import InputEmail from '@/Components/Inputs/InputEmail/InputEmail.vue';
import InputSelect from '@/Components/Inputs/InputSelect/InputSelect.vue';
import InputDateWithCalendar from '@/Components/Inputs/InputDateWithCalendar/InputDateWithCalendar.vue';
import InputDateWithDropdown from '@/Components/Inputs/InputDateWithDropdown/InputDateWithDropdown.vue';
import InputMultiCheckbox from '@/Components/Inputs/InputMultiCheckbox/InputMultiCheckbox.vue';
import ButtonWithCallback from '@/Components/Buttons/ButtonWithCallback/ButtonWithCallback.vue';
import FilesUploader from '@/Components/Files/FilesUploader/FilesUploader.vue';
import PersonListWithSearch from '@/Components/Lists/PersonListWithSearch/PersonListWithSearch.vue';
import PhoneWithCountry from '@/Components/Inputs/PhoneWithCountry/PhoneWithCountry.vue';
import InformationalTextBlock from '@/Components/Tooltips/InformationalTextBlock/InformationalTextBlock.vue';
import Drawer from '@/Components/Modals/Drawer/Drawer.vue';
import InputRadioOverlayed from '@/Components/Inputs/InputRadioOverlayed/InputRadioOverlayed.vue';
import MtplOfferOption from '@/Components/Lists/AdditionalOptionsList/Components/MtplOfferOption.vue';
import PlaceholderOption from '@/Components/Lists/AdditionalOptionsList/Components/PlaceholderOption.vue';
import Expandable from '@/Components/Expandables/Expandable/Expandable.vue';
import ProductLayout from '@/Layouts/ProductLayout.vue';

const pages: Components = {
    DetailsPage: DetailsPage,
    DetailsLithuaniaPage: DetailsLithuaniaPage,
    EditOfferPage: EditOfferPage,
    EditOfferLithuaniaPage: EditOfferLithuaniaPage,
    SummaryPage: SummaryPage,
    SummaryLithuaniaPage: SummaryLithuaniaPage,
};

const components: Components = {
    AppProductLayout: ProductLayout,
    AppRouteStepper: RouteStepper,
    AppInputText: InputText,
    AppInputNumber: InputNumber,
    AppInputCheckbox: InputCheckbox,
    AppPopup: Popup,
    AppTooltipster: Tooltipster,
    AppContentLoader: ContentLoader,
    AppCustomForm: CustomForm,
    AppLanguageSwitcher: LanguageSwitcher,
    AppInputRadio: InputRadio,
    AppInputEmail: InputEmail,
    AppInputSelect: InputSelect,
    AppInputDateWithCalendar: InputDateWithCalendar,
    AppInputDateWithDropdown: InputDateWithDropdown,
    AppInputMultiCheckbox: InputMultiCheckbox,
    AppInputRadioOverlayed: InputRadioOverlayed,
    AppButtonWithCallback: ButtonWithCallback,
    AppFilesUploader: FilesUploader,
    AppPersonListWithSearch: PersonListWithSearch,
    AppPhoneWithCountry: PhoneWithCountry,
    AppInformationalTextBlock: InformationalTextBlock,
    AppDrawer: Drawer,
    AppMtplOfferOption: MtplOfferOption,
    AppPlaceholderOption: PlaceholderOption,
    AppExpandable: Expandable,
};

export default class {
    public app(selector: string, routes: BackendRoute[]): ComponentPublicInstance {
        const app: App = new VueApp(AppComponent)
            .withComponents({ ...pages, ...components })
            .withDirectives(GlobalDirectives)
            .create();
        app.use(new Router(app, routes).create());
        app.use(createPinia());
        return app.mount(selector);
    }
}
