<script lang="ts">
import { computed, defineComponent, reactive, ref, Ref, nextTick, UnwrapNestedRefs } from 'vue';
import OneBaseService from '@/services/OneBaseService';
import CoveredPopupProperty from '@/pages/Property/CoveredPopupProperty';
import PropertyDataLayer from '@/pages/Property/PropertyDataLayer';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import { TranslateParams, useTranslate } from '@/Composables/Translate';
import { DefineParams, useDefine } from '@/Composables/Define';
import { StepsSubmitterParams, useStepsSubmitter } from '@/Composables/StepsSubmitter';
import PropertyPaymentType from '@/Enums/PropertyPaymentTypeEnum';
import PropertyEnum from '@/Enums/PropertyEnum';
import PropertyTransformation from '@/services/property.transformation.service';
import Form from '@/assets/libraries/form/form';
import AppCountry from '@/assets/libraries/app/app-country';
import { Subscription } from 'rxjs';
import VueEvent from '@/Classes/VueEventClass';
import CssClass from '@/Enums/CssClassEnum';
import Method from '@/Enums/MethodEnum';
import Url from '@/Enums/UrlEnum';
import PropertyRiskData from '@/interfaces/property.risk.data.interface';
import PropertyProductTerms from '@/interfaces/property.product.terms.interface';
import PropertyRiskDataPrice from '@/interfaces/property.risk.data.price.interface';
import { PriceParams, usePrice } from '@/Composables/Price';
import RecurringPaymentBanklink from '@/Enums/RecurringPaymentBanklinkEnum';
import FormField from '@/assets/libraries/form/form-field';
import Value from '@/assets/libraries/form/value';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import PropertyRiskType from '@/Enums/PropertyRiskTypeEnum';
import OneBase from '@/interfaces/OneBaseInterface';

export default defineComponent({
    setup: function () {
        const btaBase: OneBase = OneBaseService.getInstance();
        const { translate, translateForType, hasLocalization }: TranslateParams = useTranslate();
        const { isSet }: DefineParams = useDefine();
        const stepsSubmitter: StepsSubmitterParams = useStepsSubmitter();
        const { sparse }: PriceParams = usePrice();

        const CurrentStep: number = 6;
        const EasingSpeed: number = 300;
        const RowElementsCount: number = 3;
        const ConsentsGlue: string = ':';
        const TermsAndConditionsElement: string = '#terms-and-conditions';
        const PaymentOptionsElement: string = '[data-alias="banks-list"]';
        const PaymentOptionsTitle: string = '.payments > .title';
        const ResetTermsErrorElement: string = '.with-border';
        const SquareMetreLabel: string = 'm<sup>2</sup>';
        const coveredPopup: CoveredPopupProperty = new CoveredPopupProperty();
        const dataLayer: PropertyDataLayer = new PropertyDataLayer();
        const bankLinkId: Ref<number> = ref(0);
        const insurancePlan: Ref<string> = ref('');
        const paymentType: Ref<string> = ref('');
        const isApprovalCaseRequired: Ref<boolean> = ref(false);
        const informationText: Ref<string | null> = ref(null);
        const form: Form = new Form();
        const selectedProductWithAdditionalRisks: UnwrapNestedRefs<DynamicDictionary> = reactive({});
        let onStorageReadySubscription!: Subscription;

        const isVisiblePaymentInfo: Ref<boolean> = computed((): boolean => {
            return bankLinkId.value > 0 && !!informationText.value;
        });

        const isPaymentMethodSelected: Ref<boolean> = computed((): boolean => {
            return bankLinkId.value !== 0;
        });

        const isCoveredPopupVisible: Ref<boolean> = computed(() => {
            return btaBase.popup.type === 'covered-property';
        });

        const isPaymentTypeMonthly: Ref<boolean> = computed((): boolean => {
            return btaBase.userStorage.stepStorageData['policy[paymentType]'] === PropertyPaymentType.Monthly;
        });

        const isPropertyTypeApartment: Ref<boolean> = computed((): boolean => {
            return btaBase.userStorage.stepStorageData['property[propertyType]'] === PropertyEnum.Type.Apartment;
        });

        const hasDetailedAddress: Ref<boolean> = computed((): boolean => {
            return btaBase.userStorage.stepStorageData['property[detailedAddress]'] !== '';
        });

        const propertyTypes: Ref<string[]> = computed((): string[] => {
            let result: string = '';
            const properties: string[] = [];
            const propertyType: string = btaBase.userStorage.stepStorageData['properties[0][type]'];
            if (isSet(propertyType)) {
                const totalArea: string =
                    ', ' + btaBase.userStorage.stepStorageData['properties[0][totalArea]'] + ' ' + SquareMetreLabel;
                result =
                    translation(
                        'one_property_common_' +
                            PropertyTransformation.getInstance().transform(propertyType).toLowerCase(),
                    ) + totalArea;
                properties.push(result);
                if (propertyType === PropertyEnum.Type.House) {
                    for (let i: number = 1; i < PropertyEnum.AdditionalObjectsLimit; i++) {
                        if (isSet(btaBase.userStorage.stepStorageData['properties[' + i + '][totalArea]'])) {
                            const additionalTotalArea: string =
                                btaBase.userStorage.stepStorageData['properties[' + i + '][totalArea]'];
                            const additionalPropertyType =
                                btaBase.userStorage.stepStorageData['properties[' + i + '][subType]'];
                            const houseElement: string =
                                translation(
                                    'one_property_common_' +
                                        PropertyTransformation.getInstance()
                                            .transform(additionalPropertyType)
                                            .toLowerCase(),
                                ) +
                                ', ' +
                                additionalTotalArea +
                                ' ' +
                                SquareMetreLabel;
                            properties.push(houseElement);
                        }
                    }
                }
            }

            return properties;
        });

        const transformedProperties: Ref<DynamicDictionary[]> = computed((): DynamicDictionary[] => {
            const transformedItems: DynamicDictionary[] = [];
            const propertyItems: string[] = propertyTypes.value;
            for (let index: number = 0; index < propertyItems.length; index += RowElementsCount) {
                const temp: string[] = propertyItems.slice(index, index + RowElementsCount);
                transformedItems.push(temp);
            }

            return transformedItems;
        });

        const hasAgreedToTerms: Ref<boolean> = computed((): boolean => {
            return form.field('agreeToTerms').value;
        });

        const useCard: Ref<boolean> = computed((): boolean => {
            return btaBase.cmsFields.cardDetails.enabled && canUseCardForCountry.value && paymentMethodsAvailable.value;
        });

        const canUseCardForCountry: Ref<boolean> = computed((): boolean => {
            let result: boolean;
            switch (true) {
                case new AppCountry().isEE():
                    result = isMonthlyPayment.value;
                    break;
                default:
                    result = false;
            }

            return result;
        });

        const isMonthlyPayment: Ref<boolean> = computed((): boolean => {
            return btaBase.userStorage.stepStorageData['policy[paymentType]'] === 'monthly';
        });

        const paymentMethodsAvailable: Ref<boolean> = computed((): boolean => {
            return !isApprovalCaseRequired.value;
        });

        const showMarketingConsents: Ref<boolean> = computed((): boolean => {
            return !btaBase.user.isLogged() || (!policyHolderIsLegal() && !btaBase.user.current.receiveMarketing);
        });

        function policyHolderIsLegal(): boolean {
            let result: boolean = false;
            if (isSet(btaBase.userStorage.stepStorageData)) {
                result = btaBase.userStorage.stepStorageData['policyHolder[isLegal]'] === 'true';
            }

            return result;
        }

        function isVisibleInformationalText(): boolean {
            return isVisiblePaymentInfo.value;
        }

        function onFormSubmit(): void {
            if (useCard.value) {
                bankLinkId.value = RecurringPaymentBanklink.ByCountry[new AppCountry().iso()];
            }
            formSubmit();
        }

        function onAgreeTerms(): void {
            form.field('agreeToTerms').patch(!form.field('agreeToTerms').value);
            if (hasAgreedToTerms.value) {
                resetTermsError();
            }
        }

        function choosePayment(event: VueEvent): void {
            informationText.value = '';
            const targetBankLinkId: number = event.params.bankLinkId;
            const targetInformationText: string = event.params.informationText;
            if (targetBankLinkId > 0) {
                event.sender.siblings('.' + CssClass.Active).removeClass(CssClass.Active);
                event.sender.toggleClass(CssClass.Active);
                bankLinkId.value = targetBankLinkId === bankLinkId.value ? 0 : targetBankLinkId;
                informationText.value = targetInformationText
                    ? translate(String(targetInformationText).toLowerCase())
                    : '';
            }
            $(PaymentOptionsTitle).removeClass(CssClass.NotFilled);
        }

        function submit(): void {
            if (!isApprovalCaseRequired.value) {
                stepsSubmitter.addSubmitParam('bankLinkId', bankLinkId.value, false);
            }
            stepsSubmitter.addSubmitParam('marketingConsents', marketingConsents(), false);
            stepsSubmitter.addSubmitParam(
                'isApprovalCaseRequired',
                isApprovalCaseRequired.value ? 'true' : 'false',
                false,
            );
            stepsSubmitter.addSubmitParam('paymentFailRedirectUrl', btaBase.currentUrl.value, false);
            stepsSubmitter.addAjaxResponseCallbackAfterStepsStorage(ajaxCallbackRedirect);
            stepsSubmitter.submitMethod(Method.Post);
            stepsSubmitter.submitStep(Url.Ajax.propertySummary);
        }

        function marketingConsents(): string[] {
            const selectedConsents: string = form.field('marketingConsents').value.selected;

            return new Value(selectedConsents).isNotEmpty() ? selectedConsents.split(ConsentsGlue) : [];
        }

        function resetTermsError(): void {
            $(ResetTermsErrorElement).removeClass(CssClass.BorderWithColor);
        }

        function showPolicyPlanCovered(): void {
            insurancePlan.value = btaBase.userStorage.stepStorageData['policy[insurancePlan]'];
            paymentType.value = btaBase.userStorage.stepStorageData['policy[paymentType]'];
            showCoveredPopup();
        }

        function applyApprovalCaseRequired(approvalCaseRequired: boolean): void {
            isApprovalCaseRequired.value = approvalCaseRequired;
        }

        function risks(id: string, type: string): PropertyRiskData[] {
            let result: PropertyRiskData[] = [];
            const term: PropertyProductTerms = productTermsById(id);
            if (isSet(term.risks) && isSet(term.risks[type])) {
                result = term.risks[type];
            }

            return result;
        }

        function productTermsById(id: string): PropertyProductTerms {
            let result: PropertyProductTerms = new (class implements PropertyProductTerms {
                public id: string = '';
                public deductible: number = 0;
                public prices: PropertyRiskDataPrice[] = [];
                public risks: Record<string, PropertyRiskData[]> = {};
            })();
            if (!btaBase.userStorage.storageIsEmpty) {
                btaBase.userStorage.storageData.forEach((product: DynamicDictionary): void => {
                    if (product.id === id) {
                        const paymentTerms: DynamicDictionary =
                            isSet(selectedProductWithAdditionalRisks.id) &&
                            selectedProductWithAdditionalRisks.id === product.id
                                ? selectedProductWithAdditionalRisks.paymentTerms
                                : product.paymentTerms;
                        paymentTerms.forEach((terms: PropertyProductTerms): void => {
                            if (terms.id === paymentType.value) {
                                result = terms;
                            }
                        });
                    }
                });
            }

            return result;
        }

        function hasTranslation(stringId: string): boolean {
            return hasLocalization(stringId, PropertyEnum.TranslationType);
        }

        function sparsePrice(price: number, noCents: boolean = false): string {
            return sparse(price, noCents);
        }

        function translation(stringId: string): string {
            return translateForType(stringId, PropertyEnum.TranslationType);
        }

        function formSubmit(): void {
            resetTermsError();
            form.touch().then((): void => {
                form.validate().then((): void => {
                    if (!hasAgreedToTerms.value) {
                        $(TermsAndConditionsElement).addClass(CssClass.BorderWithColor);
                    }
                    if (!form.isValid()) {
                        const invalidElements: JQuery = $('.invalid').not('[style*="display: none"]');
                        if (invalidElements.length > 0) {
                            invalidElements[0].scrollIntoView({
                                behavior: 'smooth',
                                block: 'start',
                                inline: 'center',
                            });
                        }
                    } else {
                        if (isApprovalCaseRequired.value || isPaymentMethodSelected.value) {
                            dataLayer.pushDataLayer(dataLayerParams());
                            nextTick((): void => {
                                submit();
                            });
                        } else {
                            scrollToBanksListAndMark();
                        }
                    }
                });
            });
        }

        function dataLayerParams(): DynamicDictionary {
            return btaBase.userStorage.stepStorageData.dataLayerParams;
        }

        function ajaxCallbackRedirect(response: DynamicDictionary): void {
            if (isApprovalCaseRequired.value) {
                if (response.status === 'OK' && isSet(response.body.redirectUrl)) {
                    btaBase.navigate(response.body.redirectUrl);
                }
            } else {
                redirectToBank();
            }
        }

        function showCoveredPopup(): void {
            prepareCoveredPopup();
            PopupService.getInstance().show(new OnePopup().withType().coveredProperty);
            nextTick(() => {
                btaBase.initPopupTooltips();
            });
        }

        function prepareCoveredPopup(): void {
            const policyPlanId: string = insurancePlan.value;
            const deductible: string = String(productTermsById(policyPlanId).deductible ?? '');
            const coveredRisks: PropertyRiskData[] = risks(policyPlanId, PropertyRiskType.Covered);
            coveredPopup.build(policyPlanId, deductible, coveredRisks);
            addAdditionalRisks();
        }

        function addAdditionalRisks(): void {
            const insurancePlanRisks: DynamicDictionary = productTermsById(insurancePlan.value).risks;
            const risksCombined: PropertyRiskData[] = [
                ...insurancePlanRisks.additional,
                ...insurancePlanRisks.included,
            ];
            const storageRisks: string[] = btaBase.userStorage.stepStorageData['policy[additionalRisks]'].split(',');
            storageRisks.forEach((riskId: string): void => {
                risksCombined.forEach((risk: PropertyRiskData): void => {
                    if (risk.id === riskId) {
                        coveredPopup.addCustomRisk(risk.id, risk.prefixByIc);
                    }
                });
            });
        }

        function setupForm(): Promise<void> {
            return new Promise((resolve) => {
                form.addField(new FormField('marketingConsents'));
                form.addField(new FormField('agreeToTerms', false, 'required'));
                form.addField(new FormField('expandableContent1'));
                form.addField(new FormField('expandableContent2'));
                form.addField(new FormField('expandableContent3'));
                form.setReady();
                resolve();
            });
        }

        function redirectToBank(): void {
            btaBase.navigate(Url.PaymentsApi.paymentsPay);
        }

        function scrollToBanksListAndMark(): void {
            const target: JQuery = $(PaymentOptionsElement);
            $(PaymentOptionsTitle).addClass(CssClass.NotFilled);
            scrollToElement(target);
        }

        function scrollToElement(element: JQuery): void {
            const target: JQuery = $(element);
            if (target.length > 0) {
                const offset: JQuery.Coordinates | undefined = target.offset();
                if (isSet(offset)) {
                    $('html,body').animate({ scrollTop: offset!.top }, EasingSpeed);
                }
            }
        }

        function patchDefaultMarketingValues(): void {
            form.field('marketingConsents').patch({
                customText: {},
                selected: btaBase.user.current.marketingConsents.join(ConsentsGlue),
            });
        }

        function onUserStorageReady(): void {
            setupForm();
        }

        const onAppReady = (): void => {
            setupForm().then((): void => {
                patchDefaultMarketingValues();
            });
        };

        return {
            ...btaBase,
            ...{
                bankLinkId,
                insurancePlan,
                paymentType,
                isApprovalCaseRequired,
                informationText,
                form,
                coveredPopup,
                selectedProductWithAdditionalRisks,
                isPaymentMethodSelected,
                isPaymentTypeMonthly,
                isPropertyTypeApartment,
                hasDetailedAddress,
                propertyTypes,
                transformedProperties,
                hasAgreedToTerms,
                useCard,
                canUseCardForCountry,
                isMonthlyPayment,
                paymentMethodsAvailable,
                onFormSubmit,
                onAgreeTerms,
                choosePayment,
                isVisibleInformationalText,
                submit,
                resetTermsError,
                showPolicyPlanCovered,
                showMarketingConsents,
                applyApprovalCaseRequired,
                risks,
                productTermsById,
                hasTranslation,
                sparsePrice,
                translation,
                formSubmit,
                CurrentStep,
                stepsSubmitter,
                onAppReady,
                isCoveredPopupVisible,
                onUserStorageReady,
            },
        };
    },

    mounted() {
        this.applyApp(this);
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility('one-property');
        this.stepsSubmitter.submitMethod(Method.Get);
        const onStorageReadySubscription = this.userStorage.onStorageDataIsReady.subscribe((): void => {
            this.onUserStorageReady();
            onStorageReadySubscription.unsubscribe();
        });
        const onAppIsPreparedAndReady: Subscription = this.onAppIsPreparedAndReady.subscribe((): void => {
            this.onAppReady();
            onAppIsPreparedAndReady.unsubscribe();
        });
    },
});
</script>
