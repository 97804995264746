<script setup lang="ts">
import { useTranslate } from '@/Composables/Translate';
import { Router, useRouter } from 'vue-router';
import OneBaseService from '@/services/OneBaseService';
import { computed, getCurrentInstance, onMounted, ref, Ref } from 'vue';
import SpaUserStorage from '@/services/SpaUserStorageService';
import ClaimsTravelFormFields from '@/Apps/ClaimsTravel/Classes/ClaimsTravelFormFields';
import ClaimsTravelService from '@/Apps/ClaimsTravel/Services/ClaimsTravelService';
import GuardsService from '@/Apps/ClaimsTravel/Services/GuardsService';
import ButtonWithCallbackParams from '@/Components/Buttons/ButtonWithCallback/Enums/button.params';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import ButtonIcon from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.enum';
import ButtonIconPosition from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.position.enum';
import ClaimsTravelStepUid from '@/Apps/ClaimsTravel/Enums/ClaimsTravelStepUid';
import { useClaimsTravelHtml } from '@/Apps/ClaimsTravel/Composables/ClaimsTravelHtml';

const { translateForType, applyTranslationType, type } = useTranslate();
const router: Router = useRouter();
const { scrollToPanel } = useClaimsTravelHtml();

const claimsTravelService: ClaimsTravelService = ClaimsTravelService.getInstance();
const spaUserStorage: SpaUserStorage = SpaUserStorage.getInstance();

const Step: number = 4;

const canProceedToNextStep: Ref<boolean> = computed(() => {
    return true;
});

function setupForm(): void {
    spaUserStorage.setupForm();
}

function submitClaimButton(): ButtonWithCallbackParams {
    return {
        title: translated('submit_claim'),
        textColor: ButtonTextColor.White,
        backgroundColor: ButtonBackground.Red,
        icon: ButtonIcon.LongArrowRight,
        iconPosition: ButtonIconPosition.Right,
    };
}

function onProceed(): void {
    console.debug('Submit claim');
}

function translated(stringId: string): string {
    return translateForType(stringId, type());
}

onMounted(() => {
    OneBaseService.getInstance().applySpa(getCurrentInstance());
    applyTranslationType('claims_travel');
    spaUserStorage.init(ClaimsTravelFormFields, claimsTravelService.storageFields);
    setupForm();
    spaUserStorage.form.validate().then((): void => {
        GuardsService.getInstance().applyStepValidity(Step, canProceedToNextStep.value);
    });
});

defineExpose({});
</script>

<template>
    <div class="container horizontal-spacing">
        <app-custom-form
            v-if="spaUserStorage.isReady()"
            :form="spaUserStorage.form"
            class="form"
            @change="spaUserStorage.storeForm()"
        >
            <div class="whiteboard-panel whiteboard-panel-margin">
                <router-link
                    v-slot="{ navigate }"
                    class="back back-margin"
                    :to="{ name: ClaimsTravelStepUid.UploadFiles }"
                    :data-scroll="'1'"
                    :disabled="!spaUserStorage.form.isValid()"
                >
                    <button @click="navigate">
                        <img src="images/one/arrow-left.svg" alt="back" />
                        <span>{{ translated('back_button') }}</span>
                    </button>
                </router-link>
                <label> Recipient Data </label>
                <div class="whiteboard" :data-type="'whiteboard-0'" :data-scroll="'0'">
                    <app-input-text
                        :label="'First field'"
                        :form-field="spaUserStorage.form.field(ClaimsTravelFormFields.firstField)"
                    >
                    </app-input-text>
                    <app-input-number
                        :label="'Second field'"
                        :form-field="spaUserStorage.form.field(ClaimsTravelFormFields.secondField)"
                    >
                    </app-input-number>
                    <button class="button red" @click="scrollToPanel('1')">
                        <span>{{ translated('proceed') }}</span>
                    </button>
                </div>
            </div>
            <div class="whiteboard-panel">
                <div class="whiteboard" :data-type="'whiteboard-0'" :data-scroll="'1'">
                    <button class="back" :data-scroll="1" @click="scrollToPanel('0')">
                        <img src="images/one/arrow-left.svg" alt="back" />
                        <span>{{ translated('back_button') }}</span></button
                    >Test panel
                    <app-input-checkbox :form-field="spaUserStorage.form.field(ClaimsTravelFormFields.thirdField)">
                        Third field
                    </app-input-checkbox>
                    <button class="button red" @click="scrollToPanel('2')">
                        <span>{{ translated('proceed') }}</span>
                    </button>
                </div>
            </div>
            <div class="whiteboard-panel">
                <div class="whiteboard" :data-type="'whiteboard-0'" :data-scroll="'2'">
                    <app-button-with-callback
                        class="button"
                        data-type="contact-details-submit"
                        :disabled="!spaUserStorage.form.isValid()"
                        v-bind="submitClaimButton()"
                        @button-callback-click="onProceed"
                    >
                    </app-button-with-callback>
                </div>
            </div>
        </app-custom-form>
    </div>
</template>

<style scoped lang="scss">
.form {
    display: flex;
    flex-direction: column;
    padding: 0 !important;

    .input:not(:last-child) {
        margin-bottom: 24px;
    }

    .row {
        display: flex;
        flex-direction: row;

        .input:not(:last-child) {
            margin-right: 24px;
        }
    }

    .button {
        margin-top: 32px;
    }
}
</style>
