import { defineRule } from 'vee-validate';
import AppCountry from '@/assets/libraries/app/app-country';
import CaptionValidator from '@/Validators/CaptionValidator';
import CompanyValidator from '@/Validators/CompanyValidator';
import DateFormat from '@/Enums/DateFormatEnum';
import DisabilityValidator from '@/Validators/DisabilityValidator';
import EmailValidator from '@/Validators/EmailValidator';
import IbanValidator from '@/Validators/IbanValidator';
import moment from 'moment';
import PersonCodeValidator from '@/Validators/PersonCodeValidator';
import PhoneValidator from '@/Validators/PhoneValidator';
import Value from '@/assets/libraries/form/value';
import VehicleValidator from '@/Validators/VehicleValidator';
import TaxiValidator from '@/Validators/TaxiValidator';
import IMEIValidator from '@/Validators/ImeiValidator';

export default class Validation {
    private inputNumberPattern: RegExp = /^[0-9]{1,3}$/;
    private sumInsuredPattern: RegExp = /^[0-9]{1,7}$/;
    private timePattern: RegExp = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    private workingHoursPattern: RegExp = /^(09|1[0-6]|):[0-5][0-9]$/;
    private yearPattern: RegExp = /^(18|19)\d{2}|(2)\d{3}$/;

    public constructor() {
        defineRule('required', (value: string) => Validation.required(value));
        defineRule('mail', (value: string) => Validation.isValidEmail(value));
        defineRule('naturalPersonCode', (value: string) => Validation.isValidNaturalPersonCode(value));
        defineRule('companyRegistrationNumber', (value: string) => Validation.isValidCompanyRegistrationNumber(value));
        defineRule('isValidIBANNumber', (value: string) => Validation.isValidIBANNumber(value));
        defineRule('inputNumber', (value: string) => this.inputNumberPattern.test(value));
        defineRule('sumInsured', (value: string) => this.sumInsuredPattern.test(value));
        defineRule('checkboxChecked', (value: string) => value);
        defineRule('dateDMY', (value: moment.MomentInput) => {
            const date: moment.Moment = moment(value, DateFormat.Default.Short);

            return date.isValid();
        });

        defineRule('time', (value: string) => this.timePattern.test(value));
        defineRule('workingHours', (value: string) => this.workingHoursPattern.test(value));
        defineRule('year', (value: string) => this.yearPattern.test(value));
    }

    public static required(value: any): boolean {
        const invalid: boolean = !value || new Value(value).isEmpty();

        return !invalid;
    }

    public static requiredIf(statementFn: () => boolean): (value: any) => boolean {
        return (value: any): boolean => {
            return statementFn() ? Validation.required(value) : true;
        };
    }

    public static isValidYear(value: string): boolean {
        const yearPattern: RegExp = /^(18|19)\d{2}|(2)\d{3}$/;
        return yearPattern.test(value);
    }

    public static isValidCaption(value: string): boolean {
        return new CaptionValidator().isValidCaption(value);
    }

    public static isValidIMEINumber(value: string): boolean {
        return new IMEIValidator().isValidIMEI(value);
    }

    public static hasValidLength(value: string, length: number): boolean {
        return new CaptionValidator().hasValidLength(value, length);
    }

    public static isValidPhone(value: string, localeIso: string = new AppCountry().iso()): boolean {
        return new PhoneValidator().isValidPhone(value, localeIso);
    }

    public static isValidMobilePhone(value: string, localeIso: string = new AppCountry().iso()): boolean {
        return new PhoneValidator().isValidMobilePhone(value, localeIso);
    }

    public static isValidFixedPhone(value: string, localeIso: string = new AppCountry().iso()): boolean {
        return new PhoneValidator().isValidFixedPhone(value, localeIso);
    }

    public static isValidEngineCapacity(value: string): boolean {
        return new VehicleValidator().isValidEngineCapacity(value);
    }

    public static isValidPlateNumber(value: string, localeIso: string = new AppCountry().iso()): boolean {
        return new VehicleValidator().isValidVehiclePlateNumber(value, localeIso);
    }

    public static isValidVinNumber(value: string): boolean {
        return new VehicleValidator().isValidVehicleVinNumber(value);
    }

    public static isValidVehicleRegistrationNumber(value: string, localeIso: string = new AppCountry().iso()): boolean {
        return new VehicleValidator().isValidVehicleRegistrationNumber(value, localeIso);
    }

    public static isValidDriversLicenseNumber(value: string, localeIso: string = new AppCountry().iso()): boolean {
        return new VehicleValidator().isValidDriversLicenseNumber(value, localeIso);
    }

    public static isValidCompanyRegistrationNumber(value: string, localeIso: string = new AppCountry().iso()): boolean {
        return new CompanyValidator().isValidCompanyNumber(value, localeIso);
    }

    public static isValidDisabilityCertificateNumber(
        value: string,
        localeIso: string = new AppCountry().iso(),
    ): boolean {
        return new DisabilityValidator().isValidDisabilityCertificateNumber(value, localeIso);
    }

    public static isValidIBANNumber(value: string): boolean {
        return new IbanValidator().isValidIBANNumber(value);
    }

    public static isValidEmail(value: string): boolean {
        return new EmailValidator().isValidEmail(value);
    }

    public static isValidRestrictedEmail(value: string): boolean {
        return new EmailValidator().isValidRestrictedEmail(value);
    }

    public static isValidNaturalPersonCode(value: string, localeIso: string = new AppCountry().iso()): boolean {
        return new PersonCodeValidator().isValidPersonCode(value, localeIso);
    }

    public static isValidPersonCodeWithoutDate(value: string, localeIso: string = new AppCountry().iso()): boolean {
        return new PersonCodeValidator().isValidPersonCodeWithoutDate(value, localeIso);
    }

    public static isSumInsured(value: string): boolean {
        const sumInsuredPattern: RegExp = /^[0-9]{1,7}$/;
        return sumInsuredPattern.test(value);
    }

    public static inRange(value: number | string, minValue: number, maxValue: number): boolean {
        const formattedValue: number = typeof value === 'string' ? parseInt(value, 10) : value;

        return formattedValue >= minValue && formattedValue <= maxValue;
    }

    public static isValidPostalCode(value: string, localeIso: string = new AppCountry().iso()): boolean {
        let result: boolean;
        const postalCodePatternEE: RegExp = /^\s*((?!0)[0-9]{5})$/;
        const postalCodePatternLT: RegExp = /LT-(?!0)[0-9]{5}|^\s*((?!0)[0-9]{5})$/;
        const postalCodePatternLV: RegExp = /LV-(?!0)[0-9]{4}|^\s*((?!0)[0-9]{4})$/;
        switch (localeIso) {
            case 'EE':
                result = postalCodePatternEE.test(value);
                break;
            case 'LT':
                result = postalCodePatternLT.test(value);
                break;
            case 'LV':
                result = postalCodePatternLV.test(value);
                break;
            default:
                result = value.length > 0;
        }

        return result;
    }

    public static isValidTaxiPlateNumber(value: string, localeIso: string = new AppCountry().iso()): boolean {
        return new TaxiValidator().isValidTaxiPlateNumber(value, localeIso);
    }

    public static isValidPolicyNumber(value: string): boolean {
        const policyPattern: RegExp = new AppCountry().isLT()
            ? /[A-Z]{2}[0-9]{2}-[\w]{2,3}-[0-9]{7,8}-[0-9]|GT[0-9]{6}$/
            : /[A-Z]{2}[0-9]{2}-[\w]{2}-[0-9]{8}-[0-9]$/;
        return policyPattern.test(value);
    }
}
