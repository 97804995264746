<script setup lang="ts">
import { nextTick, onMounted, PropType, Ref, ref } from 'vue';
import FormField from '@/assets/libraries/form/form-field';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import Url from '@/Enums/UrlEnum';
import AppContentLoader from '@/Components/Loaders/ContentLoader/ContentLoader.vue';
import OneDate from '@/assets/libraries/Date/OneDate';
import Error from '@/services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import { useNavigate } from '@/Composables/Navigate';
import { AxiosResponse } from 'axios';
import { useDefine } from '@/Composables/Define';
import { useAxios } from '@/Composables/Axios';
import { useTranslate } from '@/Composables/Translate';
import ButtonWithCallbackParams from '@/Components/Buttons/ButtonWithCallback/Enums/button.params';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import ButtonIcon from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.enum';
import ButtonIconColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.color.enum';
import ButtonIconPosition from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.position.enum';
import AppButtonWithCallback from '@/Components/Buttons/ButtonWithCallback/ButtonWithCallback.vue';
import EmblaCarouselVue, { EmblaCarouselVueType } from 'embla-carousel-vue';
import LazyLoader from '@/services/lazy.loader.service';

const props = defineProps({
    componentName: { type: String, default: 'ArticlesNavigator' },
    translationType: { type: String, default: 'components' },
    formField: {
        type: Object as PropType<FormField<DynamicDictionary>>,
        default: () => new FormField(''),
    },
    dataStoreDisabled: { type: Boolean, default: false },
    articleId: { type: String, default: '' },
    url: { type: String, default: '' },
    tag: { type: String, default: '' },
    isBlogArticle: { type: Boolean, default: false },
});

const [emblaRef, emblaApi]: EmblaCarouselVueType = EmblaCarouselVue();
const { translateForType } = useTranslate();
const componentIsReady: Ref<boolean> = ref(false);
const articles: Ref<DynamicDictionary[]> = ref([]);
const isPrevDisabled: Ref<boolean> = ref(true);
const isNextDisabled: Ref<boolean> = ref(true);
const articleCountLimit: number = 9;

onMounted((): void => {
    nextTick(() => {
        fetchNestedArticles();
    });
    emblaApi.value!.on('select', updateButtonStates);
    emblaApi.value!.on('init', updateButtonStates);
    emblaApi.value!.on('reInit', updateButtonStates);
    updateButtonStates();
});

const updateButtonStates = () => {
    if (emblaApi.value) {
        isPrevDisabled.value = !emblaApi.value.canScrollPrev();
        isNextDisabled.value = !emblaApi.value.canScrollNext();
    }
};

function fetchNestedArticles(): void {
    const params: Record<string, string> = {
        articleId: props.articleId,
        tag: props.tag,
    };
    useAxios()
        .get(Url.Ajax.nestedArticles, { params })
        .then((response: AxiosResponse): void => {
            if (useDefine().validResponse(response)) {
                articles.value = response.data.data.body.slice(0, articleCountLimit);
            }
        })
        .catch((reason: DynamicDictionary): void => {
            Error.log(ErrorType.Error, 'Articles navigator / fetchNestedArticles', reason);
        })
        .finally(() => {
            componentIsReady.value = true;
            new LazyLoader().init();
        });
}

function onNavigate(slug: string): void {
    useNavigate().navigate(props.url + '/' + slug);
}

function onScrollNext(): void {
    emblaApi.value!.scrollNext();
}

function onScrollPrevious(): void {
    emblaApi.value!.scrollPrev();
}

function onShowAll(): void {
    useNavigate().navigate(props.url);
}

function transformedDate(date: string): string {
    return OneDate.short(date);
}

function showAllArticlesButton(): ButtonWithCallbackParams {
    const showAllButonLabel: string = props.isBlogArticle ? 'view_all_articles' : 'view_all_news';

    return {
        title: translateForType(showAllButonLabel, props.translationType),
        textColor: ButtonTextColor.Black,
        backgroundColor: ButtonBackground.Transparent,
        backgroundColorHover: ButtonBackground.Transparent,
        icon: ButtonIcon.LongArrowRight,
        iconColor: ButtonIconColor.Black,
        iconPosition: ButtonIconPosition.Right,
    };
}
</script>

<template>
    <div class="articles-navigator">
        <div v-if="!componentIsReady" class="loading">
            <app-content-loader></app-content-loader>
        </div>
        <div v-show="componentIsReady">
            <div class="header">
                <h3 class="title">
                    {{ translateForType('continue_reading', translationType) }}
                </h3>
                <div class="top-buttons">
                    <app-button-with-callback class="button" v-bind="showAllArticlesButton()" @click="onShowAll()">
                    </app-button-with-callback>
                </div>
            </div>
            <div ref="emblaRef" class="embla">
                <div class="embla__container">
                    <div
                        v-for="article in articles"
                        :key="article.id"
                        class="embla__slide"
                        @click="onNavigate(article.slug)"
                    >
                        <div class="intro-image" v-html="article.intro_image"></div>
                        <div class="blog-title" v-text="article.title"></div>
                        <span class="published" v-text="transformedDate(article.published)"></span>
                    </div>
                </div>
                <div class="navigation-belt">
                    <div class="arrow previous" :class="{ disabled: isPrevDisabled }" @click="onScrollPrevious">
                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7 13L1 7L7 1"
                                stroke="#1D1F23"
                                stroke-opacity="0.64"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </div>
                    <div class="arrow next" :class="{ disabled: isNextDisabled }" @click="onScrollNext">
                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1 1L7 7L1 13"
                                stroke="#1D1F23"
                                stroke-opacity="0.64"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </div>
                </div>
            </div>
            <app-button-with-callback
                class="button bottom-buttons"
                v-bind="showAllArticlesButton()"
                @click="onShowAll()"
            >
            </app-button-with-callback>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.articles-navigator {
    background-color: var(--background-light);
    max-width: 1368px;
    padding: 20px;
    margin: 0 auto;

    .loading {
        height: 50vh;
    }

    .header {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .title {
            min-width: 50%;
            white-space: nowrap;
            font-size: var(--font-size-huge);
            margin-bottom: var(--size-small);

            @include respond-below('sm') {
                font-size: var(--font-size-big);
            }
        }

        .top-buttons {
            text-align: right;
            width: 50%;

            .button {
                height: 52px;
                min-width: 218px;
                margin-bottom: 48px;
                margin-right: 8px;
            }

            @include respond-below('sm') {
                display: none;
            }
        }

        @include respond-below('sm') {
            .buttons {
                width: 100%;
            }
        }
    }

    .navigation-belt {
        display: flex;
        border: 1px solid #9297a052;
        background-color: var(--background-light);
        width: 108px;
        height: 52px;
        min-height: 52px;
        gap: var(--size-nano);
        margin: var(--size-normal) auto 0;
        border-radius: 100px;
        align-items: center;
        justify-content: center;

        .arrow {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 44px;
            height: 44px;
            border-radius: 50%;
            background-color: transparent;
            transition: background-color 0.3s ease;

            path {
                stroke: var(--brand-black);
                stroke-opacity: 1;
                transition: stroke 0.3s ease;
            }

            &:hover {
                background-color: var(--black-50);
            }

            &.next {
                padding-left: 3px;
            }

            &.previous {
                padding-right: 3px;
            }

            &.disabled {
                path {
                    stroke: var(--black-200);
                }
            }
        }

        @include respond-below('sm') {
            display: none;
        }
    }

    .bottom-buttons {
        height: 52px;

        @include respond-above('sm') {
            display: none;
        }
    }

    @include respond-above('sm') {
        padding: 60px;
    }

    .embla {
        max-width: 1380px;

        --slide-size: 100%;

        overflow: hidden;
    }

    .embla__viewport {
        overflow: hidden;
    }

    .embla__container {
        display: flex;
        touch-action: pan-y pinch-zoom;
        margin-left: calc(var(--slide-spacing) * -1);
        margin-bottom: var(--size-normal);
    }

    .embla__slide {
        transform: translate3d(0, 0, 0);
        background-color: var(--background-base);
        border-radius: var(--size-nano);
        min-width: 400px;
        max-width: 400px;
        height: 492px;
        margin-right: 20px;
        cursor: pointer;

        .intro-image {
            height: 240px;
            border-radius: var(--size-nano) var(--size-nano) 0 0;

            :deep img {
                border-radius: inherit;
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
        }

        .blog-title {
            font-size: var(--font-size-medium);
            font-weight: 700;
            line-height: var(--line-height-basic);
            margin: 40px;
            height: 116px;
            white-space: normal;

            &:hover {
                color: var(--brand-red);
            }
        }

        .published {
            bottom: 116px;
            font-size: var(--font-size-nano);
            font-weight: 500;
            color: var(--text-color-subtlest);
            margin: 40px;
        }

        @include respond-below('xs') {
            max-width: 100%;
        }

        @include respond-below('sm') {
            max-width: 325px;
            min-width: 325px;
            height: 423px;

            .intro-image {
                height: 210px;
            }

            .blog-title {
                font-size: var(--font-size-normal);
                height: 86px;
            }

            .published {
                bottom: 16px;
            }
        }
    }
}
</style>
