<script lang="ts">
import { ComputedRef, defineComponent, ref, Ref, computed } from 'vue';
import OneBaseService from '@/services/OneBaseService';
import Form from '@/assets/libraries/form/form';
import SubmitterUrls from '@/services/SubmitterUrls.service';
import { useStepsSubmitter } from '@/Composables/StepsSubmitter';
import { useTranslate } from '@/Composables/Translate';
import { useNavigate } from '@/Composables/Navigate';
import MovablePropertySteps from '@/pages/ItemsAndBelongings/Classes/MovablePropertySteps';
import UserStorage from '@/services/user.storage.service';
import Device from '@/pages/ItemsAndBelongings/Interfaces/DeviceInterface';
import FormField from '@/assets/libraries/form/form-field';
import { useHtml } from '@/Composables/Html';
import ImageTagAttributes from '@/interfaces/image.tag.attributes';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import ButtonIcon from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.enum';
import ButtonIconColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.color.enum';
import ButtonIconPosition from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.position.enum';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import { CoverageRisk } from '@/interfaces/resources/MovableProperties/CoverageRiskInterface';
import { useStrings } from '@/Composables/Strings';
import { take } from 'rxjs/operators';
import Validation from '@/services/validation.service';
import { InputOption } from '@/interfaces/InputOptionInterface';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import Sanitizer from '@/services/sanitizer.service';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const { translateForType, hasLocalization } = useTranslate();
        const stepsSubmitter = useStepsSubmitter();

        const CurrentStep: number = 4;
        const Facility: string = 'items-and-belongings';
        const translationType: string = 'items_and_belongings';
        const defaultFieldLength: number = 10;
        const form: Form<{ device: string | undefined; description: string }> = new Form({
            useValidationV2: true,
        });
        const deviceFields: Ref<Device[]> = ref([]);
        const deviceInputOptions: ComputedRef<InputOption<string>[]> = computed((): InputOption<string>[] => {
            return deviceFields.value.map((device: Device): InputOption<string> => {
                return new InputOptionBuilder<string>()
                    .setValue(device.optionIc)
                    .setName(translateForType(device.optionIc, translationType))
                    .build();
            });
        });
        const selectedDevice: ComputedRef<Device | undefined> = computed((): Device | undefined => {
            return deviceFields.value.find((device: Device): boolean => device.optionIc === form.field('device').value);
        });
        const optionLabel: ComputedRef<string> = computed((): string => {
            const hintTranslationKey: string = form.field('device').value + '_LABEL';
            return hasLocalization(hintTranslationKey, 'items_and_belongings')
                ? translateForType(hintTranslationKey, 'items_and_belongings')
                : '';
        });
        const optionPlaceholder: ComputedRef<string> = computed((): string => {
            const hintTranslationKey: string = form.field('device').value + '_PLACEHOLDER';
            return hasLocalization(hintTranslationKey, 'items_and_belongings')
                ? translateForType(hintTranslationKey, 'items_and_belongings')
                : '';
        });
        const optionHint: ComputedRef<string> = computed((): string => {
            const hintTranslationKey: string = form.field('device').value + '_HINT';
            const cautionIcon: ImageTagAttributes = {
                class: '',
                src: 'images/one/caution.svg',
                width: 16,
                height: 16,
            };
            return hasLocalization(hintTranslationKey, 'items_and_belongings')
                ? useHtml().imgTag(cautionIcon) + translateForType(hintTranslationKey, 'items_and_belongings')
                : '';
        });

        const aboutDeviceButtonIsDisabled: Ref<boolean> = computed(() => {
            return (
                form.field('device').isEmpty() ||
                !form.field('device').isValid ||
                form.field('description').isEmpty() ||
                !form.field('description').isValid
            );
        });

        function applyDeviceFields(value: Device[]): void {
            deviceFields.value = value;
        }

        function onAppReady(): void {
            btaBase.dynamicStepper.applyEnabled(MovablePropertySteps.byProductId(selectedProductId()));
        }

        function onBeforeFormRestore(): void {
            setupForm();
        }

        function setupForm(): void {
            form.addField(new FormField('device').addValidators({ required: Validation.required }));
            form.addField(
                new FormField('description')
                    .addValidators({
                        isValidDescription: (value: string): boolean => {
                            let isValid: boolean = true;
                            if (selectedDevice.value) {
                                isValid = selectedDevice.value.imei
                                    ? Validation.isValidIMEINumber(value)
                                    : Validation.hasValidLength(value, defaultFieldLength);
                            }
                            return isValid;
                        },
                    })
                    .addSanitizer((value: string): string => {
                        return selectedDevice.value?.imei
                            ? Sanitizer.cleanIMEINumber(value)
                            : Sanitizer.cleanCaption(value);
                    }),
            );
            form.setReady();
            stepsSubmitter.addForm(form);
        }

        function onBackClick(): void {
            useNavigate().navigate(SubmitterUrls.getInstance().previousStep());
        }

        function onSubmitStep(): void {
            stepsSubmitter.proceedStep('', btaBase.nextStep());
        }

        function onDeviceChange(): void {
            form.field('description').clear().then();
        }

        function onContinueClick(): void {
            form.submitAttempt().then((): void => {
                if (form.isValid()) {
                    PopupService.getInstance().show(new OnePopup().withType().loadingWait);
                    prepareSubmit();
                    stepsSubmitter.proceedStep('', 0);
                }
            });
        }

        function selectedProductId(): string {
            return UserStorage.getInstance().stepStorageData.productId ?? '';
        }

        function selectedCoverageId(): number {
            return Number(UserStorage.getInstance().stepStorageData.coverageId ?? 0);
        }

        function selectedRisks(): CoverageRisk[] {
            return UserStorage.getInstance().stepStorageData.selectedRisks ?? [];
        }

        function prepareSubmit(): void {
            let deviceField: string = '';
            if (selectedDevice.value?.description) {
                deviceField = 'description';
            } else if (selectedDevice.value?.imei) {
                deviceField = 'imei';
            } else if (selectedDevice.value?.makeModel) {
                deviceField = 'makeModel';
            } else if (selectedDevice.value?.serialNumber) {
                deviceField = 'serialNumber';
            }
            stepsSubmitter.addSubmitCustomParam('nextStep', btaBase.nextStep());
            stepsSubmitter.addSubmitCustomParam('facility', Facility);
            stepsSubmitter.addSubmitCustomParam('selectedRisks', selectedRisks());
            stepsSubmitter.addSubmitCustomParam('productId', selectedProductId());
            stepsSubmitter.addSubmitCustomParam('coverageId', selectedCoverageId());
            stepsSubmitter.addSubmitCustomParam('selectedDevice', selectedDevice.value!);
            stepsSubmitter.addSubmitCustomParam('deviceInfo', form.field('description').value);
            stepsSubmitter.addSubmitCustomParam('deviceField', useStrings().transformedToSnakeCase(deviceField));
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                form,
                onSubmitStep,
                onBackClick,
                setupForm,
                onBeforeFormRestore,
                onAppReady,
                applyDeviceFields,
                deviceFields,
                deviceInputOptions,
                optionLabel,
                optionPlaceholder,
                optionHint,
                onContinueClick,
                onDeviceChange,
                ButtonTextColor,
                ButtonBackground,
                ButtonIcon,
                ButtonIconColor,
                ButtonIconPosition,
                aboutDeviceButtonIsDisabled,
            },
        };
    },

    mounted() {
        this.applyApp(this);
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        this.setupForm();

        this.onAppIsPreparedAndReady.pipe(take(1)).subscribe((): void => {
            this.onAppReady();
        });
    },
});
</script>
