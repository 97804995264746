<script setup lang="ts"></script>

<template>
    <div v-if="$slots.default" class="input-hint">
        <slot></slot>
    </div>
</template>

<style lang="scss" scoped>
.input-hint {
    display: flex;
    font-size: var(--font-size-pico);
    color: var(--text-color-subtlest);
    line-height: var(--size-nano);
}
</style>
