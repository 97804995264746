<script lang="ts">
import { computed, defineComponent, ref, Ref } from 'vue';
import OneBaseService from '@/services/OneBaseService';
import Form from '@/assets/libraries/form/form';
import SubmitterUrls from '@/services/SubmitterUrls.service';
import { useStepsSubmitter } from '@/Composables/StepsSubmitter';
import { useTranslate } from '@/Composables/Translate';
import { useNavigate } from '@/Composables/Navigate';
import MovablePropertySteps from '@/pages/ItemsAndBelongings/Classes/MovablePropertySteps';
import UserStorage from '@/services/user.storage.service';
import ButtonWithCallbackParams from '@/Components/Buttons/ButtonWithCallback/Enums/button.params';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import ButtonIcon from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.enum';
import ButtonIconColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.color.enum';
import ButtonBorder from '@/Components/Buttons/ButtonWithCallback/Enums/button.border.enum';
import { MovableProperty } from '@/interfaces/resources/MovableProperties/MovablePropertyInterface';
import CoveredPopupTypes from '@/Components/Popups/CoveredPopup/Enums/CoveredPopupTypes';
import { CoveredPopupBuilder } from '@/pages/ItemsAndBelongings/Classes/CoveredPopupBuilder';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import moment from 'moment';
import FormField from '@/assets/libraries/form/form-field';
import OneDate from '@/assets/libraries/Date/OneDate';
import User from '@/services/user.service';
import Validation from '@/services/validation.service';
import Sanitizer from '@/services/sanitizer.service';
import Method from '@/Enums/MethodEnum';
import Url from '@/Enums/UrlEnum';
import RecurringPaymentBanklink from '@/Enums/RecurringPaymentBanklinkEnum';
import AppCountry from '@/assets/libraries/app/app-country';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import Value from '@/assets/libraries/form/value';
import ExtractDataService from '@/services/extract.data.service';
import SettingsService from '@/services/settings.service';
import { take } from 'rxjs/operators';
import AddressFieldInterface from '@/interfaces/address.field.interface';
import PhoneField from '@/interfaces/phone.field.interface';
import { CountryComponentParams } from '@/Components/Inputs/InputCountry/CountryComponentParams';
import DateRange from '@/interfaces/date.range.interface';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const { translate, translateForType } = useTranslate();
        const stepsSubmitter = useStepsSubmitter();

        const CurrentStep: number = 5;
        const Facility: string = 'items-and-belongings';
        const ConsentsGlue: string = ':';
        const AddressAdditionalHidden: string = 'hide-additional';

        const form: Form<{
            'policy-start-date': DateRange;
            'policy-holder-resident': boolean;
            'policy-holder-name': string;
            'policy-holder-surname': string;
            'policy-holder-code': string;
            'policy-holder-country': CountryComponentParams;
            'policy-holder-birth-date': Date | string;
            'policy-holder-email': string;
            'policy-holder-phone': PhoneField;
            'policy-holder-address': AddressFieldInterface;
            'agree-terms': boolean;
            'marketing-consents': DynamicDictionary;
        }> = new Form({ useValidationV2: true });
        const coveredPopup: CoveredPopupBuilder = new CoveredPopupBuilder();

        const bankLinkId: Ref<number> = ref(0);

        const showMarketingConsents: Ref<boolean> = computed(() => !btaBase.user.current.receiveMarketing);

        const isNonResident: Ref<boolean> = computed(() => {
            return !form.field('policy-holder-resident').value;
        });

        const birthDateRequired: Ref<boolean> = computed(() => {
            return isNonResident.value || policyHolderCodeIsAnonymous.value;
        });

        const policyHolderCodeIsAnonymous: Ref<boolean> = computed(() => {
            const holderCodeField: FormField = form.field('policy-holder-code');
            const holderCode: string = String(holderCodeField.value);

            return holderCodeField.isNotEmpty() && Validation.isValidPersonCodeWithoutDate(holderCode);
        });

        const policyStartDate: Ref<string> = computed(() => {
            const result: Date = moment(form.field('policy-start-date').value.startDate).toDate();

            return OneDate.short(result);
        });

        const policyEndDate: Ref<string> = computed(() => {
            const startDate: Date = form.field('policy-start-date').value.startDate as unknown as Date;
            const result: Date = moment(startDate).add(1, 'year').subtract(1, 'day').toDate();

            return OneDate.short(result);
        });

        function onBeforeFormRestore(): void {
            patchDefaultValues().then();
        }

        function onAppReady(): void {
            buildCoverageOptions();
            btaBase.dynamicStepper.applyEnabled(MovablePropertySteps.byProductId(selectedProductId()));
            patchDefaultValues().then((): void => {
                hideAddressAdditional();
            });
        }

        function setupForm(): void {
            form.addField(new FormField('policy-start-date'));
            form.addField(new FormField('policy-holder-resident'));
            form.addField(
                new FormField('policy-holder-name')
                    .addValidators({
                        required: Validation.required,
                        validCaption: Validation.isValidCaption,
                    })
                    .addSanitizer(Sanitizer.cleanUppercaseName),
            );
            form.addField(
                new FormField('policy-holder-surname')
                    .addValidators({
                        required: Validation.required,
                        validCaption: Validation.isValidCaption,
                    })
                    .addSanitizer(Sanitizer.cleanUppercaseName),
            );
            form.addField(
                new FormField('policy-holder-code').addValidators({
                    required: Validation.required,
                }),
            );
            form.addField(
                new FormField('policy-holder-country').addValidators({
                    required: Validation.requiredIf(() => isNonResident.value),
                }),
            );
            form.addField(
                new FormField('policy-holder-birth-date').addValidators({
                    required: Validation.requiredIf(() => birthDateRequired.value),
                }),
            );
            form.addField(
                new FormField('policy-holder-email').addValidators({
                    required: Validation.required,
                }),
            );
            form.addField(
                new FormField('policy-holder-phone').addValidators({
                    required: Validation.required,
                }),
            );
            form.addField(
                new FormField('policy-holder-address').addValidators({
                    required: Validation.required,
                }),
            );
            form.addField(
                new FormField('agree-terms').addValidators({
                    required: Validation.required,
                }),
            );
            form.addField(new FormField('marketing-consents'));
            form.setReady();
        }

        function patchDefaultValues(): Promise<void> {
            return new Promise((resolve) => {
                const user: User = btaBase.user;
                const settingsInstance: SettingsService = SettingsService.getInstance();
                form.field('policy-start-date').patch({
                    startDate: minPolicyStartDate() as unknown as string,
                    endDate: '',
                });
                form.field('policy-holder-resident').patch(true);
                form.field('policy-holder-country').patch({
                    ic: '100000000',
                    iso: settingsInstance.localeIso(),
                    phoneCode: settingsInstance.phoneCode(),
                });
                if (user.isLogged()) {
                    form.field('policy-holder-name').patch(user.current.firstname);
                    form.field('policy-holder-surname').patch(user.current.lastname);
                    form.field('policy-holder-code').setValue(user.current.personCode);
                    if (form.field('policy-holder-email').isEmpty()) {
                        form.field('policy-holder-email').patch(user.current.email);
                    }
                    if (form.field('policy-holder-phone').isEmpty()) {
                        form.field('policy-holder-phone').patch({
                            code: user.current.code,
                            iso: user.current.code,
                            country: user.current.phoneCode,
                            phone: user.current.phone,
                        } as PhoneField);
                    }
                    if (form.field('policy-holder-address').isEmpty()) {
                        form.field('policy-holder-address').patch({
                            addressCode: user.current.addressCode,
                            label: user.current.address,
                            value: '',
                            additional: user.current.addressExtra,
                            postCode: user.current.postCode,
                        });
                    }
                }
                resolve();
            });
        }

        function hideAddressAdditional(): void {
            const user: User = btaBase.user;
            const addressValue: DynamicDictionary = form.field('policy-holder-address').value;
            const hasPostCode: boolean = !!addressValue.postCode;
            if (user.isLogged() && hasPostCode) {
                $('#policy-holder-address').addClass(AddressAdditionalHidden);
            }
        }

        function onBackClick(): void {
            useNavigate().navigate(SubmitterUrls.getInstance().previousStep());
        }

        function onCoveredClick(): void {
            showCoveredPopup();
        }

        function onResidentClick(): void {
            const settingsInstance: SettingsService = SettingsService.getInstance();
            const residentCountry: CountryComponentParams = {
                ic: '100000000',
                iso: settingsInstance.localeIso(),
                phoneCode: settingsInstance.phoneCode(),
            };
            form.field('policy-holder-code').clear();
            isNonResident.value
                ? form.field('policy-holder-country').clear()
                : form.field('policy-holder-country').patch(residentCountry);
        }

        function productRecipient(): DynamicDictionary {
            return {
                firstName: form.field('policy-holder-name').value,
                lastName: form.field('policy-holder-surname').value,
                identityNumber: form.field('policy-holder-code').value,
                country: country(),
                birthDate: birthDate(),
                email: form.field('policy-holder-email').value,
                phone: form.field('policy-holder-phone').value,
                isResident: btaBase.user.isLogged() ? true : form.field('policy-holder-resident').value,
                marketingConsents: marketingConsents(),
                address: form.field('policy-holder-address').value,
            };
        }

        function country(): DynamicDictionary {
            const settingsInstance: SettingsService = SettingsService.getInstance();
            let source: DynamicDictionary = {
                ic: '100000000',
                iso: settingsInstance.localeIso(),
                phoneCode: settingsInstance.phoneCode(),
            };
            if (isNonResident.value) {
                source = form.field('policy-holder-country').value;
            }

            return source;
        }

        function policyDate(): string {
            const date: Date = moment(form.field('policy-start-date').value.startDate).toDate();

            return OneDate.iris(date);
        }

        function birthDate(): string {
            const user: User = btaBase.user;
            let result: string | Date = '';
            if (user.isLogged()) {
                result = user.current.birthDate;
            } else {
                if (birthDateRequired.value) {
                    result = moment(form.field('policy-holder-birth-date').value).toDate();
                } else {
                    let countryIso: string = form.field('policy-holder-country').value.iso;
                    if (new Value(countryIso).isEmpty()) {
                        countryIso = new AppCountry().iso();
                    }
                    result =
                        new ExtractDataService().birthDateFromPersonCode(
                            form.field('policy-holder-code').value,
                            countryIso,
                        ) ?? '';
                }
            }

            return OneDate.iris(result);
        }

        function marketingConsents(): string[] {
            const selectedConsents: string = form.field('marketing-consents').value.selected;

            return new Value(selectedConsents).isNotEmpty() ? selectedConsents.split(ConsentsGlue) : [];
        }

        function prepareSubmit(): void {
            stepsSubmitter.addSubmitCustomParam('nextStep', stepsSubmitter.nextStep());
            stepsSubmitter.addSubmitCustomParam('facility', Facility);
            stepsSubmitter.addSubmitParam('productRecipient', productRecipient(), false);
            stepsSubmitter.addSubmitParam('policyStartDate', policyDate(), false);
            stepsSubmitter.addSubmitParam('bankLinkId', bankLinkId.value, false);
            stepsSubmitter.addSubmitParam('paymentFailRedirectUrl', SubmitterUrls.getInstance().currentStep(), false);
            stepsSubmitter.addAjaxResponseCallbackAfterStepsStorage(redirectToBank);
        }

        function redirectToBank(): void {
            useNavigate().navigate(Url.PaymentsApi.paymentsPay);
        }

        function onAddressEditClick(): void {
            $('#policy-holder-address').removeClass(AddressAdditionalHidden);
        }

        function onConfirmAndPayClick(): void {
            if (!birthDateRequired.value) {
                form.field('policy-holder-birth-date').setValue('');
            }
            form.submitAttempt().then((): void => {
                if (form.isValid()) {
                    bankLinkId.value = RecurringPaymentBanklink.ByCountry[new AppCountry().iso()];
                    if (bankLinkId.value !== 0) {
                        prepareSubmit();
                        stepsSubmitter.submitMethod(Method.Post);
                        stepsSubmitter.submitStep(Url.Ajax.movablePropertiesPurchase);
                    }
                } else {
                    const invalidElements: JQuery = $('.invalid').not('[style*="display: none"]');
                    if (invalidElements.length > 0) {
                        invalidElements[0].scrollIntoView({
                            behavior: 'smooth',
                            block: 'start',
                            inline: 'center',
                        });
                    }
                }
            });
        }

        function selectedProductId(): string {
            return UserStorage.getInstance().stepStorageData.productId ?? '';
        }

        function buildCoverageOptions(): void {
            const productFromStorage: MovableProperty | undefined = selectedProduct();
            if (productFromStorage) {
                coveredPopup
                    .withCoveredTitle('see_what_covered_single')
                    .withTranslationKey('items_and_belongings')
                    .withCoveredType(CoveredPopupTypes.CoveredPopupSingle)
                    .withCoveragePlanKey(UserStorage.getInstance().stepStorageData.coverageId)
                    .withSelectedRisks(UserStorage.getInstance().stepStorageData.selectedRisks)
                    .withExcludedRisks(btaBase.settings.movablePropertySettings().excludedRisks)
                    .withContent([productFromStorage])
                    .withMode('summary')
                    .build();
            }
        }

        function selectedProduct(): MovableProperty | undefined {
            const storage: UserStorage = UserStorage.getInstance();
            const storedProductId: string = selectedProductId();
            return (storage.storageData as MovableProperty[]).find(
                (product: MovableProperty): boolean => product.id === storedProductId,
            );
        }

        function showCoveredPopup(): void {
            PopupService.getInstance().show(new OnePopup().withType().oneCovered);
        }

        function minPolicyStartDate(): Date {
            const offset: number = btaBase.settings.movablePropertySettings().minPolicyStartDateOffset;

            return moment().add(offset, 'day').toDate();
        }

        function maxPolicyStartDate(): Date {
            const offset: number = btaBase.settings.movablePropertySettings().maxPolicyStartDateOffset;

            return moment(minPolicyStartDate()).add(offset, 'days').toDate();
        }

        function coveredButtonParams(): ButtonWithCallbackParams {
            return {
                title: translate('see_what_covered'),
                textColor: ButtonTextColor.Black,
                backgroundColorHover: ButtonBackground.White,
                backgroundColor: ButtonBackground.White,
                icon: ButtonIcon.Covered,
                iconColor: ButtonIconColor.Green,
                borderColor: ButtonBorder.Pale,
            };
        }

        function confirmAndPayButtonParams(): ButtonWithCallbackParams {
            return {
                title: translateForType('summary_confirm_and_pay', 'items_and_belongings'),
                textColor: ButtonTextColor.White,
                backgroundColor: ButtonBackground.Red,
            };
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                form,
                onBackClick,
                setupForm,
                onBeforeFormRestore,
                onAppReady,
                coveredButtonParams,
                coveredPopup,
                onCoveredClick,
                showMarketingConsents,
                confirmAndPayButtonParams,
                onConfirmAndPayClick,
                minPolicyStartDate,
                maxPolicyStartDate,
                policyStartDate,
                policyEndDate,
                isNonResident,
                birthDateRequired,
                birthDate,
                onAddressEditClick,
                country,
                onResidentClick,
            },
        };
    },

    mounted() {
        this.applyApp(this);
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        this.setupForm();

        this.userStorage.onBeforeFormStorageDataIsRestored.pipe(take(1)).subscribe((): void => {
            this.onBeforeFormRestore();
        });

        this.onAppIsPreparedAndReady.pipe(take(1)).subscribe((): void => {
            this.onAppReady();
        });
    },
});
</script>
