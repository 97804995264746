<script lang="ts">
import { computed, defineComponent, ref, Ref } from 'vue';
import OneBaseService from '@/services/OneBaseService';
import Form from '@/assets/libraries/form/form';
import { FileUploadParams, useFileUpload } from '@/Composables/FileUpload';
import ClaimOption from '@/interfaces/claim.option.interface';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import { Subscription } from 'rxjs';
import Error from '@/services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import { useStepsSubmitter } from '@/Composables/StepsSubmitter';
import { useDefine } from '@/Composables/Define';
import { useTranslate } from '@/Composables/Translate';
import Translations from '@/services/translations.service';
import CascoClaimsPanelsNames from '@/Enums/CascoClaimsPanelsNamesEnum';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const { translateForType } = useTranslate();
        const { isSet } = useDefine();
        const stepsSubmitter = useStepsSubmitter();
        const filesUploader: FileUploadParams = useFileUpload();

        const CurrentStep: number = 5;
        const NextStep: number = CurrentStep + 1;
        const Facility: string = 'one-claims-casco';
        const uploader: string = 'claims-casco';
        const CompensationTypeOtherService: string = 'other';

        const form: Form = new Form();
        const formIsReady: Ref<boolean> = ref(false);
        const renew: Ref<boolean> = ref(false);
        const panels: Ref<ClaimOption[]> = ref([]);
        const panelNames: Ref<string[]> = ref(['necessary-documents', 'damage-photos', 'other-documents']);
        const uploadDocumentText: Ref<string> = ref('');
        const claimsCascoContactDetailsUrl: Ref<string> = ref('');
        const dbPanels: Ref<DynamicDictionary> = ref({});

        const isUploaded: Ref<boolean> = computed(() => {
            return filesUploader.filesUploader.value
                ? !(filesUploader.filesUploader.value as DynamicDictionary).filesNotUploaded &&
                      (filesUploader.filesUploader.value as DynamicDictionary).uploadsSuccessful
                : false;
        });

        const fileUploadMessage: Ref<string> = computed(() => {
            return translateForType(uploadDocumentText.value, Translations.getInstance().type);
        });

        function applyContactDetailsUrl(url: string): void {
            claimsCascoContactDetailsUrl.value = url;
        }

        function onSubmitStep(): void {
            submit();
        }

        function submit(): void {
            (filesUploader.filesUploader.value as DynamicDictionary).touch();
            prepareSubmit();
            if (isSelectedRepairService() || isSelectedOtherRepairService()) {
                const contactDetailStepNumber: number = 7;
                stepsSubmitter.proceedStep(claimsCascoContactDetailsUrl.value, contactDetailStepNumber);
            } else {
                stepsSubmitter.proceedStep('', 0);
            }
        }

        function isSelectedRepairService(): boolean {
            return !!btaBase.userStorage.stepStorageData[CascoClaimsPanelsNames.PleaseChooseRepairService];
        }

        function isSelectedOtherRepairService(): boolean {
            return (
                btaBase.userStorage.stepStorageData[CascoClaimsPanelsNames.HowYouWantToReceiveCompensation].selected ===
                CompensationTypeOtherService
            );
        }

        function applyDbPanels(value: string): void {
            dbPanels.value = JSON.parse(value);
        }

        function isPanelVisible(panelName: string): boolean {
            return panelByName(panelName).visible;
        }

        function panelByName(panelName: string): ClaimOption {
            return panels.value.find((panel: ClaimOption) => panel.name === panelName)!;
        }

        function applyFileUploadMessage(description: string): void {
            uploadDocumentText.value = description;
        }

        function init(): void {
            assemblePanels();
        }

        function onAppReady(): void {
            btaBase.dynamicStepper.applyEnabled(btaBase.userStorage.stepStorageData.dynamicStepper);
        }

        function setupForm(): void {
            form.setReady();
            formIsReady.value = true;
        }

        function assemblePanels(): void {
            panelNames.value.forEach((key: string, index: number) => {
                panels.value.push({
                    id: index,
                    name: key,
                    enabled: dbPanelExists(key) ? dbPanels.value[key] === 'Y' : false,
                    passed: false,
                    visible: dbPanelExists(key) ? dbPanels.value[key] === 'Y' : false,
                    value: '',
                    options: [],
                } as ClaimOption);
            });
        }

        function dbPanelExists(panelName: string): boolean {
            return isSet(dbPanels.value[panelName]);
        }

        function prepareSubmit(): void {
            stepsSubmitter.addSubmitCustomParams(btaBase.userStorage.stepStorageData);
            stepsSubmitter.addSubmitCustomParam('nextStep', NextStep);
            stepsSubmitter.addSubmitCustomParam('facility', Facility);
            stepsSubmitter.addSubmitCustomParam('filesUploaded', isUploaded.value);
            stepsSubmitter.addSubmitCustomParam('dynamicStepper', btaBase.dynamicStepper.enableStates);
        }

        return {
            ...btaBase,
            ...filesUploader,
            ...{
                CurrentStep,
                Facility,
                uploader,
                form,
                formIsReady,
                isUploaded,
                fileUploadMessage,
                init,
                renew,
                setupForm,
                onAppReady,
                onSubmitStep,
                applyContactDetailsUrl,
                applyFileUploadMessage,
                applyDbPanels,
                isPanelVisible,
                submit,
            },
        };
    },

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        Translations.getInstance().addType('claims');
        this.init();
        this.setupForm();
        const onAppIsPreparedAndReady: Subscription = this.onAppIsPreparedAndReady.subscribe(() => {
            this.onAppReady();
            onAppIsPreparedAndReady.unsubscribe();
        });
    },
});
</script>
