<script setup lang="ts">
import Sanitizer from '@/services/sanitizer.service';
import { computed, ComputedRef, onMounted, PropType, ref, Ref, watch } from 'vue';
import AppCountry from '@/assets/libraries/app/app-country';
import Validation from '@/services/validation.service';
import PlateNumberPlaceholder from '@/Enums/PlateNumberPlaceholderEnum';
import FormField from '@/assets/libraries/form/form-field';
import Form from '@/assets/libraries/form/form';
import Error from '@/services/error.service';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import { AxiosResponse } from 'axios';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import ErrorType from '@/Enums/ErrorTypeEnum';
import Translations from '@/services/translations.service';
import { useDefine } from '@/Composables/Define';
import RequestService from '@/services/request.service';
import { InputOption } from '@/interfaces/InputOptionInterface';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import type { SanitizerCallback } from '@/assets/libraries/form/form-field';
import AppContentLoader from '@/Components/Loaders/ContentLoader/ContentLoader.vue';
import AppInputText from '@/Components/Inputs/InputText/InputText.vue';
import AppPopup from '@/Components/Popups/Popup/Popup.vue';

type Mode = 'default' | 'with-finder';
type InfoMode = 'default' | 'reversed';
type Icon = 'default' | 'property' | 'auto' | 'accident' | 'travel';
type SearchBy = 'policy-number' | 'plate-or-vin-number';

const props = defineProps({
    placeholder: {
        type: String,
        default: () => PlateNumberPlaceholder.ByCountry[new AppCountry().iso()],
    },
    formField: {
        type: Object as PropType<FormField<{ object: object | string; finder: string }>>,
        default: () => new FormField(''),
    },
    label: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    dataStoreDisabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    options: { type: Array as PropType<InputOption[]>, default: () => [] },
    automaticallySelectTheOnlyOption: { type: Boolean, default: false },
    mode: { type: String as PropType<Mode>, default: 'default' },
    infoMode: { type: String as PropType<InfoMode>, default: 'default' },
    eventDate: { type: String, default: '' },
    fetchUrl: { type: String, default: '' },
    fetchParams: { type: Object, default: null },
    forceShowFinder: { type: Boolean, default: false },
    objectType: { type: String, default: 'object' },
    translationType: { type: String, default: '' },
    icon: { type: String as PropType<Icon>, default: 'default' },
    searchBy: { type: String as PropType<SearchBy>, default: 'policy-number' },
    skipOptionsChangeFormReset: { type: Boolean, default: false },
    finderCallback: {
        type: Function,
        default: () => {
            return () => {
                console.debug('AppInsuredObjectsBlocks::finderCallback callback not set!');
            };
        },
    },
    finderObjectBuilderCallback: { type: Function, default: null },
    titleTransformerCallback: { type: Function, default: null },
    descriptionTransformerCallback: {
        validator: function (value) {
            return value === null || typeof value === 'function';
        },
        default: null,
        type: Function,
    },
    noObjectsCallback: { type: Function, default: null },
    noObjectOverrideCallback: { type: Function, default: null },
    hideHolderCodeField: { type: Boolean, default: false },
    holderCodeValue: { type: String, default: '' },
    withoutHolderCodeField: { type: Boolean, default: false },
});

const emit = defineEmits(['change']);
const form: Form = new Form();
const finderOptionsSeparator: string = '$$';
const holderCodeField: string = 'holder-code';
const translations = Translations.getInstance();
const error: Error = Error.getInstance();
const requestService: RequestService = RequestService.getInstance();
const formValidation: Map<string, DynamicDictionary> = new Map();
const { isSet } = useDefine();

const editIconIsVisible: ComputedRef<boolean> = computed(() => {
    return !addressSelectIsOpened.value;
});
const searchByLabel: ComputedRef<string> = computed(() => {
    return '_' + props.searchBy.replace(/-/g, '_');
});
const finderIsVisible: ComputedRef<boolean> = computed(() => {
    return props.mode === 'with-finder' && addressSelectIsOpened.value && (hasOptions.value || props.forceShowFinder);
});
const hasOptions: ComputedRef<boolean> = computed(() => {
    return props.options !== undefined && props.options.length > 0;
});
const formFieldSelected: ComputedRef<string> = computed(() => {
    return props.formField.value.object as string;
});
const infoModeIsRegular: ComputedRef<boolean> = computed(() => {
    return props.infoMode === 'default';
});
const isVisibleErrorMessage: ComputedRef<boolean> = computed(() => {
    return isVisibleNotFoundMessage.value;
});
const iconClass: ComputedRef<string> = computed(() => {
    return 'block-icon-' + props.icon;
});
const filteredOptions: ComputedRef<InputOption[]> = computed(() => {
    return props.options.filter((item) => {
        return itemIsVisible(item);
    });
});
const filteredFinderOptions: ComputedRef<InputOption[]> = computed(() => {
    return finderOptions.value.filter((item) => {
        return itemFromFindIsVisible(item) && isItemFromFind.value;
    });
});
const errorMessageText: ComputedRef<string> = computed(() => {
    const errorMessage: string =
        errorDescription.value !== ''
            ? '_' + String(errorDescription.value).replace('-', '_')
            : '_single_object_not_found';

    return translations.localized(props.objectType + errorMessage, props.translationType);
});

const isMtplClaims: boolean = props.objectType === 'claims_mtpl';

const finderOptions: Ref<InputOption[]> = ref([]);
const finderOptionsCompiled: Ref<string> = ref('');
const addressSelectIsOpened: Ref<boolean> = ref(true);
const isVisibleFinder: Ref<boolean> = ref(false);
const isSearchInProgress: Ref<boolean> = ref(false);
const isItemFromFind: Ref<boolean> = ref(false);
const isVisibleNotFoundMessage: Ref<boolean> = ref(false);
const errorDescription: Ref<string> = ref('');

watch(
    () => props.holderCodeValue,
    (newValue: string, oldValue: string) => {
        if (newValue && newValue !== oldValue) {
            form.field(holderCodeField).patch(newValue);
        }
    },
);

watch(
    () => props.options,
    (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue) && !props.skipOptionsChangeFormReset) {
            props.formField.patch({
                object: '',
                finder: '',
            });
            resetFinderOptions();
            addressSelectIsOpened.value = true;
        } else {
            addressSelectIsOpened.value = true;
            newValue.forEach((item: InputOption) => {
                if (item.value === formFieldSelected.value) {
                    addressSelectIsOpened.value = false;
                }
            });
        }
        selectTheFirstOptionIfThereIsOnlyOne(newValue);
    },
);

watch(
    () => props.formField?.value,
    (newValue, oldValue) => {
        if (formIsNotEmpty()) {
            if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                finderOptionsCompiled.value = newValue.finder;
                buildFinderObject();
                select(newValue.object as string, isFinderObject());
                emitChange();
            }
        }
    },
);

onMounted(() => {
    loadValidatorsAndSanitizers();
    setupForm();
    init();
});

defineExpose({
    currentFinderOptions,
});

function loadValidatorsAndSanitizers(): void {
    formValidation.set('validators', validators());
    formValidation.set('sanitizers', sanitizers());
}

function itemIsVisible(item: InputOption): boolean {
    return (
        (item.value === formFieldSelected.value || addressSelectIsOpened.value) &&
        hasOptions.value &&
        !(!addressSelectIsOpened.value && isItemFromFind.value && optionsHasItemFromFinderOptions())
    );
}

function itemFromFindIsVisible(item: InputOption): boolean {
    return (
        (item.value === formFieldSelected.value || addressSelectIsOpened.value) &&
        (hasOptions.value || (!hasOptions.value && props.forceShowFinder))
    );
}

function optionsHasItemFromFinderOptions(): boolean {
    const objectFromFinder: string = finderOptions.value.length > 0 ? String(finderOptions.value[0].value) : '';
    return finderOptions.value.some((object: InputOption) => {
        return object.value === objectFromFinder;
    });
}

function blockTitle(item: InputOption): string[] {
    let result: string;
    if (
        (!infoModeIsRegular.value && props.titleTransformerCallback) ||
        (infoModeIsRegular.value && props.descriptionTransformerCallback)
    ) {
        result = !infoModeIsRegular.value
            ? props.titleTransformerCallback(item)
            : props.descriptionTransformerCallback(item);
    } else {
        result = translations.localized((item.custom as DynamicDictionary).type as string);
    }

    return Array.isArray(result) ? result : [result];
}

function blockDescription(item: InputOption): string[] {
    let result: string;
    if (
        (!infoModeIsRegular.value && props.descriptionTransformerCallback) ||
        (infoModeIsRegular.value && props.titleTransformerCallback)
    ) {
        result = !infoModeIsRegular.value
            ? props.descriptionTransformerCallback(item)
            : props.titleTransformerCallback(item);
    } else {
        result = item.name;
    }

    return Array.isArray(result) ? result : [result];
}

function closeFinder(): void {
    isVisibleFinder.value = false;
}

function currentFinderOptions(): InputOption[] {
    return finderOptions.value;
}

function openFinder(): void {
    isVisibleFinder.value = true;
    isVisibleNotFoundMessage.value = false;
}

function onIconEditPress(): void {
    addressSelectIsOpened.value = true;
}

function insuredBlockClasses(item: DynamicDictionary): string {
    let result: string = addressSelectIsOpened.value ? 'active' : '';
    if (props.formField.value.object === String(item.value) && addressSelectIsOpened.value) {
        result += ' selected';
    }
    if (
        isSet((item.custom as DynamicDictionary).errorMessage) &&
        (item.custom as DynamicDictionary).errorMessage !== ''
    ) {
        result += ' with-error-message';
    }

    return result;
}

function select(value: string, isFinderItem: boolean = false): void {
    if (addressSelectIsOpened.value) {
        buildFinderObjectFromSelectedItem(String(value));
        props.formField.patch({
            object: String(value),
            finder: finderOptionsCompiled.value,
        });
        addressSelectIsOpened.value = false;
        isItemFromFind.value = isFinderItem;
    }
}

function selectTheFirstOptionIfThereIsOnlyOne(options: InputOption[]): void {
    if (props.automaticallySelectTheOnlyOption && options.length === 1 && addressSelectIsOpened.value) {
        select(String(options[0].value));
    }
}

function findObjects(): void {
    isVisibleNotFoundMessage.value = false;
    isSearchInProgress.value = true;
    const params: Record<string, number | string> = searchParams();
    requestService
        .get({ uri: props.fetchUrl, content: params })
        .then((response: AxiosResponse<DynamicDictionary>): void => {
            if (isValidResponse(response)) {
                const objects: DynamicDictionary[] = response.data.data.body.objects;
                if (objects.length > 0) {
                    objects.forEach((property: DynamicDictionary) => applyFoundObject(property));
                    if (objects.length === 1) {
                        select(objects[0].id);
                    }
                    isItemFromFind.value = true;
                    closeFinder();
                } else if (props.noObjectsCallback) {
                    props.noObjectsCallback();
                } else {
                    isVisibleNotFoundMessage.value = true;
                }
            } else {
                if (props.noObjectOverrideCallback) {
                    props.noObjectOverrideCallback();
                    closeFinder();
                } else {
                    applyErrorDescription(response.data.data.body);
                    isVisibleNotFoundMessage.value = true;
                }
            }
        })
        .catch((reason: LimitedVariant): void => {
            error.show(ErrorType.Error, 'component[InsuredObjectsBlocks]::findProperty', reason as DynamicDictionary);
        })
        .finally((): void => {
            isSearchInProgress.value = false;
        });
}

function searchParams(): Record<string, number | string> {
    const params: Record<string, number | string> = {
        eventDate: props.eventDate,
    };
    if (!props.withoutHolderCodeField) {
        params.holderCode = form.field(holderCodeField).value;
    }
    if (props.searchBy === 'policy-number') {
        params.agreementNumber = form.field('policy-number').value;
    }
    if (props.searchBy === 'plate-or-vin-number') {
        params.plateOrVinNumber = form.field('plate-or-vin-number').value;
    }

    return props.fetchParams ? { ...props.fetchParams, ...params } : params;
}

function setupForm(): void {
    form.addField(new FormField(props.searchBy, '', localValidator(), localSanitizer() as SanitizerCallback));
    form.addField(
        new FormField(
            holderCodeField,
            props.holderCodeValue,
            isMtplClaims ? '' : holderCodeValidator(),
            isMtplClaims ? Sanitizer.cleanCharNumberHyphenString : Sanitizer.clearPersonCode,
        ),
    );
    form.setReady();
}

function init(): void {
    if (props.options && props.options.length > 0 && !props.formField.isEmpty()) {
        finderOptionsCompiled.value = props.formField.value.finder;
        buildFinderObject();
        isItemFromFind.value = finderValueIsNotInBasicList();
        props.options.forEach((option: InputOption) => {
            if (
                option.value === props.formField.value.object ||
                (finderOptions.value.length > 0 && finderOptions.value[0].value === props.formField.value.object)
            ) {
                addressSelectIsOpened.value = false;
            }
        });
    }
    selectTheFirstOptionIfThereIsOnlyOne(props.options);
}

function finderValueIsNotInBasicList(): boolean {
    let result: boolean = true;
    if (props.options && props.options.length > 0) {
        props.options.forEach((option: InputOption) => {
            if (option.value === props.formField.value.object) {
                result = false;
            }
        });
    }

    return result;
}

function resetFinderOptions(): void {
    isItemFromFind.value = false;
    finderOptionsCompiled.value = '';
    finderOptions.value = [];
    isVisibleNotFoundMessage.value = false;
}

function isFinderObject(): boolean {
    let result: boolean = false;
    if (finderOptions.value.length > 0) {
        result = (finderOptions.value[0].custom as DynamicDictionary).isFinderObject;
    }

    return result;
}

function holderCodeValidator(): {
    isValidPersonCodeOrRegistrationNumber: (value: string) => boolean;
} {
    return {
        isValidPersonCodeOrRegistrationNumber: (value: string) => {
            return (
                props.withoutHolderCodeField ||
                Validation.isValidCompanyRegistrationNumber(value) ||
                Validation.isValidNaturalPersonCode(value)
            );
        },
    };
}

function plateOrVinNumberValidator(): object {
    return {
        isValid: (value: string) => {
            return Validation.isValidPlateNumber(value) || Validation.isValidVinNumber(value);
        },
    };
}

function policyNumberValidator(): object {
    return {
        isValid: (value: string) => {
            return Validation.isValidPolicyNumber(value);
        },
    };
}

function formIsNotEmpty(): boolean {
    return !props.formField.isEmpty();
}

function isValidResponse(response: DynamicDictionary): boolean {
    let result: boolean =
        isSet(response.data) &&
        isSet(response.data.data) &&
        isSet(response.data.data.body) &&
        isSet(response.data.data.body.objects);
    response.data.data.body.objects.forEach((object: DynamicDictionary) => {
        result = result && isSet(object.name) && isSet(object.id) && isSet(object.objectType);
    });

    return result;
}

function applyFoundObject(object: DynamicDictionary): void {
    props.finderCallback(object);
    finderOptionsCompiled.value = [
        modifiedAddressValue(object.name),
        object.id,
        transformedObjectType(object.objectType),
        object.policyNumber,
        object.validFrom,
        object.validTo,
        true,
        object.isDirectClaim,
        ...(object.objectAttribute ? object.objectAttribute.value : ''),
    ].join(finderOptionsSeparator);
    buildFinderObject();
}

function applyErrorDescription(response: DynamicDictionary): void {
    errorDescription.value = '';
    if (isSet(response.objects[0].errorDescription)) {
        errorDescription.value = response.objects[0].errorDescription;
    }
}

function transformedObjectType(objectType: string): string {
    const parts: string[] = objectType.split('_');

    return props.objectType + '_' + String(parts[parts.length - 1]).toLowerCase();
}

function modifiedAddressValue(address: string): string {
    const regex = /, LV-\d{4}, Latvija/gm;

    return address.replace(regex, '');
}

function buildFinderObject(): void {
    const object: DynamicDictionary = finderDecompiledObject();
    if (object.isFinderObject) {
        if (!objectAlreadyFound()) {
            const hasFinderObjectBuilderCallback: boolean =
                props.finderObjectBuilderCallback !== null && typeof props.finderObjectBuilderCallback === 'function';
            finderOptions.value.push(
                hasFinderObjectBuilderCallback
                    ? props.finderObjectBuilderCallback(object)
                    : new InputOptionBuilder()
                          .setName(object.name)
                          .setValue(object.id)
                          .setCustom({
                              type: object.type,
                              policy: object.policy,
                              validFrom: object.validFrom,
                              validTo: object.validTo,
                              isFinderObject: object.isFinderObject,
                              isDirectClaim: object.isDirectClaim,
                              cascoCoverageId: object.cascoCoverageId || '',
                          })
                          .build(),
            );
        }
    }
}

function objectAlreadyFound(): boolean {
    const object: DynamicDictionary = finderDecompiledObject();

    return (
        finderOptions.value.some((option: InputOption) => option.value === object.id) ||
        props.options!.some((option: InputOption) => option.value === object.id)
    );
}

function localValidator(): object {
    return formValidation.get('validators')![props.searchBy];
}

function localSanitizer(): Function {
    return formValidation.get('sanitizers')![props.searchBy];
}

function validators(): DynamicDictionary {
    const result: DynamicDictionary = {
        'policy-number': policyNumberValidator(),
    };
    if (!isMtplClaims) {
        result['plate-or-vin-number'] = plateOrVinNumberValidator();
    }
    return result;
}

function sanitizers(): DynamicDictionary {
    const result: DynamicDictionary = {
        'holderCodeField': Sanitizer.cleanPersonCode,
        'policy-number': Sanitizer.cleanPolicyNumber,
        'plate-or-vin-number': Sanitizer.cleanPlateOrVinNumber,
    };

    if (isMtplClaims) {
        result['plate-or-vin-number'] = Sanitizer.cleanCharNumberString;
        result['holderCodeField'] = Sanitizer.cleanCharNumberHyphenString;
    }

    return result;
}

function buildFinderObjectFromSelectedItem(value: string): void {
    if (props.options) {
        props.options.forEach((item: DynamicDictionary) => {
            if (item.value === value) {
                finderOptionsCompiled.value = [
                    item.name,
                    item.value,
                    item.custom.type,
                    item.custom.policy,
                    item.custom.validFrom,
                    item.custom.validTo,
                    false,
                    item.custom.isDirectClaim,
                ].join(finderOptionsSeparator);
            }
        });
    }
}

function finderDecompiledObject(): DynamicDictionary {
    const parts: string[] = finderOptionsCompiled.value.split(finderOptionsSeparator);
    const finderObject: boolean = Boolean(isSet(parts[6]) ? String(parts[6]) === 'true' : false);
    return {
        name: parts[0],
        id: parts[1],
        type: parts[2],
        policy: parts[3],
        validFrom: parts[4],
        validTo: parts[5],
        isFinderObject: finderObject,
        isDirectClaim: parts[6],
        cascoCoverageId: parts[8],
    };
}

function emitChange(): void {
    props.formField.touch();
    props.formField.sanitize();
    props.formField.validate();

    emit('change', props.formField.value);
}

function translated(stringId: string): string {
    return translations.localized(props.objectType + stringId, props.translationType);
}
</script>

<template>
    <div
        :id="formField.name"
        class="input insured-objects-blocks"
        :class="{ ...formField.classes(), disabled: disabled }"
        :data-store="dataStoreDisabled ? '' : formField.name"
        :data-store-value="dataStoreDisabled ? '' : JSON.stringify(formField.value)"
    >
        <div v-if="label" class="label hide-on-mobile">
            <p>{{ label }}<span v-if="required" class="asterisk">&#42;</span></p>
            <slot name="app-tooltipster"></slot>
        </div>
        <div class="container">
            <div class="container-inner-scroll" :class="{ empty: !hasOptions }">
                <div
                    v-for="(item, index) in filteredOptions"
                    :key="index"
                    class="object-block"
                    :class="insuredBlockClasses(item)"
                    :data-type="'propertyObject' + String(item.value)"
                >
                    <div class="button-container">
                        <button
                            :disabled="item.disabled"
                            :class="{ disabled: item.disabled }"
                            @click="select(String(item.value))"
                        >
                            <div class="block-icon" :class="iconClass"></div>
                            <div class="block-info">
                                <div v-if="!infoModeIsRegular" class="block-info-regular">
                                    <p v-for="(chunk, blockTitleIndex) in blockTitle(item)" :key="blockTitleIndex">
                                        {{ chunk }}
                                    </p>
                                </div>
                                <div v-if="!infoModeIsRegular" class="block-info-bold">
                                    <p
                                        v-for="(chunk, blockDescriptionIndex) in blockDescription(item)"
                                        :key="blockDescriptionIndex"
                                    >
                                        {{ chunk }}
                                    </p>
                                </div>
                                <div v-if="infoModeIsRegular" class="block-info-bold">
                                    <p
                                        v-for="(chunk, descriptionIndex) in blockDescription(item)"
                                        :key="descriptionIndex"
                                    >
                                        {{ chunk }}
                                    </p>
                                </div>
                                <div
                                    v-if="infoModeIsRegular && (item.custom as DynamicDictionary).cascoCoverageId"
                                    class="block-info-regular"
                                >
                                    <p>
                                        {{
                                            translations.localized(
                                                'tl_casco_coverage_value_' +
                                                    (item.custom as DynamicDictionary).cascoCoverageId,
                                            )
                                        }}
                                    </p>
                                </div>
                                <div v-if="infoModeIsRegular" class="block-info-regular">
                                    <p v-for="(chunk, titleIndex) in blockTitle(item)" :key="titleIndex">{{ chunk }}</p>
                                </div>
                            </div>
                        </button>
                        <button
                            v-if="editIconIsVisible"
                            class="block-icon-edit"
                            :aria-label="translations.localized('edit')"
                            @click="onIconEditPress()"
                        ></button>
                    </div>
                    <span v-if="isSet((item.custom as DynamicDictionary).errorMessage)" class="error-message">
                        {{ (item.custom as DynamicDictionary).errorMessage }}
                    </span>
                </div>
            </div>
            <div class="container-static-blocks">
                <div
                    v-for="(item, index) in filteredFinderOptions"
                    :key="index"
                    class="object-block"
                    :class="insuredBlockClasses(item)"
                    :data-type="'propertyObjectFromFinder'"
                >
                    <div class="button-container">
                        <button
                            :disabled="item.disabled"
                            :class="{ disabled: item.disabled }"
                            @click="select(String(item.value), true)"
                        >
                            <div class="block-icon" :class="iconClass"></div>
                            <div class="block-info">
                                <div v-if="!infoModeIsRegular" class="block-info-regular">
                                    <p v-for="(chunk, blockIndex) in blockTitle(item)" :key="blockIndex">{{ chunk }}</p>
                                </div>
                                <div v-if="!infoModeIsRegular" class="block-info-bold">
                                    <p
                                        v-for="(chunk, blockDescriptionIndex) in blockDescription(item)"
                                        :key="blockDescriptionIndex"
                                    >
                                        {{ chunk }}
                                    </p>
                                </div>
                                <div v-if="infoModeIsRegular" class="block-info-bold">
                                    <p
                                        v-for="(chunk, descriptionIndex) in blockDescription(item)"
                                        :key="descriptionIndex"
                                    >
                                        {{ chunk }}
                                    </p>
                                </div>
                                <div
                                    v-if="infoModeIsRegular && (item.custom as DynamicDictionary).cascoCoverageId"
                                    class="block-info-regular"
                                >
                                    <p>
                                        {{
                                            translations.localized(
                                                'tl_casco_coverage_value_' +
                                                    (item.custom as DynamicDictionary).cascoCoverageId,
                                            )
                                        }}
                                    </p>
                                </div>
                                <div v-if="infoModeIsRegular" class="block-info-regular">
                                    <p v-for="(chunk, blockTitleIndex) in blockTitle(item)" :key="blockTitleIndex">
                                        {{ chunk }}
                                    </p>
                                </div>
                            </div>
                        </button>
                        <button
                            v-if="editIconIsVisible"
                            class="block-icon-edit"
                            :aria-label="translations.localized('edit')"
                            @click="onIconEditPress()"
                        ></button>
                    </div>
                    <span v-if="isSet((item.custom as DynamicDictionary).errorMessage)" class="error-message">
                        {{ (item.custom as DynamicDictionary).errorMessage }}
                    </span>
                </div>
                <button
                    v-if="finderIsVisible"
                    class="object-block active"
                    :data-type="'propertyObjectFinder'"
                    @click="openFinder()"
                >
                    <div class="block-icon block-icon-open-finder" :class="iconClass"></div>
                    <div class="block-info">
                        <div class="block-info-regular">
                            {{ translated('_find_not_in_the_list') }}
                        </div>
                        <div class="block-info-bold">{{ translated('_find') }}</div>
                    </div>
                </button>
                <div v-if="!hasOptions && !forceShowFinder" class="object-block">
                    <div class="block-icon" :class="iconClass"></div>
                    <div class="block-info">
                        <span class="block-info-regular">
                            {{ translated('_object_list_not_found_title') }}
                        </span>
                        <span class="block-info-bold">
                            {{ translated('_object_list_not_found_description') }}
                        </span>
                    </div>
                </div>
            </div>
            <div v-if="isVisibleFinder" class="popups" :class="{ loading: isSearchInProgress }">
                <app-popup class="simple list-search" :disable-close="isSearchInProgress" @close="closeFinder()">
                    <div v-if="isSearchInProgress" class="loading-panel">
                        <app-content-loader></app-content-loader>
                        {{ translated('_searching') }}
                    </div>
                    <div v-if="!isSearchInProgress" class="title">
                        {{ translated('_find') }}
                    </div>
                    <div v-if="!isSearchInProgress" class="elements">
                        <div v-if="isVisibleErrorMessage" class="not-valid">
                            <span class="invalid-icon"></span>
                            {{ errorMessageText }}
                        </div>
                        <app-input-text
                            :label="translated(searchByLabel)"
                            :form-field="form.field(searchBy)"
                            :disable-error-text="true"
                        ></app-input-text>
                        <app-input-text
                            v-if="!props.withoutHolderCodeField"
                            :disable-error-text="true"
                            :form-field="form.field(holderCodeField)"
                            :hidden="props.hideHolderCodeField"
                            :label="translated('_person_code_or_reg_number')"
                        ></app-input-text>
                        <button
                            :id="formField.name + '-proceed'"
                            class="button red"
                            :disabled="!form.isValid()"
                            @click="findObjects()"
                        >
                            {{ translated('_proceed') }}
                        </button>
                    </div>
                </app-popup>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@mixin background-icon($name) {
    & {
        background-image: image('icons/components/insured_objects_blocks/#{$name}.svg');
        background-position: center;
        background-repeat: no-repeat;
    }
}

@mixin violet-bg {
    & {
        background-color: var(--blue-100);
    }
}

$gap: 12px;

.insured-objects-blocks {
    width: 100%;

    .label {
        font-size: var(--font-size-small);
        font-weight: 600;
        color: var(--component-color-text-label);
        margin-bottom: 20px;
    }

    .container {
        flex-direction: column;
        height: auto;

        .container-inner-scroll {
            max-height: 317px;
            overflow: hidden auto;
            padding: var(--size-femto);
            margin-bottom: $gap;

            &.empty {
                margin-bottom: 0;
            }

            .object-block {
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        .container-static-blocks {
            margin-left: var(--size-femto);

            > div {
                margin-bottom: $gap;
            }

            button.object-block {
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        .object-block {
            display: flex;
            align-items: center;
            width: 100%;
            max-width: 880px;
            min-height: 90px;
            background-color: var(--component-color-background-base);
            border: 1px solid var(--component-color-border-default);
            transition: border-color 0.4s;
            border-radius: 8px;
            margin-bottom: $gap;
            padding: 21px;
            margin-right: 10px;

            &.active {
                cursor: pointer;
            }

            &:focus-within,
            &:focus-visible {
                outline: 2px solid var(--button-color-border-secondary-focus);
                outline-offset: 2px;
            }

            &:hover {
                border: 1px solid var(--component-color-border-hover);
            }

            &.selected {
                border: 2px solid var(--brand-blue);
                background-color: var(--blue-50);
                padding: 20px;
            }

            &.with-error-message {
                flex-direction: column;
                align-items: flex-start;
                border: 2px solid var(--system-color-error-default);
                cursor: default;
                padding: 21px;

                .error-message {
                    margin-top: var(--size-pico);
                    color: red;
                    font-size: 14px;
                    line-height: var(--line-height-basic);
                }
            }

            .button-container {
                display: flex;
                flex-direction: row;
                flex: 1;
                width: 100%;
            }

            button {
                &:first-of-type {
                    display: flex;
                    flex: 1;
                }

                &.disabled {
                    cursor: default;
                }
            }

            .block-icon {
                width: 52px;
                height: 52px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                align-self: center;
                margin-right: var(--size-tiny);
                @include violet-bg;

                &.block-icon-open-finder {
                    border: 1px solid transparent;
                    transition: border-color 0.6s;

                    &:focus {
                        border: 1px solid var(--brand-teal);
                    }

                    @include background-icon(find-blue);

                    &.block-icon- {
                        &accident,
                        &auto {
                            @include background-icon(find-blue);
                        }
                    }
                }

                .icon- {
                    &default,
                    &accident,
                    &auto,
                    &property {
                        display: none;
                    }
                }

                &-default,
                &-property {
                    @include background-icon(property);
                }

                &-accident {
                    @include background-icon(accident);
                }

                &-auto {
                    @include background-icon(auto);
                }

                &-travel {
                    @include background-icon(travel);
                }

                &-open-finder {
                    background-image: none;
                }

                &-warning {
                    @include background-icon(exclamation-in-circle);
                }
            }

            .block-info {
                flex: 1;
                text-align: left;

                span {
                    display: flex;
                }

                &-regular,
                &-regular > p {
                    font-weight: 500;
                    font-size: var(--font-size-nano);
                    color: var(--text-color-subtle);
                    margin-top: 2px;
                    margin-bottom: 2px;
                }

                &-bold,
                &-bold > p {
                    font-weight: 600;
                    font-size: var(--font-size-tiny);
                    color: var(--component-color-text-value);
                    margin-top: 2px;
                    margin-bottom: 2px;
                }
            }

            .block-icon-edit {
                width: 52px;
                height: 52px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                border: 1px solid transparent;
                transition: border-color 0.6s;
                border-radius: 50%;

                @include background-icon(edit);

                &:focus {
                    border: 1px solid var(--brand-teal);
                }

                &:hover {
                    svg {
                        g {
                            opacity: unset;

                            path {
                                fill: var(--brand-teal);
                            }
                        }
                    }
                }
            }
        }

        :deep(.popups) {
            &.loading {
                .single-popup > .wrapper {
                    height: auto;
                    min-height: 140px;
                }
            }

            .single-popup > .wrapper {
                max-width: 600px;
                min-height: 384px;
                border-radius: 16px;

                @include respond-below('sm') {
                    max-width: 100%;
                }

                .loading-panel {
                    font-size: var(--font-size-nano);

                    .content-loader {
                        height: 40px;
                        margin-bottom: 18px;
                    }
                }

                .elements {
                    .not-valid {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 10px 20px;
                        width: 100%;
                        background: linear-gradient(0deg, rgb(227 6 19 / 0.04), rgb(227 6 19 / 0.04)), #fff;
                        border-radius: 8px;
                        font-size: var(--font-size-nano);
                        margin-bottom: 20px;

                        .invalid-icon {
                            height: 24px;
                            margin-right: var(--size-nano);
                            width: 24px;
                            flex-shrink: 0;

                            @include background-icon(exclamation-in-triangle);
                        }
                    }

                    .input-text {
                        width: 100%;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }

    &.error {
        .container {
            .object-block {
                border: 2px solid var(--system-color-error-default);
                padding: 21px;
            }
        }
    }
}
</style>
