export enum FieldNames {
    AccidentDate = 'accidentDate',
    AccidentTime = 'accidentTime',
    AccidentCountry = 'accidentCountry',
    SufferingPerson = 'sufferingPerson',
    SufferingPersonOtherIsResident = 'sufferingPersonOtherIsResident',
    SufferingPersonOtherName = 'sufferingPersonOtherName',
    SufferingPersonOtherSurname = 'sufferingPersonOtherSurname',
    SufferingPersonOtherPersonCode = 'sufferingPersonOtherPersonCode',
    SufferingPersonOtherBirthDate = 'sufferingPersonOtherBirthDate',
    SufferingPersonOtherPolicyNumber = 'sufferingPersonOtherPolicyNumber',
    Policy = 'policy',
    AccidentType = 'accidentType',
}
