<script lang="ts">
import { defineComponent, ref, Ref } from 'vue';
import OneBaseService from '@/services/OneBaseService';
import { useStepsSubmitter } from '@/Composables/StepsSubmitter';
import ProductBlockItem from '@/Components/Other/ProductBlock/Interfaces/ProductBlockItemInterface';
import ProductBlockCoveredPopup from '@/Components/Other/ProductBlock/classes/ProductBlockCoveredPopup';
import { Subscription } from 'rxjs';
import { useScroll } from '@/Composables/Scroll';
import Url from '@/Enums/UrlEnum';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import OneDayPolicyAdapter from '@/Components/Other/ProductBlock/adapters/OneDayPolicyAdapter';
import Error from '@/services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import { AxiosParams, useAxios } from '@/Composables/Axios';
import { useTranslate } from '@/Composables/Translate';
import ProductBlockItemBuilder from '@/Components/Other/ProductBlock/Builders/ProductBlockItemBuilder';
import FormField from '@/assets/libraries/form/form-field';
import Form from '@/assets/libraries/form/form';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const { translate } = useTranslate();
        const request: AxiosParams = useAxios();
        const stepsSubmitter = useStepsSubmitter();

        const CurrentStep: number = 1;
        const Facility: string = 'accident-single-day';
        const ProductsContainerId: string = 'accidentSingleDayProducts';
        const coveredPopup: ProductBlockCoveredPopup = new ProductBlockCoveredPopup();
        const form: Form = new Form();
        const programs: Ref<ProductBlockItem[]> = ref([]);
        const program: Ref<string> = ref('');

        function onAppReady(): void {
            btaBase.dynamicStepper.enableAll();
            request
                .get(Url.Ajax.oneDayPolicies, {})
                .then((response: DynamicDictionary): void => {
                    const policies: DynamicDictionary = response.data.data.body;
                    buildProductBlocks(policies.policies);
                    coveredPopup.buildCoveredPopup(
                        new OneDayPolicyAdapter().transformed(policies.policies, translate('what_covered_one_day')),
                    );
                    form.addField(new FormField('product-panel-blocks'));
                    form.setReady();
                    stepsSubmitter.addForm(form);
                })
                .catch((reason: DynamicDictionary): void => {
                    Error.log(ErrorType.Error, 'Accident Single Day / Policy APP / onAppReady', reason);
                });
        }

        function onSubmitStep(): void {
            useScroll().scrollToId(ProductsContainerId).then();
        }

        function onSelectProductClick(programId: string): void {
            program.value = programId;
            btaBase.captcha
                .executeCaptcha(submit)
                .then()
                .catch((reason: string) => {
                    Error.log(ErrorType.Error, 'onSubmitStep', reason, true);
                });
        }

        function onCustomProductClick(programId: string[]): void {
            showCoveredPopup(programId[0]);
        }

        function submit(token: string): void {
            prepareSubmit(token);
            stepsSubmitter.proceedStep('', 0);
        }

        function prepareSubmit(token: string): void {
            stepsSubmitter.addSubmitCustomParam('nextStep', btaBase.nextStep());
            stepsSubmitter.addSubmitCustomParam('facility', Facility);
            stepsSubmitter.addSubmitCustomParam('product', program.value);
            stepsSubmitter.addSubmitCustomParam('g-recaptcha-response', token);
        }

        function buildProductBlocks(products: DynamicDictionary): void {
            products.forEach((policy: DynamicDictionary): void => {
                const productBlockItemBuilder: ProductBlockItemBuilder = new ProductBlockItemBuilder();
                const productName: string = translate('one_day_product_' + policy.id);
                const terms: DynamicDictionary = policy.paymentTerms[0];
                const price: number = terms.prices[0].price;
                const discountPostfix: string = translate('euro_per_day');
                const customModule: string = 'AppProductBlockInnerOneDayPolicy';
                const selectButtonText: string = translate('one_day_select');
                const customButtonText: string = translate('see_what_covered');
                productBlockItemBuilder
                    .withProductId(policy.id)
                    .withProductName(productName)
                    .withDiscountSum(price)
                    .withDiscountSumPostfix(discountPostfix)
                    .withDynamicContent(customModule, featuredRisks(terms.risks))
                    .withButtonSelectTitle(selectButtonText)
                    .withButtonCustomTitle(customButtonText);
                if (policy.badge !== '') {
                    productBlockItemBuilder.withBadgeText(policy.badge);
                }
                programs.value.push(productBlockItemBuilder.build());
            });
        }

        function featuredRisks(risks: DynamicDictionary[]): DynamicDictionary[] {
            const result: DynamicDictionary[] = [];
            risks.forEach((risk: DynamicDictionary): void => {
                if (risk.isFeatured) {
                    result.push(risk);
                }
            });

            return result;
        }

        function showCoveredPopup(id: string): void {
            coveredPopup.show(id);
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                stepsSubmitter,
                programs,
                form,
                coveredPopup,
                onAppReady,
                onSubmitStep,
                onSelectProductClick,
                onCustomProductClick,
                submit,
            },
        };
    },

    mounted() {
        this.applyApp(this);
        this.initBtaBase();

        if (this.captcha.isEnabled()) {
            this.captcha.renderCaptcha();
        }
        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        const onAppIsPreparedAndReady: Subscription = this.onAppIsPreparedAndReady.subscribe((): void => {
            this.onAppReady();
            onAppIsPreparedAndReady.unsubscribe();
        });
    },
});
</script>
