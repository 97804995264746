<script setup lang="ts">
import { usePrice } from '@/Composables/Price';
import { useTranslate } from '@/Composables/Translate';
import AppButtonWithCallback from '@/Components/Buttons/ButtonWithCallback/ButtonWithCallback.vue';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';

const props = defineProps({
    amount: { default: null, type: Number },
    callback: { default: (): void => {}, type: Function },
    headingLevel: { default: 2, type: Number },
    translationType: { default: '', type: String },
    useHeading: { default: true, type: Boolean },
    wrapperClasses: { default: null, type: String },
    wrapperElement: { default: 'section', type: String },
    wrapperId: { default: null, type: String },
});

const { sparse } = usePrice();
const { translateForType } = useTranslate();
</script>

<template>
    <component :is="`h${headingLevel}`" v-if="useHeading" class="subscribe-and-pay-heading">
        {{ translateForType('subscription_payment', translationType) }}
    </component>
    <component :is="wrapperElement" :id="wrapperId" :class="wrapperClasses" class="subscribe-and-pay">
        <div class="title">
            <p class="text">
                {{ translateForType('subscription_payment_details', translationType) }}
            </p>
            <div class="card-logos">
                <img src="/images/one/bank/visa_title.svg" alt="" />
                <img src="/images/one/bank/master_card_title.svg" alt="" />
            </div>
        </div>
        <div class="amount">
            <p class="label">
                {{ translateForType('subscription_amount_label', translationType) }}
            </p>
            <p class="value">
                <strong>{{ sparse(amount, false) }} <span class="currency">€</span></strong>
            </p>
        </div>
        <app-button-with-callback
            :background-color="ButtonBackground.Red"
            :text-color="ButtonTextColor.White"
            :title="translateForType('confirm_and_pay', translationType)"
            @button-callback-click="callback as any"
        ></app-button-with-callback>
    </component>
</template>

<style lang="scss" scoped>
.subscribe-and-pay-heading {
    line-height: 2.4rem;
    margin-bottom: var(--size-small);
    text-align: center;
}

.subscribe-and-pay {
    display: flex;
    flex-direction: column;
    gap: var(--size-small);
    width: 100%;

    > * {
        width: 100%;
    }

    button {
        min-height: 52px;
    }

    p {
        color: var(--black-950);
    }

    .amount {
        background-color: var(--teal-50);
        border-radius: var(--size-pico);
        display: flex;
        font-size: var(--font-size-nano);
        padding: var(--size-tiny) var(--size-small);

        .label {
            align-self: center;
            flex: auto;
            font-size: var(--font-size-nano);
        }

        .value {
            font-size: var(--size-normal);

            .currency {
                font-size: var(--font-size-small);
            }
        }
    }

    .title {
        display: flex;
        flex-direction: column;
        gap: var(--size-small);

        @include respond-above('sm') {
            flex-direction: row;
        }

        .card-logos {
            column-gap: 0.5rem;
            display: inline-flex;
            height: var(--size-medium);

            img {
                height: 100%;
            }
        }

        .text {
            flex: auto;
            font-size: var(--font-size-small);
            font-weight: 700;
        }
    }
}
</style>
