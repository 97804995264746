import { computed, ComputedRef } from 'vue';
import { Router, useRouter } from 'vue-router';

export const useStepper = (): StepperParams => {
    const router: Router = useRouter();
    const currentStepNumber = (): ComputedRef<number> => {
        return computed(() => {
            return stepNumber((router.currentRoute.value.name as string) || '');
        });
    };

    const stepNumber = (name: string): number => router.options.routes.findIndex((route) => route.name === name) + 1;

    return {
        currentStepNumber,
        stepNumber,
    };
};

export interface StepperParams {
    currentStepNumber: () => ComputedRef<number>;
    stepNumber: (name: string) => number;
}
