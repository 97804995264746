<script setup lang="ts">
import ClaimsMtplService from '@/Apps/ClaimsMtpl/Services/ClaimsMtplService';
import Form from '@/assets/libraries/form/form';
import { computed, onMounted, reactive, ref, Ref, UnwrapNestedRefs } from 'vue';
import FormField from '@/assets/libraries/form/form-field';
import { TranslateReplaceParts, useTranslate } from '@/Composables/Translate';
import { useClaimsMtplHtml } from '@/Apps/ClaimsMtpl/Composables/ClaimsMtplHtml';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import OneBase from '@/interfaces/OneBaseInterface';
import OneBaseService from '@/services/OneBaseService';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import { InputOption } from '@/interfaces/InputOptionInterface';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonIconPosition from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.position.enum';
import ButtonIcon from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.enum';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import ButtonWithCallbackParams from '@/Components/Buttons/ButtonWithCallback/Enums/button.params';
import ClaimsMtplOptions from '@/Apps/ClaimsMtpl/Interfaces/ClaimsMtplOptionsInterface';
import { useStrings } from '@/Composables/Strings';
import VehicleDamageNames from '@/Apps/ClaimsMtpl/Enums/Damage/VehicleDamageNames';
import DamageTypes from '@/Apps/ClaimsMtpl/Enums/DamageTypesEnum';
import { SubFlowEvent } from '@/Apps/ClaimsMtpl/Interfaces/SubFlowEvent';
import Translations from '@/services/translations.service';
import ClaimsMtplClaimTypes from '@/Apps/ClaimsMtpl/Enums/ClaimsMtplClaimTypes';
import Value from '@/assets/libraries/form/value';
import ClaimsMtplFormFields from '@/Apps/ClaimsMtpl/Classes/ClaimsMtplFormFields';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import { useDefine } from '@/Composables/Define';
import moment from 'moment/moment';
import Validation from '@/services/validation.service';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import MtplClaimsAjaxCalls from '@/Apps/ClaimsMtpl/Enums/MtplClaimsAjaxCallsEnum';
import { AxiosResponse } from 'axios';
import DateRange from '@/interfaces/date.range.interface';
import RequestService from '@/services/request.service';
import ClaimsMtplObject from '@/Apps/ClaimsMtpl/Interfaces/ClaimsMtplObjectInterface';
import Sanitizer from '@/services/sanitizer.service';
import AccidentDataPanelsNames from '@/Apps/ClaimsMtpl/Enums/AccidentDataPanelsNamesEnum';
import AppCountry from '@/assets/libraries/app/app-country';
import InputDateLayout from '@/Components/Inputs/InputDate/InputDateLayout';
import ClaimsMtplOption from '@/Apps/ClaimsMtpl/Interfaces/ClaimsMtplOptionInterface';
import type { SanitizerCallback } from '@/assets/libraries/form/form-field';

const props = defineProps({
    dataScroll: { type: String, default: () => '' },
});
const emit = defineEmits<{
    (event: 'completed', subFlowEvent: SubFlowEvent): void;
    (event: 'proceedAgreedStatementChange', selected: string): void;
    (event: 'lastPanel', dataScroll: string): void;
    (event: 'change'): void;
}>();

const { translate, translateForType } = useTranslate();
const { isSet } = useDefine();

const btaBase: OneBase = OneBaseService.getInstance();
const claimsMtplService: ClaimsMtplService = ClaimsMtplService.getInstance();
const requestService: RequestService = RequestService.getInstance();

const form: Form = new Form();
const inputOptions: UnwrapNestedRefs<ClaimsMtplOptions> = reactive({});
const canClearFormsAhead: Ref<boolean> = ref(false);
const isSearchInProgress: Ref<boolean> = ref(false);
const electronicStatementAddress: Ref<string> = ref('');
const registrationNumbersWithNotFoundVehicles: Ref<string[]> = ref([]);
const showVehicleRegistrationNumberError: Ref<boolean> = ref(false);
const vehicleRegistrationNumberError: Ref<string> = ref('');

const residentOptionValue: string = 'Y';
const nonResidentOptionValue: string = 'N';
const agreedStatementProceedOptionValue: string = 'yes';
const accidentHandledAgreedStatementOptionValue: string = 'agreed_statement';
const accidentTypeAgreedStatement: string = 'AGREED_STATEMENT';

const dateLayout: Ref<string> = computed(() => {
    return new AppCountry().isLT() ? InputDateLayout.yearMonthDay : InputDateLayout.dayMonthYear;
});

const accidentEventDateTime: Ref<string> = computed(() => {
    const date: DynamicDictionary = claimsMtplService.fields.whenEventHappened!;
    const formattedDate: string = moment(new Date((date as DateRange).startDate)).format('YYYY-MM-DD');
    const time: string = claimsMtplService.fields.whatTimeDidItHappened!;
    const formattedTime: string = moment(time, 'HH:mm').format('HH:mm:ss');

    return `${formattedDate} ${formattedTime}`;
});

const accidentEventTimestamp: Ref<string> = computed(() => {
    return moment(accidentEventDateTime.value, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss');
});

const accidentRegistryDataRequestParams: Ref<DynamicDictionary> = computed(() => {
    return {
        accidentDate: accidentEventTimestamp.value,
        claimType: claimsMtplService.fields.typeOfClaim?.selected,
        selectedObjectId: claimsMtplService.fields.whatVehicleWasInvolved!.object,
        otherVehicleRegistrationNumber: form.field(VehicleDamageNames.VehicleRegistrationNumber).value,
    };
});

const vehicleRegistryDataRequestParams: Ref<DynamicDictionary> = computed(() => {
    return {
        date: accidentEventTimestamp.value,
        registrationNumber: form.field(VehicleDamageNames.VehicleRegistrationNumber).value,
    };
});

const showResidentDriverDateInput: Ref<boolean> = computed(() => {
    return btaBase.settings.localeIso() === 'LV'
        ? /^3[2-9]/.test(form.field(VehicleDamageNames.ResidentDriverPersonCode).value)
        : false;
});

const vehicleDriverSubformVisible: Ref<boolean> = computed(() => {
    return form.field(VehicleDamageNames.VehicleDriverIsApplicant).value.selected === 'no';
});

const vehicleDriverBlockFilled: Ref<boolean> = computed(() => {
    const residentFormFilled: boolean =
        basicFieldIsValid(VehicleDamageNames.ResidentDriverName) &&
        basicFieldIsValid(VehicleDamageNames.ResidentDriverSurname) &&
        basicFieldIsValid(VehicleDamageNames.ResidentDriverPersonCode) &&
        (!showResidentDriverDateInput.value || basicFieldIsValid(VehicleDamageNames.ResidentDriverBirthDate));
    const nonResidentFormFilled: boolean =
        basicFieldIsValid(VehicleDamageNames.NonResidentDriverName) &&
        basicFieldIsValid(VehicleDamageNames.NonResidentDriverSurname) &&
        basicFieldIsValid(VehicleDamageNames.NonResidentDriverBirthDate) &&
        basicFieldIsValid(VehicleDamageNames.NonResidentDriverLicenseNumber);
    const vehicleDriverSubformFilled: boolean =
        (form.field(VehicleDamageNames.VehicleDriverResident).value === residentOptionValue && residentFormFilled) ||
        (form.field(VehicleDamageNames.VehicleDriverResident).value === nonResidentOptionValue &&
            nonResidentFormFilled);

    return (
        basicFieldIsValid(VehicleDamageNames.VehicleDriverIsApplicant) &&
        (!vehicleDriverSubformVisible.value || vehicleDriverSubformFilled)
    );
});

const vehicleDamagedBeforeAccident: Ref<boolean> = computed(() => {
    return form.field(VehicleDamageNames.VehicleDamagedBeforeAccident).value.selected === 'yes';
});

const vehicleDamagedBeforeBlockFilled: Ref<boolean> = computed(() => {
    return (
        basicFieldIsValid(VehicleDamageNames.VehicleDamagedBeforeAccident) &&
        (!vehicleDamagedBeforeAccident.value || basicFieldIsValid(VehicleDamageNames.DamageBeforeAccident))
    );
});

const isDirectClaimFlow: Ref<boolean> = computed(() => {
    return claimsMtplService.fields.typeOfClaim?.selected === ClaimsMtplClaimTypes.DirectClaim;
});

const isGuiltyFlow: Ref<boolean> = computed(() => {
    return claimsMtplService.fields.typeOfClaim?.selected === ClaimsMtplClaimTypes.Guilty;
});

const isVictimFlow: Ref<boolean> = computed(() => {
    return claimsMtplService.fields.typeOfClaim?.selected === ClaimsMtplClaimTypes.Victim;
});

const showVehicleRegistrationNumberBlock: Ref<boolean> = computed(() => {
    return isGuiltyFlow.value || isVictimFlow.value;
});

const digitalAgreedStatementSelected: Ref<boolean> = computed(() => {
    return (
        claimsMtplService.fields.weFoundElectronically?.selected === agreedStatementProceedOptionValue ||
        form.field(VehicleDamageNames.ProceedWithAgreedStatement).value.selected === agreedStatementProceedOptionValue
    );
});

const showAgreedStatementLabelBlock: Ref<boolean> = computed(() => {
    return (
        btaBase.settings.localeIso() === 'LV' &&
        !digitalAgreedStatementSelected.value &&
        claimsMtplService.fields.howWasInvolvedHandled?.selected === accidentHandledAgreedStatementOptionValue
    );
});

const showVehicleInDrivableConditionBlock: Ref<boolean> = computed(() => {
    return isVictimFlow.value || isDirectClaimFlow.value;
});

const showVehicleDamagedBeforeAccident: Ref<boolean> = computed(() => {
    return isVictimFlow.value || isDirectClaimFlow.value;
});

const nextPanelNameVehicleRegistrationNumberBlock: Ref<string> = computed(() => {
    return isDirectClaimFlow.value
        ? showAgreedStatementLabelBlock.value
            ? VehicleDamageNames.AgreedStatementLabel
            : VehicleDamageNames.VehicleDriverIsApplicant
        : VehicleDamageNames.ProceedWithAgreedStatement;
});

const nextPanelNameProceedWithAgreedStatementBlock: Ref<string> = computed(() => {
    return showAgreedStatementLabelBlock.value
        ? VehicleDamageNames.AgreedStatementLabel
        : showVehicleInDrivableConditionBlock.value
          ? VehicleDamageNames.VehicleIsDrivable
          : VehicleDamageNames.VehicleDriverIsApplicant;
});

const nextPanelAgreedStatementLabelBlock: Ref<string> = computed(() => {
    return showVehicleInDrivableConditionBlock.value
        ? VehicleDamageNames.VehicleIsDrivable
        : VehicleDamageNames.VehicleDriverIsApplicant;
});

const nextPanelVehicleIsDrivableBlock: Ref<string> = computed(() => {
    return digitalAgreedStatementSelected.value
        ? VehicleDamageNames.VehicleDamagedBeforeAccident
        : VehicleDamageNames.VehicleDriverIsApplicant;
});

const nextPanelVehicleDriverIsApplicant: Ref<string> = computed(() => {
    return showVehicleDamagedBeforeAccident.value ? VehicleDamageNames.VehicleDamagedBeforeAccident : '';
});

const proceedWithDigitalAgreedStatementTitle: Ref<string> = computed(() => {
    const replacements: DynamicDictionary = {
        '%address%': electronicStatementAddress.value,
    };

    return localized('vehicle_damage_proceed_with_agreed_statement_title', replacements);
});

const involvedVehicle: Ref<ClaimsMtplObject> = computed(() => {
    return claimsMtplService.insuredObjects.value.find(
        (object: ClaimsMtplObject) => object.id === claimsMtplService.fields.whatVehicleWasInvolved!.object,
    )!;
});

const buildInitialOptions = (): void => {
    buildVehicleDriverAgreedStatementProceedOptions();
    buildVehicleDriverAgreedStatementLabelOptions();
    buildVehicleIsDrivableOptions();
    buildVehicleDriverIsApplicantOptions();
    buildVehicleDriverOptions();
    buildVehicleDamagedBeforeAccidentOptions();
};

function preparePanels(): void {
    Object.keys(VehicleDamageNames).forEach((key: string) => {
        const panelName: string = VehicleDamageNames[key as keyof VehicleDamageNames];
        if (isPanelName(panelName)) {
            inputOptions[panelName] = reactive(
                new (class implements ClaimsMtplOption {
                    public enabled: boolean = true;
                    public passed: boolean = false;
                    public visible: boolean = false;
                    public value: LimitedVariant = '';
                    public options: InputOption[] = [];
                })(),
            );
        }
    });
}

function basicFieldIsValid(field: string): boolean {
    return form.field(field).isValid && form.field(field).isNotEmpty();
}

function optionsFieldIsValid(field: string): boolean {
    return basicFieldIsValid(field) && form.field(field).value?.selected !== '';
}

function isPanelName(name: string): boolean {
    return ![
        VehicleDamageNames.ResidentDriverName,
        VehicleDamageNames.ResidentDriverSurname,
        VehicleDamageNames.ResidentDriverPersonCode,
        VehicleDamageNames.ResidentDriverBirthDate,
        VehicleDamageNames.NonResidentDriverName,
        VehicleDamageNames.NonResidentDriverSurname,
        VehicleDamageNames.NonResidentDriverBirthDate,
        VehicleDamageNames.NonResidentDriverLicenseNumber,
        VehicleDamageNames.DamageBeforeAccident,
    ].includes(name);
}

function enableFirstPanel(): void {
    if (showVehicleRegistrationNumberBlock.value) {
        inputOptions[VehicleDamageNames.VehicleRegistrationNumber].visible = true;
    } else if (showAgreedStatementLabelBlock.value) {
        inputOptions[VehicleDamageNames.AgreedStatementLabel].visible = true;
    } else {
        inputOptions[VehicleDamageNames.VehicleIsDrivable].visible = true;
    }
}

function buildVehicleDriverIsApplicantOptions(): void {
    inputOptions[VehicleDamageNames.VehicleDriverIsApplicant].options = [
        new InputOptionBuilder().setName(translate('btar_yes')).setValue('yes').build(),
        new InputOptionBuilder().setName(translate('btar_no')).setValue('no').build(),
    ];
}

function buildVehicleDriverOptions(): void {
    inputOptions[VehicleDamageNames.VehicleDriverResident].options = [
        new InputOptionBuilder().setName(localized('resident')).setValue(residentOptionValue).build(),
        new InputOptionBuilder().setName(localized('non_resident')).setValue(nonResidentOptionValue).build(),
    ];
}

function buildVehicleDriverAgreedStatementProceedOptions(): void {
    inputOptions[VehicleDamageNames.ProceedWithAgreedStatement].options = [
        new InputOptionBuilder().setName(translate('btar_yes')).setValue('yes').build(),
        new InputOptionBuilder().setName(translate('btar_no')).setValue('no').build(),
    ];
}

function buildVehicleDriverAgreedStatementLabelOptions(): void {
    inputOptions[VehicleDamageNames.AgreedStatementLabel].options = [
        new InputOptionBuilder().setName(localized('agreed_statement_label_a')).setValue('A').build(),
        new InputOptionBuilder().setName(localized('agreed_statement_label_b')).setValue('B').build(),
    ];
}

function buildVehicleIsDrivableOptions(): void {
    inputOptions[VehicleDamageNames.VehicleIsDrivable].options = [
        new InputOptionBuilder().setName(translate('btar_yes')).setValue('yes').build(),
        new InputOptionBuilder().setName(translate('btar_no')).setValue('no').build(),
    ];
}

function buildVehicleDamagedBeforeAccidentOptions(): void {
    inputOptions[VehicleDamageNames.VehicleDamagedBeforeAccident].options = [
        new InputOptionBuilder().setName(translate('btar_yes')).setValue('yes').build(),
        new InputOptionBuilder().setName(translate('btar_no')).setValue('no').build(),
    ];
}

function proceedButton(): ButtonWithCallbackParams {
    return {
        title: localized('proceed'),
        textColor: ButtonTextColor.White,
        backgroundColor: ButtonBackground.Red,
        icon: ButtonIcon.LongArrowRight,
        iconPosition: ButtonIconPosition.Right,
    };
}

function proceedButtonIsDisabled(panelName: string): boolean {
    return !inputOptions[panelName].passed;
}

function registrationNumberProceedIsDisabled(panelName: string): boolean {
    return (
        registrationNumbersWithNotFoundVehicles.value.includes(
            form.field(VehicleDamageNames.VehicleRegistrationNumber).value,
        ) || proceedButtonIsDisabled(panelName)
    );
}

function isPanelVisible(panelName: string): boolean {
    return inputOptions[panelName].visible && inputOptions[panelName].enabled;
}

function isInnerPanel(panelName: string): boolean {
    return isPanelName(panelName) && [VehicleDamageNames.VehicleDriverResident].includes(panelName);
}

function onFormPanelInputChange(panel: string): void {
    onRegistrationNumberChange();
    clearFormsAhead(panel);
    calculateEnabledPanels();
    form.validate().then(() => {
        calculatePanelsPass();
    });
}

function calculateEnabledPanels(): void {
    const proceedWithAgreedStatementSelected: boolean =
        form.field(VehicleDamageNames.ProceedWithAgreedStatement).value.selected === 'yes';

    inputOptions[VehicleDamageNames.VehicleRegistrationNumber].enabled = showVehicleRegistrationNumberBlock.value;
    inputOptions[VehicleDamageNames.ProceedWithAgreedStatement].enabled = false;
    if (claimsMtplService.electronicStatement.value) {
        const electronicStatement: DynamicDictionary = claimsMtplService.electronicStatement.value as DynamicDictionary;
        inputOptions[VehicleDamageNames.ProceedWithAgreedStatement].enabled =
            electronicStatement.electronicallySigned &&
            electronicStatement.accidentType === accidentTypeAgreedStatement &&
            !isDirectClaimFlow.value;
    }
    inputOptions[VehicleDamageNames.AgreedStatementLabel].enabled =
        showAgreedStatementLabelBlock.value && !proceedWithAgreedStatementSelected;
    inputOptions[VehicleDamageNames.VehicleIsDrivable].enabled =
        showVehicleInDrivableConditionBlock.value && !proceedWithAgreedStatementSelected;
    inputOptions[VehicleDamageNames.VehicleDriverIsApplicant].enabled =
        !digitalAgreedStatementSelected.value && !proceedWithAgreedStatementSelected;
    inputOptions[VehicleDamageNames.VehicleDamagedBeforeAccident].enabled =
        showVehicleDamagedBeforeAccident.value && !proceedWithAgreedStatementSelected;
}

function clearFormsAhead(panel: string): void {
    let clear: boolean = false;
    Object.keys(inputOptions).forEach((panelKey: string) => {
        if (clear) {
            applyResetOnOption(panelKey);
            clearFormValue(panelKey);
        }
        if (panelKey === panel) {
            clear = true;
            inputOptions[panelKey].passed = false;
        }
    });
}

function applyResetOnOption(panel: string): void {
    inputOptions[panel].value = '';
    inputOptions[panel].passed = false;
    inputOptions[panel].visible = false;
    form.field(panel).clear().then();
}

function clearFormValue(panel: string): void {
    form.field(panel).clear();
    if (panel === VehicleDamageNames.VehicleDriverIsApplicant) {
        form.field(VehicleDamageNames.ResidentDriverName).clear();
        form.field(VehicleDamageNames.ResidentDriverSurname).clear();
        form.field(VehicleDamageNames.ResidentDriverPersonCode).clear();
        form.field(VehicleDamageNames.ResidentDriverBirthDate).clear();
        form.field(VehicleDamageNames.NonResidentDriverName).clear();
        form.field(VehicleDamageNames.NonResidentDriverSurname).clear();
        form.field(VehicleDamageNames.NonResidentDriverBirthDate).clear();
        form.field(VehicleDamageNames.NonResidentDriverLicenseNumber).clear();
    }
    if (panel === VehicleDamageNames.VehicleDamagedBeforeAccident) {
        form.field(VehicleDamageNames.DamageBeforeAccident).clear();
    }
}

function calculatePanelsPass(): void {
    const fieldsWithOptions: string[] = [
        VehicleDamageNames.ProceedWithAgreedStatement,
        VehicleDamageNames.AgreedStatementLabel,
        VehicleDamageNames.VehicleIsDrivable,
        VehicleDamageNames.VehicleDriverIsApplicant,
        VehicleDamageNames.VehicleDriverResident,
        VehicleDamageNames.VehicleDamagedBeforeAccident,
    ];
    form.validate().then(() => {
        Object.keys(inputOptions).forEach((panelKey: string) => {
            let passed: boolean;
            if (panelKey === VehicleDamageNames.VehicleDriverIsApplicant) {
                passed = vehicleDriverBlockFilled.value;
            } else if (panelKey === VehicleDamageNames.VehicleDamagedBeforeAccident) {
                passed = vehicleDamagedBeforeBlockFilled.value;
            } else if (fieldsWithOptions.includes(panelKey)) {
                passed = optionsFieldIsValid(panelKey);
            } else {
                passed = basicFieldIsValid(panelKey);
            }
            inputOptions[panelKey].passed = passed;
        });
    });
}

function calculatePanelsVisibility(): void {
    let previousPanel: string = '';
    Object.keys(inputOptions)
        .filter((panelKey: string): boolean => !isInnerPanel(panelKey))
        .forEach((panelKey: string): void => {
            if (previousPanel !== '' && inputOptions[previousPanel].enabled) {
                if (previousPanel === VehicleDamageNames.VehicleRegistrationNumber) {
                    inputOptions[panelKey].visible = claimsMtplService.vehicleRegistryData.value !== null;
                } else {
                    inputOptions[panelKey].visible = inputOptions[previousPanel].passed;
                }
            }
            if (inputOptions[panelKey].enabled) {
                previousPanel = panelKey;
            }
        });
}

function setupForm(): void {
    const validators: (string | Record<string, any>)[] = [
        registrationNumberValidator(),
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        personCodeValidator(),
        birthDateValidator(),
        '',
        '',
        birthDateValidator(),
        '',
        '',
        descriptionValidator(),
    ];
    const sanitizers: (SanitizerCallback<string> | undefined)[] = [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        Sanitizer.cleanName,
        Sanitizer.cleanName,
        Sanitizer.clearPersonCode,
        undefined,
        Sanitizer.cleanName,
        Sanitizer.cleanName,
        undefined,
        Sanitizer.cleanDriversLicenseNumber,
        undefined,
        undefined,
    ];
    Object.keys(VehicleDamageNames).forEach((field: string, index: number) => {
        form.addField(
            new FormField(
                VehicleDamageNames[field as keyof VehicleDamageNames],
                '',
                validators[index],
                sanitizers[index],
            ),
        );
    });
    form.setReady();
}

function registrationNumberValidator(): object {
    return {
        registrationNumberFound: (value: string) => {
            return !registrationNumbersWithNotFoundVehicles.value.includes(value);
        },
        registrationNumberIsNotSameAsOtherParty: (value: string) => {
            return value !== involvedVehicle.value.plateNumber;
        },
    };
}

function personCodeValidator(): object {
    return {
        personCodeIsValid: (value: string) => {
            return Validation.isValidNaturalPersonCode(value);
        },
    };
}

function birthDateValidator(): object {
    return {
        birthDateIsValid: (value: string): boolean => {
            let result: boolean = true;
            if (value !== '') {
                result = moment(value).isValid();
            }

            return result;
        },
    };
}

function descriptionValidator(): object {
    return {
        descriptionIsValid: (value: string): boolean => {
            return !(stringOutOfBounds(value) || stringHasInvalidText(value));
        },
    };
}

function stringOutOfBounds(text: string): boolean {
    const multibyteLength: number = useStrings().multibyteLength(text);
    const minLength: number = btaBase.settings.claimsSettings().mtpl.descriptionFieldMinLength;
    const maxLength: number = btaBase.settings.claimsSettings().mtpl.descriptionFieldMaxLength;

    return multibyteLength < minLength || multibyteLength > maxLength;
}

function stringHasInvalidText(text: string): boolean {
    return !useStrings().isValidWordString(text);
}

function vehicleRegistrationNumberTitle(): string {
    return isGuiltyFlow.value
        ? localized('victim_vehicle_damage_registration_number')
        : localized('vehicle_damage_registration_number');
}

function vehicleDamageDriverIsApplicantTitle(): string {
    return isGuiltyFlow.value
        ? localized('vehicle_damage_guilty_party_driver_is_applicant_title')
        : localized('vehicle_damage_victim_driver_is_applicant_title');
}

function storeFormToService(): void {
    Object.keys(VehicleDamageNames).forEach((field: string) => {
        const enumKey: string = VehicleDamageNames[field as keyof VehicleDamageNames];
        const serviceKey: string = 'vehicle' + enumKey.charAt(0).toUpperCase() + enumKey.slice(1);
        claimsMtplService.fields[serviceKey as keyof ClaimsMtplFormFields] = form.field(enumKey).value;
    });
}

function restoreValues(): void {
    const storedValues: DynamicDictionary = claimsMtplService.fields;
    Object.keys(VehicleDamageNames).forEach((field: string) => {
        const formKey: string = VehicleDamageNames[field as keyof VehicleDamageNames];
        const serviceKey: string = 'vehicle' + formKey.charAt(0).toUpperCase() + formKey.slice(1);
        if (isSet(storedValues[serviceKey]) && new Value(storedValues[serviceKey]).isNotEmpty()) {
            form.field(formKey).setValue(storedValues[serviceKey]);
        }
    });
}

function localized(stringUid: string, replaceParts?: TranslateReplaceParts): string {
    return translateForType(stringUid, Translations.getInstance().type, replaceParts);
}

function updateWeFoundElectronicallyTitle(): void {
    if (claimsMtplService.electronicStatement.value) {
        electronicStatementAddress.value = claimsMtplService.electronicStatement.value.address.text;
    }
}

function fetchVehicleRegistryData(): Promise<void> {
    claimsMtplService.vehicleRegistryData.value = null;
    PopupService.getInstance().show(new OnePopup().withType().loading);
    isSearchInProgress.value = true;

    return requestService
        .get({
            uri: MtplClaimsAjaxCalls.VehicleRegistryData,
            content: vehicleRegistryDataRequestParams.value,
        })
        .then((response: AxiosResponse<DynamicDictionary>): void | Promise<void> => {
            if (isValidVehicleRegistryDataResponse(response)) {
                claimsMtplService.vehicleRegistryData.value = response.data.data.body.response;
            } else {
                form.field(VehicleDamageNames.VehicleRegistrationNumber).touch();
                registrationNumbersWithNotFoundVehicles.value.push(
                    form.field(VehicleDamageNames.VehicleRegistrationNumber).value,
                );
                return Promise.reject();
            }
        })
        .catch((): Promise<void> => {
            onRegistrationNumberChange();
            return Promise.reject('Vehicle registry fetch failed');
        })
        .finally((): void => {
            PopupService.getInstance().hide().then();
            isSearchInProgress.value = false;
        });
}

function fetchAccidentRegistryData(): Promise<void> {
    PopupService.getInstance().show(new OnePopup().withType().loading);
    isSearchInProgress.value = true;
    claimsMtplService.electronicStatement.value = null;

    return requestService
        .get({
            uri: MtplClaimsAjaxCalls.AccidentRegistryData,
            content: accidentRegistryDataRequestParams.value,
        })
        .then((response: AxiosResponse<DynamicDictionary>): void => {
            if (isValidAccidentRegistryDataResponse(response)) {
                claimsMtplService.electronicStatement.value = response.data.data.body.response;
                updateWeFoundElectronicallyTitle();
                calculatePanelsPass();
            }
            calculateEnabledPanels();
        })
        .finally((): void => {
            PopupService.getInstance().hide().then();
            isSearchInProgress.value = false;
        });
}

function isValidAccidentRegistryDataResponse(response: DynamicDictionary): boolean {
    return (
        isSet(response.data) &&
        isSet(response.data.data.body) &&
        isSet(response.data.data.body.response) &&
        isSet(response.data.data.body.response.accidentType)
    );
}

function isValidVehicleRegistryDataResponse(response: DynamicDictionary): boolean {
    return (
        isSet(response.data) &&
        isSet(response.data.data.body) &&
        isSet(response.data.data.body.response) &&
        isSet(response.data.data.body.response.releaseYear)
    );
}

function previousPanelsPassed(panel: string): boolean {
    const panelIndex: number = Object.values(VehicleDamageNames).indexOf(panel);

    return Object.values(VehicleDamageNames)
        .slice(0, panelIndex)
        .filter((value: string): boolean => isPanelName(value) && inputOptions[value].enabled)
        .every((value: string): boolean => inputOptions[value].passed);
}

function mustFetchVehicleRegistryData(): boolean {
    return isVictimFlow.value && previousPanelsPassed(VehicleDamageNames.ProceedWithAgreedStatement);
}

function mustFetchAccidentRegistryData(): boolean {
    return !isDirectClaimFlow.value && previousPanelsPassed(VehicleDamageNames.ProceedWithAgreedStatement);
}

function restoreAccidentRegistryData(): Promise<void> {
    return new Promise((resolve): void => {
        if (mustFetchAccidentRegistryData()) {
            fetchAccidentRegistryData().finally(() => resolve());
        } else {
            updateWeFoundElectronicallyTitle();
            resolve();
        }
    });
}

function restoreVehicleRegistryData(): Promise<void> {
    return new Promise((resolve): void => {
        if (mustFetchVehicleRegistryData()) {
            fetchVehicleRegistryData().finally(() => resolve());
        } else {
            resolve();
        }
    });
}

function emitCompletedEvent(): void {
    emit('completed', {
        [DamageTypes.Vehicle]: {
            description: isDirectClaimFlow.value
                ? involvedVehicle.value.plateNumber
                : form.field(VehicleDamageNames.VehicleRegistrationNumber).value,
        },
    });
}

function onClickProceedWithAgreedStatement(): void {
    emit('proceedAgreedStatementChange', form.field(VehicleDamageNames.ProceedWithAgreedStatement).value.selected);

    if (form.field(VehicleDamageNames.ProceedWithAgreedStatement).value.selected !== 'yes') {
        onPanelProceedClick(nextPanelNameProceedWithAgreedStatementBlock.value);
    } else {
        emit('lastPanel', VehicleDamageNames.ProceedWithAgreedStatement);
    }
}

function onPanelProceedClick(panelName: string): void {
    if (panelName === '') {
        emitCompletedEvent();
        return;
    }
    (mustFetchAccidentRegistryData() ? fetchAccidentRegistryData() : Promise.resolve())
        .finally((): Promise<void> => {
            return mustFetchVehicleRegistryData() ? fetchVehicleRegistryData() : Promise.resolve();
        })
        .then((): void => {
            if (panelName === VehicleDamageNames.ProceedWithAgreedStatement && !inputOptions[panelName].enabled) {
                panelName = nextPanelNameProceedWithAgreedStatementBlock.value;
            }
            inputOptions[panelName].visible = true;
            emit('lastPanel', panelName);
            useClaimsMtplHtml().scrollToPanel(panelName);
        });
}

function onRegistrationNumberChange(): void {
    form.validate().then((): void => {
        showVehicleRegistrationNumberError.value =
            form.field(VehicleDamageNames.VehicleRegistrationNumber).value !== '' &&
            (form
                .field(VehicleDamageNames.VehicleRegistrationNumber)
                .hasError('registrationNumberIsNotSameAsOtherParty') ||
                form.field(VehicleDamageNames.VehicleRegistrationNumber).hasError('registrationNumberFound'));

        const vehicleRegistrationNumberErrorTranslationKey = form
            .field(VehicleDamageNames.VehicleRegistrationNumber)
            .hasError('registrationNumberIsNotSameAsOtherParty')
            ? 'same_plate_number_both_parties'
            : 'vehicle_not_found_enter_valid_plate_number';
        vehicleRegistrationNumberError.value = localized(vehicleRegistrationNumberErrorTranslationKey);
    });
}

function onChange(): void {
    emit('change');
    storeFormToService();
}

preparePanels();
buildInitialOptions();

onMounted(() => {
    setupForm();
    restoreValues();
    enableFirstPanel();
    form.validate()
        .then((): Promise<void> => {
            calculateEnabledPanels();
            calculatePanelsPass();
            calculatePanelsVisibility();

            return restoreAccidentRegistryData();
        })
        .finally((): void => {
            calculateEnabledPanels();
            restoreVehicleRegistryData();
        });
});
defineExpose({
    claimsMtplService,
});
</script>

<template>
    <div class="container">
        <app-custom-form v-if="form.isReady()" :form="form" :data-scroll="dataScroll" class="form" @change="onChange()">
            <div class="whiteboard-panel whiteboard-panel-margin">
                <label>{{ localized('vehicle_damage_title') }}</label>
                <div
                    v-if="isPanelVisible(VehicleDamageNames.VehicleRegistrationNumber)"
                    class="whiteboard"
                    :data-type="'whiteboard-damage-0'"
                >
                    <h4 class="title">{{ vehicleRegistrationNumberTitle() }}</h4>
                    <app-input-plate-number
                        :form-field="form.field(VehicleDamageNames.VehicleRegistrationNumber)"
                        :placeholder="localized('vehicle_damage_registration_number_placeholder')"
                        :feedback-message="vehicleRegistrationNumberError"
                        :with-hint-icon="true"
                        :disable-error-text="!showVehicleRegistrationNumberError"
                        @change="onRegistrationNumberChange"
                        @keyup="onFormPanelInputChange(VehicleDamageNames.VehicleRegistrationNumber)"
                    >
                    </app-input-plate-number>
                    <app-button-with-callback
                        class="button"
                        data-type="vehicle-damage-submit"
                        v-bind="proceedButton()"
                        :disabled="registrationNumberProceedIsDisabled(VehicleDamageNames.VehicleRegistrationNumber)"
                        @button-callback-click="onPanelProceedClick(nextPanelNameVehicleRegistrationNumberBlock)"
                    >
                    </app-button-with-callback>
                </div>
            </div>
            <div class="whiteboard-panel">
                <div
                    v-if="isPanelVisible(VehicleDamageNames.ProceedWithAgreedStatement)"
                    class="whiteboard"
                    :data-type="'whiteboard-damage-1'"
                    :data-scroll="VehicleDamageNames.ProceedWithAgreedStatement"
                >
                    <h4 class="title">{{ proceedWithDigitalAgreedStatementTitle }}</h4>
                    <app-options-smart-list
                        :type="'radio'"
                        :option-class="'filled'"
                        :required="true"
                        :show-error-borders="false"
                        :options="inputOptions[VehicleDamageNames.ProceedWithAgreedStatement].options"
                        :form-field="form.field(VehicleDamageNames.ProceedWithAgreedStatement)"
                        :skip-options-change-form-reset="!canClearFormsAhead"
                        @change="onFormPanelInputChange(VehicleDamageNames.ProceedWithAgreedStatement)"
                    >
                    </app-options-smart-list>
                    <app-button-with-callback
                        class="button"
                        v-bind="proceedButton()"
                        :disabled="proceedButtonIsDisabled(VehicleDamageNames.ProceedWithAgreedStatement)"
                        @button-callback-click="onClickProceedWithAgreedStatement"
                    >
                    </app-button-with-callback>
                </div>
            </div>
            <div class="whiteboard-panel">
                <div
                    v-if="isPanelVisible(VehicleDamageNames.AgreedStatementLabel)"
                    class="whiteboard"
                    :data-type="'whiteboard-damage-2'"
                    :data-scroll="VehicleDamageNames.AgreedStatementLabel"
                >
                    <h4 class="title">{{ localized('vehicle_damage_agreed_statement_label_title') }}</h4>
                    <app-options-smart-list
                        :type="'radio'"
                        :option-class="'filled'"
                        :required="true"
                        :show-error-borders="false"
                        :options="inputOptions[VehicleDamageNames.AgreedStatementLabel].options"
                        :form-field="form.field(VehicleDamageNames.AgreedStatementLabel)"
                        :skip-options-change-form-reset="!canClearFormsAhead"
                        @change="onFormPanelInputChange(VehicleDamageNames.AgreedStatementLabel)"
                    >
                    </app-options-smart-list>
                    <app-button-with-callback
                        class="button"
                        v-bind="proceedButton()"
                        :disabled="proceedButtonIsDisabled(VehicleDamageNames.AgreedStatementLabel)"
                        @button-callback-click="onPanelProceedClick(nextPanelAgreedStatementLabelBlock)"
                    >
                    </app-button-with-callback>
                </div>
            </div>
            <div class="whiteboard-panel">
                <div
                    v-if="isPanelVisible(VehicleDamageNames.VehicleIsDrivable)"
                    class="whiteboard"
                    :data-type="'whiteboard-damage-3'"
                    :data-scroll="VehicleDamageNames.VehicleIsDrivable"
                >
                    <h4 class="title">{{ localized('vehicle_damage_vehicle_is_drivable_title') }}</h4>
                    <app-options-smart-list
                        :type="'radio'"
                        :option-class="'filled'"
                        :required="true"
                        :show-error-borders="false"
                        :options="inputOptions[VehicleDamageNames.VehicleIsDrivable].options"
                        :form-field="form.field(VehicleDamageNames.VehicleIsDrivable)"
                        :skip-options-change-form-reset="!canClearFormsAhead"
                        @change="onFormPanelInputChange(VehicleDamageNames.VehicleIsDrivable)"
                    >
                    </app-options-smart-list>
                    <app-button-with-callback
                        class="button"
                        data-type="is-drivable"
                        v-bind="proceedButton()"
                        :disabled="proceedButtonIsDisabled(VehicleDamageNames.VehicleIsDrivable)"
                        @button-callback-click="onPanelProceedClick(nextPanelVehicleIsDrivableBlock)"
                    >
                    </app-button-with-callback>
                </div>
            </div>
            <div class="whiteboard-panel">
                <div
                    v-if="isPanelVisible(VehicleDamageNames.VehicleDriverIsApplicant)"
                    class="whiteboard"
                    :data-type="'whiteboard-damage-4'"
                    :data-scroll="VehicleDamageNames.VehicleDriverIsApplicant"
                >
                    <h4 class="title">{{ vehicleDamageDriverIsApplicantTitle() }}</h4>
                    <app-options-smart-list
                        :type="'radio'"
                        :option-class="'filled'"
                        :required="true"
                        :show-error-borders="false"
                        :options="inputOptions[VehicleDamageNames.VehicleDriverIsApplicant].options"
                        :form-field="form.field(VehicleDamageNames.VehicleDriverIsApplicant)"
                        :skip-options-change-form-reset="!canClearFormsAhead"
                        @change="onFormPanelInputChange(VehicleDamageNames.VehicleDriverIsApplicant)"
                    >
                    </app-options-smart-list>
                    <template v-if="vehicleDriverSubformVisible">
                        <h4 class="title">{{ localized('vehicle_damage_vehicle_driver_title') }}</h4>
                        <app-input-radio-overlayed
                            :form-field="form.field(VehicleDamageNames.VehicleDriverResident)"
                            :options="inputOptions[VehicleDamageNames.VehicleDriverResident].options"
                            @change="onFormPanelInputChange(VehicleDamageNames.VehicleDriverResident)"
                        >
                        </app-input-radio-overlayed>
                        <template
                            v-if="form.field(VehicleDamageNames.VehicleDriverResident).value === residentOptionValue"
                        >
                            <div class="vehicle-row">
                                <app-input-text
                                    :key="VehicleDamageNames.ResidentDriverName"
                                    :placeholder="localized('vehicle_damage_vehicle_driver_name_placeholder')"
                                    :label="localized('vehicle_damage_vehicle_driver_name_label')"
                                    :form-field="form.field(VehicleDamageNames.ResidentDriverName)"
                                    @change="onFormPanelInputChange(VehicleDamageNames.ResidentDriverName)"
                                >
                                </app-input-text>
                                <app-input-text
                                    :key="VehicleDamageNames.ResidentDriverSurname"
                                    :placeholder="localized('vehicle_damage_vehicle_driver_surname_placeholder')"
                                    :label="localized('vehicle_damage_vehicle_driver_surname_label')"
                                    :form-field="form.field(VehicleDamageNames.ResidentDriverSurname)"
                                    @change="onFormPanelInputChange(VehicleDamageNames.ResidentDriverSurname)"
                                >
                                </app-input-text>
                            </div>
                            <div class="vehicle-row">
                                <app-input-text
                                    v-if="form.field(VehicleDamageNames.VehicleDriverResident).value"
                                    :key="VehicleDamageNames.ResidentDriverPersonCode"
                                    :placeholder="localized('vehicle_damage_vehicle_driver_person_code_placeholder')"
                                    :label="localized('vehicle_damage_vehicle_driver_person_code_label')"
                                    :form-field="form.field(VehicleDamageNames.ResidentDriverPersonCode)"
                                    @change="onFormPanelInputChange(VehicleDamageNames.ResidentDriverPersonCode)"
                                >
                                </app-input-text>
                                <app-input-date
                                    v-if="showResidentDriverDateInput"
                                    :key="VehicleDamageNames.ResidentDriverBirthDate"
                                    class="input-full input-date"
                                    :layout="dateLayout"
                                    :delimiter="'-'"
                                    :validate-birth-date="true"
                                    :disable-error-text="true"
                                    :allow-invalid-value="true"
                                    :style="'width: 100%'"
                                    :placeholder="localized('vehicle_damage_vehicle_driver_birth_date_placeholder')"
                                    :label="localized('vehicle_damage_vehicle_driver_birth_date_label')"
                                    :form-field="form.field(VehicleDamageNames.ResidentDriverBirthDate)"
                                    @change="onFormPanelInputChange(VehicleDamageNames.ResidentDriverBirthDate)"
                                >
                                </app-input-date>
                            </div>
                        </template>
                        <template
                            v-if="form.field(VehicleDamageNames.VehicleDriverResident).value === nonResidentOptionValue"
                        >
                            <div class="vehicle-row">
                                <app-input-text
                                    :key="VehicleDamageNames.NonResidentDriverName"
                                    :placeholder="localized('vehicle_damage_vehicle_driver_name_placeholder')"
                                    :label="localized('vehicle_damage_vehicle_driver_name_label')"
                                    :form-field="form.field(VehicleDamageNames.NonResidentDriverName)"
                                    @change="onFormPanelInputChange(VehicleDamageNames.NonResidentDriverName)"
                                >
                                </app-input-text>
                                <app-input-text
                                    :key="VehicleDamageNames.NonResidentDriverSurname"
                                    :placeholder="localized('vehicle_damage_vehicle_driver_surname_placeholder')"
                                    :label="localized('vehicle_damage_vehicle_driver_surname_label')"
                                    :form-field="form.field(VehicleDamageNames.NonResidentDriverSurname)"
                                    @change="onFormPanelInputChange(VehicleDamageNames.NonResidentDriverSurname)"
                                >
                                </app-input-text>
                            </div>
                            <div class="vehicle-row">
                                <app-input-date
                                    :key="VehicleDamageNames.NonResidentDriverBirthDate"
                                    class="input-full input-date"
                                    :layout="dateLayout"
                                    :delimiter="'-'"
                                    :validate-birth-date="true"
                                    :allow-invalid-value="true"
                                    :style="'width: 100%'"
                                    :placeholder="localized('vehicle_damage_vehicle_driver_birth_date_placeholder')"
                                    :label="localized('vehicle_damage_vehicle_driver_birth_date_label')"
                                    :form-field="form.field(VehicleDamageNames.NonResidentDriverBirthDate)"
                                    @change="onFormPanelInputChange(VehicleDamageNames.NonResidentDriverBirthDate)"
                                >
                                </app-input-date>
                                <app-input-text
                                    :key="VehicleDamageNames.NonResidentDriverLicenseNumber"
                                    :style="'width: 100%'"
                                    :placeholder="localized('vehicle_damage_vehicle_driver_license_number_placeholder')"
                                    :label="localized('vehicle_damage_vehicle_driver_license_number_label')"
                                    :form-field="form.field(VehicleDamageNames.NonResidentDriverLicenseNumber)"
                                    @change="onFormPanelInputChange(VehicleDamageNames.NonResidentDriverLicenseNumber)"
                                >
                                </app-input-text>
                            </div>
                        </template>
                    </template>
                    <app-button-with-callback
                        class="button"
                        data-type="vehicle-applicant-submit"
                        v-bind="proceedButton()"
                        :data-scroll="4"
                        :disabled="proceedButtonIsDisabled(VehicleDamageNames.VehicleDriverIsApplicant)"
                        @button-callback-click="onPanelProceedClick(nextPanelVehicleDriverIsApplicant)"
                    >
                    </app-button-with-callback>
                </div>
            </div>
            <div class="whiteboard-panel">
                <div
                    v-if="isPanelVisible(VehicleDamageNames.VehicleDamagedBeforeAccident)"
                    class="whiteboard"
                    :data-type="'whiteboard-damage-5'"
                    :data-scroll="VehicleDamageNames.VehicleDamagedBeforeAccident"
                >
                    <h4 class="title">{{ localized('vehicle_damage_vehicle_damaged_before_accident_title') }}</h4>
                    <app-options-smart-list
                        :type="'radio'"
                        :option-class="'filled'"
                        :required="true"
                        :show-error-borders="false"
                        :options="inputOptions[VehicleDamageNames.VehicleDamagedBeforeAccident].options"
                        :form-field="form.field(VehicleDamageNames.VehicleDamagedBeforeAccident)"
                        :skip-options-change-form-reset="!canClearFormsAhead"
                        @change="onFormPanelInputChange(VehicleDamageNames.VehicleDamagedBeforeAccident)"
                    >
                    </app-options-smart-list>
                    <template v-if="vehicleDamagedBeforeAccident">
                        <h4 class="title">{{ localized('vehicle_damaged_damage_before_accident_title') }}</h4>
                        <div class="description">
                            {{ localized('vehicle_damage_damage_before_accident_explanation') }}
                        </div>
                        <app-input-textarea
                            :form-field="form.field(VehicleDamageNames.DamageBeforeAccident)"
                            :placeholder="localized('vehicle_damage_damage_before_accident_placeholder')"
                            @change="onFormPanelInputChange(VehicleDamageNames.DamageBeforeAccident)"
                        >
                        </app-input-textarea>
                    </template>
                    <app-button-with-callback
                        class="button"
                        v-bind="proceedButton()"
                        data-type="damaged-desc"
                        :disabled="proceedButtonIsDisabled(VehicleDamageNames.VehicleDamagedBeforeAccident)"
                        @button-callback-click="onPanelProceedClick('')"
                    >
                    </app-button-with-callback>
                </div>
            </div>
        </app-custom-form>
    </div>
</template>

<style lang="scss" scoped>
.form {
    display: flex;
    flex-direction: column;

    .vehicle-row {
        width: 100%;
        display: grid;
        gap: var(--size-small);
        grid-template-columns: 1fr;

        @include respond-above('sm') {
            grid-template-columns: 1fr 1fr;
        }
    }

    .input-plate-number {
        width: 100%;
    }

    .button {
        padding: 0 var(--size-medium);

        .icon-right {
            margin-left: var(--size-nano);
        }
    }

    .description {
        margin-bottom: var(--size-small);
        color: var(--text-color-subtlest);
    }

    .button-with-callback {
        height: 52px;
    }

    .whiteboard h4.title:first-of-type {
        margin-bottom: var(--size-small);
    }

    label {
        line-height: var(--size-medium);
    }

    .vehicle-row:not(:last-of-type) {
        margin-bottom: var(--size-small);
    }

    #vehicleDriverIsApplicant + h4.title {
        margin-top: var(--size-big);
    }

    #vehicleDamagedBeforeAccident + h4.title {
        margin-top: var(--size-big);
        margin-bottom: var(--size-nano);
    }

    .input-full {
        flex-basis: 100%;
    }

    :deep(.input-radio .buttons) {
        margin-bottom: var(--size-small);
    }

    :deep(.input .label.informative, .input .label.hide-on-mobile) {
        margin-bottom: var(--size-pico);
        color: var(--text-color-default);
    }

    :deep(#nonResidentDriverBirthDate, #residentDriverBirthDate) {
        .frame {
            position: relative;
        }

        .localized {
            position: absolute;
        }
    }

    :deep(.input-radio-overlayed) {
        margin-bottom: var(--size-small);

        .buttons {
            .overlay-button {
                padding: var(--size-tiny);
                width: 50%;
            }
        }
    }

    :deep(.input-date .wrapper) {
        justify-content: flex-start;
        min-height: 52px;
        padding: 0 30px 0 20px;
    }
}
</style>
