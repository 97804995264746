<script setup lang="ts">
import PayByLinkService from '@/Apps/PayByLink/Pay/Services/PayByLinkService';
import Form from '@/assets/libraries/form/form';
import { computed, onMounted, ref, Ref, nextTick, getCurrentInstance } from 'vue';
import { useTranslate } from '@/Composables/Translate';
import FormField from '@/assets/libraries/form/form-field';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import { useDefine } from '@/Composables/Define';
import Value from '@/assets/libraries/form/value';
import AgreementPeriodMap from '@/Apps/PayByLink/Classes/AgreementPeriodMap';
import MomentBuilder from '@/assets/libraries/Date/Builders/MomentBuilder';
import OneDate from '@/assets/libraries/Date/OneDate';
import OfferRisk from '@/Apps/PayByLink/Pay/Interfaces/OfferRiskInterface';
import AdditionalOptionsMap from '@/Apps/PayByLink/Classes/AdditionalOptionsMap';
import { usePrice } from '@/Composables/Price';
import { useComparisons } from '@/Composables/Comparisons';
import { useSorting } from '@/Composables/Sorting';
import { useAxios } from '@/Composables/Axios';
import { AxiosResponse } from 'axios';
import Url from '@/Enums/UrlEnum';
import ButtonWithCallbackParams from '@/Components/Buttons/ButtonWithCallback/Enums/button.params';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import RecurringPaymentBanklink from '@/Enums/RecurringPaymentBanklinkEnum';
import AppCountry from '@/assets/libraries/app/app-country';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import ErrorType from '@/Enums/ErrorTypeEnum';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import { useNavigate } from '@/Composables/Navigate';
import Error from '@/services/error.service';
import { useRouter, Router } from 'vue-router';
import { useScroll } from '@/Composables/Scroll';
import RequestService from '@/services/request.service';
import TransferStateService from '@/Core/ServerState/TransferStateService';
import Validation from '@/services/validation.service';
import AppCustomForm from '@/Components/Inputs/CustomForm/CustomForm.vue';
import AppInputText from '@/Components/Inputs/InputText/InputText.vue';
import AppInputEmail from '@/Components/Inputs/InputEmail/InputEmail.vue';
import AppInputCheckbox from '@/Components/Inputs/InputCheckbox/InputCheckbox.vue';
import AppConsentsList from '@/Components/Lists/ConsentsList/ConsentsList.vue';
import AppPaymentMethods from '@/Components/Widgets/PaymentMethods/PaymentMethods.vue';
import AppSubscribeAndPay from '@/Components/Other/SubscribeAndPay/SubscribeAndPay.vue';
import AppButtonWithCallback from '@/Components/Buttons/ButtonWithCallback/ButtonWithCallback.vue';
import OneBaseService from '@/services/OneBaseService';
import { usePayByLink } from '@/Apps/PayByLink/Composables/PayByLink';
import { useNumbers } from '@/Composables/Numbers';
import VehicleType from '@/Apps/PayByLink/Pay/Enums/VehicleType';

const { translate, translateForType } = useTranslate();
const { sparse } = usePrice();
const { validResponse } = useDefine();
const { acceptCalculationResponse, includedDefaultRisks, basePremium } = usePayByLink();
const router: Router = useRouter();

const payByLinkService: PayByLinkService = PayByLinkService.getInstance();
const TranslationType: string = 'mtpl_renewal';
const Facility: string = 'pay-by-link-pay';

const form: Form = new Form();
const bankLinkIdError: Ref<boolean> = ref(false);
const bankLinkInfo: Ref<string> = ref('');
const bankLinkId: Ref<number> = ref(0);
const viewIsReady: Ref<boolean> = ref(false);
const riskSequence: string[] = payByLinkService.payByLink.value.offerRisks.map((offerRisk) => offerRisk.ic);

const payByCard: Ref<boolean> = computed((): boolean => {
    return payByLinkService.payByLink.value.useCard;
});
const bankIsSelected: Ref<boolean> = computed(() => {
    return bankLinkId.value !== 0;
});
const showYoungestDriver: Ref<boolean> = computed((): boolean => {
    return payByLinkService.payByLink.value.vehicleTypeId !== VehicleType.C3;
});

onMounted(() => {
    OneBaseService.getInstance().applySpa(getCurrentInstance());
    PopupService.getInstance().show(new OnePopup().withType().loading);
    fetchOffer().then((): void => {
        viewIsReady.value = true;
        setupForm();
        restoreValues();
        useScroll().scrollToTop();
        PopupService.getInstance().hide();
    });
});

defineExpose({
    bankIsSelected,
});

async function fetchOffer(): Promise<void> {
    return RequestService.getInstance()
        .get({
            uri: Url.Ajax.mtplRenewalFetchOffer,
            content: {
                hash: payByLinkService.payByLink.value.id,
                driverAge: youngestDriverAge(),
            },
        })
        .then((response: AxiosResponse): void => {
            if (acceptCalculationResponse(response)) {
                TransferStateService.getInstance().set('mtplOffer', response.data.data.body.offer);
                payByLinkService.init();
            }
        });
}

function setupForm(): void {
    form.addField(new FormField('agree-terms', '', 'checkboxChecked'));
    form.addField(new FormField('marketing-consents'));
    form.addField(new FormField('holder-email', holderEmail(), 'required'));
    form.addField(new FormField('holder-full-name', payByLinkService.payByLink.value.holderName));
    form.setReady();
}

function restoreValues(): void {
    const storedValues: DynamicDictionary = payByLinkService.fields;
    const fieldsMap: DynamicDictionary = {
        agreeTerms: 'agree-terms',
        marketingConsents: 'marketing-consents',
        holderEmail: 'holder-email',
    };

    Object.keys(fieldsMap).forEach((key: string): void => {
        if (useDefine().isSet(storedValues[key]) && new Value(storedValues[key]).isNotEmpty()) {
            form.field(fieldsMap[key]).setValue(storedValues[key]);
        }
    });
}

function storeValues(): void {
    const existingValues: DynamicDictionary = JSON.parse(JSON.stringify(payByLinkService.fields));
    Object.assign(payByLinkService.fields, {
        ...existingValues,
        agreeTerms: form.field('agree-terms').value,
        marketingConsents: form.field('marketing-consents').value,
        holderEmail: form.field('holder-email').value,
    });
}

function holderEmail(): string {
    return Validation.isValidEmail(payByLinkService.payByLink.value.holderEmail)
        ? payByLinkService.payByLink.value.holderEmail
        : '';
}

function offerPremium(): number {
    return payByLinkService.mtplOffer.value
        ? payByLinkService.mtplOffer.value.prices[offerPeriodIc()].premium[offerPaymentCountIc()]
        : basePremium();
}

function riskPremium(ic: string): number {
    const riskKey: string = new AdditionalOptionsMap().keyByIc(ic) + 'Premium';
    let result: number = 0;
    if (payByLinkService.mtplOffer.value) {
        result = payByLinkService.mtplOffer.value.prices[offerPeriodIc()][riskKey];
    } else {
        const riskFromPayByLink: OfferRisk | undefined = payByLinkService.payByLink.value.offerRisks.find(
            (risk) => risk.ic === ic,
        );
        result = riskFromPayByLink ? riskFromPayByLink.premium : 0;
    }
    return result;
}

function totalPayment(): number {
    return offerPremium() + useNumbers().arraySum(includedRiskIcs().map((ic: string): number => riskPremium(ic)));
}

function duePayment(): number {
    return usePayByLink().duePayment(
        [offerPremium(), includedRiskIcs().map((ic: string): number => riskPremium(ic))].flat(),
        offerPaymentCountIc(),
    );
}

function laterPayment(): number {
    return usePayByLink().laterPayment(
        [offerPremium(), includedRiskIcs().map((ic: string): number => riskPremium(ic))].flat(),
        offerPaymentCountIc(),
    );
}

function includedRiskIcs(): string[] {
    let result: string[];
    if (offerHasChanges()) {
        const risks: DynamicDictionary =
            payByLinkService.fields.offerOptions[payByLinkService.fields.offerPeriod] ?? {};
        result = Object.keys(useSorting().sortedObject(risks, riskSequence)).filter(
            (key: string): boolean => risks[key],
        );
    } else {
        result = includedDefaultRisks().map((risk: OfferRisk): string => risk.ic);
    }

    return result;
}

function offerPeriodLabel(): string {
    const map: AgreementPeriodMap = new AgreementPeriodMap();
    const periodIc = offerPeriodIc();
    const months = map.monthsByIc(periodIc);
    const [startDateString] = payByLinkService.payByLink.value.insurancePeriod.split(' - ');
    const startDate = new MomentBuilder().withInitialDate(startDateString).build();
    const endDate = startDate.clone().add(months, 'months').subtract(1, 'day').hours(23).minutes(59);
    const formatDate = (date: moment.Moment) => {
        return `(${date.format('HH:mm')})`;
    };

    return `${OneDate.short(startDate.toDate())} ${formatDate(startDate)} - ${OneDate.short(endDate.toDate())} ${formatDate(endDate)}`;
}

function monthsLabel(): string {
    const map: AgreementPeriodMap = new AgreementPeriodMap();
    const periodIc: string = offerPeriodIc();
    const months: number = map.monthsByIc(periodIc);
    const label: string = map.monthsLabelByIc(periodIc);

    return `${String(months)} ${label}`;
}

function offerPeriodIc(): string {
    return payByLinkService.fields.offerPeriod !== ''
        ? payByLinkService.fields.offerPeriod
        : payByLinkService.payByLink.value.periodIc;
}

function offerPaymentCountIc(): string {
    return payByLinkService.fields.offerPaymentCount !== ''
        ? payByLinkService.fields.offerPaymentCount
        : `M${payByLinkService.payByLink.value!.paymentCount}`;
}

function youngestDriverAge(): string {
    return payByLinkService.fields.driverAge !== ''
        ? payByLinkService.fields.driverAge
        : String(payByLinkService.payByLink.value!.youngestDriver);
}

function payByLinkOffer(): DynamicDictionary {
    const tempValue: DynamicDictionary = {};
    payByLinkService.payByLink.value.offerRisks.forEach((risk: OfferRisk): void => {
        tempValue[risk.ic] = risk.included;
    });
    const sortedRisks: DynamicDictionary = useSorting().sortedObject(tempValue);

    return {
        risks: sortedRisks,
    };
}

function currentOffer(): DynamicDictionary {
    const sortedRisks: DynamicDictionary = useSorting().sortedObject(
        payByLinkService.fields.offerOptions[payByLinkService.fields.offerPeriod] ?? {},
    );

    return {
        risks: sortedRisks,
    };
}

function offerHasChanges(): boolean {
    const offerHasStoredOptions: boolean = payByLinkService.fields.offerOptions
        ? !!payByLinkService.fields.offerOptions[payByLinkService.fields.offerPeriod]
        : false;
    const offerHasChangedOptions: boolean = offerHasStoredOptions
        ? !useComparisons().isSameObject(payByLinkOffer(), currentOffer())
        : false;
    const offerHasChangedPeriod: boolean =
        payByLinkService.fields.offerPeriod !== '' &&
        payByLinkService.fields.offerPeriod !== payByLinkService.payByLink.value.periodIc;
    const offerHasChangedDriverAge: boolean =
        payByLinkService.fields.driverAge !== '' &&
        String(payByLinkService.fields.driverAge) !== String(payByLinkService.payByLink.value!.youngestDriver);
    const offerHasChangedPaymentCount: boolean = payByLinkService.fields.offerPaymentCount
        ? payByLinkService.fields.offerPaymentCount !== `M${payByLinkService.payByLink.value!.paymentCount}`
        : false;

    return offerHasChangedOptions || offerHasChangedPeriod || offerHasChangedPaymentCount || offerHasChangedDriverAge;
}

function onPaymentMethodClick(value: DynamicDictionary): void {
    bankLinkIdError.value = false;
    bankLinkInfo.value = value.bankLinkInfo;
    bankLinkId.value = value.bankLinkId;
}

function onMakePaymentClick(): void {
    validateForm();
    if (payByCard.value) {
        bankLinkId.value = RecurringPaymentBanklink.ByCountry[new AppCountry().iso()];
    }
    validateBanklink();
    bankLinkIdError.value ? onSubmitShowBankPaymentError() : submitPayment();
}

function onSubmitShowBankPaymentError(): void {
    scrollToBanksList();
    const errorTitle: string = translate('btar_error_payment_method_not_selected_title');
    const errorDescription: string = translate('btar_error_payment_method_not_selected_description');
    PopupService.getInstance().show(
        new OnePopup().withType().simpleError.withTitle(errorTitle).withDescription(errorDescription),
    );
}

function showOfferErrorPopup(): void {
    PopupService.getInstance().show(new OnePopup().withType().simpleError);
}

function validateForm(): void {
    form.touch().then((): void => {
        form.validate().then((): void => {
            if (!form.isValid()) {
                scrollToInvalidElement();
            }
        });
    });
}

function submitPayment(): void {
    if (form.isValid()) {
        nextTick((): void => {
            PopupService.getInstance().show(new OnePopup().withType().loadingWait);
            if (offerHasChanges()) {
                useAxios()
                    .post(Url.Ajax.mtplRenewalEditOffer, editOfferContent())
                    .then((response: AxiosResponse): void => {
                        if (validResponse(response)) {
                            payByLinkService.renewedOfferId.value = response.data.data.body.offer.id;
                            postPayment();
                        } else {
                            showOfferErrorPopup();
                        }
                    });
            } else {
                postPayment();
            }
        });
    } else {
        scrollToInvalidElement();
    }
}

function postPayment(): void {
    const params: Record<string, Record<string, string | unknown>> = assemblePaymentData();
    useAxios()
        .post(Url.Ajax.payByLink, params)
        .then((value: AxiosResponse<DynamicDictionary>): void => {
            if (validResponse(value) && value.data.data.status === 'OK') {
                useNavigate().navigate(value.data.data.body.redirectUrl);
            } else {
                PopupService.getInstance().hide();
                Error.getInstance().show(ErrorType.Error, 'submitPayment', value);
            }
        })
        .catch((reason: LimitedVariant): void => {
            PopupService.getInstance().hide();
            Error.getInstance().show(ErrorType.Error, 'submitPayment', reason as DynamicDictionary);
        });
}

function assemblePaymentData(): Record<string, Record<string, string | unknown>> {
    const json: Record<string, Record<string, number | string>> = {
        data: {},
    };
    json.data.id = payByLinkService.payByLink.value.id;
    json.data.bankLinkId = bankLinkId.value;

    return {
        data: {
            json: JSON.stringify(json),
            facility: Facility,
            extra: '',
            paymentFailRedirectUrl: router.currentRoute.value.path,
            policyReceiverEmail: payByLinkService.fields.holderEmail,
        },
    };
}

function scrollToInvalidElement(): void {
    const invalidElements: JQuery = $('.invalid').not('[style*="display: none"]');
    if (invalidElements.length > 0) {
        invalidElements[0].scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
    }
}

function scrollToBanksList(): void {
    useScroll().scrollToView('#banks-list');
}

function validateBanklink(): void {
    bankLinkIdError.value = bankLinkId.value === 0;
}

function offerId(): string {
    return payByLinkService.renewedOfferId && payByLinkService.renewedOfferId.value !== ''
        ? payByLinkService.renewedOfferId.value
        : payByLinkService.payByLink.value.documentId;
}

function editOfferContent(): DynamicDictionary {
    return {
        accidentTypeIc: payByLinkService.mtplOffer.value.accidentTypeIc,
        placeCount: payByLinkService.mtplOffer.value.placeCount,
        risks: risksWithRoadAssistance(),
        id: offerId(),
        periodIc: offerPeriodIc(),
        paymentCountIc: offerPaymentCountIc(),
        youngestDriverAge: youngestDriverAge(),
        number: payByLinkService.payByLink.value.documentNumber,
        hash: payByLinkService.payByLink.value.id,
    };
}

function risksWithRoadAssistance(): DynamicDictionary {
    const result: DynamicDictionary = JSON.parse(JSON.stringify(currentOffer().risks));
    const hasRoadAssistance: boolean = !!Object.keys(result).find((ic) => ic === 'TT_R_HELP_ON_ROAD');
    if (!hasRoadAssistance) {
        result['TT_R_HELP_ON_ROAD'] = false;
    }

    return result;
}

function paymentCount(): number {
    return Number(offerPaymentCountIc().replace(/\D/, ''));
}

function schedulePayment(paymantNumber: string): number {
    return paymantNumber === '1' ? duePayment() : laterPayment();
}

function confirmAndPayButtonParams(): ButtonWithCallbackParams {
    const buttonTitle: string =
        translateForType('summary_confirm_and_pay', TranslationType) + ' · ' + sparse(duePayment(), false) + ' &euro;';

    return {
        title: buttonTitle,
        textColor: ButtonTextColor.White,
        backgroundColor: ButtonBackground.Red,
    };
}
</script>

<template>
    <div v-if="viewIsReady" class="step-container content-text">
        <app-custom-form v-if="form.isReady()" :form="form" class="form" @change="storeValues()">
            <section class="header">
                <h2 class="title">{{ translateForType('summary_view_title', TranslationType) }}</h2>
                <div class="description">{{ translateForType('summary_view_description', TranslationType) }}</div>
            </section>
            <div class="summary-form">
                <section id="whiteboard-0" class="whiteboard">
                    <div class="whiteboard-title">{{ translateForType('policy_details', TranslationType) }}</div>
                    <ul class="list details">
                        <li id="whiteboard-item-0-0" class="item">
                            <span>{{ translateForType('insured_object', TranslationType) }}</span>
                            <span class="item-name">{{
                                translateForType('insured_object_value', TranslationType)
                            }}</span>
                        </li>
                        <li id="whiteboard-item-0-1" class="item">
                            <span>{{ translateForType('insured_vehicle', TranslationType) }}</span>
                            <span class="item-name">{{ payByLinkService.payByLink.value.objectName }}</span>
                        </li>
                        <li
                            v-if="payByLinkService.payByLink.value.youngestDriver && showYoungestDriver"
                            id="whiteboard-item-0-2"
                            class="item"
                        >
                            <span>{{ translateForType('youngest_driver_title', TranslationType) }}</span>
                            <span class="item-name">{{ youngestDriverAge() }}</span>
                        </li>
                    </ul>
                </section>
                <section id="whiteboard-1" class="whiteboard">
                    <div class="whiteboard-title">{{ translateForType('mtpl_period', TranslationType) }}</div>
                    <ul class="list details">
                        <li id="whiteboard-item-1-0" class="item">
                            <span>{{ translateForType('policy_period', TranslationType) }}</span>
                            <div class="item-name period-info">
                                <span class="period-duration">{{ monthsLabel() }}</span>
                                <span class="period-dates">{{ offerPeriodLabel() }}</span>
                            </div>
                        </li>
                    </ul>
                </section>
                <section id="whiteboard-2" class="whiteboard">
                    <div class="whiteboard-title">{{ translateForType('payment_details', TranslationType) }}</div>
                    <ul class="list details">
                        <li id="whiteboard-item-2-0" class="item">
                            <span>{{ translateForType('mtpl_policy', TranslationType) }}</span>
                            <span class="item-name">{{ sparse(offerPremium(), false) }}&nbsp;&euro;</span>
                        </li>
                        <li
                            v-for="(ic, index) in includedRiskIcs()"
                            :id="'whiteboard-item-2-' + (index + 1)"
                            :key="index"
                            class="item test"
                        >
                            <span>{{ translateForType(ic, TranslationType) }}</span>
                            <span class="item-name">{{ sparse(riskPremium(ic), false) }}&nbsp;&euro;</span>
                        </li>
                        <li id="payment-total" class="item">
                            <span>{{ translateForType('total_price', TranslationType) }}</span>
                            <span class="item-name">{{ sparse(totalPayment(), false) }}&nbsp;&euro;</span>
                        </li>
                        <li id="payment-count" class="item">
                            <span>{{ translateForType('number_of_payments', TranslationType) }}</span>
                            <span class="item-name">{{ paymentCount() }}</span>
                        </li>
                        <li id="payment-due" class="item total inline">
                            <span class="item-name payment">{{
                                translateForType('payment_due', TranslationType)
                            }}</span>
                            <span class="item-total"
                                >{{ sparse(duePayment(), false) }}<span class="currency">&nbsp;&euro;</span></span
                            >
                        </li>
                    </ul>
                    <app-expandable
                        v-if="payByLinkService.mtplOffer.value"
                        :show-more-text-id="translateForType('show_payment_schedule', TranslationType)"
                        :show-less-text-id="translateForType('hide_payment_schedule', TranslationType)"
                        :show-arrows="true"
                    >
                        <template #expander>
                            <div class="whiteboard-title">
                                {{ translateForType('payment_schedule', TranslationType) }}
                            </div>
                            <ul class="list details">
                                <li class="item header">
                                    <span class="item-nr"
                                        >{{ translateForType('payment_schedule_number', TranslationType) }}.</span
                                    >
                                    <span class="item-date">{{
                                        translateForType('payment_schedule_due_date', TranslationType)
                                    }}</span>
                                    <span class="item-name">{{
                                        translateForType('payment_schedule_amount', TranslationType)
                                    }}</span>
                                </li>
                                <li
                                    v-for="(payment, index) in payByLinkService.mtplOffer.value.schedule[
                                        offerPeriodIc()
                                    ][offerPaymentCountIc()]"
                                    :id="'schedule-item-' + index"
                                    :key="index"
                                    class="item"
                                >
                                    <span class="item-nr">{{ payment.paymentNumber }}</span>
                                    <span class="item-date">{{ OneDate.short(payment.paymentTerm) }}</span>
                                    <span class="item-name"
                                        >{{ sparse(schedulePayment(payment.paymentNumber), false) }} &euro;</span
                                    >
                                </li>
                            </ul>
                        </template>
                    </app-expandable>
                </section>
                <section id="whiteboard-3" class="whiteboard">
                    <div class="whiteboard-title">{{ translateForType('policy_holder', TranslationType) }}</div>
                    <div class="full-width flex column">
                        <app-input-text
                            class="full-width"
                            :form-field="form.field('holder-full-name')"
                            :label="translate('one_full_name')"
                            :disabled="true"
                            :data-store-disabled="true"
                        >
                        </app-input-text>
                        <app-input-email
                            class="full-width"
                            :form-field="form.field('holder-email')"
                            :label="translate('btar_email')"
                        >
                        </app-input-email>
                    </div>
                </section>
                <section id="whiteboard-4" class="whiteboard">
                    <div class="whiteboard-title">
                        {{ translateForType('summary_terms_and_agreements', TranslationType) }}
                    </div>
                    <div
                        class="purchase-terms"
                        v-html="translateForType('description_terms_and_agreements', TranslationType)"
                    ></div>
                    <hr class="line-break" />
                    <app-input-checkbox class="terms-checkbox" :form-field="form.field('agree-terms')">
                        {{ translateForType('checkbox_agree_terms', TranslationType) }}
                    </app-input-checkbox>
                    <hr class="line-break" />
                    <app-consents-list :form-field="form.field('marketing-consents')"> </app-consents-list>
                </section>
                <app-payment-methods
                    v-if="!payByCard"
                    :id="'banks-list'"
                    :error-state="bankLinkIdError"
                    @payment-method-click="onPaymentMethodClick($event)"
                >
                </app-payment-methods>
                <app-subscribe-and-pay
                    v-if="payByCard"
                    :amount="totalPayment()"
                    :callback="onMakePaymentClick"
                    :wrapper-classes="'whiteboard'"
                ></app-subscribe-and-pay>
            </div>
        </app-custom-form>
        <footer v-if="bankIsSelected && !payByCard" class="additional-footer popup-right-padding">
            <div class="wrapper">
                <div class="container">
                    <div class="content" v-html="bankLinkInfo"></div>
                </div>
                <app-button-with-callback
                    class="button"
                    v-bind="confirmAndPayButtonParams()"
                    @button-callback-click="onMakePaymentClick()"
                >
                </app-button-with-callback>
            </div>
        </footer>
    </div>
</template>

<style lang="scss" scoped>
.step-container {
    .header {
        padding: 0 var(--size-small);

        @include respond-above('sm') {
            padding: 0 var(--size-big);
        }

        .title,
        .description {
            text-align: center;
        }

        .title {
            font-size: var(--font-size-big);
        }

        .description {
            margin-top: var(--size-tiny);
            color: var(--text-color-subtlest);
            font-size: var(--font-size-tiny);
        }
    }

    .summary-form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--size-small);
        margin-top: var(--size-big);
        padding: var(--size-small) 0;

        @include respond-above('sm') {
            gap: var(--size-normal);
            align-items: center;
        }

        .button-with-callback {
            width: 100%;
            height: 52px;
        }

        .info {
            font-size: 14px;
            font-weight: 500;
            line-height: 16.8px;
            text-align: left;
            color: var(--text-color-subtlest);
        }

        :deep(.whiteboard) {
            display: flex;
            padding: var(--size-small);
            flex-direction: column;
            align-items: flex-start;
            border-radius: var(--size-tiny);
            background: var(--component-color-background-base);
            gap: var(--size-small);
            width: 100%;
            max-width: 800px;

            @include respond-above('sm') {
                padding: var(--size-big);
            }

            .whiteboard-title {
                color: var(--text-color-default);
                font-size: var(--font-size-small);
                font-weight: 700;
            }

            .purchase-terms {
                font-size: var(--font-size-nano);
            }

            .terms-checkbox {
                :deep(label) {
                    color: var(--text-color-default);
                    font-weight: 400;
                }
            }

            .expandable {
                width: 100%;
                gap: 0;

                :deep(.expander-expanded) {
                    width: 100%;
                    margin-bottom: var(--size-small);
                }

                :deep(.expand-button) {
                    color: var(--text-color-link);
                    font-size: var(--font-size-tiny);
                    font-weight: 600;
                    gap: var(--size-nano);

                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            .line-break {
                width: 100%;
                height: 1px;
                background-color: var(--component-color-border-default);
            }

            .list {
                width: 100%;
                border-radius: var(--size-pico);
                border: 1px solid var(--black-100);
                display: flex;
                flex-direction: column;

                .item {
                    display: flex;
                    padding: var(--size-small);
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: var(--size-pico);
                    align-self: stretch;
                    font-size: var(--font-size-nano);

                    @include respond-above('sm') {
                        flex-direction: row;
                        justify-content: space-between;
                        padding: var(--size-small);
                    }

                    &.inline {
                        display: flex;
                        padding: var(--size-small);
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: flex-start;
                        gap: var(--size-pico);
                        align-self: stretch;
                        font-size: var(--font-size-nano);
                        text-align: left;
                    }

                    &.total {
                        background: var(--system-color-success-light);
                    }

                    &.header {
                        background: var(--background-light);
                    }

                    .item-total {
                        font-size: var(--font-size-medium);
                        font-weight: 700;
                    }

                    .currency {
                        font-size: var(--font-size-tiny);
                        font-weight: 600;
                    }

                    .item-name {
                        font-weight: 600;

                        @include respond-above('sm') {
                            width: 50%;
                            text-align: right;
                        }

                        &.payment {
                            text-align: left;
                        }

                        &.period-info {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;

                            @include respond-above('sm') {
                                width: 50%;
                                text-align: right;
                                align-items: flex-end;
                            }
                        }
                    }

                    .item-nr {
                        @include respond-above('sm') {
                            width: 40px;
                            text-align: left;
                        }
                    }

                    .item-date {
                        text-align: left;
                        flex-grow: 1;

                        @include respond-above('sm') {
                            text-align: left;
                        }
                    }

                    &:not(:last-child) {
                        border-bottom: 1px solid var(--black-100);
                    }
                }
            }
        }

        :deep(.subscribe-and-pay-heading) {
            font-size: var(--font-size-big);
            margin-bottom: 0;
            margin-top: calc(var(--size-big) - var(--size-small));
        }
    }

    .full-width {
        width: 100%;
    }

    .half-children {
        .input {
            @include respond-above('sm') {
                width: 50%;
            }
        }
    }

    .flex {
        display: flex;
        gap: var(--size-small);

        &.column {
            flex-direction: column;
        }

        &.mobile-column {
            flex-direction: column;

            @include respond-above('sm') {
                flex-direction: row;
            }
        }
    }

    .additional-footer {
        .wrapper {
            max-width: 840px;
            flex-direction: column-reverse;

            .button {
                margin-top: 0;
                margin-bottom: var(--size-normal);
                border-radius: 8px;
                height: 52px;
            }

            .content {
                max-width: 509px;
                display: block;
                margin-bottom: var(--size-normal);
                padding-right: var(--size-huge);
                font-size: var(--font-size-nano);

                :deep(a) {
                    font-weight: bold;
                }

                @include respond-above('sm') {
                    margin-bottom: 0;
                    padding-right: 0;
                }
            }

            @include respond-above('sm') {
                flex-direction: row;

                .button {
                    margin: 0;
                }
            }
        }
    }
}
</style>
