<script lang="ts">
// @ts-nocheck
import { computed, defineComponent, reactive, ref, Ref, watch, nextTick, UnwrapNestedRefs } from 'vue';
import OneBaseService from '@/services/OneBaseService';
import { useTranslate } from '@/Composables/Translate';
import { Auto } from '@/pages/Auto/Composables/Auto';
import AutoService from '@/pages/Auto/Services/AutoService';
import { AutoStorage } from '@/pages/Auto/Composables/AutoStorage';
import AutoStorageService from '@/pages/Auto/Services/AutoStorageService';
import { AutoForms } from '@/pages/Auto/Composables/AutoForms';
import AutoFormsService from '@/pages/Auto/Services/AutoFormsService';
import { useDefine } from '@/Composables/Define';
import { usePrice } from '@/Composables/Price';
import { useStepsSubmitter } from '@/Composables/StepsSubmitter';
import { AxiosParams, useAxios } from '@/Composables/Axios';
import Countries from '@/services/countries.service';
import { Subscription } from 'rxjs';
import { InputOption } from '@/interfaces/InputOptionInterface';
import AnyAbstract from '@/Classes/AnyAbsctractClass';
import { UrlParams, useUrl } from '@/Composables/Url';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import AppCountry from '@/assets/libraries/app/app-country';
import VehicleSumCalculation from '@/pages/Auto/Classes/VehicleSumCalculation';
import VehicleProduct from '@/Enums/VehicleProductEnum';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import FormField from '@/assets/libraries/form/form-field';
import PaymentType from '@/Enums/PaymentTypeEnum';
import PolicyOwner from '@/Enums/PolicyOwnerEnum';
import moment from 'moment';
import DateRange from '@/interfaces/date.range.interface';
import Sanitizer from '@/services/sanitizer.service';
import type { SanitizerCallback } from '@/assets/libraries/form/form-field';
import Validation from '@/services/validation.service';
import CssClass from '@/Enums/CssClassEnum';
import VueEvent from '@/Classes/VueEventClass';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import ExtractDataService from '@/services/extract.data.service';
import RecurringPaymentBanklink from '@/Enums/RecurringPaymentBanklinkEnum';
import SimpleError from '@/assets/libraries/popups/types/simple.error';
import PopupType from '@/Enums/PopupTypeEnum';
import PolicyPeriods from '@/Enums/PolicyPeriodsEnum';
import { useNavigate } from '@/Composables/Navigate';
import Value from '@/assets/libraries/form/value';
import Url from '@/Enums/UrlEnum';
import ErrorType from '@/Enums/ErrorTypeEnum';
import Error from '@/services/error.service';
import DataLayerType from '@/Enums/DataLayerTypeEnum';
import DataLayer from '@/services/data.layer.service';
import Popup from '@/services/popup.service';
import SubmitterUrls from '@/services/SubmitterUrls.service';
import Info from '@/assets/libraries/popups/types/Info';
import { AxiosResponse } from 'axios';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();
        const auto: Auto = AutoService.getInstance();
        const autoStorage: AutoStorage = AutoStorageService.getInstance();
        const forms: AutoForms = AutoFormsService.getInstance();
        const { isSet, arrayContains, validResponse, assocArrayLength } = useDefine();
        const { translate, hasLocalization } = useTranslate();
        const { formattedCurrency, currencyEuroOrPercentPostfix } = usePrice();
        const stepsSubmitter = useStepsSubmitter();
        const request: AxiosParams = useAxios();
        const url: UrlParams = useUrl();
        const CurrentStep: number = 4;
        const NextStep: number = 5;
        const Facility: string = 'auto-policy';
        const NoAdditionalRisks: Ref<string> = ref('none');
        const appCountry: AppCountry = new AppCountry();
        const vehicleSumCalculation: VehicleSumCalculation = new VehicleSumCalculation();
        const submitIsLocked: Ref<boolean> = ref(false);
        const securityDevicesTabInputOptions: Ref<InputOption[]> = ref([]);
        const cascoPolicyPeriodInputOptions: Ref<InputOption[]> = ref([]);
        const backendDateTime: Ref<string> = ref('');
        const autoInsuranceMaxMonthCount: Ref<number> = ref(1);
        const specialInfoDate: Ref<string> = ref('');
        const skipValidation: Ref<boolean> = ref(false);
        const withAdditionalRisks: Ref<boolean> = ref(false);
        const onlineDiscount: Ref<number> = ref(0);
        const totalPaymentText: Ref<string> = ref('');
        const bankLinkId: Ref<number> = ref(0);
        const bankLinkIdError: Ref<boolean> = ref(false);
        const showAgreementPopup: Ref<boolean> = ref(false);
        const isSecurityError: Ref<boolean> = ref(false);
        const subscriptionCascoDaysToNextPayment: Ref<number> = ref(0);
        const subscriptionMtplDaysToNextPayment: Ref<number> = ref(0);
        const cascoSubscriptionPaymentDateOffsetDays: Ref<number> = ref(0);
        const mtplSubscriptionPaymentDateOffsetDays: Ref<number> = ref(0);
        const turnOnLogin: Ref<boolean> = ref(false);
        const timeToChangeMtplPeriodEndDate: Ref<string> = ref('12:00');
        const useSettlementField: Ref<boolean> = ref(true);
        const policyStartDateOffsetHours: Ref<number> = ref(1);
        const policyStartDateOffsetMinutes: Ref<number> = ref(10);
        const canShowSpecialInfo: Ref<boolean> = ref(false);
        const specialInfoShown: Ref<boolean> = ref(false);
        const informationText: Ref<string | null> = ref(null);
        const openers: UnwrapNestedRefs<AnyAbstract> = reactive(
            new (class extends AnyAbstract {
                public vehicleAndDriver: boolean = true;
                public insurance: boolean = false;
                public paymentDetails: boolean = false;
            })(),
        );
        const monthlyPayments: Ref<DynamicDictionary> = ref({});

        const isSubscription: Ref<boolean> = computed(() => {
            let result: boolean = false;
            if (storageIsReady.value && stepStorageData.value) {
                result = useDefine().isSet(stepStorageData.value.isSubscription)
                    ? stepStorageData.value.isSubscription
                    : false;
            }

            return result;
        });

        const productIsBundle: Ref<boolean> = computed(() => {
            return vehicleProduct.value === VehicleProduct.Bundle;
        });

        const productIsCasco: Ref<boolean> = computed(() => {
            return vehicleProduct.value === VehicleProduct.Casco;
        });

        const productIsCascoOrBundle: Ref<boolean> = computed(() => {
            return productIsCasco.value || productIsBundle.value;
        });

        const summaryTitle: Ref<string> = computed(() => {
            return isSubscription.value ? translate('subscription_details') : translate('btar_summary_title');
        });

        const hasSecurityDevicesField: Ref<boolean> = computed(() => {
            return forms.form.field('securityDevicesTab').value === ''
                ? true
                : forms.form.field('securityDevicesTab').value;
        });

        const mtplAndCascoStartDatesAreEqual: Ref<boolean> = computed(() => {
            return (
                forms.form.field('cascoPolicyStartingDateField').value ===
                forms.form.field('mtplPolicyStartingDateField').value
            );
        });

        const showMarketingConsents: Ref<boolean> = computed(() => {
            return !btaBase.user.isLogged() || (!policyHolderIsLegal() && !btaBase.user.current.receiveMarketing);
        });

        const isVisibleSecurityDevicesTab: Ref<boolean> = computed(() => {
            const alarmGroup: string = '8';
            const attributes: DynamicDictionary = isSet(btaBase.userStorage.storageData.attributes)
                ? btaBase.userStorage.storageData.attributes
                : {};
            const haveSecurityDevices: boolean =
                isSet(attributes.securityDevices) && attributes.securityDevices.length > 0;

            return (
                btaBase.cmsFields.securityDevicesTab.enabled &&
                haveSecurityDevices &&
                (productIsCasco.value || productIsBundle.value) &&
                !(attributes.keylessCar === 'N' && attributes.alarmGroup === alarmGroup)
            );
        });

        const productIsMtpl: Ref<boolean> = computed(() => {
            return vehicleProduct.value === VehicleProduct.Mtpl;
        });

        const securityDevices: Ref<DynamicDictionary> = computed(() => {
            return btaBase.userStorage.storageIsEmpty ? [] : btaBase.userStorage.storageData.attributes.securityDevices;
        });

        const isMobileDevice: Ref<boolean> = computed(() => {
            return btaBase.isMobile.value;
        });

        const hasSecurityDevices: Ref<boolean> = computed(() => {
            return (
                isSet(btaBase.userStorage.storageData.attributes.securityDevices) &&
                btaBase.userStorage.storageData.attributes.securityDevices.length > 0 &&
                (productIsCasco.value || productIsBundle.value)
            );
        });

        const storageIsReady: Ref<boolean> = computed(() => {
            return !btaBase.userStorage.storageIsEmpty;
        });

        const vehicleProduct: Ref<string> = computed(() => {
            return stepStorageData.value ? stepStorageData.value.vehicleProduct : '';
        });

        const storageData: Ref<DynamicDictionary | null> = computed(() => {
            return btaBase.userStorage.storageIsEmpty ? null : btaBase.userStorage.storageData;
        });

        const stepStorageData: Ref<DynamicDictionary | null> = computed(() => {
            return btaBase.userStorage.storageIsEmpty ? null : btaBase.userStorage.stepStorageData;
        });

        const useSettlement: Ref<boolean> = computed(() => {
            return appCountry.isLT() && useSettlementField.value;
        });

        const showEarlyTerminationField: Ref<boolean> = computed(() => {
            return appCountry.isLV() && (productIsMtpl.value || productIsBundle.value);
        });

        const emptyOption: Ref<InputOption> = computed(() => {
            return new InputOptionBuilder().setName('').build();
        });

        const earlyTerminationOptions: Ref<InputOption[]> = computed(() => {
            return [
                new InputOptionBuilder().setValue('Y').setName(useTranslate().translate('btar_yes')).build(),
                new InputOptionBuilder().setValue('N').setName(useTranslate().translate('btar_no')).build(),
            ];
        });

        const hasRequiredSecurityLevel: Ref<boolean> = computed(() => {
            return btaBase.userStorage.storageData.attributes.protectionLevel !== 'NOT_NEEDED';
        });

        const includeSecurityDevicesField: Ref<boolean> = computed(() => {
            const result: boolean = productIsCascoOrBundle.value;

            return appCountry.isLT() ? result && hasRequiredSecurityLevel.value : result;
        });

        const showPhotoNotification: Ref<boolean> = computed(() => {
            return !productIsMtpl.value && btaBase.userStorage.storageData.attributes.photoRequired;
        });

        const insuranceProducts: Ref<string> = computed(() => {
            let result: string = '';
            if (stepStorageData.value) {
                const productLowerCase: string = String(stepStorageData.value.vehicleProduct).toLowerCase();
                result = translate('btar_summary_' + productLowerCase);
                if (withAdditionalRisks.value) {
                    result += ' ' + translate('btar_summary_with_additional_options');
                }
            }

            return result;
        });

        const isPaymentMethodsAvailable: Ref<boolean> = computed(() => {
            return !isApprovalCaseNecessary.value || vehicleProduct.value !== VehicleProduct.Casco;
        });

        const isApprovalCaseNecessary: Ref<boolean> = computed(() => {
            let result: boolean = false;
            if (!btaBase.userStorage.storageIsEmpty && needToCheckIfApprovalCaseIsNecessary()) {
                const splitPaymentsField: string = 'splitCascoPayments';
                const storedVehicleProduct: DynamicDictionary =
                    btaBase.userStorage.storageData.attributes.vehicleProducts[vehicleProduct.value];
                let isApprovalCaseNecessaryValue: string = storedVehicleProduct.isApprovalCaseNecessary || '';
                if (storedVehicleProduct.isApprovalCaseNecessaryByPaymentPeriod) {
                    const period: string = forms.form.exists(splitPaymentsField)
                        ? forms.form.field(splitPaymentsField).value
                        : (stepStorageData.value as DynamicDictionary).paymentType;
                    isApprovalCaseNecessaryValue =
                        storedVehicleProduct.isApprovalCaseNecessaryByPaymentPeriod[period] ||
                        isApprovalCaseNecessaryValue;
                }
                result = ['Y', 'N/A'].includes(isApprovalCaseNecessaryValue);
            }

            return result;
        });

        /* INFO: SUSPENDED: only specific hardcoded info text while MTPL has no items (for now) */
        const coveredInfoTextMtplWithRoadAssistance: Ref<string> = computed(() => {
            let result: string = '';
            if (!btaBase.userStorage.storageIsEmpty) {
                if (
                    stepStorageData.value &&
                    String(stepStorageData.value.additionalOptions).includes('TE_R_ROAD_ASSISTANCE')
                ) {
                    result = translate('btar_covered_info_block_mtpl_with_road_assistance');
                }
            }

            return result;
        });

        const vehicleProductLocalized: Ref<string> = computed(() => {
            let result: string;
            if (vehicleProduct.value === VehicleProduct.Bundle && isApprovalCaseNecessary.value) {
                result = translate('btar_product_' + String(VehicleProduct.Mtpl).toLowerCase());
            } else {
                result = translate('btar_product_' + String(vehicleProduct.value).toLowerCase());
            }

            return result;
        });

        const showAdditionalOptionsText: Ref<boolean> = computed(() => {
            return stepStorageData.value ? stepStorageData.value.additionalOptions !== 'none' : false;
        });

        const previousStepSums: Ref<DynamicDictionary> = computed(() => {
            let result: DynamicDictionary = {};
            if (stepStorageData.value) {
                result = btaBase.user.isLogged()
                    ? stepStorageData.value.sums.authenticated
                    : stepStorageData.value.sums.guest;
            }

            return result;
        });

        const footerDiscountSumFormatted: Ref<string> = computed(() => {
            let result: string = '';
            if (isSubscription.value) {
                let productKeyName: string = vehicleProduct.value.toLowerCase() + 'SubscriptionPrice';
                if (productIsBundle.value && isApprovalCaseNecessary.value) {
                    productKeyName = 'mtplSubscriptionBundlePrice';
                }
                const productSubscriptionPrice: string = previousStepSums.value[productKeyName];
                result = Number(productSubscriptionPrice).toFixed(2);
            } else {
                result = Number(previousStepSums.value.finalDiscountSum).toFixed(2);
                if (
                    isEnabledSplitCascoPayments.value &&
                    (isFixedCascoPaymentType.value || isMonthlyCascoPaymentType.value)
                ) {
                    const mtplDiscountSum: number = productIsBundle.value ? Number(detailsBlockMtplPrice.value) : 0;
                    if (productIsBundle.value && isApprovalCaseNecessary.value) {
                        result = Number(mtplDiscountSum).toFixed(2);
                    } else {
                        result = Number(detailsBlockCascoPrice.value + mtplDiscountSum).toFixed(2);
                    }
                } else {
                    if (productIsBundle.value && isApprovalCaseNecessary.value) {
                        result = Number(previousStepSums.value.mtplBundlePrice).toFixed(2);
                    } else {
                        const productDiscountSum: string = vehicleProduct.value.toLowerCase() + 'DiscountSum';
                        result = Number(previousStepSums.value[productDiscountSum]).toFixed(2);
                    }
                }
            }

            return result;
        });

        const detailsBlockCascoPrice: Ref<string> = computed(() => {
            let result: string;
            if (isFixedCascoPaymentType.value) {
                if (vehicleProduct.value === VehicleProduct.Bundle) {
                    result = formattedCurrency(previousStepSums.value.cascoBundlePrice);
                } else {
                    result = formattedCurrency(previousStepSums.value.cascoFullSum);
                }
            } else {
                if (appCountry.isLV()) {
                    if (vehicleProduct.value === VehicleProduct.Bundle) {
                        result = formattedCurrency(previousStepSums.value.cascoBundlePrice);
                    } else {
                        result = formattedCurrency(
                            vehicleSumCalculation.calculateDiscountSum(previousStepSums.value, VehicleProduct.Casco),
                        );
                    }
                } else {
                    if (vehicleProduct.value === VehicleProduct.Bundle) {
                        result = formattedCurrency(previousStepSums.value.cascoBundlePrice);
                    } else {
                        result = formattedCurrency(previousStepSums.value.cascoDiscountSum);
                    }
                }
            }

            return result;
        });

        const detailsBlockMtplPrice: Ref<string> = computed(() => {
            let result: string;
            if (vehicleProduct.value === VehicleProduct.Bundle) {
                result = formattedCurrency(previousStepSums.value.mtplBundlePrice);
            } else {
                result = formattedCurrency(previousStepSums.value.mtplDiscountSum);
            }
            return result;
        });

        const isEnabledSplitCascoPayments: Ref<boolean> = computed(() => {
            return btaBase.cmsFields.splitCascoPayments.enabled;
        });

        const isMonthlyCascoPaymentType: Ref<boolean> = computed(() => {
            const field: FormField = forms.form.field('splitCascoPayments');

            return (
                (btaBase.cmsFields.splitCascoPayments.enabled
                    ? field.value
                    : btaBase.cmsFields.splitCascoPayments.value) === PaymentType.Monthly
            );
        });

        const isFixedCascoPaymentType: Ref<boolean> = computed(() => {
            const field: FormField = forms.form.field('splitCascoPayments');

            return (
                (btaBase.cmsFields.splitCascoPayments.enabled
                    ? field.value
                    : btaBase.cmsFields.splitCascoPayments.value) === PaymentType.Fixed
            );
        });

        const showFooterDiscountBlock: Ref<boolean> = computed(() => {
            let show: boolean = false;
            if (!isApprovalCaseNecessary.value) {
                if (isSubscription.value) {
                    show =
                        parseFloat(Number(subscriptionSum(true)).toFixed(2)) >
                        parseFloat(Number(subscriptionSum(false)).toFixed(2));
                } else {
                    show = parseFloat(footerFullSumFormatted.value) > parseFloat(footerDiscountSumFormatted.value);
                }
            }

            return show;
        });

        const footerFullSumFormatted: Ref<string> = computed(() => {
            let result: string = '';
            if (isSubscription.value) {
                result = String(subscriptionSum(true).toFixed(2));
            } else {
                result = Number(previousStepSums.value.finalFullSum).toFixed(2);
                if (productIsBundle.value) {
                    result = Number(previousStepSums.value.bundleFullSum).toFixed(2);
                }
            }

            return result;
        });

        const footerSaveSumFormatted: Ref<string> = computed(() => {
            let result: string = '';
            if (isSubscription.value) {
                result = String((subscriptionSum(true) - subscriptionSum(false)).toFixed(2));
            } else {
                result = Number(previousStepSums.value.finalFullSum - previousStepSums.value.finalDiscountSum).toFixed(
                    2,
                );
                if (productIsBundle.value) {
                    result = Number(
                        previousStepSums.value.bundleFullSum - previousStepSums.value.bundleDiscountSum,
                    ).toFixed(2);
                }
            }

            return result;
        });

        const isFixedPaymentType: Ref<boolean> = computed(() => {
            let result: boolean = false;
            if (!isSubscription.value && stepStorageData.value) {
                result = stepStorageData.value.paymentType === PaymentType.Fixed;
            }

            return result;
        });

        const isVisibleProductMtpl: Ref<boolean> = computed(() => {
            let newVehicleProduct: string = '';
            if (stepStorageData.value) {
                newVehicleProduct = stepStorageData.value.vehicleProduct;
            }

            return newVehicleProduct === VehicleProduct.Mtpl || newVehicleProduct === VehicleProduct.Bundle;
        });

        const isVisibleProductCasco: Ref<boolean> = computed(() => {
            let newVehicleProduct: string = '';
            if (stepStorageData.value) {
                newVehicleProduct = stepStorageData.value.vehicleProduct;
            }

            return newVehicleProduct === VehicleProduct.Casco || newVehicleProduct === VehicleProduct.Bundle;
        });

        const residentCheckBoxIsVisible: Ref<boolean> = computed(() => {
            /*  INFO: SUSPENDED:
             *      NOW: hardcoded: always hidden
             *      UNTIL: non resident IRIS support
             */
            return false;
        });

        const detailsBlockTotalPrice: Ref<string> = computed(() => {
            let total: number = Number(detailsBlockMtplPrice.value) + Number(detailsBlockCascoPrice.value);
            if (isEnabledSplitCascoPayments.value && isMonthlyCascoPaymentType.value) {
                total = Number(detailsBlockMtplPrice.value) + Number(detailsBlockCascoPriceByPeriod.value);
            }

            return formattedCurrency(total);
        });

        const detailsBlockSubscriptionPrices: Ref<DynamicDictionary> = computed(() => {
            const sums: DynamicDictionary = previousStepSums.value;
            const results: DynamicDictionary = {};
            if (isSubscription.value) {
                if (productIsBundle.value) {
                    results[VehicleProduct.Mtpl] = {
                        label: translate('mtpl_subscription_policy_price'),
                        price: formattedCurrency(sums.mtplSubscriptionBundlePrice),
                    };
                    results[VehicleProduct.Casco] = {
                        label: translate('casco_subscription_policy_price'),
                        price: formattedCurrency(sums.cascoSubscriptionBundlePrice),
                    };
                    results['TotalPayment'] = {
                        label: translate('btar_payment_details_total_payment'),
                        price: formattedCurrency(sums.bundleSubscriptionPrice),
                    };
                } else {
                    const productPriceName: string = vehicleProduct.value.toLowerCase() + 'SubscriptionPrice';
                    results[vehicleProduct.value] = {
                        label: translate(vehicleProduct.value.toLowerCase() + '_subscription_policy_price'),
                        price: formattedCurrency(previousStepSums.value[productPriceName]),
                    };
                }
            }

            return results;
        });

        const detailsBlockCascoPriceByPeriod: Ref<string> = computed(() => {
            let result: string;
            if (isFixedCascoPaymentType.value) {
                result = formattedCurrency(previousStepSums.value.cascoDiscountSum);
            } else {
                result = formattedCurrency(previousStepSums.value.cascoMonthlySum);
            }

            return result;
        });

        const detailsBlockTotalPriceLabel: Ref<string> = computed(() => {
            let result: string = translate('btar_payment_details_total_payment');
            if (isEnabledSplitCascoPayments.value && isMonthlyCascoPaymentType.value) {
                result = translate('first_casco_payment_and_mtpl');
            }
            return result;
        });

        const subscriptionTermsTitle: Ref<string> = computed(() => {
            let result: string = '';
            if (isSubscription.value) {
                result = translate(
                    btaBase.cmsFields.subscriptionTerms.title + '_' + vehicleProduct.value.toLowerCase(),
                );
            }
            return result;
        });

        const showTotalPayment: Ref<boolean> = computed(() => {
            let result: boolean = false;
            if (stepStorageData.value) {
                const selectedVehicleProduct: string = stepStorageData.value.vehicleProduct;
                const paymentType: string = stepStorageData.value.paymentType;
                const notBundleAndMonthly: boolean =
                    selectedVehicleProduct !== VehicleProduct.Bundle && paymentType === PaymentType.Monthly;
                const bundle: boolean = selectedVehicleProduct === VehicleProduct.Bundle;
                result = (notBundleAndMonthly || bundle) && !isApprovalCaseNecessary.value;
            }

            return result;
        });

        const isMonthlyPaymentDetails: Ref<boolean> = computed(() => {
            let result: boolean = false;
            if (stepStorageData.value) {
                result = stepStorageData.value.paymentType === PaymentType.Monthly;
            }

            return result;
        });

        const detailsBlockSavings: Ref<number> = computed(() => {
            const storageFinalDiscountSum: number = parseFloat(previousStepSums.value.finalDiscountSum);
            const storageFinalFullSum: number = parseFloat(previousStepSums.value.finalFullSum);

            return storageFinalFullSum - storageFinalDiscountSum;
        });

        const detailsBlockSavingsIsVisible: Ref<boolean> = computed(() => {
            return detailsBlockSavings.value !== 0;
        });

        const isVisibleSeparateDatesCheckbox: Ref<boolean> = computed(() => {
            let result: boolean = false;
            if (stepStorageData.value) {
                result = storageIsReady.value && stepStorageData.value.vehicleProduct === VehicleProduct.Bundle;
            }

            return result;
        });

        const isVisibleMtplCalendar: Ref<boolean> = computed(() => {
            return storageIsReady.value && isVisibleProductMtpl.value;
        });

        const isVisibleCascoCalendar: Ref<boolean> = computed(() => {
            let result: boolean = false;
            if (stepStorageData.value && storageIsReady.value && isVisibleProductCasco.value) {
                const product: string = stepStorageData.value.vehicleProduct;
                result = product === VehicleProduct.Bundle ? forms.form.field('differentDates').value : true;
            }

            return result;
        });

        const isAuthUserSameAsOwner: Ref<boolean> = computed(() => {
            let result: boolean = false;
            if (stepStorageData.value) {
                result =
                    btaBase.user.isLogged() &&
                    isOwnerPersonal &&
                    (!stepStorageData.value.ownerPersonCode ||
                        stepStorageData.value.ownerPersonCode === btaBase.user.current.personCode);
            }

            return result;
        });

        const isNameSurnameIsDisabled: Ref<boolean> = computed(() => {
            let result: boolean;
            switch (btaBase.settings.value('LOCALE_ISO')) {
                case 'LT':
                    result = isAuthUserSameAsOwner.value;
                    break;
                default:
                    result = btaBase.user.isLogged();
            }

            return result;
        });

        const isPersonCodeFieldDisabled: Ref<boolean> = computed(() => {
            let result: boolean;
            switch (btaBase.settings.value('LOCALE_ISO')) {
                case 'LT':
                    result =
                        btaBase.user.current.logged ||
                        (typeof stepStorageData.value !== 'undefined' &&
                            stepStorageData.value !== null &&
                            isSet(stepStorageData.value.ownerPersonCode));
                    break;
                default:
                    result = btaBase.user.isLogged();
            }

            return result;
        });

        const isBusinessTitleAndCompanyDisabled: Ref<boolean> = computed(() => {
            let result: boolean;
            switch (btaBase.settings.value('LOCALE_ISO')) {
                case 'LT':
                    result = false;
                    break;
                default:
                    result = btaBase.user.isLogged();
            }

            return result;
        });

        const isRegNumFieldDisabled: Ref<boolean> = computed(() => {
            let result: boolean;
            switch (btaBase.settings.value('LOCALE_ISO')) {
                case 'LT':
                    result = stepStorageData.value && stepStorageData.value.ownerRegistrationCode;
                    break;
                default:
                    result = btaBase.user.isLogged();
            }

            return result;
        });

        const isOwnerPersonal: Ref<boolean> = computed(() => {
            let result: boolean;
            switch (btaBase.settings.value('LOCALE_ISO')) {
                case 'LT':
                    result = forms.form.field('policyOwner').value === PolicyOwner.Personal;
                    break;
                default:
                    result = forms.form.field('policyOwner').value === PolicyOwner.Personal || btaBase.user.isLogged();
            }

            return result;
        });

        const personTypeIsVisible: Ref<boolean> = computed(() => {
            let result: boolean;
            switch (btaBase.settings.value('LOCALE_ISO')) {
                case 'LT':
                    if (stepStorageData.value) {
                        result =
                            !btaBase.user.isLogged() &&
                            !isSet(stepStorageData.value.ownerPersonCode) &&
                            !isSet(stepStorageData.value.ownerRegistrationCode);
                    } else {
                        result = false;
                    }
                    break;
                default:
                    result = !btaBase.user.isLogged();
            }

            return result;
        });

        const calendarInfoShouldBeShowed: Ref<boolean> = computed(() => {
            let result: boolean = true;
            if (btaBase.settings.localeIso() === 'LV' && productIsBundle.value) {
                const bothPoliciesAvailableToday: boolean =
                    isTodayMtplPolicyStartingDate.value && isTodayCascoPolicyStartingDate.value;
                result = isDifferentDates.value || !bothPoliciesAvailableToday;
            }

            return result;
        });

        const isPhoneFieldMobileOnly: Ref<boolean> = computed(() => {
            return new AppCountry().isEE();
        });

        const isResident: Ref<boolean> = computed(() => {
            return forms.form.field('resident').value;
        });

        const isDifferentDates: Ref<boolean> = computed(() => {
            return forms.form.field('differentDates').value;
        });

        const deductiblesAreVisible: Ref<boolean> = computed(() => {
            return isSet(btaBase.userStorage.storageData.attributes.vehicleDeductibles);
        });

        const coveredPopupIsVisible: Ref<boolean> = computed(() => {
            let result: boolean = true;
            if (storageIsReady.value && stepStorageData.value) {
                result = stepStorageData.value.additionalOptions !== '';
            }

            return result;
        });

        const calendarProductName: Ref<string> = computed(() => {
            let result: string = '';
            if (stepStorageData.value) {
                const product: string = stepStorageData.value.vehicleProduct;
                const bothProducts: string =
                    translatedProduct(VehicleProduct.Mtpl) +
                    ' ' +
                    translate('btar_and') +
                    ' ' +
                    translatedProduct(VehicleProduct.Casco);
                const singleCalendarTitle: string =
                    product === VehicleProduct.Bundle ? bothProducts : translatedProduct(product);
                result = isVisibleCascoCalendar.value ? translatedProduct(VehicleProduct.Mtpl) : singleCalendarTitle;
            }

            return result;
        });

        const agreeTermsText: Ref<string> = computed(() => {
            return translate('btar_summary_checkbox_agree_' + String(vehicleProduct.value).toLowerCase());
        });

        const backendTime: Ref<string> = computed(() => {
            return backendDateTime.value;
        });

        const backendTimeAsDate: Ref<Date> = computed(() => {
            return moment(backendTime.value).toDate();
        });

        const useCard: Ref<boolean> = computed(() => {
            return (
                btaBase.cmsFields.cardDetails.enabled && canUseCardForCountry.value && isPaymentMethodsAvailable.value
            );
        });

        const footerSubmitText: Ref<string> = computed(() => {
            return useCard.value ? 'btar_subscribe_and_pay' : 'btar_continue';
        });

        const mtplPolicyMinStartDate: Ref<DateRange> = computed(() => {
            return policyMinStartDate(VehicleProduct.Mtpl);
        });

        const mtplPolicyMinStartDateAsDate: Ref<Date> = computed(() => {
            return moment(mtplPolicyMinStartDate.value.startDate).toDate();
        });

        const cascoPolicyMinStartDate: Ref<DateRange> = computed(() => {
            return policyMinStartDate(VehicleProduct.Casco);
        });

        const cascoPolicyMinStartDateAsDate: Ref<Date> = computed(() => {
            return moment(cascoPolicyMinStartDate.value.startDate).toDate();
        });

        const bundlePolicyMinStartDate: Ref<DateRange> = computed(() => {
            return moment(mtplPolicyMinStartDate.value.startDate).isAfter(
                moment(cascoPolicyMinStartDate.value.startDate),
            )
                ? mtplPolicyMinStartDate.value
                : cascoPolicyMinStartDate.value;
        });

        const mtplPolicyMaxStartDate: Ref<DateRange> = computed(() => {
            return policyMaxStartDate(VehicleProduct.Mtpl);
        });

        const mtplPolicyMaxStartDateAsDate: Ref<Date> = computed(() => {
            return moment(mtplPolicyMaxStartDate.value.startDate).toDate();
        });

        const cascoPolicyMaxStartDate: Ref<DateRange> = computed(() => {
            return policyMaxStartDate(VehicleProduct.Casco);
        });

        const cascoPolicyMaxStartDateAsDate: Ref<Date> = computed(() => {
            return moment(cascoPolicyMaxStartDate.value.startDate).toDate();
        });

        const bundlePolicyMaxStartDate: Ref<DateRange> = computed(() => {
            return moment(mtplPolicyMaxStartDate.value.startDate).isAfter(
                moment(cascoPolicyMaxStartDate.value.startDate),
            )
                ? mtplPolicyMaxStartDate.value
                : cascoPolicyMaxStartDate.value;
        });

        const mtplPolicyStartingDate: Ref<DateRange> = computed(() => {
            return forms.form.field('mtplPolicyStartingDateField').value;
        });

        const cascoPolicyStartingDate: Ref<DateRange> = computed(() => {
            return forms.form.field('cascoPolicyStartingDateField').value;
        });

        const mtplPolicyEndingDate: Ref<DateRange> = computed(() => {
            return policyEndDate(mtplPolicyStartingDate.value.startDate, VehicleProduct.Mtpl);
        });

        const cascoPolicyEndingDate: Ref<DateRange> = computed(() => {
            return policyEndDate(cascoPolicyStartingDate.value.startDate, VehicleProduct.Casco);
        });

        const isTodayMtplPolicyStartingDate: Ref<boolean> = computed(() => {
            return isDateToday(mtplPolicyStartingDate.value.startDate);
        });

        const isTodayCascoPolicyStartingDate: Ref<boolean> = computed(() => {
            return isDateToday(cascoPolicyStartingDate.value.startDate);
        });

        const showBirthDate: Ref<boolean> = computed(() => {
            return (
                isOwnerPersonal.value &&
                btaBase.cmsFields.birthDate.enabled &&
                !isPersonCodeFieldDisabled.value &&
                isValidPersonCodeWithoutDate()
            );
        });

        const policyStartingDateOffsetHours: Ref<number> = computed(() => {
            return policyStartDateOffsetHours.value;
        });

        const policyStartingDateOffsetMinutes: Ref<number> = computed(() => {
            return policyStartDateOffsetMinutes.value;
        });

        const canUseCardForCountry: Ref<boolean> = computed(() => {
            let result: boolean;
            switch (true) {
                case appCountry.isLV():
                    result = isSubscription.value;
                    break;
                case appCountry.isEE():
                    result = isMonthlyPaymentDetails.value;
                    break;
                default:
                    result = false;
            }

            return result;
        });

        const policyHolderTypeIsPrivate: Ref<boolean> = computed(() => {
            return forms.form.field('policyOwner').value === PolicyOwner.Personal;
        });

        const insuranceOptionsAreVisible: Ref<boolean> = computed(() => {
            return isSet(btaBase.userStorage.storageData.attributes.cascoInsuranceOptionsIc);
        });

        const insuranceOptionsValue: Ref<string> = computed(() => {
            let result: string = '';
            if (insuranceOptionsAreVisible.value) {
                result = btaBase.userStorage.storageData.attributes.cascoInsuranceOptionsIc.toLowerCase();
            }
            return result;
        });

        const hasDeductiblesOptions: Ref<boolean> = computed(() => {
            let hasDeductibleOptions = !isMtplProduct.value;
            if (hasDeductibleOptions && isSet(storageAttributes()?.vehicleProducts?.CASCO?.damageDeductiblePremiums)) {
                const deductiblePremiums = storageAttributes().vehicleProducts.CASCO.damageDeductiblePremiums;
                hasDeductibleOptions = Object.keys(deductiblePremiums).length > 1;
            } else {
                hasDeductibleOptions = hasDeductibleOptions && isSet(storageAttributes().vehicleDeductibleOptions);
            }
            return hasDeductibleOptions;
        });

        const isMtplProduct: Ref<boolean> = computed(() => {
            return vehicleProduct.value === VehicleProduct.Mtpl;
        });

        let onFormFieldValidationSubscription: Subscription;

        watch(
            () => showBirthDate.value,
            () => {
                forms.form.field('birthDate').value = '';
            },
        );

        watch(
            () => isDifferentDates.value,
            (isDifferent) => {
                onDifferentDatesChange(isDifferent);
            },
        );

        watch(
            () => mtplPolicyStartingDate.value,
            (value: Date | string | DateRange) => {
                if (value instanceof Date) {
                    const dateWithOffset: Date = policyDateWithOffsetTime(value, VehicleProduct.Mtpl);
                    if (value.toString() !== dateWithOffset.toString()) {
                        forms.form.field('mtplPolicyStartingDateField').patch(dateWithOffset);
                    }
                } else if (typeof value === 'string') {
                    const dateWithOffset: Date = policyDateWithOffsetTime(value, VehicleProduct.Mtpl);
                    if (value !== dateWithOffset.toString()) {
                        forms.form.field('mtplPolicyStartingDateField').patch(dateWithOffset);
                    }
                } else {
                    const dateWithOffset: Date = policyDateWithOffsetTime(value.startDate, VehicleProduct.Mtpl);
                    if (value.startDate.toString() !== dateWithOffset.toString()) {
                        forms.form.field('mtplPolicyStartingDateField').patch(dateWithOffset);
                    }
                }
                if (vehicleProduct.value === VehicleProduct.Bundle && !isDifferentDates.value) {
                    forms.form
                        .field('cascoPolicyStartingDateField')
                        .patch(forms.form.field('mtplPolicyStartingDateField').value);
                }
                generatePaymentDates(vehicleProduct.value);
            },
        );

        watch(
            () => cascoPolicyStartingDate.value,
            (value: Date | string | DateRange) => {
                if (value instanceof Date || typeof value === 'string') {
                    const dateWithOffset: Date = policyDateWithOffsetTime(value, VehicleProduct.Casco);
                    if (value.toString() !== dateWithOffset.toString()) {
                        forms.form.field('cascoPolicyStartingDateField').patch(dateWithOffset);
                    }
                } else {
                    const dateWithOffset: Date = policyDateWithOffsetTime(value.startDate, VehicleProduct.Casco);
                    if (value.startDate.toString() !== dateWithOffset.toString()) {
                        forms.form.field('cascoPolicyStartingDateField').patch(dateWithOffset);
                    }
                }
                generatePaymentDates(vehicleProduct.value);
            },
        );

        function setupForm(): void {
            btaBase.setStep(CurrentStep);
            forms.form.addField(new FormField('policyOwner', PolicyOwner.Personal));
            forms.form.addField(new FormField('resident', ''));
            const nameSanitizer: Function = new AppCountry().isLT() ? Sanitizer.cleanNameLt : Sanitizer.cleanName;
            forms.form.addField(
                new FormField('firstName', '', personNameValidator('firstName'), nameSanitizer as SanitizerCallback),
            );
            forms.form.addField(
                new FormField('lastName', '', personNameValidator('lastName'), nameSanitizer as SanitizerCallback),
            );
            forms.form.addField(new FormField('personCode', '', personCodeValidator(), Sanitizer.clearPersonCode));
            forms.form.addField(new FormField('businessTitle', '', companyNameValidator()));
            if (btaBase.cmsFields.companyType.enabled) {
                forms.form.addField(new FormField('companyType', '', companyTypeValidator()));
            }
            forms.form.addField(
                new FormField(
                    'businessRegNumber',
                    '',
                    businessRegNumberValidator(),
                    Sanitizer.cleanCompanyRegistrationNumber,
                ),
            );
            if (btaBase.cmsFields.country.enabled) {
                forms.form.addField(new FormField('country', '', countryValidator()));
            }
            if (btaBase.cmsFields.address.enabled) {
                forms.form.addField(new FormField('address', '', 'required'));
            }
            if (btaBase.cmsFields.birthDate.enabled) {
                forms.form.addField(new FormField('birthDate', '', birthDateValidator()));
            }
            forms.form.addField(new FormField('email', '', 'required'));
            forms.form.addField(new FormField('phone', '', 'required'));
            forms.form.addField(
                new FormField(
                    'mtplPolicyStartingDateField',
                    '',
                    mtplPolicyStartingDateValidator(
                        vehicleProduct.value === VehicleProduct.Mtpl && vehicleProduct.value !== VehicleProduct.Bundle,
                    ),
                ),
            );
            forms.form.addField(
                new FormField(
                    'cascoPolicyStartingDateField',
                    '',
                    cascoPolicyStartingDateValidator(
                        vehicleProduct.value === VehicleProduct.Casco || vehicleProduct.value === VehicleProduct.Bundle,
                    ),
                ),
            );
            forms.form.addField(new FormField('differentDates', ''));
            if (isVisibleSecurityDevicesTab.value) {
                forms.form.addField(new FormField('securityDevicesTab', '', securityDevicesTabValidator()));
            }
            if (btaBase.cmsFields.agreeTerms.enabled) {
                forms.form.addField(new FormField('agreeTerms', '', 'checkboxChecked'));
            }
            forms.form.addField(new FormField('marketingConsents'));
            if (btaBase.cmsFields.subscriptionTerms.enabled) {
                forms.form.addField(new FormField('subscriptionTerms', '', 'checkboxChecked'));
            }
            if (btaBase.cmsFields.securityDevicesInstalled.enabled && includeSecurityDevicesField.value) {
                forms.form.addField(new FormField('securityDevicesInstalled', '', securityDevicesInstalledValidator()));
            }
            if (
                btaBase.cmsFields.splitCascoPayments.enabled &&
                (vehicleProduct.value === VehicleProduct.Casco || vehicleProduct.value === VehicleProduct.Bundle)
            ) {
                forms.form.addField(new FormField('splitCascoPayments', PaymentType.Fixed));
            }
            onFormFieldValidationSubscription = forms.form.onValidation.subscribe((): void => {
                isSecurityError.value =
                    forms.form.field('securityDevicesTab').isTouched && !forms.form.field('securityDevicesTab').isValid;
            });
            if (showEarlyTerminationField.value) {
                forms.form.addField(new FormField('earlyTermination', '', 'required'));
            }
            forms.form.setReady();
            forms.formsAreReady.value = true;
        }

        function personNameValidator(fieldName: string): object {
            return {
                validate: () =>
                    Validation.isValidCaption(forms.form.field(fieldName).value) || !policyHolderTypeIsPrivate.value,
            };
        }

        function personCodeValidator(): object {
            return {
                validate: () => {
                    return (
                        Validation.isValidNaturalPersonCode(forms.form.field('personCode').value) ||
                        !policyHolderTypeIsPrivate.value
                    );
                },
            };
        }

        function isValidPersonCodeWithoutDate(value: string = ''): boolean {
            return isSet(value)
                ? Validation.isValidPersonCodeWithoutDate(forms.form.field('personCode').value) ||
                      !policyHolderTypeIsPrivate.value
                : false;
        }

        function birthDateValidator(): object {
            return {
                validate: () => {
                    return (
                        (policyHolderTypeIsPrivate.value && !forms.form.field('birthDate').isEmpty()) ||
                        moment(forms.form.field('birthDate').value).isBefore(moment()) ||
                        !policyHolderTypeIsPrivate.value
                    );
                },
            };
        }

        function companyNameValidator(): object {
            return {
                validate: () =>
                    Validation.isValidCaption(forms.form.field('businessTitle').value) ||
                    policyHolderTypeIsPrivate.value,
            };
        }

        function companyTypeValidator(): object {
            return {
                validate: () => !forms.form.field('companyType').isEmpty() || policyHolderTypeIsPrivate.value,
            };
        }

        function businessRegNumberValidator(): object {
            return {
                validate: () =>
                    Validation.isValidCompanyRegistrationNumber(forms.form.field('businessRegNumber').value) ||
                    policyHolderTypeIsPrivate.value,
            };
        }

        function countryValidator(): object {
            return {
                validate: () => !forms.form.field('country').isEmpty() || policyHolderTypeIsPrivate.value,
            };
        }

        function securityDevicesTabValidator(): object {
            return {
                validate: () => !forms.form.field('securityDevicesTab').isEmpty(),
            };
        }

        function securityDevicesInstalledValidator(): object {
            return {
                validate: () => forms.form.field('securityDevicesInstalled').value,
            };
        }

        function mtplPolicyStartingDateValidator(isRequired: boolean): object {
            return {
                validate: () => !forms.form.field('mtplPolicyStartingDateField').isEmpty() || !isRequired,
            };
        }

        function cascoPolicyStartingDateValidator(isRequired: boolean): object {
            return {
                validate: () => !forms.form.field('cascoPolicyStartingDateField').isEmpty() || !isRequired,
            };
        }

        function onAddressEdit(): void {
            useSettlementField.value = true;
        }

        function subscriptionSum(full: boolean): number {
            const fullPrice: string = full ? 'Full' : '';
            const productKeyName: string = vehicleProduct.value.toLowerCase() + 'Subscription' + fullPrice + 'Price';

            return previousStepSums.value[productKeyName];
        }

        function policyHolderIsLegal(): boolean {
            let result: boolean = false;
            if (stepStorageData.value) {
                result = !!stepStorageData.value.ownerRegistrationCode;
            }

            return result;
        }

        function needToCheckIfApprovalCaseIsNecessary(): boolean {
            return [VehicleProduct.Casco, VehicleProduct.Bundle].includes(vehicleProduct.value);
        }

        function applySpecialInfoDate(value: string): void {
            specialInfoDate.value = value;
        }

        function applyBackendDateTime(value: string): void {
            backendDateTime.value = value;
        }

        function applyLoginRequirement(value: boolean): void {
            turnOnLogin.value = value;
        }

        function applyShowAgreementPopupStatus(value: boolean): void {
            showAgreementPopup.value = value;
        }

        function applySubscriptionMtplDaysToNextPayment(value: number): void {
            subscriptionMtplDaysToNextPayment.value = value;
        }

        function applySubscriptionCascoDaysToNextPayment(value: number): void {
            subscriptionCascoDaysToNextPayment.value = value;
        }

        function applyMtplSubscriptionPaymentDateOffsetDays(value: number): void {
            mtplSubscriptionPaymentDateOffsetDays.value = value;
        }

        function applyCascoSubscriptionPaymentDateOffsetDays(value: number): void {
            cascoSubscriptionPaymentDateOffsetDays.value = value;
        }

        function applyOnlineDiscount(value: number): void {
            onlineDiscount.value = value;
        }

        function applyInsuranceMaxMonthCount(value: number): void {
            autoInsuranceMaxMonthCount.value = value;
        }

        function propertyLabel(): string {
            return btaBase.countryIso.value === 'LT'
                ? translate('btar_town_village')
                : translate('btar_property_address');
        }

        function addressLabel(): string {
            return translate('btar_apartment');
        }

        function hintLabel(): string {
            return translate('btar_property_address');
        }

        function receiverCallback(param: any): any {
            const result: any = param;
            if (btaBase.countryIso.value === 'LT') {
                const parts: string[] = String(param.label).split(',');
                const expectedParts: number = 2;
                if (parts.length >= expectedParts) {
                    result.label = parts[0] + ', ' + parts[1];
                }
            }
            return result;
        }

        function onAddressLockChange(isLocked: boolean): void {
            const showApartment: boolean =
                isSet(forms.form.field('address').value.apartment) &&
                forms.form.field('address').value.apartment !== '';
            if (isLocked && !showApartment) {
                skipValidation.value = true;
                $('.apartment').hide();
            } else {
                skipValidation.value = false;
                $('.apartment').show();
            }
        }

        function openerClasses(openerId: string): string {
            let result: string = '';
            if (openers[openerId]) {
                result = CssClass.Active;
            }

            return result;
        }

        function openerToggle(event: VueEvent): void {
            const dataId: string = event.data('id') as string;
            openers[dataId] = !openers[dataId];
        }

        function coveredProductTabButtonClick(product: string): void {
            auto.coveredPopup.contentID = product;
            auto.coveredPopup.default.setValue(auto.coveredPopup.contentID);
        }

        function additionalFooterCoveredButton(event: VueEvent): void {
            if (stepStorageData.value && stepStorageData.value.coveredPopup) {
                prepareCoveredPopup(vehicleProduct.value, true);
                PopupService.getInstance().show(new OnePopup().withType().covered);
                nextTick((): void => {
                    btaBase.initPopupTooltips();
                });
            }
        }

        function prepareCoveredPopup(productId: string, allRisks: boolean): void {
            const storage: DynamicDictionary = btaBase.userStorage.storageData;
            auto.coveredPopup.contentID = productId === VehicleProduct.Bundle ? VehicleProduct.Casco : productId;
            auto.coveredPopup.default.setValue(auto.coveredPopup.contentID);
            auto.coveredPopup.title = translate('btar_what_covered');
            auto.coveredPopup.descriptionMtpl = translate('btar_what_covered_description_mtpl');
            auto.coveredPopup.descriptionCasco = translate('btar_what_covered_description_casco');
            if (isSet(storage.attributes.vehicleDeductibles)) {
                const vehicleDeductibles: DynamicDictionary = storage.attributes.vehicleDeductibles;
                const damageDeductible: string = popupsDamageDeductible(vehicleDeductibles);
                if (damageDeductible) {
                    damageDeductible !== vehicleDeductibles.totalLossDeductibleIc
                        ? (auto.coveredPopup.deductibles.btar_damage_deductible =
                              currencyEuroOrPercentPostfix(damageDeductible))
                        : (auto.coveredPopup.deductibles.btar_vehicle_damage_total_loss_deductible =
                              currencyEuroOrPercentPostfix(damageDeductible));
                }
                if (vehicleDeductibles.theftAndTotalLossDeductibleIc) {
                    auto.coveredPopup.deductibles.theft_and_total_loss_deductible = currencyEuroOrPercentPostfix(
                        vehicleDeductibles.theftAndTotalLossDeductibleIc,
                    );
                } else {
                    if (vehicleDeductibles.theftDeductibleIc) {
                        auto.coveredPopup.deductibles.btar_theft_deductible = currencyEuroOrPercentPostfix(
                            vehicleDeductibles.theftDeductibleIc,
                        );
                    }
                    if (
                        vehicleDeductibles.totalLossDeductibleIc &&
                        vehicleDeductibles.damageDeductibleIc !== vehicleDeductibles.totalLossDeductibleIc
                    ) {
                        auto.coveredPopup.deductibles.btar_total_loss_deductible = currencyEuroOrPercentPostfix(
                            vehicleDeductibles.totalLossDeductibleIc,
                        );
                    }
                }
                if (vehicleDeductibles.glassDeductibleIc) {
                    auto.coveredPopup.deductibles.btar_glass_deductible = currencyEuroOrPercentPostfix(
                        popupGlassDeductible(vehicleDeductibles),
                    );
                }
                if (vehicleDeductibles.deductibleIc) {
                    auto.coveredPopup.deductibles.btar_deductible = currencyEuroOrPercentPostfix(
                        vehicleDeductibles.deductibleIc,
                    );
                }
            }
            let applyFromProducts: string[] = [VehicleProduct.Mtpl, VehicleProduct.Casco];
            auto.coveredPopup.insuredRisksMTPL = {};
            auto.coveredPopup.insuredRisksCASCO = {};
            for (let i: number = 0; i < applyFromProducts.length; i++) {
                auto.appendBasicRisksToCoveredPopup(applyFromProducts[i]);
            }
            if (allRisks) {
                applyFromProducts = applyFromThisProducts();
                for (let i: number = 0; i < applyFromProducts.length; i++) {
                    appendSelectedRisksToCoveredPopup(applyFromProducts[i]);
                }
            }
        }

        function popupsDamageDeductible(vehicleDeductibles: DynamicDictionary): string {
            let result: string;
            if (hasDeductiblesOptions.value) {
                result = String(damageDeductiblePrice());
            } else {
                result = vehicleDeductibles.modifiedDamageDeductibleIc
                    ? vehicleDeductibles.modifiedDamageDeductibleIc
                    : vehicleDeductibles.damageDeductibleIc;
            }

            return result;
        }

        function damageDeductiblePrice(): number {
            const price: number = forms.form.field('damageDeductibles').isEmpty()
                ? 0
                : parseFloat(forms.form.field('damageDeductibles').value);

            return hasDeductiblesOptions.value ? price : 0;
        }

        function storageAttributes(): DynamicDictionary {
            const storage: DynamicDictionary = btaBase.userStorage.storageData;

            return isSet(storage.attributes) ? storage.attributes : {};
        }

        function applyFromThisProducts(): string[] {
            return productIsBundle.value ? [VehicleProduct.Mtpl, VehicleProduct.Casco] : [vehicleProduct.value];
        }

        function appendSelectedRisksToCoveredPopup(product: string): void {
            const additionalRisks =
                product === VehicleProduct.Casco
                    ? Object.keys(stepStorageData.value!.coveredPopup.insuredRisksCASCO)
                    : Object.keys(stepStorageData.value!.coveredPopup.insuredRisksMTPL);
            additionalRisks.forEach((risk: string) => {
                auto.coveredPopup['insuredRisks' + product][risk] = risk + '_DESCRIPTION';
                auto.appendRiskChildren(product, risk);
            });
        }

        function popupGlassDeductible(vehicleDeductibles: DynamicDictionary): string {
            return hasDeductiblesOptions.value ? String(glassDeductiblePrice()) : vehicleDeductibles.glassDeductibleIc;
        }

        function glassDeductiblePrice(): number {
            const price: number = forms.form.field('glassDeductibles').isEmpty()
                ? '0P'
                : forms.form.field('glassDeductibles').value;

            return hasDeductiblesOptions.value ? price : 0;
        }

        function additionalFooterSubmit(event: VueEvent): void {
            if (useCard.value) {
                bankLinkId.value = RecurringPaymentBanklink.ByCountry[appCountry.iso()];
            }

            formSubmit();
        }

        function updateContentID(): void {
            auto.coveredPopup.contentID = auto.coveredPopup.default.value;
        }

        function formSubmit(): void {
            if (btaBase.cmsFields.birthDate.enabled && isOwnerPersonal.value && !showBirthDate.value) {
                const birthDate: Date | undefined = new ExtractDataService().birthDateFromPersonCode(
                    forms.form.field('personCode').value,
                );
                if (birthDate) {
                    forms.form.field('birthDate').patch(birthDate);
                }
            }
            if (isPaymentMethodsAvailable.value && !useCard.value) {
                validateBankLinkId();
            } else {
                bankLinkIdError.value = false;
            }
            forms.form.touch().then((): void => {
                forms.form.validate().then((): void => {
                    if (forms.form.isValid()) {
                        if (bankLinkIdError.value) {
                            onSubmitShowBankPaymentError();
                        } else if (useCard.value) {
                            if (!showAgreementPopup.value) {
                                submitStep3();
                            } else {
                                if (showAgreementPopup.value) {
                                    showAgreement();
                                } else {
                                    submitStep3();
                                }
                            }
                        } else {
                            showAgreementPopup.value ? showAgreement() : submitStep3();
                        }
                    } else {
                        const invalidElements: JQuery = $('.invalid').not('[style*="display: none"]');
                        if (invalidElements.length > 0) {
                            invalidElements[0].scrollIntoView({
                                behavior: 'smooth',
                                block: 'start',
                                inline: 'center',
                            });
                        }
                    }
                });
            });
        }

        function validateBankLinkId(): void {
            bankLinkIdError.value = bankLinkId.value === 0;
        }

        function onSubmitShowBankPaymentError(): void {
            scrollToBanksBlock();
            const popup: SimpleError = new OnePopup()
                .withType()
                .simpleError.withTitle(translate('btar_error_payment_method_not_selected_title'))
                .withDescription(translate('btar_error_payment_method_not_selected_description'));
            PopupService.getInstance().show(popup);
        }

        function scrollToBanksBlock(): void {
            btaBase.scrollToAliasByName('banks-list');
        }

        function choosePayment(event: VueEvent): void {
            const newBankLinkId: number = event.params.bankLinkId;
            const newInformationText: string = event.params.informationText;
            if (newBankLinkId > 0) {
                event.sender.siblings('.' + CssClass.Active).removeClass(CssClass.Active);
                event.sender.toggleClass(CssClass.Active);
                bankLinkId.value = newBankLinkId;
                informationText.value = newInformationText ? translate(String(newInformationText).toLowerCase()) : null;
            }
        }

        function isVisibleInformationalText(): boolean {
            return bankLinkId.value > 0 && !!informationText.value;
        }

        function translatedProduct(newVehicleProduct: string): string {
            return translate('btar_product_' + String(newVehicleProduct).toLowerCase());
        }

        function showAgreement(): void {
            const product: string = stepStorageData.value && stepStorageData.value.vehicleProduct;
            const products: DynamicDictionary = storageData.value && storageData.value.attributes.vehicleProducts;
            let title: string = !appCountry.isLT()
                ? translate('additional_terms_popup_title')
                : translate('btar_summary_additional_terms');
            let agreeText: string =
                appCountry.isLV() && product === VehicleProduct.Casco
                    ? translate('btar_summary_casco_additional_terms_agree')
                    : translate('btar_summary_additional_terms_agree');
            title = title.split('%').join(product);
            agreeText = agreeText.split('%').join(product);
            const productsToCheck: string[] = [];
            if (product === VehicleProduct.Bundle) {
                productsToCheck.push(VehicleProduct.Mtpl);
                productsToCheck.push(VehicleProduct.Casco);
            } else {
                productsToCheck.push(product);
            }
            const description: string[] = [];
            for (let i: number = 0; i < productsToCheck.length; i++) {
                const productToCheck: DynamicDictionary = products[productsToCheck[i]];
                if (isSet(productToCheck.additionalTerms) && productToCheck.additionalTerms.length > 0) {
                    description.push(fetchAdditionalTerms(productToCheck.additionalTerms));
                }
            }
            if (description.length === 0) {
                submitStep3();
            } else {
                const descriptionHtml: string = description.join('');
                btaBase.popup
                    .applyTextTitle(title)
                    .applyTextDescription(descriptionHtml)
                    .applyTextAgree(agreeText)
                    .applyCallbackModel(submitStep3)
                    .applyLogoutOnClose(false)
                    .showPopup(PopupType.TextAgree);
            }
        }

        function fetchAdditionalTerms(terms: any[]): string {
            let result: string = '';
            for (let i: number = 0; i < terms.length; i++) {
                if (hasLocalization(terms[i])) {
                    result += '<p>' + translate(terms[i]) + '</p>';
                }
            }

            return result;
        }

        function generateTotalPayments(): void {
            const selectedVehicleProduct: string = stepStorageData.value && stepStorageData.value.vehicleProduct;
            const paymentType: string = stepStorageData.value && stepStorageData.value.paymentType;
            const notBundleAndMonthly: boolean =
                selectedVehicleProduct !== VehicleProduct.Bundle && paymentType === PaymentType.Monthly;
            const bundle: boolean = selectedVehicleProduct === VehicleProduct.Bundle;
            if (notBundleAndMonthly) {
                totalPaymentText.value = useTranslate().translate('btar_payment_details_monthly_payment');
            } else if (bundle && paymentType === PaymentType.Fixed) {
                totalPaymentText.value = useTranslate().translate('btar_payment_details_total_payment');
            } else if (bundle && paymentType === PaymentType.Monthly) {
                totalPaymentText.value = useTranslate().translate('btar_payment_details_total_monthly_payment');
            }
        }

        function applyPolicyDates(): void {
            forms.form.field('mtplPolicyStartingDateField').patch(mtplPolicyMinStartDate.value);
            forms.form.field('cascoPolicyStartingDateField').patch(cascoPolicyMinStartDate.value);
            if (
                vehicleProduct.value === VehicleProduct.Bundle &&
                !isDifferentDates.value &&
                !moment(mtplPolicyStartingDate.value.startDate).isSame(moment(cascoPolicyStartingDate.value.startDate))
            ) {
                forms.form.field('differentDates').patch(true);
            }
        }

        function generatePaymentDates(selectedVehicleProduct: string): void {
            monthlyPayments.value = {};
            if (selectedVehicleProduct === VehicleProduct.Bundle) {
                addDatesForBundle();
            } else {
                addDatesForProduct(selectedVehicleProduct);
            }
            checkProductEndDatesAndShowPopup();
        }

        function addDatesForProduct(selectedVehicleProduct: string): void {
            if (btaBase.userStorage.storageData.attributes) {
                const vehicleProducts: DynamicDictionary = btaBase.userStorage.storageData.attributes.vehicleProducts;
                const endTime: string = '23:59';
                if (useDefine().isSet(vehicleProducts[selectedVehicleProduct].policyStartDate)) {
                    const date: string = moment(transportPolicyStartDate(selectedVehicleProduct)).toISOString();
                    monthlyPayments.value[selectedVehicleProduct] = {
                        startTitle: translate('btar_payment_details_start_date'),
                        startDate: paymentDateColoredString(date),
                        startHours: redHours(date),
                        nextTitle: translate('btar_payment_details_next_date'),
                        nextDate: paymentDateNextPayment(date),
                        endDate: appCountry.isLT()
                            ? moment(policyEndDate(date, selectedVehicleProduct).endDate).format(auto.dateFormatLT)
                            : btaBase
                                  .momentLocalized(policyEndDate(date, selectedVehicleProduct).endDate)
                                  .format(auto.dateFormat),
                        endHours: endTime,
                    };
                }
            }
        }

        function addDatesForBundle(): void {
            const paymentStartDate: string = translate('btar_payment_details_start_date');
            const paymentNextDate: string = translate('btar_payment_details_next_date');
            const endTime: string = '23:59';
            const vehicleProducts: DynamicDictionary = btaBase.userStorage.storageData.attributes.vehicleProducts;
            if (!isDifferentDates.value && bothPaymentDatesAreEqual()) {
                const policyDate: string =
                    mtplPolicyStartingDate.value instanceof Date || typeof mtplPolicyStartingDate.value === 'string'
                        ? mtplPolicyStartingDate.value.toString()
                        : mtplPolicyStartingDate.value.startDate.toString();
                monthlyPayments.value[VehicleProduct.Mtpl] = {
                    startTitle: translate('btar_payment_details_policies_start_dates'),
                    startDate: paymentDateColoredString(policyDate),
                    startHours: redHours(policyDate),
                    nextTitle: paymentNextDate,
                    nextDate: paymentDateNextPayment(policyDate),
                    endDate: appCountry.isLT()
                        ? moment(policyEndDate(policyDate, VehicleProduct.Mtpl).endDate).format(auto.dateFormatLT)
                        : btaBase
                              .momentLocalized(policyEndDate(policyDate, VehicleProduct.Mtpl).endDate)
                              .format(auto.dateFormat),
                    endHours: endTime,
                };
                monthlyPayments.value[VehicleProduct.Casco] = {
                    startHours: redHours(policyDate),
                };
            } else {
                const mtplIsSet: boolean = useDefine().isSet(vehicleProducts[VehicleProduct.Mtpl].policyStartDate);
                const cascoIsSet: boolean = useDefine().isSet(vehicleProducts[VehicleProduct.Casco].policyStartDate);
                if (mtplIsSet) {
                    const mtplDate: string =
                        mtplPolicyStartingDate.value instanceof Date || typeof mtplPolicyStartingDate.value === 'string'
                            ? mtplPolicyStartingDate.value.toString()
                            : mtplPolicyStartingDate.value.startDate.toString();
                    monthlyPayments.value[VehicleProduct.Mtpl] = {
                        startTitle: translatedProduct(VehicleProduct.Mtpl) + ' ' + paymentStartDate,
                        startDate: paymentDateColoredString(mtplDate),
                        startHours: redHours(mtplDate),
                        nextTitle: translatedProduct(VehicleProduct.Mtpl) + ' ' + paymentNextDate,
                        nextDate: paymentDateNextPayment(mtplDate),
                        endDate: appCountry.isLT()
                            ? moment(policyEndDate(mtplDate, VehicleProduct.Mtpl).endDate).format(auto.dateFormatLT)
                            : btaBase
                                  .momentLocalized(policyEndDate(mtplDate, VehicleProduct.Mtpl).endDate)
                                  .format(auto.dateFormat),
                        endHours: endTime,
                    };
                }
                if (cascoIsSet) {
                    const cascoDate: string =
                        cascoPolicyStartingDate.value instanceof Date ||
                        typeof cascoPolicyStartingDate.value === 'string'
                            ? cascoPolicyStartingDate.value.toString()
                            : cascoPolicyStartingDate.value.startDate.toString();
                    monthlyPayments.value[VehicleProduct.Casco] = {
                        startTitle: translatedProduct(VehicleProduct.Casco) + ' ' + paymentStartDate,
                        startDate: paymentDateColoredString(cascoDate),
                        startHours: redHours(cascoDate),
                        nextTitle: translatedProduct(VehicleProduct.Casco) + ' ' + paymentNextDate,
                        nextDate: paymentDateNextPayment(cascoDate),
                        endDate: appCountry.isLT()
                            ? moment(policyEndDate(cascoDate, VehicleProduct.Casco).endDate).format(auto.dateFormatLT)
                            : btaBase
                                  .momentLocalized(policyEndDate(cascoDate, VehicleProduct.Casco).endDate)
                                  .format(auto.dateFormat),
                        endHours: endTime,
                    };
                }
                if (
                    cascoIsSet &&
                    mtplIsSet &&
                    monthlyPayments.value[VehicleProduct.Mtpl].nextDate ===
                        monthlyPayments.value[VehicleProduct.Casco].nextDate
                ) {
                    delete monthlyPayments.value[VehicleProduct.Mtpl]['nextDate'];
                    delete monthlyPayments.value[VehicleProduct.Mtpl]['nextTitle'];
                    monthlyPayments.value[VehicleProduct.Casco].nextTitle = paymentNextDate;
                }
            }
        }

        function calendarToIsoString(calendarDate: Date | string): string {
            const time: moment.Moment = btaBase.momentLocalized(calendarDate);

            return time.toISOString(true);
        }

        function transportPolicyStartDate(policyProduct: string): Date | string {
            const newPolicyStartDate: DateRange =
                policyProduct === VehicleProduct.Mtpl ? mtplPolicyStartingDate.value : cascoPolicyStartingDate.value;

            return (
                (newPolicyStartDate instanceof Date ? newPolicyStartDate : newPolicyStartDate.startDate) ||
                policyMinStartDate(policyProduct).startDate
            );
        }

        function policyMinStartDate(vehicleProductId: string): DateRange {
            const product: DynamicDictionary =
                btaBase.userStorage.storageData.attributes.vehicleProducts[vehicleProductId];
            const backendDate: moment.Moment = btaBase.momentLocalized(backendDateTime.value);
            const policyStartDateFormatted: string = moment(product.policyStartDate, 'YYYY-MM-DD').toDate().toString();
            const productStartDate: moment.Moment = useDefine().isSet(product.policyStartDate)
                ? btaBase.momentLocalized(policyStartDateFormatted)
                : backendDate;
            const minStartDate: moment.Moment = productStartDate.isAfter(backendDate, 'day')
                ? productStartDate
                : backendDate;
            const newPolicyDateWithOffsetTime: string = policyDateWithOffsetTime(
                minStartDate.toDate(),
                vehicleProductId,
            ).toString();

            return new (class implements DateRange {
                public startDate: string = newPolicyDateWithOffsetTime;
                public endDate: string = '';
            })();
        }

        function policyMaxStartDate(vehicleProductId: string): DateRange {
            const backendDate: moment.Moment = btaBase.momentLocalized(backendDateTime.value);
            const maxMonthCount: number = autoInsuranceMaxMonthCount.value;

            return new (class implements DateRange {
                public startDate: string = backendDate
                    .add(maxMonthCount * 30, 'day')
                    .toDate()
                    .toString();
                public endDate: string = '';
            })();
        }

        function policyDateWithOffsetTime(date: string | Date, vehicleProductId: string): Date {
            let startDateMoment: moment.Moment = moment(date);
            const todayMoment: moment.Moment = btaBase.momentLocalized(backendDateTime.value);
            if (isDateToday(date)) {
                startDateMoment = todayMoment;
                const offset: {
                    offset: number;
                    unit: moment.unitOfTime.DurationConstructor;
                } = productOffset(vehicleProductId);
                startDateMoment.add(offset.offset, offset.unit);
            } else {
                startDateMoment.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
            }

            return startDateMoment.toDate();
        }

        function productOffset(product: string): {
            offset: number;
            unit: moment.unitOfTime.DurationConstructor;
        } {
            const useMinutesOffset: boolean = product.toUpperCase() === VehicleProduct.Mtpl && appCountry.isLV();
            const offset: number = useMinutesOffset
                ? policyStartingDateOffsetMinutes.value
                : policyStartingDateOffsetHours.value;
            const unit: moment.unitOfTime.DurationConstructor = useMinutesOffset ? 'minutes' : 'hours';

            return { offset: offset, unit: unit };
        }

        function isDateToday(date: string | Date): boolean {
            const startDateMoment: moment.Moment = moment(date);
            const todayMoment: moment.Moment = moment(backendDateTime.value);

            return startDateMoment.isSame(todayMoment, 'day');
        }

        function bothPaymentDatesAreEqual(): boolean {
            const mtplStartDate: moment.Moment = moment(mtplPolicyStartingDate.value.startDate);
            const mtplEndDate: moment.Moment = moment(mtplPolicyEndingDate.value.endDate);
            const cascoStartDate: moment.Moment = moment(cascoPolicyStartingDate.value.startDate);
            const cascoEndDate: moment.Moment = moment(cascoPolicyEndingDate.value.endDate);

            return mtplStartDate.isSame(cascoStartDate) && mtplEndDate.isSame(cascoEndDate);
        }

        function paymentDateColoredString(date: string): string {
            const time: moment.Moment = appCountry.isLT() ? moment(date) : btaBase.momentLocalized(date);
            const dateFormat: string = appCountry.isLT() ? auto.dateFormatLT : auto.dateFormat;

            return time.format(dateFormat);
        }

        function redHours(date: string): string {
            const time: moment.Moment = btaBase.momentLocalized(date);
            let result: string = '';
            if (time.hour() > 0 || time.minutes() > 0) {
                result = time.format('HH:mm');
            }

            return result;
        }

        function paymentDateNextPayment(date: string): string {
            const decade: number = 10;
            const time: moment.Moment = btaBase.momentLocalized(date).add(1, 'M').date(decade);

            return time.format(auto.dateFormat);
        }

        function policyEndDate(date: string | Date, product: string): DateRange {
            const period: string = stepStorageData.value ? stepStorageData.value.policyPeriod : '';
            let policyPeriod: number = parseInt(period, 10);
            if (product === VehicleProduct.Casco) {
                policyPeriod = parseInt(PolicyPeriods.OneYearPayment, 10);
            }
            const policyPeriodEndDate: moment.Moment = moment(date).subtract(-policyPeriod, 'months');
            const backendTimeNowWithCountryOffset: string = moment(backendDateTime.value)
                .add(policyStartingDateOffsetHours.value, 'hours')
                .format();
            let policyPeriodEndTime: moment.Moment = btaBase.momentLocalized(
                moment(policyPeriodEndDate).subtract(1, 'days').format(),
            );
            const policyStartDate: string = calendarToIsoString(transportPolicyStartDate(product));
            if (
                product === VehicleProduct.Mtpl &&
                appCountry.isLV() &&
                moment(moment(timeToChangeMtplPeriodEndDate.value, 'hh:mm:ss').format()).isBefore(
                    backendTimeNowWithCountryOffset,
                ) &&
                moment(policyStartDate).isSame(backendTimeNowWithCountryOffset, 'day')
            ) {
                policyPeriodEndTime = btaBase.momentLocalized(moment(policyPeriodEndDate).format());
            }

            return new (class implements DateRange {
                public startDate: string = '';
                public endDate: string = policyPeriodEndTime.toDate().toString();
            })();
        }

        function offerToLogin(): void {
            if (turnOnLogin.value) {
                if (!btaBase.user.isLogged() && !btaBase.user.isGuestOnly && !userHasSelectGuestMode()) {
                    PopupService.getInstance()
                        .onHide()
                        .subscribe((popupType: string): void => {
                            nextTick((): void => {
                                if (popupType === PopupType.Login) {
                                    if (userHasSelectGuestMode()) {
                                        checkProductEndDatesAndShowPopup();
                                    } else {
                                        PopupService.getInstance().show(new OnePopup().withType().loadingWait);
                                        useNavigate().navigate(stepsSubmitter.previousStep());
                                    }
                                }
                            });
                        });
                    PopupService.getInstance().show(new OnePopup().withType().login.withoutCloseButton());
                    nextTick((): void => {
                        guestButtonVisibility();
                    });
                }
            }
        }

        function applyUser(): void {
            const applyAuthUserData: boolean = btaBase.user.isLogged();
            const fieldValues: DynamicDictionary = {};
            const ownerPersonCode: string = stepStorageData.value!.ownerPersonCode || '';
            const ownerRegistrationCode: string = stepStorageData.value!.ownerRegistrationCode || '';
            if (applyAuthUserData) {
                const useCurrentUserData: boolean = isAuthUserSameAsOwner.value || appCountry.isLV();
                fieldValues.firstName = useCurrentUserData ? btaBase.user.current.firstname : '';
                fieldValues.lastName = useCurrentUserData ? btaBase.user.current.lastname : '';
                fieldValues.personCode = useCurrentUserData ? btaBase.user.current.personCode : ownerPersonCode;
                fieldValues.email = useCurrentUserData ? btaBase.user.current.email : '';
                fieldValues.address = useCurrentUserData
                    ? {
                          label: btaBase.user.current.address,
                          value: '',
                          additional: btaBase.user.current.addressExtra,
                      }
                    : '';
                fieldValues.phone = useCurrentUserData
                    ? {
                          phone: btaBase.user.current.phone,
                          country: btaBase.user.current.phoneCode,
                      }
                    : '';
            }
            if (ownerRegistrationCode) {
                fieldValues.businessTitle = btaBase.userStorage.storageData.attributes.legalPersonName || '';
                fieldValues.businessRegNumber = ownerRegistrationCode;
            }
            Object.keys(fieldValues).forEach((fieldName: string): void => {
                if (
                    (forms.form.field(fieldName).isEmpty() && !!fieldValues[fieldName]) ||
                    (!!fieldValues[fieldName] && forms.form.field(fieldName).value !== fieldValues[fieldName])
                ) {
                    forms.form.field(fieldName).patch(fieldValues[fieldName]);
                }
            });
            if (
                btaBase.cmsFields.address.enabled &&
                appCountry.isLT() &&
                applyAuthUserData &&
                isAuthUserSameAsOwner.value
            ) {
                forms.form.field('address').patch({
                    addressCode: btaBase.user.current.addressCode,
                    label: btaBase.user.current.address,
                    value: '',
                    additional: btaBase.user.current.addressExtra,
                });
                useSettlementField.value = false;
            }
        }

        function guestButtonVisibility(): void {
            if (guestButtonIsVisible()) {
                $('.continue-as-guest-button').removeClass(CssClass.Hidden);
            }
        }

        function guestButtonIsVisible(): boolean {
            let result: boolean = false;
            if (btaBase.settings.value('LOCALE_ISO') === 'EE') {
                const previousStep: DynamicDictionary = stepStorageData.value!;
                if (
                    previousStep.vehicleProduct === VehicleProduct.Mtpl &&
                    previousStep.paymentType !== PaymentType.Monthly
                ) {
                    result = true;
                }
            } else {
                result = true;
            }

            return result;
        }

        function userHasSelectGuestMode(): boolean {
            return guestButtonIsVisible() && useUrl().guestModeEnabled();
        }

        function applyPredefinedSettingsForLoggedUser(): void {
            let policyOwner: string = PolicyOwner.Personal;
            let resident: boolean = true;
            if (stepStorageData.value && stepStorageData.value.ownerRegistrationCode && appCountry.isLT()) {
                policyOwner = PolicyOwner.Business;
            }
            if (btaBase.user.isLogged()) {
                resident = btaBase.user.current.resident;
            }
            forms.form.field('policyOwner').patch(policyOwner);
            forms.form.field('resident').patch(resident);
            forms.form.field('resident').patch(true);
        }

        function assembleStep3(): any {
            const stepStorage: DynamicDictionary = btaBase.userStorage.stepStorageData;
            const product: string = stepStorageData.value!.vehicleProduct;
            const data: DynamicDictionary = {
                ...(stepStorage.referralCode ? { referralCode: stepStorage.referralCode } : {}),
            };
            const extractDataService: ExtractDataService = new ExtractDataService();
            if (isOwnerPersonal.value) {
                data.firstName = forms.form.field('firstName').value;
                data.lastName = forms.form.field('lastName').value;
                data.personCode = extractDataService.formatPersonCode(forms.form.field('personCode').value);
            } else {
                data.businessTitle = forms.form.field('businessTitle').value;
                data.companyType = forms.form.field('companyType').value.iso;
                data.businessRegNumber = forms.form.field('businessRegNumber').value;
            }
            const phoneValue: DynamicDictionary = forms.form.field('phone').value;
            data.fixedPhone = phoneValue.fixed;
            data.mobilePhone = phoneValue.mobile;
            if (data.fixedPhone === '' && data.mobilePhone === '') {
                data.mobilePhone = phoneValue.phone;
            }
            data.phoneCountryCode = phoneValue.country ? phoneValue.country : '';
            data.email = forms.form.field('email').value;
            if (forms.form.exists('country')) {
                data.country = forms.form.field('country').value;
            }
            if (isOwnerPersonal.value) {
                let birthDate: Date | undefined = new Date();
                switch (btaBase.user.isLogged()) {
                    case true:
                        birthDate = moment(btaBase.user.current.birthDate, 'DD.MM.YYYY').toDate();
                        break;
                    case false:
                        birthDate = btaBase.cmsFields.birthDate.enabled
                            ? moment(forms.form.field('birthDate').value).toDate()
                            : extractDataService.birthDateFromPersonCode(data.personCode);
                        break;
                    default:
                }
                data.birthDate = {
                    year: String(birthDate!.getFullYear()),
                    month: String(birthDate!.getMonth() + 1),
                    day: String(birthDate!.getDate()),
                };
            }
            if (forms.form.exists('address') && !forms.form.field('address').isEmpty()) {
                const address: DynamicDictionary = forms.form.field('address').value;
                if (address.value && address.value !== '') {
                    data.address = {
                        id: address.value,
                        addressCode: address.addressCode,
                        additional: address.apartment,
                        postCode: address.postCode,
                        detailed: address.detailed,
                        apartment: address.apartment,
                    };
                }
            }
            if (product === VehicleProduct.Mtpl) {
                data.mtplPolicyStartingDate = calendarToIsoString(transportPolicyStartDate(product));
            }
            if (product === VehicleProduct.Casco) {
                data.cascoPolicyStartingDate = calendarToIsoString(transportPolicyStartDate(product));
            }
            if (product === VehicleProduct.Bundle) {
                data.mtplPolicyStartingDate = calendarToIsoString(transportPolicyStartDate(VehicleProduct.Mtpl));
                data.cascoPolicyStartingDate = isDifferentDates.value
                    ? calendarToIsoString(transportPolicyStartDate(VehicleProduct.Casco))
                    : data.mtplPolicyStartingDate;
            }
            data.marketingConsents = marketingConsents();
            data.bankLinkId = bankLinkId.value;
            data.vehicleUsage = btaBase.userStorage.storageData.attributes.vehicleUsage;
            if (forms.form.exists('splitCascoPayments')) {
                data.paymentType = forms.form.field('splitCascoPayments').value;
            }
            if (forms.form.exists('securityDevicesTab')) {
                data.haveAllSecurityDevices = forms.form.field('securityDevicesTab').value ? 'true' : 'false';
            } else if (hasSecurityDevices.value) {
                data.haveAllSecurityDevices = 'true';
            }
            if (product === VehicleProduct.Bundle && isApprovalCaseNecessary.value) {
                data.isApprovalCaseNecessary = isApprovalCaseNecessary.value;
            }
            if (showEarlyTerminationField.value) {
                data.informAboutEarlyTermination = forms.form.field('earlyTermination').value;
            }

            return {
                data: {
                    uid: btaBase.userStorage.uUid,
                    json: JSON.stringify({ data }),
                    extra: null,
                    facility: Facility,
                    step: NextStep,
                    nextStepUrl: stepsSubmitter.nextStep(),
                    previousStepUrl: stepsSubmitter.previousStep(),
                    initialStepUrl: auto.initialStepUrl.value,
                    paymentFailRedirectUrl: paymentFailRedirectUrl(),
                },
            };
        }

        function paymentFailRedirectUrl(): string {
            return url.withQueryParam(
                url.guestParam(),
                url.queryParam(url.guestParam()) ?? 'false',
                SubmitterUrls.getInstance().currentStep(),
            );
        }

        function marketingConsents(): string[] {
            const selectedConsents: string = forms.form.field('marketingConsents').value.selected;

            return new Value(selectedConsents).isNotEmpty() ? selectedConsents.split(auto.ConsentsGlue) : [];
        }

        function submitStep3() {
            pushDataLayer();
            nextTick((): void => {
                doSubmit();
            });
        }

        function doSubmit(): void {
            if (!submitIsLocked.value) {
                submitIsLocked.value = true;
                nextTick((): void => {
                    btaBase.lockInput(true);
                    PopupService.getInstance().show(new OnePopup().withType().loadingWait);
                });
                const params: DynamicDictionary = assembleStep3();
                request
                    .post(Url.Ajax.autoSummary, params)
                    .then((value: AxiosResponse): void => {
                        if (validResponse(value) && value.data.data.status === 'OK') {
                            useNavigate().navigate(value.data.data.body.redirectUrl);
                        } else {
                            PopupService.getInstance().hide();
                            btaBase.lockInput(false);
                            btaBase.popup.applyCallbackModel('');
                            btaBase.error.show(ErrorType.Error, 'submitStep3', value);
                        }
                    })
                    .catch((reason: any): void => {
                        PopupService.getInstance().hide();
                        btaBase.lockInput(false);
                        btaBase.popup.applyCallbackModel('');
                        btaBase.error.show(ErrorType.Error, 'submitStep3', reason);
                    })
                    .finally(() => {
                        submitIsLocked.value = false;
                    });
            }
        }

        function additionalOptionsForVehicleProduct(): string {
            return String(btaBase.userStorage.stepStorageData.additionalOptions).split(',').join('|');
        }

        function initApplicationSteps(): void {
            const titleElement: JQuery<HTMLElement> = $('div.application-step-subtitle0').first();
            let resultText: string = '';
            if (titleElement) {
                resultText = applicationFirstStepDynamicTitle();
            }
            if (resultText.length > 0) {
                titleElement.text(resultText);
            }
        }

        function applicationFirstStepDynamicTitle(): string {
            const storage: any = btaBase.userStorage.storageData;
            const attributes = storage.attributes || {};
            let title: string = '';
            const make = attributes.vehicleMake;
            const modelModification = attributes.modelModification;
            const technicalCertificateNumber = attributes.technicalCertificateNumber;
            if (make) {
                title += make;
            }
            if (modelModification) {
                title += ' ' + modelModification;
            }
            if (technicalCertificateNumber) {
                title += ' / ' + technicalCertificateNumber;
            }

            return title;
        }

        function makeSecurityDevicesTabInputOptions(): void {
            securityDevicesTabInputOptions.value = btaBase.cmsFields.securityDevicesTab.items.map((option: any) => {
                return new InputOptionBuilder().setValue(option.value).setName(option.name).build();
            });
        }

        function makeCascoPolicyPeriodInputOptions(): void {
            const insuranceFilterType: string = vehicleProduct.value || VehicleProduct.Casco;
            const prices: any = autoStorage.storageDataPrices(insuranceFilterType);
            cascoPolicyPeriodInputOptions.value = [];
            if (prices && prices.monthlyPayment && prices.fixedPeriodPayment) {
                cascoPolicyPeriodInputOptions.value = [
                    new InputOptionBuilder().setValue(PaymentType.Fixed).setName('1').build(),
                    new InputOptionBuilder().setValue(PaymentType.Monthly).setName('12').build(),
                ];
            }
        }

        function changePaymentType(): void {
            const field: FormField = forms.form.field('splitCascoPayments');
            if (productIsCasco.value) {
                stepStorageData.value!.paymentType = field.value;
            }
        }

        function subscriptionNextPaymentDate(product: string): string {
            let nextPaymentDate: string = '';
            if (isSubscription.value) {
                const defaultPeriodToAdd: number = 1;
                const date: string = moment(transportPolicyStartDate(product)).toISOString();
                const policyStartDate: moment.Moment = btaBase.momentLocalized(date);
                const daysToSubtract: number =
                    product === VehicleProduct.Mtpl
                        ? mtplSubscriptionPaymentDateOffsetDays.value
                        : cascoSubscriptionPaymentDateOffsetDays.value;
                nextPaymentDate = policyStartDate
                    .add(defaultPeriodToAdd, 'M')
                    .subtract(daysToSubtract, 'days')
                    .format(auto.dateFormat)
                    .toString();
            }

            return nextPaymentDate;
        }

        function subscriptionProductPolicyPeriodLocalized(product: string): string {
            return translate(String(product).toLowerCase() + '_subscription_policy_period');
        }

        function subscriptionNextPaymentDateTitleLocalized(product: string): string {
            return translate('subscription_next_payment_' + String(product).toLowerCase());
        }

        function subscriptionProductLocalized(product: string): string {
            return translate('product_' + String(product).toLowerCase());
        }

        function securityDevicesTabChange(): void {
            if (forms.form.isInputLocked()) {
                Error.log(ErrorType.Warning, 'fetchSums', 'locked');
            } else {
                forms.form.lockInput();
                PopupService.getInstance().show(new OnePopup().withType().loadingWait);
                auto.fetchIsApprovalCaseNecessary(hasSecurityDevicesField.value).then((): void => {
                    forms.form.unlockInput();
                    if (!PopupService.getInstance().isErrorPopup) {
                        PopupService.getInstance().hide();
                    }
                });
            }
        }

        function pushDataLayer(): void {
            const dataLayerInstance: DataLayer = DataLayer.getInstance()
                .useCheckout()
                .addActionParam('step', CurrentStep)
                .addActionParam('option', DataLayerType.ProductTypeVehicle);
            let products: string[] = [vehicleProduct.value];
            if (productIsBundle.value) {
                products = [VehicleProduct.Mtpl, VehicleProduct.Casco];
            }
            products.forEach((product: string) => {
                const productPriceName: string =
                    String(product).toLowerCase() + (productIsBundle.value ? 'BundlePrice' : 'DiscountSum');
                const productPrice: string = previousStepSums.value[productPriceName] as string;
                dataLayerInstance
                    .newProduct()
                    .addProductParam('name', DataLayerType.AutoInsurance)
                    .addProductParam('id', DataLayerType.AutoProductFakeId)
                    .addProductParam('price', productPrice)
                    .addProductParam('category', DataLayerType.AutoInsurance)
                    .addProductParam('quantity', 1)
                    .addProductParam('variant', product)
                    .addProductParam('coupon', '')
                    .addProductParam(
                        'dimension2',
                        btaBase.userStorage.stepStorageData.policyPeriod + DataLayerType.AutoProductPolicyPeriodPostfix,
                    )
                    .addProductParam('dimension8', btaBase.userStorage.stepStorageData.territoryCode)
                    .addProductParam('dimension9', additionalOptionsForVehicleProduct())
                    .addProductParam('dimension10', dataLayerTransformedPaymentCount())
                    .addProductParam(
                        'dimension11',
                        productIsBundle.value ? DataLayerType.AutoProductBundle : DataLayerType.AutoProductStandalone,
                    );
            });
            dataLayerInstance.buildAndPush();
        }

        function dataLayerTransformedPaymentCount(): string {
            let result: string = '';
            if (isSubscription.value) {
                result = DataLayerType.AutoProductSubscription;
            } else {
                result =
                    btaBase.userStorage.stepStorageData.paymentType === 'M12'
                        ? DataLayerType.AutoProductMonthly
                        : DataLayerType.AutoProductOnce;
            }
            return result;
        }

        function onUserStorageReady(): void {
            makeSecurityDevicesTabInputOptions();
            setupForm();
            applyPolicyDates();
        }

        function onAppReady(): void {
            initApplicationSteps();
            offerToLogin();
            applyPredefinedSettingsForLoggedUser();
            generateTotalPayments();
            makeCascoPolicyPeriodInputOptions();
            withAdditionalRisks.value = stepStorageData.value!.additionalOptions !== NoAdditionalRisks.value;
            if (!btaBase.user.isLogged() && stepStorageData.value!.ownerPersonCode) {
                if (forms.form.field('personCode').isEmpty()) {
                    forms.form.field('personCode').patch(stepStorageData.value!.ownerPersonCode);
                }
            }
            applyUser();
            patchDefaultMarketingValues();
        }

        function patchDefaultMarketingValues(): void {
            forms.form.field('marketingConsents').patch({
                customText: {},
                selected: btaBase.user.current.marketingConsents.join(auto.ConsentsGlue),
            });
        }

        function checkProductEndDatesAndShowPopup(): void {
            const mtplIsSet: boolean = useDefine().isSet(monthlyPayments.value[VehicleProduct.Mtpl]);
            if (
                !specialInfoShown.value &&
                mtplIsSet &&
                moment(monthlyPayments.value[VehicleProduct.Mtpl].endDate).isAfter(specialInfoDate.value)
            ) {
                showSpecialInfoPopup();
            }
        }

        function showSpecialInfoPopup(): void {
            const popup: Popup = Popup.getInstance();
            popup
                .applyTextTitle(useTranslate().translate('auto_summary_special_info_title'))
                .applyTextDescription(useTranslate().translate('auto_summary_special_info_description'));
            if (popup.popupTypeIs(PopupType.None)) {
                specialInfoShown.value = true;
                const confirm: Info = new OnePopup().withType().info;
                PopupService.getInstance().show(confirm);
            }
        }

        function onDifferentDatesChange(isDifferent: boolean): void {
            if (btaBase.userStorage.isDataRestored) {
                if (isDifferent) {
                    forms.form.field('cascoPolicyStartingDateField').patch(cascoPolicyMinStartDate.value);
                }
                if (mtplAndCascoStartDatesAreEqual.value) {
                    forms.form
                        .field('cascoPolicyStartingDateField')
                        .patch(forms.form.field('mtplPolicyStartingDateField').value);
                }
                if (
                    !isDifferent &&
                    moment(mtplPolicyStartingDate.value.startDate).isBefore(
                        moment(bundlePolicyMinStartDate.value.startDate),
                    )
                ) {
                    forms.form.field('mtplPolicyStartingDateField').patch(bundlePolicyMinStartDate.value);
                }
                generatePaymentDates(vehicleProduct.value);
            }
        }

        return {
            ...btaBase,
            ...forms,
            ...auto,
            ...{
                url,
                CurrentStep,
                Facility,
                summaryTitle,
                hasSecurityDevicesField,
                mtplAndCascoStartDatesAreEqual,
                showMarketingConsents,
                isVisibleSecurityDevicesTab,
                productIsMtpl,
                securityDevices,
                isMobileDevice,
                hasSecurityDevices,
                storageIsReady,
                storageData,
                useSettlement,
                showEarlyTerminationField,
                emptyOption,
                earlyTerminationOptions,
                hasRequiredSecurityLevel,
                includeSecurityDevicesField,
                showPhotoNotification,
                insuranceProducts,
                isPaymentMethodsAvailable,
                isApprovalCaseNecessary,
                coveredInfoTextMtplWithRoadAssistance,
                vehicleProductLocalized,
                showAdditionalOptionsText,
                previousStepSums,
                stepStorageData,
                productIsBundle,
                isSubscription,
                productIsCasco,
                productIsCascoOrBundle,
                showFooterDiscountBlock,
                footerFullSumFormatted,
                footerSaveSumFormatted,
                isFixedPaymentType,
                isVisibleProductMtpl,
                isVisibleProductCasco,
                residentCheckBoxIsVisible,
                detailsBlockTotalPrice,
                detailsBlockSubscriptionPrices,
                detailsBlockCascoPriceByPeriod,
                detailsBlockTotalPriceLabel,
                subscriptionTermsTitle,
                showTotalPayment,
                isMonthlyPaymentDetails,
                detailsBlockSavings,
                detailsBlockSavingsIsVisible,
                isVisibleSeparateDatesCheckbox,
                isVisibleMtplCalendar,
                isVisibleCascoCalendar,
                isAuthUserSameAsOwner,
                isNameSurnameIsDisabled,
                isPersonCodeFieldDisabled,
                isBusinessTitleAndCompanyDisabled,
                isRegNumFieldDisabled,
                isOwnerPersonal,
                personTypeIsVisible,
                calendarInfoShouldBeShowed,
                isPhoneFieldMobileOnly,
                isResident,
                isDifferentDates,
                deductiblesAreVisible,
                insuranceOptionsAreVisible,
                insuranceOptionsValue,
                calendarProductName,
                agreeTermsText,
                backendTimeAsDate,
                useCard,
                footerSubmitText,
                mtplPolicyMinStartDate,
                mtplPolicyMinStartDateAsDate,
                cascoPolicyMinStartDate,
                cascoPolicyMinStartDateAsDate,
                bundlePolicyMinStartDate,
                mtplPolicyMaxStartDate,
                mtplPolicyMaxStartDateAsDate,
                cascoPolicyMaxStartDate,
                cascoPolicyMaxStartDateAsDate,
                bundlePolicyMaxStartDate,
                mtplPolicyStartingDate,
                cascoPolicyStartingDate,
                mtplPolicyEndingDate,
                cascoPolicyEndingDate,
                isTodayMtplPolicyStartingDate,
                isTodayCascoPolicyStartingDate,
                showBirthDate,
                policyStartingDateOffsetHours,
                policyStartingDateOffsetMinutes,
                canUseCardForCountry,
                coveredPopupIsVisible,
                canShowSpecialInfo,
                appCountry,
                monthlyPayments,
                detailsBlockMtplPrice,
                isMonthlyCascoPaymentType,
                detailsBlockCascoPrice,
                footerDiscountSumFormatted,
                informationText,
                assocArrayLength,
                onDifferentDatesChange,
                securityDevicesTabChange,
                setupForm,
                onAppReady,
                onUserStorageReady,
                checkProductEndDatesAndShowPopup,
                onAddressEdit,
                applySpecialInfoDate,
                applyBackendDateTime,
                applyLoginRequirement,
                applyShowAgreementPopupStatus,
                applySubscriptionMtplDaysToNextPayment,
                applySubscriptionCascoDaysToNextPayment,
                applyMtplSubscriptionPaymentDateOffsetDays,
                applyCascoSubscriptionPaymentDateOffsetDays,
                applyOnlineDiscount,
                applyInsuranceMaxMonthCount,
                propertyLabel,
                addressLabel,
                hintLabel,
                choosePayment,
                receiverCallback,
                onAddressLockChange,
                openerClasses,
                openerToggle,
                coveredProductTabButtonClick,
                additionalFooterCoveredButton,
                additionalFooterSubmit,
                updateContentID,
                formSubmit,
                validateBankLinkId,
                isVisibleInformationalText,
                translatedProduct,
                showAgreement,
                fetchAdditionalTerms,
                generateTotalPayments,
                applyPolicyDates,
                generatePaymentDates,
                addDatesForProduct,
                addDatesForBundle,
                calendarToIsoString,
                transportPolicyStartDate,
                policyMaxStartDate,
                policyDateWithOffsetTime,
                productOffset,
                isDateToday,
                bothPaymentDatesAreEqual,
                paymentDateColoredString,
                offerToLogin,
                submitStep3,
                changePaymentType,
                subscriptionNextPaymentDate,
                subscriptionProductPolicyPeriodLocalized,
                subscriptionNextPaymentDateTitleLocalized,
                subscriptionProductLocalized,
                skipValidation,
                isEnabledSplitCascoPayments,
            },
        };
    },

    mounted() {
        this.applyApp(this);
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);

        const onCountriesDataIsReady: Subscription = Countries.getInstance().onExternalDataIsReady.subscribe(
            (): void => {
                this.canShowSpecialInfo = this.appCountry.isLT();
                onCountriesDataIsReady.unsubscribe();
            },
        );
        const onStorageReadySubscription: Subscription = this.userStorage.onStorageDataIsReady.subscribe((): void => {
            this.onUserStorageReady();
            onStorageReadySubscription.unsubscribe();
        });
        const onAppIsPreparedAndReady: Subscription = this.onAppIsPreparedAndReady.subscribe((): void => {
            this.onAppReady();
            if (this.canShowSpecialInfo) {
                this.checkProductEndDatesAndShowPopup();
            }
            onAppIsPreparedAndReady.unsubscribe();
        });
    },
});
</script>
