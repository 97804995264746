import {
    Router,
    useRouter,
    RouteRecord,
    RouteLocationNormalized,
    NavigationGuardNext,
    RouteLocationNormalizedLoaded,
} from 'vue-router';
import ValidRoutes from '@/services/ValidRoutesService';
import ClaimTravel from '@/Apps/ClaimsTravel/Services/ClaimsTravelService';
import { useScroll } from '@/Composables/Scroll';
import ClaimsTravelService from '@/Apps/ClaimsTravel/Services/ClaimsTravelService';

export default class GuardsService {
    private static instance: GuardsService;
    private router: Router = useRouter();
    private initialStepUrl: string = '';

    public static getInstance(): GuardsService {
        if (!GuardsService.instance) {
            GuardsService.instance = new GuardsService();
            GuardsService.instance.prepareRoutes();
        }
        return GuardsService.instance;
    }

    public init(): void {
        this.router.beforeEach(
            (to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded, next: NavigationGuardNext): void => {
                const routeIndex: number = this.routeIndexByName(to.name as string);
                let hasInvalidRoutes: boolean = false;
                GuardsService.instance.router.getRoutes().forEach((route: RouteRecord, index: number): void => {
                    if (index < routeIndex && !ValidRoutes.getInstance().routeIsValid(index)) {
                        hasInvalidRoutes = true;
                    }
                });
                if (hasInvalidRoutes) {
                    next(false);
                } else {
                    next();
                }
            },
        );
        this.router.afterEach((to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded): void => {
            useScroll().scrollToTopInstantly();
        });
    }

    public applyStepValidity(step: number, isValid: boolean, clearAhead: boolean = false): void {
        for (let i: number = 0; i <= step; i++) {
            ValidRoutes.getInstance().applyRoute(i, isValid);
        }
        if (clearAhead) {
            GuardsService.instance.router.getRoutes().forEach((route: RouteRecord, index: number): void => {
                if (index > step) {
                    ValidRoutes.getInstance().applyRoute(index, false);
                }
            });
        }
    }

    public applyInitialStepUrl(initialStepUrl: string): void {
        this.initialStepUrl = initialStepUrl;
    }

    private prepareRoutes(): void {
        GuardsService.instance.router.getRoutes().forEach((value: RouteRecord, index: number): void => {
            ValidRoutes.getInstance().applyRoute(index, false);
        });
    }

    private routeIndexByName(routeName: string): number {
        let result: number = 0;
        GuardsService.instance.router.getRoutes().forEach((route: RouteRecord, index: number): void => {
            if (route.name === routeName) {
                result = index;
            }
        });

        return result;
    }
}
